import React, {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelectedOrganization } from '../store/organization';
import { useProject } from '../store/project';
import { useRecipesStore } from '../store/recipe';
import { useUIState } from '../store/ui';
import amplitudeLog from '../amplitude';
import { capitalize } from 'lodash';
import { usePrevious } from '../hooks/hooks';
import EditProductDialog from '../projects/EditProject/EditProductDialog';
import { useInitElementsSortFilter } from '../hooks/filter-elements.hook';

const ProductsView = lazy(() => import('./ProductsView'));
const ProjectDesignsView = lazy(() => import('./ProjectDesignsView'));
const Calculations = lazy(() => import('../calculations/Calculations'));

const EditProjectView: React.FC = () => {
  useInitElementsSortFilter();
  const navigate = useNavigate();

  const project = useProject();
  const { selectedPage } = useUIState('selectedPage');
  const selectedOrganization = useSelectedOrganization();

  const fetchRecipes = useRecipesStore(
    useCallback(({ fetchRecipes }) => fetchRecipes, []),
  );

  const [organizations, setOrganizations] = useState(
    project?.organizations ?? [],
  );

  const prevSelectedPage = usePrevious(selectedPage);

  useEffect(() => {
    const projOrgs = project?.organizations;
    const id = project?.id;
    if (projOrgs && id) {
      const hasNewOrganization = organizations?.some(
        (organization) => !projOrgs.includes(organization),
      );
      if (hasNewOrganization) {
        setOrganizations(projOrgs);
        fetchRecipes();
      }
    }
  }, [
    fetchRecipes,
    project?.id,
    project?.organizations,
    navigate,
    organizations,
    selectedOrganization,
  ]);

  const view = useMemo(() => {
    if (prevSelectedPage !== selectedPage) {
      amplitudeLog('Navigation To ' + capitalize(selectedPage));
    }
    switch (selectedPage) {
      case 'products':
        return (
          <Suspense fallback={null}>
            <ProductsView />
          </Suspense>
        );
      case 'elements':
        return (
          <Suspense fallback={null}>
            <ProjectDesignsView />
          </Suspense>
        );
      case 'calculations':
        return (
          <Suspense fallback={null}>
            <Calculations />
          </Suspense>
        );
      default:
        return null;
    }
  }, [prevSelectedPage, selectedPage]);

  return (
    <>
      {/* Main content */}
      {view}

      {/* Edit Product Dialog */}
      <EditProductDialog />
    </>
  );
};
export default EditProjectView;
