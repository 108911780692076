import React, { useCallback } from 'react';
import { Grid } from '@mui/material';
import { useCommentsStore } from '../../store/comment/comment.store';
import { IComment } from '../../../../shared/models/comment.interface';
import { v4 } from 'uuid';
import { IElementID } from '../../../../shared/models/project.interface';
import { getProject } from '../../store/project';
import CommentMessage from './CommentMessage';
import UserAvatar from '../UserAvatar';
import { NEW_COMMENT_ID_PREFIX } from '../../../../shared/constants';
import {
  gridItemLeftStyles,
  gridItemRightStyles,
} from '../SidePanel/Element/ElementComments';
import { useUser } from '../../hooks/user.hook';

const NewComment: React.FC<{ elementId: IElementID }> = ({ elementId }) => {
  const project = getProject();
  const user = useUser();
  const createComment = useCommentsStore(({ createComment }) => createComment);

  const componentId = NEW_COMMENT_ID_PREFIX + '-' + elementId;

  const create = useCallback(
    (message: string) => {
      if (!user) return;

      const comment: IComment = {
        id: v4(),
        project_id: project.id,
        element_id: elementId,
        owner: user.id,
        created_at: '',
        updated_at: '',
        message,
      };

      createComment(comment, componentId);
    },
    [createComment, elementId, componentId, project.id, user],
  );

  return (
    <Grid container pt={3} pb={3}>
      <Grid item {...gridItemLeftStyles}>
        <UserAvatar small mt={1.5} mr={1.5} />
      </Grid>
      <Grid item {...gridItemRightStyles}>
        <CommentMessage id={componentId} message="" onSave={create} />
      </Grid>
    </Grid>
  );
};

export default NewComment;
