import React, { memo, useCallback, useEffect, useMemo } from 'react';
import InlineTextField from '../../InlineTextField';
import { useIsSelected } from '../../../store/ui/ui.hook';
import { useUIState } from '../../../store/ui';
import { useIsReadonly } from '../../../hooks/user.hook';

import { useToggleElementExpanded } from '../../../hooks/expand-elements.hook';
import { isDeactivated } from '../../../../../shared/helpers/element_helpers';
import { Row } from '../Row';
import { RowCell } from '../RowCell';
import {
  ROOT_CELL_WIDTH,
  ROOT_CELL_WIDTH_RESPONSIVE,
  LIST_ITEM_HEIGHT,
  LIST_SPACING,
  CONTENT_CELL_WIDTH,
  CONTENT_CELL_WIDTH_RESPONSIVE,
} from '../list.constants';
import { useBooleanState } from '../../../hooks/hooks';
import {
  useIsKebabMenuOpen,
  useTriggerContextKebabMenu,
} from '../../kebab-menu/kebab-menu.hooks';
import { useListRowStyles } from '../list.style';
import { useMouseEventCallback } from '../../../hooks/events.hook';
import amplitudeLog from '../../../amplitude';
import { useFolderState } from '../../../store/folder';
import { useProjects } from '../../../store/project';
import { OutlinedTextFieldProps } from '@mui/material';
import ExpressionTypography from '../../ExpressionTypography';
import { IProjectFolder } from '../../../../../shared/models/folder.interface';
import ExpandIcon from '../ExpandIcon';
import { IProjectInfo } from '../../../../../shared/models/project.interface';
import ProjectFolderKebabMenu from '../KebabMenus/ProjectFolderKebabMenu';
interface IProjectFolderListItemInput {
  folder: IProjectFolder;
  indentation?: number;
  autoFocus?: boolean;
}

const ProjectFolderListItem: React.FC<IProjectFolderListItemInput> = ({
  folder,
  indentation = 0,
  autoFocus,
}) => {
  const { classes: listClasses } = useListRowStyles();
  const readonly = useIsReadonly();
  const { setAddedElementId, setSelectedProjectInfoOrFolderId } = useUIState(
    'setAddedElementId',
    'setSelectedProjectInfoOrFolderId',
  );
  const toggleElementExpanded = useToggleElementExpanded(folder);

  const [hover, startHover, endHover] = useBooleanState(false);
  const [isEditingName, startEditingName, stopEditingName] =
    useBooleanState(false);
  const triggerContextMenu = useTriggerContextKebabMenu(folder.id);
  const isKebabMenuOpen = useIsKebabMenuOpen(folder.id);
  const deactivated = isDeactivated(folder);
  const { name, id } = folder;

  const selected = useIsSelected(folder);
  const projectInfos = useProjects();

  const { folders, updateFolder } = useFolderState('folders', 'updateFolder');

  const projectFolderTotalGFA = useMemo(() => {
    const isInParent = (
      parentId: string,
      projectInfo: IProjectFolder | IProjectInfo,
    ) => {
      let inParent = false;
      let currentFolder = folders.find(
        ({ id }) => id === projectInfo.parent_id,
      );
      while (!inParent && currentFolder) {
        if (currentFolder?.id === parentId) {
          inParent = true;
        } else {
          currentFolder = folders.find(
            ({ id }) => id === currentFolder?.parent_id,
          );
        }
      }
      return inParent;
    };
    return projectInfos.reduce((acc, projectInfo) => {
      const inParent = isInParent(folder.id, projectInfo);
      return inParent ? acc + (projectInfo.gfa ?? 0) : acc;
    }, 0);
  }, [folder.id, folders, projectInfos]);

  const nameInputProps: Partial<OutlinedTextFieldProps> = useMemo(
    () => ({
      autoFocus,
      onFocus: (e) => {
        autoFocus && e.currentTarget.select();
        setAddedElementId();
      },
    }),
    [autoFocus, setAddedElementId],
  );

  const saveName = useCallback(
    async (name: string) => {
      stopEditingName();
      await updateFolder({ ...folder, name });

      amplitudeLog('Element Name Set', {
        Name: name,
        ElementID: id,
      });
    },
    [stopEditingName, updateFolder, folder, id],
  );

  const handleNameInputClick = useMouseEventCallback(() => {
    setSelectedProjectInfoOrFolderId(folder.id);
    startEditingName();
  });

  const handleClick = useMouseEventCallback(
    () => {
      setSelectedProjectInfoOrFolderId(selected ? undefined : folder.id);
    },
    { ignoreInputEvents: true, ignoreModalEvents: true },
  );

  // Start editing the name if autoFocus is set
  useEffect(() => {
    autoFocus && startEditingName();
  }, [autoFocus, startEditingName]);

  return (
    <Row
      classes={listClasses}
      deactivated={deactivated}
      height={LIST_ITEM_HEIGHT}
      hover={hover}
      onClick={handleClick}
      onContextMenu={triggerContextMenu}
      onDoubleClick={toggleElementExpanded}
      onMouseLeave={endHover}
      onMouseOver={startHover}
      padding={true}
      selected={selected}
      spacing={LIST_SPACING}
    >
      {/* CONTENT. Group content in a shared cell to make sure we can align bar charts as one */}
      <RowCell
        width={ROOT_CELL_WIDTH.CONTENT}
        responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.CONTENT}
      >
        <Row height={LIST_ITEM_HEIGHT} spacing={LIST_SPACING}>
          {/* Lock/unlock icon */}
          <RowCell width={ROOT_CELL_WIDTH.ICON}></RowCell>

          {/* Expand icon */}
          <RowCell
            indentation={indentation} // Put indentation on the first item in row
            width={ROOT_CELL_WIDTH.ICON}
          >
            <ExpandIcon element={folder} />
          </RowCell>

          {/* Name input */}
          <RowCell
            width={CONTENT_CELL_WIDTH_RESPONSIVE.NAME}
            responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.NAME}
          >
            <InlineTextField
              onClick={handleNameInputClick}
              value={name}
              variant="subtitle1"
              editing={isEditingName}
              onSave={saveName}
              onCancel={stopEditingName}
              textFieldProps={nameInputProps}
              disabled={readonly}
            />
          </RowCell>

          {/* Expression input */}
          <RowCell
            align="right"
            width={CONTENT_CELL_WIDTH_RESPONSIVE.EXPRESSION}
            responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.EXPRESSION}
          >
            <ExpressionTypography value={projectFolderTotalGFA} unit={'m²'} />
          </RowCell>
        </Row>
      </RowCell>

      {/* Owner menu */}
      <RowCell
        width={ROOT_CELL_WIDTH.BAR}
        responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.BAR}
        align="right"
      ></RowCell>

      {/* Kebab menu */}
      <RowCell width={CONTENT_CELL_WIDTH.ICON} hideOnPrint align="center">
        {(hover || isKebabMenuOpen) && (
          <ProjectFolderKebabMenu folder={folder} />
        )}
      </RowCell>
    </Row>
  );
};

export default memo(ProjectFolderListItem);
