import React, { memo } from 'react';
import { List, Collapse } from '@mui/material';
import { OneOfListElements } from '../../../../shared/models/project.interface';
import NestedElement from './NestedElement';
/**
 * Shared properties between NestedElementList and NestedElement
 */
export interface INestedListBase {
  indentation?: number;
  selectMode?: boolean;

  /**
   * Provide hook to get sorted and filtered children.
   * This function will be different for each element type.
   * If not provided, the default function will be used.
   */
  useFilterSortChildren?:
    | ((element?: OneOfListElements) => OneOfListElements[])
    // | ((element?: T) => OneOfChildElements<T>[])
    | undefined;
}

interface INestedElementListInput<T extends OneOfListElements>
  extends INestedListBase {
  elements: T[];
  expanded?: boolean;
}

const NestedElementList = <T extends OneOfListElements>({
  // parents,
  elements,
  expanded,
  indentation = 0,
  selectMode,
  useFilterSortChildren,
}: INestedElementListInput<T>) => {
  return (
    <Collapse in={expanded !== false} timeout="auto" unmountOnExit>
      <List sx={noPadding} disablePadding dense>
        {elements.map((element) => {
          return (
            <NestedElement
              element={element}
              indentation={indentation}
              key={element.id}
              selectMode={selectMode}
              useFilterSortChildren={useFilterSortChildren}
            />
          );
        })}
      </List>
    </Collapse>
  );
};

const noPadding = { paddingTop: '0 !important', paddingBottom: '0 !important' };

export default memo(NestedElementList);
