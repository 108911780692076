import { LabelOptions } from 'chartjs-plugin-datalabels/types/options';
import { getElementCategory } from '../../../shared/helpers/element_category_helpers';
import {
  BarChartData,
  BarChartDataset,
  BarChartDatasetOptions,
  BarChartTooltipContext,
  BarChartTooltipHorizontalPlacement,
  StackedBarChartData,
  RenderTooltipOptions,
} from '../../../shared/models/chart.interface';
import {
  formatThousands,
  toNumber,
} from '../../../shared/helpers/math_helpers';
import { DEFAULT_BAR_CHART_CO2_COLOR } from '../../../shared/constants';
import { inputStringToNumber } from '../../../shared/helpers/string_helpers';
import { FactorUnitLabels } from '../../../shared/models/unit.interface';
import { convertToThousandSEK } from '../../../shared/helpers/conversion_helpers';
import { BarChartTooltipContentStyle } from '../style/constants';

export const getDatasets = (
  data: BarChartData,
  { barPercentage, backgroundColor, datalabels }: BarChartDatasetOptions,
  horizontal?: boolean,
): BarChartDataset[] => {
  const singleDatasetOptions: BarChartDatasetOptions = {
    minBarLength: 1,
    datalabels,
    barPercentage,
    backgroundColor: backgroundColor || 'rgba(0, 0, 0, 0.8)',
  };

  return typeof data === 'number'
    ? [getDataset(data, singleDatasetOptions)]
    : getStackedDatasets(data, datalabels, horizontal);
};

const getStackedDatasets = (
  data: StackedBarChartData,
  labelOptions?: LabelOptions,
  horizontal?: boolean,
): BarChartDataset[] =>
  Object.entries(data).map(
    ([categoryId, { co2e_total, ['sek_A1-A3']: cost }], index) => {
      const category = getElementCategory(categoryId);
      const isLastItem = Object.entries(data).length === index + 1;

      const options: BarChartDatasetOptions = {
        /**
         * Include cost in label so the value will be available in the tooltip render function 
          @see externalTooltipHandler
         */
        label: `${category?.name}: ${cost ?? 0} `,
        backgroundColor: category?.color || DEFAULT_BAR_CHART_CO2_COLOR,
        barPercentage: 0.75,
        minBarLength: 1,
        datalabels: {
          ...labelOptions,
          display: !!labelOptions?.formatter && isLastItem,
        },
        borderColor: '#fff',
        borderWidth: { [horizontal ? 'right' : 'top']: isLastItem ? 0 : 1 },
      };

      return getDataset(co2e_total, options);
    },
  );

const getDataset = (
  data: number,
  other?: BarChartDatasetOptions,
): BarChartDataset => {
  return {
    ...other,
    data: [data],
  };
};

export const getFormattedValueByGFA = (value: number, gfa: number): string => {
  const valuePerGFA = gfa > 0 ? value / gfa : 0;
  return formatThousands(valuePerGFA);
};

export const externalTooltipHandler = (
  context: BarChartTooltipContext,
  gfa: number,
  { horizontalPlacement, offsetBottom }: RenderTooltipOptions = {},
): void => {
  const { chart, tooltip: nativeTooltip } = context;
  const customTooltip = chart.canvas.parentNode?.querySelector(
    '#custom-tooltip',
  ) as HTMLDivElement;

  if (!customTooltip) return;

  // Hide if no tooltip
  if (nativeTooltip.opacity === 0) {
    customTooltip.style.opacity = '0';
    return;
  }

  if (nativeTooltip.body) {
    const bodyLines = nativeTooltip.body.flatMap((b) => b.lines);

    const innerContainer = document.createElement('div');

    bodyLines.forEach((textContent) => {
      const parts = textContent.split(':');

      if (parts.length < 3) {
        return;
      }
      const categoryName = parts[0];
      const costStringValue = parts[1];
      const gwpStringValue = parts[2];

      const co2e = inputStringToNumber(gwpStringValue);
      const cost = convertToThousandSEK(toNumber(costStringValue));

      const top = document.createElement('p');
      const middle = document.createElement('div');
      const bottom = document.createElement('p');

      const co2eContainer = createCostContainer(co2e, gfa, 'co2e');
      const costContainer = createCostContainer(cost, gfa, 'cost');

      top.append(categoryName);
      middle.append(co2eContainer, costContainer);
      bottom.append(FactorUnitLabels.perGFA);

      innerContainer.append(top, middle, bottom);

      setTooltipContentStyle('whiteSpace', top, bottom, middle);
      setTooltipContentStyle('fontSize', top, middle);
      setTooltipContentStyle('fontWeight', top);
      setTooltipContentStyle('display', co2eContainer, costContainer);
      setTooltipContentStyle('justifyContent', co2eContainer, costContainer);
    });

    while (customTooltip.firstChild) {
      customTooltip.firstChild.remove();
    }
    customTooltip.append(innerContainer);
  }

  const [verticalPosition, horizontalPosition] = getTooltipPosition(
    chart.canvas,
    customTooltip,
    horizontalPlacement,
    offsetBottom,
  );

  setStyle('bottom', verticalPosition + 'px', customTooltip);
  setStyle(
    horizontalPlacement || 'left',
    horizontalPosition + 'px',
    customTooltip,
  );

  setStyle('opacity', '1', customTooltip);
  setStyle('color', String(nativeTooltip.options.bodyColor), customTooltip);
  setStyle(
    'padding',
    String(nativeTooltip.options.padding) + 'px',
    customTooltip,
  );
};

const createCostContainer = (
  cost: number,
  gfa: number,
  label: keyof typeof FactorUnitLabels,
): HTMLDivElement => {
  const container = document.createElement('div');
  const costValueContainer = document.createElement('span');

  costValueContainer.append(getFormattedValueByGFA(cost, gfa));
  container.append(costValueContainer, FactorUnitLabels[label]);

  setTooltipContentStyle('marginRight', costValueContainer);
  setTooltipContentStyle('fontWeight', costValueContainer);

  return container;
};

const getTooltipPosition = (
  canvas: HTMLCanvasElement,
  tooltip: HTMLDivElement,
  placement?: BarChartTooltipHorizontalPlacement,
  extraMargin = 1,
): number[] => {
  const margin = 2;

  const { width: canvasWidth, height: canvasHeight } =
    canvas.getBoundingClientRect();

  const { height: tooltipHeight } = tooltip.getBoundingClientRect();

  if (placement === 'left') {
    return [canvasHeight / 6, -canvasWidth / 2 + margin * 3];
  }
  if (placement === 'right') {
    return [canvasHeight / 6, -canvasWidth * 1.5 + margin * 3];
  }

  return [-tooltipHeight - margin * extraMargin, canvasWidth / 2 - margin];
};

const setTooltipContentStyle = (
  styleName: keyof typeof BarChartTooltipContentStyle,
  ...elements: HTMLElement[]
): void => {
  setStyle(styleName, BarChartTooltipContentStyle[styleName], ...elements);
};

const setStyle = (
  styleName: string,
  value: string,
  ...elements: HTMLElement[]
): void => {
  elements.forEach((el) => {
    (el.style as any)[styleName] = value;
  });
};
