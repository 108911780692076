import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import NestedElementList from '../ElementList/NestedElementList';
import { Box, Button } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from 'tss-react/mui';
import { FormattedMessage } from 'react-intl';
import amplitudeLog from '../../amplitude';
import { useCreateProject, useProjects } from '../../store/project';
import { useUIState } from '../../store/ui';
import { useFolderState, useProjectFolderTree } from '../../store/folder';
import { v4 } from 'uuid';
import { getSelectedOrganization } from '../../store/organization';
import { ElementKind } from '../../../../shared/models/project.interface';
import ImportProjectButton from '../../projects/EditProject/Settingspage/ImportProjectButton';
import { useProjectSelectorDrop } from '../../hooks/droppable.hook';
import { setElementExpanded } from '../../hooks/expand-elements.hook';
import { isProjectInfo } from '../../../../shared/helpers/project-folder.helpers';
import { getTimestamps } from '../../../../shared/helpers/date.helpers';
import { useUserId } from '../../hooks/user.hook';
import ProjectSelectorRow from './ProjectSelectorRow';
import { findFreeName } from '../../../../shared/helpers/string_helpers';

const scrollToTopOfProjectSelector = (
  containerRef: HTMLDivElement | null,
): void => {
  if (containerRef) {
    containerRef.scroll({ top: 0, behavior: 'smooth' });
  }
};

interface ClickAwayEventTarget {
  localName?: string;
  className?: string;
  dataset?: { keepProjectSelectorOpen?: boolean };
  parentElement?: ClickAwayEventTarget;
}

const ProjectSelector: FC = () => {
  const { classes } = useStyles();
  const user = useUserId();
  const { folders, fetchFolders, createFolder } = useFolderState(
    'folders',
    'fetchFolders',
    'createFolder',
  );

  const [shouldFilter, setShouldFilter] = useState(false);

  const {
    selectedProjectInfoOrFolderId,
    appHeaderOffset,
    setShowProjectSelector,
  } = useUIState(
    'selectedProjectInfoOrFolderId',
    'setShowProjectSelector',
    'appHeaderOffset',
  );
  const onProjectSelectorDrop = useProjectSelectorDrop();
  const createProject = useCreateProject();
  const projectInfos = useProjects();

  const divContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchFolders().catch(console.error);
  }, [fetchFolders]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setShowProjectSelector(false);
      }
    };
    window.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [setShowProjectSelector]);

  const tree = useProjectFolderTree(shouldFilter ? user : undefined);

  const disableFilter = useCallback(() => setShouldFilter(false), []);
  const enableFilter = useCallback(() => setShouldFilter(true), []);

  const handleNewFolderClick = useCallback(() => {
    const organization = getSelectedOrganization(true);

    const selectedProjectInfoOrFolder = [...folders, ...projectInfos].find(
      ({ id }) => id === selectedProjectInfoOrFolderId,
    );

    const items = [...folders, ...projectInfos].filter(
      ({ parent_id }) =>
        (!selectedProjectInfoOrFolder && parent_id === null) ||
        parent_id ===
          (isProjectInfo(selectedProjectInfoOrFolder)
            ? selectedProjectInfoOrFolder?.parent_id
            : selectedProjectInfoOrFolder?.id),
    );

    createFolder({
      id: v4(),
      kind: ElementKind.ProjectFolder,
      organizations: [organization],

      location: items.length + 1,

      name: findFreeName(
        folders.map(({ name }) => name),
        'Folder 1',
        ' ',
      ),

      parent_id:
        (isProjectInfo(selectedProjectInfoOrFolder)
          ? selectedProjectInfoOrFolder?.parent_id
          : selectedProjectInfoOrFolder?.id) ?? null,

      ...getTimestamps(),
    }).then(({ parent_id, id }) => {
      // to place at correct location
      onProjectSelectorDrop(
        id,
        selectedProjectInfoOrFolder?.id ?? null,
        isProjectInfo(selectedProjectInfoOrFolder) ? 'below' : 'inside',
      );

      if (parent_id) {
        setElementExpanded(parent_id, true, true);
      } else {
        scrollToTopOfProjectSelector(divContainerRef.current);
      }
    });
  }, [
    folders,
    projectInfos,
    createFolder,
    selectedProjectInfoOrFolderId,
    onProjectSelectorDrop,
  ]);

  const handleNewProjectClick = useCallback(() => {
    const selectedProjectInfoOrFolder = [...folders, ...projectInfos].find(
      ({ id }) => id === selectedProjectInfoOrFolderId,
    );

    const projectPartial = {
      owner: user,
      parent_id:
        (isProjectInfo(selectedProjectInfoOrFolder)
          ? selectedProjectInfoOrFolder?.parent_id
          : selectedProjectInfoOrFolder?.id) ?? null,
    };

    createProject({ projectPartial }).then(({ parent_id }) => {
      if (parent_id) {
        setElementExpanded(parent_id, true, true);
      } else {
        scrollToTopOfProjectSelector(divContainerRef.current);
      }
      amplitudeLog('New Project clicked (in Project Selector)');
    });
  }, [
    user,
    folders,
    projectInfos,
    createProject,
    selectedProjectInfoOrFolderId,
  ]);

  const onClickAway: (event: MouseEvent | TouchEvent) => void = useCallback(
    (e) => {
      let currentTarget = e.target as unknown as
        | ClickAwayEventTarget
        | undefined;
      let done = false;

      if (
        currentTarget?.dataset?.keepProjectSelectorOpen ||
        currentTarget?.localName === 'body' ||
        (typeof currentTarget?.parentElement?.className === 'string' &&
          currentTarget?.parentElement?.className?.includes('MuiDialog'))
      ) {
        return;
      }

      if (
        typeof currentTarget?.className === 'string' &&
        currentTarget?.className?.includes('adminOption')
      ) {
        setShowProjectSelector(false);
        return;
      }

      while (!done) {
        if (
          currentTarget?.dataset?.keepProjectSelectorOpen ||
          (currentTarget?.className === 'string' &&
            currentTarget?.className?.includes('MuiPopover'))
        ) {
          done = true;
        } else if (!currentTarget?.parentElement) {
          currentTarget = undefined;
          done = true;
        } else {
          currentTarget = currentTarget?.parentElement;
        }
      }

      if (done && !currentTarget) {
        setShowProjectSelector(false);
      }
    },
    [setShowProjectSelector],
  );

  const rowItems = useMemo(
    () => ({
      name: 'Name',
      date: 'Date',
      expression: 'Gross Floor Area',
      owner: 'Owner',
    }),
    [],
  );

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Box
        className={classes.container}
        ref={divContainerRef}
        sx={{ marginTop: appHeaderOffset + 'px' }}
      >
        <Box className={classes.header}>
          <Box
            display="flex"
            justifyContent="space-between"
            flex={1}
            padding={4}
          >
            <Box>
              <Button
                className={classes.filterButton}
                sx={{ marginRight: 4 }}
                variant={shouldFilter ? 'text' : 'outlined'}
                onClick={disableFilter}
              >
                All projects
              </Button>
              <Button
                className={classes.filterButton}
                variant={shouldFilter ? 'outlined' : 'text'}
                onClick={enableFilter}
              >
                My projects
              </Button>
            </Box>
            <Box className={classes.projectButtons}>
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={handleNewFolderClick}
                startIcon={<AddIcon />}
              >
                <FormattedMessage
                  id="projects_panel.new_folder"
                  defaultMessage="New folder"
                />
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={handleNewProjectClick}
                startIcon={<AddIcon />}
              >
                <FormattedMessage
                  id="projects_panel.new_project"
                  defaultMessage="New project"
                />
              </Button>
              <ImportProjectButton />
            </Box>
          </Box>

          <ProjectSelectorRow items={rowItems} />
        </Box>

        <Box className={classes.list}>
          <NestedElementList elements={tree} indentation={0} />
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

const useStyles = makeStyles()(({ spacing, palette }) => ({
  container: {
    position: 'absolute',
    inset: 0,
    height: '100vh',
    zIndex: 1000,
    background: '#fff',
    overflow: 'auto',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    backgroundColor: palette.background.paper,
    width: '99%',
    zIndex: 2,
  },
  projectButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: spacing(4),
  },
  filterButton: {
    padding: spacing(2),
  },
  list: {
    paddingTop: 133,
    paddingBottom: 76,
  },
}));

export default ProjectSelector;
