/**
 * Temporary is used to add elements that should not be saved to the server
 */
export const TEMPORARY_ID = 'temp_id';
export const COMMENT_MAX_LENGTH = 2000;
export const NEW_COMMENT_ID_PREFIX = 'new-comment';

export const NODON_PREFIX = `nodon_`;
export const NODON_SOURCE = `Nodon`;
export const OTHER_PRODUCT_ID = `${NODON_PREFIX}other_product`;
