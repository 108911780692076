import { ProjectMetadata, IStorey } from '../models/project.interface';
import { isDefined } from './array_helpers';
import { expressionVariablesConstants } from '../constants/expression_variables.constants';
import { highest, sum } from './math_helpers';
import { getKeys, omit } from './object_helpers';
import { SemiRequired } from '../models/type_helpers.interface';
import { getActivityExpressionVariables } from './activities.helpers';
import {
  getBuildingLengthEstimate,
  getBuildingVariables,
  getBuildingWidth,
} from './calculation_helpers';
import { CALCULATIONS } from '../../client/src/calculations/calculations.constants';
import {
  MainCategoryVariables,
  getBuildingGFA,
} from './expression_variables_helpers';

/**
 * Storey with all number properties defined
 */
type IRequiredStorey = SemiRequired<IStorey, 'name'>;

export type IRequiredStoreyWithElevation = IRequiredStorey & {
  elevation: number;
  isGroundFloor: boolean;
};

interface ICalculatedStoreyValues {
  gfa_above_ground: number;
  gfa_below_ground: number;
  total_height: number;
  total_height_above_ground: number;
  total_height_below_ground: number;
  external_gwa: number;
  external_gwa_above_ground: number;
  external_gwa_below_ground: number;
  gross_volume: number;
  gross_volume_above_ground: number;
  gross_volume_below_ground: number;
  elevation: number;
  gfa_cantilevered_floor_slabs: number;
  gfa_terrace_slabs: number;
  gfa_sky: number;
  gfa_activities: number;
  gfa_facades: number;
  gfa_installations: number;
  gfa_apartments: number;
  la_apartments: number;
  gfa_stairwells: number;
  apartment_count: number;
  stairwell_count: number;
  gwa_apartment_parting_internal_walls: number;
  gwa_stairwell_parting_walls: number;
  gwa_elevator_shaft_walls: number;
  gwa_partition_walls: number;
  gfa_balconies: number;
  gfa_stairs: number;
  gfa_floor_slabs: number;
  gfa_ground_floor_slabs: number;
  length_facade_pillars: number;
}

interface ICalculationVariables {
  is_ground_floor: number;
  perimeter: number;
  inner_height: number;
  gfa: number;
  stairwells_apartments_per_stairwell_per_storey: number;
  apartments_living_area_per_apartment: number;
  apartments_balcony_area_per_apartment: number;
  garage_gfa: number;
  gfa_other_activities: number;
  gfa_apartment_plus_stairwell: number;
  gfa_stairwell_per_gfa_apartment: number;
  gfa_openings_per_stairwell_per_storey: number;
  gfa_elevator_per_stairwell_per_storey: number;
  gfa_floor_per_stairwell_per_storey: number;
  gfa_per_stairwell_per_storey: number;
  gfa_stairwell_per_living_area: number;
  gfa_stairs_per_gfa_stairwell: number;
  gfa_floor_per_gfa_stairwell: number;
  facade_pillar_count: number;
}

export interface IBuildingProperties {
  gfa?: number;
  perimeter?: number;
  below_ground?: number;
}

export type IStoreySum = IRequiredStorey & ICalculatedStoreyValues;

export type IStoreyVariables = ICalculatedStoreyValues & ICalculationVariables;

export type StoreySelector = number | string | undefined;

export const emptyStorey: Readonly<IRequiredStorey> = {
  gfa: 0,
  perimeter: 0,
  inner_height: 0,
};

export const emptyStoreyValues: Readonly<IStoreySum> = {
  ...emptyStorey,
  gfa_above_ground: 0,
  gfa_below_ground: 0,
  total_height: 0,
  total_height_above_ground: 0,
  total_height_below_ground: 0,
  external_gwa: 0,
  external_gwa_above_ground: 0,
  external_gwa_below_ground: 0,
  gross_volume: 0,
  gross_volume_above_ground: 0,
  gross_volume_below_ground: 0,
  elevation: 0,
  gfa_cantilevered_floor_slabs: 0,
  gfa_terrace_slabs: 0,
  gfa_sky: 0,
  gfa_activities: 0,
  gfa_facades: 0,
  gfa_installations: 0,
  la_apartments: 0,
  gfa_apartments: 0,
  gfa_stairwells: 0,
  apartment_count: 0,
  stairwell_count: 0,
  gwa_apartment_parting_internal_walls: 0,
  gwa_stairwell_parting_walls: 0,
  gwa_elevator_shaft_walls: 0,
  gwa_partition_walls: 0,
  gfa_balconies: 0,
  gfa_stairs: 0,
  gfa_floor_slabs: 0,
  gfa_ground_floor_slabs: 0,
  length_facade_pillars: 0,
};

/**
 * Get a list of storeys that match the selectors.
 * If no selectors are provided, all storeys are returned.
 * @param storeys List of all storeys
 * @param selectors Either indexes (from 0) or names of storeys
 */
export const getStoreys = (
  storeys: IStorey[] = [],
  ...selectors: StoreySelector[]
): IStorey[] => {
  if (selectors.length === 0) {
    return [...storeys];
  }

  const names = selectors
    .filter((n): n is string => typeof n === 'string' && !!n)
    .map((n) => n.toLowerCase());
  const indexes = selectors.filter((n): n is number => typeof n === 'number');

  return storeys.filter((storey, index) => {
    const name = storey.name?.toLowerCase();
    return (name && names.includes(name)) || indexes.includes(index);
  });
};

const getGeoSettings = (
  meta: ProjectMetadata,
): { gfa: number; perimeter: number } => ({
  gfa: getBuildingGFA(meta),
  perimeter:
    meta.building_perimeter === undefined
      ? meta.building_footprint.perimeter
      : meta.building_perimeter,
});

const getGFAAndPerimeterSums = (
  storeys: IStorey[],
  geoSettings: { gfa: number; perimeter: number },
): { gfa: number; perimeter: number } =>
  storeys.reduce(
    (acc: { gfa: number; perimeter: number }, { gfa, perimeter }) => ({
      ...acc,
      gfa: acc.gfa + (gfa ?? 0),
      perimeter:
        perimeter && perimeter > acc.perimeter ? perimeter : acc.perimeter, // highest
    }),
    { gfa: 0, perimeter: geoSettings.perimeter },
  );

const getNumberOfUndefinedGFAAndPerimeterStoreys = (
  storeys: IStorey[],
): number =>
  storeys.reduce(
    (acc, { gfa, perimeter }) =>
      !isDefined(gfa) && !isDefined(perimeter) ? acc + 1 : acc,
    0,
  );

/**
 * Get storeys with fallbacks (also known as auto-values)
 * @param meta
 * @returns
 */
export const getStoreysWithFallbacks = (
  meta: ProjectMetadata,
): IRequiredStorey[] => {
  const { storeys = [] } = meta;

  // First go through all storeys that have perimeter or gfa (to know how much is left)
  const gfas: (number | undefined)[] = storeys.map((storey) => {
    if (isDefined(storey.gfa) || isDefined(storey.perimeter)) {
      return getGFAWithFallback(meta, storey);
    }
  });
  // Total GFA "taken up" by storeys
  const totalGFA = sum(gfas);

  const storeyCalculations = storeys.map((storey, index) => {
    const { name, inner_height } = storey;
    const gfa = gfas[index] ?? getGFAWithFallback(meta, storey, totalGFA);
    const perimeter = getPerimeterWithFallback(meta, storey, gfa);
    return {
      name,
      gfa,
      perimeter,
      inner_height: typeof inner_height === 'number' ? inner_height : 2.7,
    };
  });

  return limitStoreyFallbackGFA(meta, storeyCalculations);
};

/**
 * Prevent users from actively overflowing the building GFA (by entering too large perimeter values)
 * @param meta
 * @param storeyCalculations
 * @returns
 */
const limitStoreyFallbackGFA = (
  meta: ProjectMetadata,
  storeyCalculations: IRequiredStorey[],
): IRequiredStorey[] => {
  const { storeys = [], gfa_building } = meta;
  const newTotalGFA = sum(storeyCalculations, 'gfa');

  if (gfa_building && newTotalGFA > gfa_building) {
    const overflow = newTotalGFA - gfa_building;
    const storeysFromPerimeter = storeys.filter(
      ({ gfa, perimeter }) => !isDefined(gfa) && isDefined(perimeter),
    );

    if (storeysFromPerimeter.length === 0) {
      return storeyCalculations;
    }

    return storeyCalculations.map((calc, i) => {
      const storey = storeys[i];

      if (!isDefined(storey.gfa) && isDefined(storey.perimeter)) {
        return {
          ...calc,
          gfa: Math.max(0, calc.gfa - overflow / storeysFromPerimeter.length),
        };
      }
      return calc;
    });
  }

  return storeyCalculations;
};

const getGFAWithFallback = (
  meta: ProjectMetadata,
  storey: IStorey,
  totalGFA = 0,
): number => {
  const { gfa, perimeter } = storey;

  if (typeof gfa === 'number') {
    return gfa;
  }

  const { storeys = [], building_footprint } = meta;
  const geoSettings = getGeoSettings(meta);

  const numberOfUndefinedGFAStoreys =
    getNumberOfUndefinedGFAAndPerimeterStoreys(storeys);

  // User has defined perimeter
  if (typeof perimeter === 'number') {
    const footprintArea = building_footprint?.area;
    const width = getBuildingWidth(building_footprint?.coordinates);
    // Assume that all increase/decrease affects the length and not the width
    const lengthIncrease = (perimeter - building_footprint.perimeter) / 2;
    return Math.max(0, footprintArea + lengthIncrease * width);
  }

  // Should not happen since this storey.gfa must be undefined
  if (numberOfUndefinedGFAStoreys === 0) {
    return 0;
  }

  return Math.max(
    0,
    (geoSettings.gfa - totalGFA) / numberOfUndefinedGFAStoreys,
  );
};

const getPerimeterWithFallback = (
  meta: ProjectMetadata,
  storey: IStorey,
  storeyGFA: number,
): number => {
  // If perimeter is defined, use it
  if (typeof storey.perimeter === 'number') {
    return storey.perimeter;
  }

  const { storeys = [], building_footprint } = meta;
  const geoSettings = getGeoSettings(meta);
  const sums = getGFAAndPerimeterSums(storeys, geoSettings);
  const footprintArea = building_footprint?.area;
  const width = getBuildingWidth(building_footprint?.coordinates);
  const length = getBuildingLengthEstimate(footprintArea, width);
  const lengthIncrease = length * (storeyGFA / footprintArea - 1);

  // Get new perimeter based on storeyGFA by shortening/increasing the longest side
  return Math.max(0, sums.perimeter + lengthIncrease * 2);
};

/**
 * Get a calculated merge of a storeys or a set of stories from project metadata
 * @param meta
 * @param selectors List of either indices (from 0) or names of storeys
 * @returns
 */
export const getStoreySumExpressionVariables = (
  meta: ProjectMetadata,
  selectors?: StoreySelector[],
  mainCategoryVariables: MainCategoryVariables = {},
): { storeys: IStoreyVariables[]; sum: IStoreySum } => {
  const storeys = getStoreysWithFallbacks(meta);

  const storeysWithElevation = applyStoreyElevations(
    { below_ground: meta.below_ground ?? 0 },
    storeys,
  );

  const selectedStoreys = getStoreys(
    storeysWithElevation,
    ...(selectors ?? []),
  );

  return sumStoreys(
    meta,
    { gfa: 0, perimeter: 0, inner_height: 0 }, // dummy prop, never used since storeys const always has numbers
    mainCategoryVariables,
    ...selectedStoreys,
  );
};

/**
 * Sum all provided storeys.
 * @param selectedStoreys
 */
export const sumStoreys = (
  meta: ProjectMetadata,
  defaults: IRequiredStorey,
  mainCategoryVariables: MainCategoryVariables = {},
  ...selectedStoreys: (IRequiredStoreyWithElevation | IStorey)[]
): { storeys: IStoreyVariables[]; sum: IStoreySum } => {
  if (selectedStoreys.length === 0) {
    return { storeys: [], sum: { ...emptyStoreyValues } };
  }

  const results: {
    storeys: IStoreyVariables[];
    sum: IStoreySum;
  } = {
    storeys: [],
    sum: {
      ...emptyStoreyValues,
      elevation: getElevation(meta.below_ground ?? 0),
    },
  };

  // Keys for properties that can be summarized
  const sumKeys = getKeys(omit(results.sum, 'name'));

  const sumGFA = selectedStoreys.reduce(
    (acc, { gfa = defaults.gfa }) => acc + gfa,
    0,
  );

  selectedStoreys.forEach((storey, index) => {
    const is_ground_floor = 'isGroundFloor' in storey && storey.isGroundFloor;

    const {
      gfa = defaults.gfa,
      perimeter = defaults.perimeter,
      inner_height = defaults.inner_height,
      elevation = 0,
    } = storey as IRequiredStoreyWithElevation;

    const {
      total_height,
      total_height_above_ground,
      total_height_below_ground,
      gfa_above_ground,
      gfa_below_ground,
      external_gwa,
      external_gwa_above_ground,
      external_gwa_below_ground,
      gross_volume,
      gross_volume_above_ground,
      gross_volume_below_ground,
      gfa_ground_floor_slabs,
      gfa_cantilevered_floor_slabs,
      gfa_terrace_slabs,
      gfa_sky,
      gfa_activities,
    } = getBuildingVariables(
      selectedStoreys as IRequiredStoreyWithElevation[],
      {
        gfa,
        perimeter,
        inner_height,
        elevation,
      } as IRequiredStoreyWithElevation,
      index,
      meta,
      mainCategoryVariables,
    );

    const {
      stairwells_apartments_per_stairwell_per_storey = 0, // returns same for every storey
      apartments_living_area_per_apartment = 0, // returns same for every storey
      garage_gfa = 0, // returns value divided per storey
      gfa_other_activities = 0, // returns value divided per storey
    } = getActivityExpressionVariables(
      {
        gfa,
        sumGFA,
      },
      meta.activities,
    );

    const gfa_openings_per_stairwell_per_storey =
      CALCULATIONS.gfa_openings_per_stairwell_per_storey.calculate();

    const gfa_elevator_per_stairwell_per_storey =
      CALCULATIONS.gfa_elevator_per_stairwell_per_storey.calculate();

    const gfa_floor_per_stairwell_per_storey =
      CALCULATIONS.gfa_floor_per_stairwell_per_storey.calculate({
        stairwells_apartments_per_stairwell_per_storey,
      });

    const gfa_per_stairwell_per_storey =
      CALCULATIONS.gfa_per_stairwell_per_storey.calculate({
        gfa_openings_per_stairwell_per_storey,
        gfa_elevator_per_stairwell_per_storey,
        gfa_floor_per_stairwell_per_storey,
      });

    const gfa_stairwell_per_living_area =
      CALCULATIONS.gfa_stairwell_per_living_area.calculate({
        stairwells_apartments_per_stairwell_per_storey,
        apartments_living_area_per_apartment,
        gfa_per_stairwell_per_storey,
      });

    const gfa_stairs_per_gfa_stairwell =
      CALCULATIONS.gfa_stairs_per_gfa_stairwell.calculate({
        gfa_per_stairwell_per_storey,
      });

    const gfa_floor_per_gfa_stairwell =
      CALCULATIONS.gfa_floor_per_gfa_stairwell.calculate({
        gfa_floor_per_stairwell_per_storey,
        gfa_per_stairwell_per_storey,
      });

    const gfa_facades = CALCULATIONS.gfa_facades.calculate({ perimeter });
    const gfa_installations = CALCULATIONS.gfa_installations.calculate({ gfa });

    const gfa_apartment_plus_stairwell =
      CALCULATIONS.gfa_apartment_plus_stairwell.calculate({
        gfa_activities,
        gfa_facades,
        gfa_installations,
        garage_gfa,
        gfa_other_activities,
      });

    const gfa_stairwell_per_gfa_apartment =
      CALCULATIONS.gfa_stairwell_per_gfa_apartment.calculate({
        gfa_stairwell_per_living_area,
      });

    const gfa_apartments = CALCULATIONS.gfa_apartments.calculate({
      gfa_apartment_plus_stairwell,
      gfa_stairwell_per_gfa_apartment,
    });

    const la_apartments = CALCULATIONS.la_apartments.calculate({
      gfa_apartments,
    });

    const gfa_stairwells = CALCULATIONS.gfa_stairwells.calculate({
      gfa_apartment_plus_stairwell,
      gfa_apartments,
    });
    const apartment_count = CALCULATIONS.apartment_count.calculate({
      apartments_living_area_per_apartment,
      la_apartments,
    });
    const stairwell_count = CALCULATIONS.stairwell_count.calculate({
      apartment_count,
      stairwells_apartments_per_stairwell_per_storey,
    });

    const gwa_apartment_parting_internal_walls =
      CALCULATIONS.gwa_apartment_parting_internal_walls.calculate({
        apartments_living_area_per_apartment,
        apartment_count,
        inner_height,
      });
    const gwa_stairwell_parting_walls =
      CALCULATIONS.gwa_stairwell_parting_walls.calculate({
        gfa_stairwells,
        inner_height,
      });
    const gwa_elevator_shaft_walls =
      CALCULATIONS.gwa_elevator_shaft_walls.calculate({
        stairwell_count,
        inner_height,
      });
    const gwa_partition_walls = CALCULATIONS.gwa_partition_walls.calculate({
      la_apartments,
      inner_height,
    });

    const {
      apartments_balcony_area_per_apartment, // checks for undefined/null below
    } = getActivityExpressionVariables(
      {
        gfa,
        sumGFA,
      },
      meta.activities,
    );

    const gfa_balconies = CALCULATIONS.gfa_balconies.calculate({
      apartments_balcony_area_per_apartment,
      apartment_count,
    });
    const gfa_stairs = CALCULATIONS.gfa_stairs.calculate({
      gfa_stairs_per_gfa_stairwell,
      gfa_stairwells,
    });
    const gfa_floor_slabs = CALCULATIONS.gfa_floor_slabs.calculate({
      is_ground_floor: Number(is_ground_floor),
      gfa_stairwells,
      gfa_floor_per_gfa_stairwell,
      gfa_installations,
      garage_gfa,
      gfa_other_activities,
      gfa_apartments,
    });
    const facade_pillar_count = CALCULATIONS.facade_pillar_count.calculate({
      perimeter,
      apartment_count,
    });
    const length_facade_pillars = CALCULATIONS.length_facade_pillars.calculate({
      facade_pillar_count,
      inner_height,
    });

    const enrichedStorey: IStoreySum = {
      perimeter,
      inner_height,
      gfa,
      gfa_above_ground,
      gfa_below_ground,
      total_height,
      total_height_above_ground,
      total_height_below_ground,
      external_gwa,
      external_gwa_above_ground,
      external_gwa_below_ground,
      gross_volume,
      gross_volume_above_ground,
      gross_volume_below_ground,
      elevation: Math.min(elevation, results.sum.elevation),
      gfa_cantilevered_floor_slabs,
      gfa_terrace_slabs,
      gfa_sky,
      gfa_activities,
      gfa_facades,
      gfa_installations,
      la_apartments,
      gfa_apartments,
      gfa_stairwells,
      apartment_count,
      stairwell_count,
      gwa_apartment_parting_internal_walls,
      gwa_stairwell_parting_walls,
      gwa_elevator_shaft_walls,
      gwa_partition_walls,
      gfa_balconies,
      gfa_stairs,
      gfa_floor_slabs,
      gfa_ground_floor_slabs,
      length_facade_pillars,
    };

    results.storeys.push({
      is_ground_floor: Number(is_ground_floor),
      perimeter,
      inner_height,
      gfa,
      gfa_above_ground,
      gfa_below_ground,
      total_height,
      total_height_above_ground,
      total_height_below_ground,
      external_gwa,
      external_gwa_above_ground,
      external_gwa_below_ground,
      gross_volume,
      gross_volume_above_ground,
      gross_volume_below_ground,
      elevation,
      gfa_cantilevered_floor_slabs,
      gfa_terrace_slabs,
      gfa_sky,
      gfa_activities,
      stairwells_apartments_per_stairwell_per_storey,
      apartments_living_area_per_apartment,
      apartments_balcony_area_per_apartment,
      garage_gfa,
      gfa_other_activities,
      gfa_facades,
      gfa_installations,
      gfa_apartment_plus_stairwell,
      gfa_stairwell_per_gfa_apartment,
      gfa_apartments,
      la_apartments,
      gfa_stairwells,
      apartment_count,
      stairwell_count,
      gwa_apartment_parting_internal_walls,
      gwa_stairwell_parting_walls,
      gwa_elevator_shaft_walls,
      gwa_partition_walls,
      gfa_balconies,
      gfa_stairs,
      gfa_floor_slabs,
      gfa_ground_floor_slabs,
      length_facade_pillars,
      gfa_openings_per_stairwell_per_storey,
      gfa_elevator_per_stairwell_per_storey,
      gfa_floor_per_stairwell_per_storey,
      gfa_per_stairwell_per_storey,
      gfa_stairwell_per_living_area,
      gfa_stairs_per_gfa_stairwell,
      gfa_floor_per_gfa_stairwell,
      facade_pillar_count,
    });

    // Add values to sum
    sumKeys.forEach((key) => {
      const value = enrichedStorey[key];
      if (typeof value === 'number') {
        results.sum[key] += value;
      }
    });
  });

  // Static (not summed) values
  results.sum.perimeter = highest(selectedStoreys, 'perimeter');

  // Add names of all storeys
  results.sum.name = joinNames(selectedStoreys);

  return results;
};

/**
 * Get how many overrides there is for a certain property.
 * Useful when calculating average values.
 * @param storeys
 * @param key
 * @returns
 */
export const getStoreyOverrideCount = (
  storeys: IStorey[],
  key: keyof IStorey,
): number =>
  storeys.reduce((acc, val) => {
    return acc + (isDefined(val[key]) ? 1 : 0);
  }, 0);

/**
 * Check if all storeys overrides a certain property.
 * @param storeys
 * @param key
 * @returns
 */
export const isStoreyPropertyFullyOverridden = (
  storeys: IStorey[],
  key: keyof IStorey,
): boolean => storeys.length === getStoreyOverrideCount(storeys, key);

export const applyStoreyElevations = (
  { below_ground }: IBuildingProperties,
  storeys: IRequiredStorey[] = [],
): IRequiredStoreyWithElevation[] => {
  const elevationStoreys: IRequiredStoreyWithElevation[] = [];
  const slab = expressionVariablesConstants.floor_slab_height;
  let elevation = getElevation(below_ground);
  storeys.forEach((storey, index) => {
    elevationStoreys.push({ ...storey, elevation, isGroundFloor: index === 0 });
    elevation += (storey.inner_height ?? 0) + slab;
  });
  return elevationStoreys;
};

/**
 * To not return -0 and break tests
 * @param below_ground
 * @returns
 */
const getElevation = (below_ground = 0): number => -below_ground || 0;

/**
 * Join names of storeys.
 * @param storeys
 * @returns
 */
const joinNames = (storeys: IStorey[]): string =>
  storeys.map((s, i) => s.name || `Storey ${i}`).join(', ');
