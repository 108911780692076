import React, { ReactNode, useEffect, useState, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FormattedMessage } from 'react-intl';
import { useDropzone, Accept } from 'react-dropzone';
import { Description } from '@mui/icons-material';

type FileType = '.txt' | '.csv' | '.xml' | '.xlsx' | '.json';
const availableMimeTypeMap: Readonly<Record<FileType, string>> = {
  '.txt': 'text/plain',
  '.csv': 'text/csv',
  '.xml': 'text/xml',
  '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.json': 'application/json',
};

interface ImportFilesDialogProps {
  children?: ReactNode;
  title: string;
  content: string;
  open: boolean;
  acceptedFormats?: FileType[];
  onImport: (files: File[]) => void;
  onClose: () => void;
}

const useStyles = makeStyles()(({ spacing }: Theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: spacing(30),
  },

  dropzone: {
    flex: 1,
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: spacing(4),
    textAlign: 'center',
    borderWidth: spacing(0.5),
    borderRadius: spacing(0.5),
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
}));

const ImportElementsDialog: React.FC<ImportFilesDialogProps> = ({
  children,
  title,
  content,
  open,
  acceptedFormats = ['.txt', '.csv', '.xml', '.xlsx'],
  onImport,
  onClose,
}) => {
  const { classes } = useStyles();

  const [files, setFiles] = useState<File[]>([]);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      multiple: true,
      accept: getAcceptMimeTypes(acceptedFormats),
    });

  const handleImport = useCallback(() => onImport(files), [onImport, files]);

  useEffect(() => {
    setFiles(acceptedFiles);
  }, [acceptedFiles]);

  useEffect(() => {
    if (open) {
      setFiles([]);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.content}>
        <div {...getRootProps({ className: classes.dropzone })}>
          <input {...getInputProps()} />
          <Typography variant="h6">
            {isDragActive ? 'Drop to import' : content}
          </Typography>
        </div>
        <List>
          {files.map((file, index) => (
            <ListItem key={'file-' + index}>
              <ListItemIcon>
                <Description />
              </ListItemIcon>
              <ListItemText primary={file.name} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        {children}

        <Button
          TouchRippleProps={{ style: { top: 'auto', bottom: 0, height: 4 } }}
          onClick={onClose}
        >
          <FormattedMessage
            id="import_files_dialog.cancel"
            defaultMessage="Cancel"
          />
        </Button>
        <Button
          variant="contained"
          TouchRippleProps={{ style: { top: 'auto', bottom: 0, height: 4 } }}
          onClick={handleImport}
          disabled={files.length === 0}
        >
          <FormattedMessage
            id="import_files_dialog.import"
            defaultMessage="Import"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const getAcceptMimeTypes = (acceptedFormats: FileType[]): Accept => {
  return acceptedFormats.reduce(
    (acc, format) => ({ ...acc, [availableMimeTypeMap[format]]: [] }),
    {} as Accept,
  );
};

export default ImportElementsDialog;
