import {
  CO2eConversionFactors,
  ConversionFactorQuantityRecord,
  ConversionFactors,
  emptyConversionFactors,
  energyUnits,
  ProductOrConversionFactors,
  QuantityUnit,
} from '../models/unit.interface';
import {
  FootprintCoordinate,
  IBuildingVersion,
  IElement,
  IElementID,
  IProductElement,
  OneOfElements,
  ProjectMetadata,
} from '../models/project.interface';
import {
  flattenElements,
  forEachElement,
  getAllProductElements,
  getChildElements,
  isElement,
  isProductElement,
} from './recursive_element_helpers';
import { Product } from '../models/product.interface';
import {
  applyCalculatedConversionFactors,
  convertConversionFactors,
} from './conversion_helpers';
import { getElementTotalCount } from './expression_solving_helpers';
import { clamp, cloneDeep, mapValues, sortBy } from 'lodash';
import { cacheFactory } from './function_helpers';
import { IRequiredStoreyWithElevation } from './storeys_helpers';
import { isOneOf } from './array_helpers';
import { CALCULATIONS } from '../../client/src/calculations/calculations.constants';
import { getMedianValue, roundToDecimals } from './math_helpers';
import { MainCategoryVariables } from './expression_variables_helpers';
import { ElementPropertyName } from '../models/element_property.interface';
import { inputStringToNumber } from './string_helpers';
import { isDeactivated } from './element_helpers';
import {
  createConversionFactors,
  getCO2eTotalFromConversionFactors,
  sumConversionFactors,
} from './conversion-factors.helpers';

const isProductConversionFactors = (
  product?: ProductOrConversionFactors,
): product is Pick<Product, 'conversion_factors'> =>
  !!product && 'conversion_factors' in product;

export const getConversionFactorsById = (
  record: ConversionFactorQuantityRecord,
  id?: string,
): CO2eConversionFactors => (id && record[id]) || { ...emptyConversionFactors };

/**
 * Get a specific quantity from a ConversionFactorsRecord
 * @param record
 * @param id
 * @param unit
 * @returns
 */
export const getQuantityFromConversionFactorsRecord = (
  record: ConversionFactorQuantityRecord,
  id: IElementID,
  unit: QuantityUnit,
): number => {
  const factors = record[id];
  return factors?.[unit] ?? 0;
};

/**
 * Get a conversion factor record with any undefined values set to 0
 * @param product
 * @returns
 */
export const getConversionFactors = (
  productOrConversionFactors?: ProductOrConversionFactors,
): CO2eConversionFactors => {
  const conversion_factors = isProductConversionFactors(
    productOrConversionFactors,
  )
    ? productOrConversionFactors.conversion_factors
    : productOrConversionFactors;
  return createConversionFactors(conversion_factors);
};

/**
 * Get the relative conversion factors for a product.
 * Need to be multiplied with how many units of the product is used.
 * @param version Version should not be used when when calc
 * @param product
 * @returns
 */
export const getProductConversionFactors = (
  product: Product | undefined,
  perUnit?: QuantityUnit,
): CO2eConversionFactors => {
  // TODO: This happens but should it be allowed?
  if (!product) {
    return { ...emptyConversionFactors };
  }

  let conversionFactors: CO2eConversionFactors = getConversionFactors(product);

  // In accordance with DEC-172, assign all energy products emissions to A5
  if (isOneOf(energyUnits, product.unit)) {
    conversionFactors = {
      ...conversionFactors,
      'co2e_A1-A3': 0,
      co2e_A4: 0,
      co2e_A5: getCO2eTotalFromConversionFactors(conversionFactors),
    };
  }

  // Apply kWh and mm/m2
  conversionFactors = applyCalculatedConversionFactors(conversionFactors);

  // Change the scale of conversion factors relative to another unit
  if (perUnit && perUnit !== product.unit) {
    conversionFactors = convertConversionFactors(conversionFactors, perUnit);
  }

  return conversionFactors;
};

/** Value used by SCB, calculating 40km on a smaller truck. */
const LOCAL_TRUCK_EMISSION = 0.0001125;
const LONG_WAY_TRUCK_EMISSION = 0.000075;
const LOCAL_TRANSPORT_KM = 40;
const SMALL_TRUCK_EMISSION = 40 * LOCAL_TRUCK_EMISSION;

/**
 * Get A4 in KM for a product
 * @param product
 * @returns
 */
export const getProductTransportValue = (
  productOrConversionFactors: ProductOrConversionFactors,
): number => {
  const { co2e_A4, kg } = getConversionFactors(productOrConversionFactors);

  // No transport value if we can't translate unit to kg
  return kg ? a4ToKm(co2e_A4, kg) : 0;
};

/**
 * Get a factor between 0 and 1 that represents the amount of waste generated by the product.
 * A5 = (A1toA3 + A4) * waste_factor
 * @param product
 * @returns
 */
export const getProductWasteFactor = (
  productOrConversionFactors: ProductOrConversionFactors,
): number => {
  const {
    'co2e_A1-A3': a1_a3,
    co2e_A4: a4,
    co2e_A5: a5,
  } = getConversionFactors(productOrConversionFactors);
  const denominator = a1_a3 + a4;

  // Avoid division by zero (resulting in NaN)
  return denominator !== 0 ? a5 / denominator : 0;
};

/**
 * Convert a user input value to a conversion factor value.
 * Handles the cases for A4 and A5 in which the user input km or a percentage.
 * @param inputValue
 * @param product
 * @param factor
 * @returns
 */
export const inputToConversionFactorValue = (
  inputValue: string | number | undefined | null,
  product: ProductOrConversionFactors,
  factor: QuantityUnit,
): number => {
  if (!inputValue) {
    return 0;
  }

  switch (factor) {
    case 'co2e_A4':
      return kmToA4(Number(inputValue), getConversionFactors(product).kg ?? 0);
    case 'co2e_A5':
      return getA5FromWasteFactorPercentage(product, inputValue);
    default:
      return inputStringToNumber(inputValue);
  }
};

/**
 * Get conversion factors from user input values.
 * co2e_A4 in km is converted to a kgCO2e/[unit] value
 * co2e_A5 in a percentage is converted to a a kgCO2e/[unit] value
 * @param conversion_factors
 * @returns
 */
export const fromTransportWasteConversionFactors = (
  conversion_factors: ConversionFactors,
): ConversionFactors => {
  const co2e_A4 =
    conversion_factors.co2e_A4 !== undefined
      ? inputToConversionFactorValue(
          conversion_factors.co2e_A4,
          conversion_factors,
          'co2e_A4',
        )
      : undefined;

  // A4 must be set before A5 since A5 depends on "transport"
  const co2e_A5 =
    conversion_factors.co2e_A5 !== undefined
      ? inputToConversionFactorValue(
          conversion_factors.co2e_A5,
          { ...conversion_factors, co2e_A4 },
          'co2e_A5',
        )
      : undefined;

  const factors = { ...conversion_factors, co2e_A4, co2e_A5 };

  return { ...factors, co2e_total: getCO2eTotalFromConversionFactors(factors) };
};

/**
 * Get conversion factors where A4 and A5 are converted to user input values.
 * co2e_A4 is converted to km (transport)
 * co2e_A5 is converted to a percentage (waste)
 * @param conversion_factors
 * @returns
 */
export const toTransportWasteConversionFactors = (
  conversion_factors: ConversionFactors,
): ConversionFactors => {
  const co2e_A4 =
    conversion_factors.co2e_A4 !== undefined
      ? getProductConversionFactorInputValue(conversion_factors, 'co2e_A4')
      : undefined;

  const co2e_A5 =
    conversion_factors.co2e_A5 !== undefined
      ? getProductConversionFactorInputValue(conversion_factors, 'co2e_A5')
      : undefined;

  const factors = { ...conversion_factors, co2e_A4, co2e_A5 };

  return {
    ...factors,
    co2e_total: getCO2eTotalFromConversionFactors(conversion_factors),
  };
};

export const getConversionFactorUnit = (
  factor: QuantityUnit,
  product: Product,
): string => {
  switch (factor) {
    case 'co2e_A1-A3':
      return 'kgCO2e';
    case 'co2e_A4':
      return 'km';
    case 'co2e_A5':
      return '%';
    case 'sek_A1-A3':
      return `kr/${product.unit}`;
    default:
      return 'kg';
  }
};

/**
 *
 * @param product
 * @param wasteFactorInput A percentage between 0 and 100 OR 100 and 200;
 * @returns
 */
export const getA5FromWasteFactorPercentage = (
  productOrConversionFactors: ProductOrConversionFactors,
  wasteFactorInput: number | string,
): number => {
  let wasteFactor = inputStringToNumber(wasteFactorInput) / 100;

  // Boverket uses waste factor as 1.12 for 12% waste
  if (wasteFactor > 1) {
    wasteFactor -= 1;
  }

  const { 'co2e_A1-A3': a1_a3, co2e_A4: a4 } = getConversionFactors(
    productOrConversionFactors,
  );

  return (a1_a3 + a4) * clamp(wasteFactor, 0, 1);
};

/**
 * Get the editable value for a product. For transport (A4) it will be in km, for waste (A5) it will be a factor between 0 and 100%
 * @param product
 * @param factor
 */
export const getProductConversionFactorInputValue = (
  productOrConversionFactors: ProductOrConversionFactors,
  factor: keyof CO2eConversionFactors,
): number => {
  const conversionFactors = getConversionFactors(productOrConversionFactors);

  if (factor === 'co2e_A4') {
    return getProductTransportValue(conversionFactors);
  }
  if (factor === 'co2e_A5') {
    return roundToDecimals(getProductWasteFactor(conversionFactors) * 100);
  }
  return conversionFactors[factor] ?? 0;
};

/**
 * Get A4 values (in kg co2e / [unit])
 * Page 146: https://www.boverket.se/contentassets/5c704bbb2b2f4bd1a31beecf355efaa4/referensvarden-for-klimatpaverkan-vid-uppforande-av-byggnader_kth-2021.pdf
 */
export const kmToA4 = (km: number, kgPerUnit = 1): number => {
  return (
    (km < LOCAL_TRANSPORT_KM
      ? km * LOCAL_TRUCK_EMISSION
      : SMALL_TRUCK_EMISSION +
        (km - LOCAL_TRANSPORT_KM) * LONG_WAY_TRUCK_EMISSION) * kgPerUnit
  );
};

/** Returns the distance traveled for a given kgCO2e. */
export const a4ToKm = (a4: number, kgPerUnit = 1): number => {
  if (!a4 || !kgPerUnit) {
    return 0;
  }

  // A4 per other unit like m3
  const a4PerUnit = a4 / kgPerUnit;

  if (a4PerUnit > SMALL_TRUCK_EMISSION) {
    return Math.round(
      LOCAL_TRANSPORT_KM +
        (a4PerUnit - SMALL_TRUCK_EMISSION) / LONG_WAY_TRUCK_EMISSION,
    );
  }
  return Math.round(a4PerUnit / LOCAL_TRUCK_EMISSION);
};

/**
 * Get conversion factors, including global warming potential, for a ProductElement.
 * Note that this values are relative to how much the product is used (parentElement.count).
 * @param version
 * @param element
 * @param product Calculating a recipe will require the product to be passed in.
 * @returns
 */
const sumProductElementConversionFactors = (
  version: IBuildingVersion,
  element: IProductElement,
  product?: Product,
): CO2eConversionFactors => {
  // ProductElements should always have a product
  if (!product) {
    product = version.products[element.product_id];

    if (!product) {
      return { ...emptyConversionFactors };
    }
  }

  // Conversion factors for a single unit of a product
  const conversionFactors = getProductConversionFactors(product);

  // How many units of the productElement is used in total
  const productCount = getElementTotalCount(version, element);

  // How much of each QuantityUnit (kg, m³, co2e_A1-A3 etc) is used PER UNIT of the parent element
  const productElementFactors = convertConversionFactors(
    conversionFactors,
    element.unit,
    productCount,
  );

  return productElementFactors;
};

/**
 * Get latest conversion factors sums for each element as record.
 *  { [id]: sum, ... }
 *
 */
export const getVersionConversionFactorsSumRecord = (
  version: IBuildingVersion,
): ConversionFactorQuantityRecord => {
  // Cache this calculation by version id and version
  return cacheFactory(
    () => {
      const record: ConversionFactorQuantityRecord = {};

      // Step 1. Remove all deactivated elements from the calculation
      // TODO: Can't we just filter out deactivated elements?
      const versionClone = cloneDeep(version);
      flattenElements(versionClone).forEach((element) => {
        if ((element as IElement).isDeactivated) {
          (element as IElement).elements = [];
          (element as unknown as { count: undefined }).count = undefined;
        }
      });

      // Step 2. Calculate all productElements since elements are just sum of these
      getAllProductElements(versionClone).forEach((element) => {
        record[element.id] = getElementConversionFactorSum(
          versionClone,
          element,
        );
      });

      // Step 3. Sum all productElements in each element
      flattenElements(versionClone)
        .filter(
          (el): el is IElement | IBuildingVersion => !isProductElement(el),
        )
        .forEach((element) => {
          // An elements factor is the sum of all products in it
          const productFactors = getAllProductElements(element).map(
            (p) => record[p.id],
          );

          record[element.id] = sumConversionFactors(...productFactors);
        });
      return record;
    },
    `getVersionConversionFactorTotalsRecord[${version.id}]`,
    [version],
  );
};

/**
 * Get latest conversion factors totals for a specific element
 * IMPORTANT: Only use this if you need to recalculate the conversion factors for a specific element only.
 * All other cases use getVersionConversionFactorTotalsRecord above
 * @param version
 * @returns
 */
const sumElementConversionFactors = (
  version: IBuildingVersion,
  element: IElement,
): CO2eConversionFactors => {
  // We use this to calculate possible recipes so cache it by element id + recipe id
  return cacheFactory(
    () => {
      if (isDeactivated(element)) {
        return { ...emptyConversionFactors };
      }
      const factors: CO2eConversionFactors[] = [emptyConversionFactors];

      forEachElement(element, (el, path) => {
        if (isProductElement(el) && !path.some(isDeactivated)) {
          factors.push(sumProductElementConversionFactors(version, el));
        }
      });

      return sumConversionFactors(...factors);
    },
    `getVersionConversionFactorTotalsRecord[${element.id}, ${element.recipe_id}]`,
    [element],
    false, // TODO: Make sure caching is GOOOOD
  );
};

/**
 * Get summary of conversion factors for a specific element or product element.
 * @param record
 * @param element
 * @param product
 * @returns A record containing how much of each unit the elemenet contains (kg, m³, co2e_total etc)
 */
export const getElementConversionFactorSum = (
  version: IBuildingVersion,
  element: IElement | IProductElement, // TODO: Make this support versions
  product?: Product, // Needed for recipes?
): CO2eConversionFactors => {
  if (isElement(element)) {
    return sumElementConversionFactors(version, element);
  } else if (isProductElement(element)) {
    return sumProductElementConversionFactors(version, element, product);
  }
  return { ...emptyConversionFactors };
};

/**
 * Get summary of all conversion factors in an element or product element in a specific unit.
 * Return 0 if the unit is not found.
 * @param version
 * @param element
 * @param unit
 * @returns
 */
export const getElementSumInUnit = (
  version: IBuildingVersion,
  element: IElement,
  unit: QuantityUnit,
): number => getElementConversionFactorSum(version, element)[unit] ?? 0;

/**
 * Get mass of element.
 * Which is the sum of all productElement mass in the element.
 * @param version
 * @param element
 * @returns
 */
export const getElementMass = (version: IBuildingVersion, element: IElement) =>
  getElementSumInUnit(version, element, 'kg');

/**
 * Get total amount of CO2e of an element.
 * @param version
 * @param element
 * @returns
 */
export const getElementCO2eTotal = (
  version: IBuildingVersion,
  element: IElement,
) => getElementSumInUnit(version, element, 'co2e_total');

/**
 * Get volume of element.
 * Which is the sum of all productElement volumes in the element.
 * @param version
 * @param element
 * @returns
 */
export const getElementVolume = (
  version: IBuildingVersion,
  element: IElement,
) => getElementSumInUnit(version, element, 'm³');

export const getHighestConversionFactorOfChildren = (
  quantitiesRecord: ConversionFactorQuantityRecord,
  element: OneOfElements,
  factor: QuantityUnit = 'co2e_total',
): number => {
  const totals =
    element &&
    getChildElements(element).map(
      (el) => getConversionFactorsById(quantitiesRecord, el.id)[factor] ?? 0,
    );

  return totals?.length ? Math.max(...totals) : 0;
};

export const getCO2eTotalRecord = (
  factorRecord: ConversionFactorQuantityRecord,
  elementQuantity = 1,
): Record<IElementID, number> => {
  return mapValues(
    factorRecord,
    (factors) => factors.co2e_total * elementQuantity,
  );
};
interface BuildingVariables {
  total_height: number;
  total_height_above_ground: number;
  total_height_below_ground: number;
  gfa_above_ground: number;
  gfa_below_ground: number;
  external_gwa: number;
  external_gwa_above_ground: number;
  external_gwa_below_ground: number;
  gross_volume: number;
  gross_volume_above_ground: number;
  gross_volume_below_ground: number;
  gfa_ground_floor_slabs: number;
  gfa_cantilevered_floor_slabs: number;
  gfa_terrace_slabs: number;
  gfa_sky: number;
  gfa_activities: number;
}

/**
 * Calculate the width under a saddle roof where the room height is minHeight or more.
 * @param building_width
 * @param minHeight
 * @param roofPitch
 * @returns
 */
const getWidthOfMinimumRoomHeight = (
  building_width: number,
  innerHeight: number,
  roofPitch: number,
): number => {
  const minHeight = 1.9 - innerHeight;

  if (!roofPitch || minHeight <= 0) {
    return building_width;
  }

  // +60cm after the point where height is 1.9m height is counted as width
  const minWidth =
    0.6 * 2 +
    building_width -
    (2 * minHeight) / Math.tan((roofPitch * Math.PI) / 180);

  // width can't be larger than building width.
  return Math.min(building_width, minWidth);
};

/**
 * Get the building length based on the area and span
 * Note that this length is an estimate of length if we recalculate the shape to a rectangle.
 * @param footprintArea
 * @param buildingWidth
 */
export const getBuildingLengthEstimate = (
  footprintArea: number,
  buildingWidth: number,
): number => (buildingWidth ? footprintArea / buildingWidth : 0);

/**
 * Get available GFA for Activities on a Storey.
 * @param gfa_sky The of this storey that is not covered by a roof
 * @param gfa The total gfa of this storey
 * @param building_width
 * @param innerHeight Height to the point where the roof starts
 * @param roof_pitch Angle in degrees
 * @returns
 */
const getActivitiesGFA = (
  gfa_sky: number,
  gfa: number,
  building_width: number,
  innerHeight: number,
  roof_pitch = 20,
) => {
  const length = getBuildingLengthEstimate(gfa, building_width);
  const lengthWithRoof = length * (gfa_sky / gfa);
  const lengthWithoutRoof = length - lengthWithRoof;

  const areaWithoutRoof = lengthWithoutRoof * building_width;
  const areaWithRoof =
    lengthWithRoof *
    getWidthOfMinimumRoomHeight(building_width, innerHeight, roof_pitch);

  return roundToDecimals(areaWithoutRoof + areaWithRoof);
};

export const getBuildingVariables = (
  storeys: IRequiredStoreyWithElevation[],
  { gfa, perimeter, inner_height, elevation = 0 }: IRequiredStoreyWithElevation,
  index: number,
  meta: ProjectMetadata,
  mainCategoryVariables: MainCategoryVariables = {},
): BuildingVariables => {
  const total_height = CALCULATIONS.total_height.calculate({
    inner_height,
  });
  const total_height_above_ground =
    CALCULATIONS.total_height_above_ground.calculate({
      elevation,
      total_height,
    });
  const total_height_below_ground =
    CALCULATIONS.total_height_below_ground.calculate({
      total_height,
      total_height_above_ground,
    });
  const gfa_above_ground = CALCULATIONS.gfa_above_ground.calculate({
    total_height_below_ground,
    gfa,
  });
  const gfa_below_ground = CALCULATIONS.gfa_below_ground.calculate({
    total_height_below_ground,
    gfa,
  });
  const external_gwa = CALCULATIONS.external_gwa.calculate({
    perimeter,
    total_height,
  });
  const external_gwa_above_ground =
    CALCULATIONS.external_gwa_above_ground.calculate({
      perimeter,
      total_height_above_ground,
    });
  const external_gwa_below_ground =
    CALCULATIONS.external_gwa_below_ground.calculate({
      perimeter,
      total_height_below_ground,
    });
  const gross_volume = CALCULATIONS.gross_volume.calculate({
    gfa,
    total_height,
  });
  const gross_volume_above_ground =
    CALCULATIONS.gross_volume_above_ground.calculate({
      gfa,
      total_height_above_ground,
    });
  const gross_volume_below_ground =
    CALCULATIONS.gross_volume_below_ground.calculate({
      gfa,
      total_height_below_ground,
    });

  let extraBelow = gfa;
  let extraAbove = gfa;
  let gfa_ground_floor_slabs = CALCULATIONS.gfa_ground_floor_slabs.calculate();
  let gfa_cantilevered_floor_slabs =
    CALCULATIONS.gfa_cantilevered_floor_slabs.calculate();
  let gfa_terrace_slabs = CALCULATIONS.gfa_terrace_slabs.calculate();
  let gfa_sky = CALCULATIONS.gfa_sky.calculate();

  const prevStorey = storeys[index - 1];
  const nextStorey = storeys[index + 1];

  if (prevStorey?.gfa) {
    extraBelow = gfa - prevStorey.gfa;
  }
  if (nextStorey?.gfa) {
    extraAbove = gfa - nextStorey.gfa;
  }

  if (extraBelow > 0) {
    if (elevation <= 0) {
      gfa_ground_floor_slabs = extraBelow;
    } else {
      gfa_cantilevered_floor_slabs = extraBelow;
    }
  }
  if (extraAbove > 0) {
    if (elevation + inner_height <= 0) {
      gfa_terrace_slabs = extraAbove;
    } else {
      gfa_sky = extraAbove;
    }
  }

  const roof_pitch = mainCategoryVariables[ElementPropertyName.RoofPitch];
  const building_width = getBuildingWidth(meta.building_footprint.coordinates);
  const gfa_activities = getActivitiesGFA(
    gfa_sky,
    gfa,
    building_width,
    inner_height,
    typeof roof_pitch === 'number' ? roof_pitch : undefined,
  );

  return {
    total_height,
    total_height_above_ground,
    total_height_below_ground,
    gfa_above_ground,
    gfa_below_ground,
    external_gwa,
    external_gwa_above_ground,
    external_gwa_below_ground,
    gross_volume,
    gross_volume_above_ground,
    gross_volume_below_ground,
    gfa_ground_floor_slabs,
    gfa_cantilevered_floor_slabs,
    gfa_terrace_slabs,
    gfa_sky,
    gfa_activities,
  };
};

export type Point = [number, number];
export type Line = [Point, Point];

export const getPointOnLine = (line: Line, ratio: number): Point => {
  const [p1, p2] = line;
  const x1 = p1[0];
  const y1 = p1[1];
  const x2 = p2[0];
  const y2 = p2[1];

  const x = x1 + ratio * (x2 - x1);
  const y = y1 + ratio * (y2 - y1);

  return [x, y];
};

const createPerpendicularPoint = (
  p1: Point,
  p2: Point,
  distance = 1,
): Point => [
  p2[0] +
    (distance * (p1[1] - p2[1])) /
      Math.sqrt(Math.pow(p1[0] - p2[0], 2) + Math.pow(p1[1] - p2[1], 2)),
  p2[1] -
    (distance * (p1[0] - p2[0])) /
      Math.sqrt(Math.pow(p1[0] - p2[0], 2) + Math.pow(p1[1] - p2[1], 2)),
];

export const createPerpendicularLine = (
  line: Line,
  length = 1,
  anchorPosition = 1,
): Line => {
  let p1: Point;
  let p2: Point;
  // Use start point as anchor
  if (anchorPosition === 0) {
    [p1, p2] = reverseLine(line);
  } else {
    p1 = line[0];
    p2 = getPointOnLine(line, anchorPosition);
  }

  const perp1 = createPerpendicularPoint(p1, p2, length / 2);
  const perp2 = createPerpendicularPoint(p1, p2, -length / 2);
  return [perp1, perp2];
};

export const findIntervalIntersection = (
  interval1: [number, number],
  interval2: [number, number],
): [number, number] | undefined => {
  const [start1, end1] = interval1;
  const [start2, end2] = interval2;

  const start = Math.max(start1, start2);
  const end = Math.min(end1, end2);

  if (start <= end) {
    return [start, end];
  }
};

const getDistance = (p1: Point, p2: Point) => {
  const [x1, y1] = p1;
  const [x2, y2] = p2;

  return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
};

/**
 * Get the metric distance [x, y] from a coordinate  to a base coordinate
 * @param coordinate
 * @param base
 * @returns
 */
const convertCoordinateToMetricOffset = (
  coordinate: FootprintCoordinate,
  base: FootprintCoordinate,
): Point => {
  const { lat, lng } = coordinate;
  const distanceX = getCoordinateDistance(coordinate, { lat, lng: base.lng });
  const directionX = lng > base.lng ? 1 : -1;

  const distanceY = getCoordinateDistance(coordinate, { lat: base.lat, lng });
  const directionY = lat > base.lat ? 1 : -1;

  return [distanceX * directionX, distanceY * directionY];
};

function getCoordinateDistance(
  coordinate1: FootprintCoordinate,
  coordinate2?: Partial<FootprintCoordinate>,
) {
  // generally used geo measurement function
  const lat1 = coordinate1.lat;
  const lon1 = coordinate1.lng;
  const lat2 = coordinate2?.lat ?? 0;
  const lon2 = coordinate2?.lng ?? 0;
  const R = 6378.137; // Radius of earth in KM
  const dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
  const dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d * 1000; // meters
}

const isFootprintCoordinates = (
  coord: Point[] | FootprintCoordinate[],
): coord is FootprintCoordinate[] => 'lat' in coord[0];

const MEASURE_POINTS = 100;

const getMeasurePoints = (line: Line, totalLength: number): number[] => {
  const lineLength = getDistance(line[0], line[1]);
  const lineRatio = lineLength / totalLength;
  const numberOfMeasurePoints = Math.round(lineRatio * MEASURE_POINTS);
  const measurePoints = [];

  if (numberOfMeasurePoints > 1) {
    for (let i = 0; i < 1; i += 1 / numberOfMeasurePoints) {
      measurePoints.push(i);
    }
  } else {
    measurePoints.push(0);
  }
  measurePoints.push(1);
  return measurePoints;
};

export const getBuildingWidth = (
  pointsOrCoordinates: Point[] | FootprintCoordinate[] = [],
): number => {
  if (pointsOrCoordinates.length === 0) {
    return 0;
  }

  const isFootprint = isFootprintCoordinates(pointsOrCoordinates);
  const base = isFootprint ? pointsOrCoordinates[0] : { lat: 0, lng: 0 };
  const points: Point[] = isFootprint
    ? pointsOrCoordinates.map((p) => convertCoordinateToMetricOffset(p, base))
    : pointsOrCoordinates;

  return cacheFactory(
    () => {
      const spans: number[] = [];

      const lines = points.reduce((acc, point, index) => {
        const nextPoint = points[(index + 1) % points.length];
        acc.push([point, nextPoint]);
        return acc;
      }, [] as Line[]);

      const totalLength = lines.reduce(
        (acc, line) => acc + getDistance(line[0], line[1]),
        0,
      );

      lines.forEach((line1, index1) => {
        const measurePoints = getMeasurePoints(line1, totalLength);
        const perpendicularLines = measurePoints.map((ratio) =>
          createPerpendicularLine(line1, 100000, ratio),
        );

        lines.forEach((line2, index2) => {
          if (index1 === index2) {
            return;
          }

          const intersectingLines: Line[] = perpendicularLines
            .map((perpendicularLine) => [
              findIntersectionPoint(perpendicularLine, line1),
              findIntersectionPoint(perpendicularLine, line2),
            ])
            .filter((line): line is Line => !!line[0] && !!line[1]);

          const distances = intersectingLines
            .map(([p1, p2]) => getDistance(p1, p2))
            .filter((distance) => distance > 0.005); // Filter out values like 7.290154618149881e-12 and 0

          if (distances.length) {
            spans.push(...distances);
          }
        });
      });

      // remove extreme values
      const filteredSpans = filterExtremeValues(sortBy(spans));

      // Return a value slighly lower than the median value
      return roundToDecimals(getMedianValue(filteredSpans, 0.4));
    },
    'getBuildingSpan',
    points.flat(),
  );
};

export const getSmallestPerpendicularSize = (
  points: Point[],
): [number, number] => {
  const [p1, p2, p3, p4] = points;

  const width = Math.min(
    getDistance(p1, p2),
    getDistance(p2, p3),
    getDistance(p3, p4),
    getDistance(p4, p1),
  );

  const height = Math.min(
    getDistanceToLine(p1, p2, p4),
    getDistanceToLine(p2, p3, p1),
    getDistanceToLine(p3, p4, p2),
    getDistanceToLine(p4, p1, p3),
  );

  return [width, height];
};

const getDistanceToLine = (p1: Point, p2: Point, p3: Point): number => {
  const [x1, y1] = p1;
  const [x2, y2] = p2;
  const [x3, y3] = p3;
  const numerator = Math.abs(
    (y2 - y1) * x3 - (x2 - x1) * y3 + x2 * y1 - y2 * x1,
  );
  const denominator = getDistance(p1, p2);
  return numerator / denominator;
};

const filterExtremeValues = (numbers: number[]): number[] => {
  const mean =
    numbers.reduce((sum, number) => sum + number, 0) / numbers.length;
  const variance =
    numbers.reduce((sum, number) => sum + (number - mean) ** 2, 0) /
    numbers.length;
  const standardDeviation = Math.sqrt(variance);
  const lowerBound = mean - 2 * standardDeviation;
  const upperBound = mean + 2 * standardDeviation;
  return numbers.filter(
    (number) => number >= lowerBound && number <= upperBound,
  );
};

export const findIntersectionPoint = (
  line1: Line,
  line2: Line,
): Point | undefined => {
  const [p1, p2] = line1;
  const [p3, p4] = line2;

  const x1 = p1[0];
  const y1 = p1[1];
  const x2 = p2[0];
  const y2 = p2[1];
  const x3 = p3[0];
  const y3 = p3[1];
  const x4 = p4[0];
  const y4 = p4[1];

  const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
  if (denominator === 0) {
    // The lines are parallel
    return undefined;
  }

  const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
  const ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

  if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
    // The intersection point is outside the line segments
    return undefined;
  }

  const x = x1 + ua * (x2 - x1);
  const y = y1 + ua * (y2 - y1);

  return [x, y];
};

const reverseLine = (line: Line): Line => [line[1], line[0]];
