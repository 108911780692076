import { io, Socket } from 'socket.io-client';
import { useAuth0 } from '@auth0/auth0-react';
import * as uuid from 'uuid';
import { getProject, useProjectState } from '../store/project';
import {
  useCreateRecipeLocally,
  useDeleteRecipeLocally,
  useUpdateRecipeLocally,
} from '../store/recipe/recipe.hook';
import { useProductStoreState } from '../store/product';
import { useSelectedOrganization } from '../store/organization';
import { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import { Project } from '../../../shared/models/project.interface';
import { Recipe } from '../../../shared/models/recipe.interface';
import { Product } from '../../../shared/models/product.interface';
import { useConfig } from '../providers/ConfigProvider';

type SocketListenerUtils = () => [
  (socketListenersRegistered: MutableRefObject<boolean>) => Promise<void>,
  () => void,
];

export const useSocketListeners: SocketListenerUtils = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [config] = useConfig();
  const { updateProjectLocally } = useProjectState('updateProjectLocally');
  const createRecipeLocally = useCreateRecipeLocally();
  const updateRecipeLocally = useUpdateRecipeLocally();
  const deleteRecipeLocally = useDeleteRecipeLocally();
  const { createProductLocally, updateProductLocally, deleteProductLocally } =
    useProductStoreState(
      'createProductLocally',
      'updateProductLocally',
      'deleteProductLocally',
    );
  const selectedOrganization = useSelectedOrganization();
  const socketRef = useRef<Socket | undefined>();

  useEffect(() => window.sessionStorage.setItem('id', uuid.v4()), []);

  const handleProjectUpdate = useCallback(
    (sessionId: string) => (project: Project, initiatorSessionId: string) => {
      if (initiatorSessionId === sessionId) {
        return;
      }
      if (isEmpty(project)) {
        return;
      }
      updateProjectLocally(project);
    },
    [updateProjectLocally],
  );

  const handleProjectDelete = useCallback(
    (sessionId: string) => (id: string, initiatorSessionId: string) => {
      if (initiatorSessionId === sessionId || String(getProject().id) !== id) {
        return;
      }
      window.location.reload();
    },
    [],
  );

  const handleRecipeCreate = useCallback(
    (sessionId: string) => (item: Recipe, initiatorSessionId: string) => {
      if (initiatorSessionId === sessionId) {
        return;
      }
      createRecipeLocally(item);
    },
    [createRecipeLocally],
  );

  const handleRecipeUpdate = useCallback(
    (sessionId: string) => (recipe: Recipe, initiatorSessionId: string) => {
      if (initiatorSessionId === sessionId) {
        return;
      }
      updateRecipeLocally(recipe);
    },
    [updateRecipeLocally],
  );

  const handleRecipeDelete = useCallback(
    (sessionId: string) => (id: string, initiatorSessionId: string) => {
      if (initiatorSessionId === sessionId) {
        return;
      }
      deleteRecipeLocally(id);
    },
    [deleteRecipeLocally],
  );

  const handleProductCreate = useCallback(
    (sessionId: string) => (item: Product, initiatorSessionId: string) => {
      if (initiatorSessionId === sessionId) {
        return;
      }
      createProductLocally(item);
    },
    [createProductLocally],
  );

  const handleProductUpdate = useCallback(
    (sessionId: string) => (product: Product, initiatorSessionId: string) => {
      if (initiatorSessionId === sessionId) {
        return;
      }
      updateProductLocally(product);
    },
    [updateProductLocally],
  );

  const handleProductDelete = useCallback(
    (sessionId: string) => (id: string, initiatorSessionId: string) => {
      if (initiatorSessionId === sessionId) {
        return;
      }
      deleteProductLocally(id);
    },
    [deleteProductLocally],
  );

  const addSocketListeners = useCallback(
    async (socketListenersRegistered: MutableRefObject<boolean>) => {
      const token = await getAccessTokenSilently();
      const socket = io(config.baseUrl, {
        auth: {
          token: `Bearer ${token}`,
        },
        extraHeaders: {
          AppVersion: config.version,
        },
        query: {
          organization: selectedOrganization,
        },
        transports: ['websocket'],
      });
      socketRef.current = socket;
      const sessionId = window.sessionStorage.getItem('id') ?? '';

      socket.on('project_update', handleProjectUpdate(sessionId));
      socket.on('project_delete', handleProjectDelete(sessionId));
      socket.on('recipe_create', handleRecipeCreate(sessionId));
      socket.on('recipe_update', handleRecipeUpdate(sessionId));
      socket.on('recipe_delete', handleRecipeDelete(sessionId));
      socket.on('product_create', handleProductCreate(sessionId));
      socket.on('product_update', handleProductUpdate(sessionId));
      socket.on('product_delete', handleProductDelete(sessionId));

      socketListenersRegistered.current = true;
    },
    [
      config,
      selectedOrganization,
      socketRef,
      getAccessTokenSilently,
      handleProjectUpdate,
      handleProjectDelete,
      handleRecipeCreate,
      handleRecipeUpdate,
      handleRecipeDelete,
      handleProductCreate,
      handleProductUpdate,
      handleProductDelete,
    ],
  );

  const clearSocketListeners = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.removeAllListeners();
    }
  }, [socketRef]);

  return [addSocketListeners, clearSocketListeners];
};
