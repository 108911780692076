import React, { useMemo } from 'react';
import {
  IBuildingVersion,
  IElementID,
  OneOfElements,
} from '../../../shared/models/project.interface';
import {
  CO2eConversionFactors,
  ConversionFactorQuantityRecord,
  QuantityUnit,
  emptyConversionFactors,
} from '../../../shared/models/unit.interface';
import {
  getConversionFactorsById,
  getHighestConversionFactorOfChildren,
  getQuantityFromConversionFactorsRecord,
  getVersionConversionFactorsSumRecord,
} from '../../../shared/helpers/calculation_helpers';
import {
  flattenElements,
  getAllBuildingVersions,
} from '../../../shared/helpers/recursive_element_helpers';
import { useParentElement } from './useElement';
import { useProject } from '../store/project';
import { useSelectedVersion } from '../store/ui';
import { getElementTotalCount } from '../../../shared/helpers/expression_solving_helpers';

/**
 * Get latest conversion factors totals for each element.
 * TODO Can be optimized
 */
export function useProjectConversionFactorQuantitiesRecord(): ConversionFactorQuantityRecord {
  const project = useProject();
  const versions = getAllBuildingVersions(project);

  return React.useMemo(() => {
    const factors = versions.map((version) =>
      getConversionFactorRecordFromResults(version),
    );
    return factors.reduce((acc, curr) => ({ ...acc, ...curr }), {});
  }, [versions]);
}

/**
 * Use already calculated conversion factors from elements if they exist, else calculate them.
 * @param version
 * @returns
 */
const getConversionFactorRecordFromResults = (
  version: IBuildingVersion,
): ConversionFactorQuantityRecord => {
  if (version.results) {
    flattenElements(version).reduce((acc, el) => {
      if (!el.results) {
        throw new Error('Element results missing');
      }
      acc[el.id] = el.results;
      return acc;
    }, {} as ConversionFactorQuantityRecord);
  }
  return getVersionConversionFactorsSumRecord(version);
};

/**
 * Get latest conversion factors totals for each element for a specific version.
 * @param version The version to get the conversion factors for, if not provided, the selectedVersion is used.
 */
export function useVersionConversionFactorQuantitiesRecord(
  version?: IBuildingVersion,
): ConversionFactorQuantityRecord {
  const selectedVersion = useSelectedVersion();
  if (!version) {
    version = selectedVersion;
  }

  return React.useMemo(() => {
    if (!version) {
      return {};
    }
    return getConversionFactorRecordFromResults(version);
  }, [version]);
}

/**
 * Get conversion factors for a specific element.
 * @param id
 * @returns
 */
export const useElementConversionFactorQuantities = (
  id?: IElementID,
  version?: IBuildingVersion,
): CO2eConversionFactors => {
  const conversionFactors = useVersionConversionFactorQuantitiesRecord(version);

  if (!id) {
    return { ...emptyConversionFactors };
  }

  return getConversionFactorsById(conversionFactors, id);
};

export const useElementQuantityInUnit = (
  id: IElementID,
  unit: QuantityUnit,
): number => {
  return useElementConversionFactorQuantities(id)[unit] ?? 0;
};

/**
 * Get highest conversionFactor value of the children of an element.
 * @param elementId
 * @param factor Unit to count. Defaults to 'co2e_total'.
 * @returns
 */
export const useHighestConversionFactorOfChildren = (
  element: OneOfElements | undefined,
  factor: QuantityUnit = 'co2e_total',
): number => {
  const quantitiesRecord = useVersionConversionFactorQuantitiesRecord();
  const selectedVersion = useSelectedVersion();

  return useMemo(() => {
    if (!selectedVersion || !element) {
      return 0;
    }
    return getHighestConversionFactorOfChildren(
      quantitiesRecord,
      element,
      factor,
    );
  }, [element, factor, selectedVersion, quantitiesRecord]);
};

export const useHighestConversionFactorOfSiblings = (
  element: OneOfElements,
  factor: QuantityUnit = 'co2e_total',
): number => {
  const parent = useParentElement(element);
  return useHighestConversionFactorOfChildren(parent, factor);
};

export const useProjectVersionsMax = (
  unit: QuantityUnit = 'co2e_total',
): number => {
  const project = useProject();
  const projectsTotals = useProjectConversionFactorQuantitiesRecord();
  return useMemo(() => {
    const versionFactors = getAllBuildingVersions(project).map((version) =>
      getQuantityFromConversionFactorsRecord(projectsTotals, version.id, unit),
    );
    return Math.max(...versionFactors, 0);
  }, [project, projectsTotals, unit]);
};

export const useElementTotalCount = (element?: OneOfElements): number => {
  const selectedVersion = useSelectedVersion();
  return useMemo(
    () =>
      selectedVersion && element
        ? getElementTotalCount(selectedVersion, element)
        : 0,
    [element, selectedVersion],
  );
};
