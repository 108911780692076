import { elementCategories } from '.';
import { getItemById } from '../../helpers/array_helpers';
import {
  ElementCategoryID,
  IElementCategory,
} from '../../models/element_categories.interface';

/**
 * Get all available categories including those in use,
 * even if they are disabled.
 * @param usedIds
 * @returns
 */
export const getAvailableCategories = (
  ...usedIds: (ElementCategoryID | undefined)[]
): IElementCategory[] => {
  return elementCategories.filter(
    (cat) => !cat.disabled || usedIds.includes(cat.id),
  );
};

export const getElementCategoryById = (
  id: ElementCategoryID | string,
): Readonly<IElementCategory> =>
  getItemById(elementCategories, id as ElementCategoryID);

/**
 *
 * @param id
 * @returns
 */
export const createElementCategory = (
  id: ElementCategoryID | string,
): IElementCategory => ({ ...getElementCategoryById(id) });

/**
 * Get root parent to a specific Element Category
 * @param id
 * @returns
 */
export const getRootCategory = (
  id: ElementCategoryID,
): Readonly<IElementCategory> => {
  let category = getElementCategoryById(id);
  while (category.parent_id) {
    category = getElementCategoryById(category.parent_id);
  }
  return category;
};

/**
 * Get parent to a specific Element Category
 * @param id
 * @returns
 */
export const getParentCategory = (
  id: ElementCategoryID,
): Readonly<IElementCategory> | undefined => {
  const category = id ? getElementCategoryById(id) : undefined;
  if (category?.parent_id) {
    return getElementCategoryById(category.parent_id);
  }
};

/**
 * Check if a categoryId is a category or a child of that category.
 * Useful when you want to target all categories in a group.
 * Like all FramingOfJoists (bjälklag)
 * @param categoryId
 * @param parentId
 * @returns
 */
export const isCategoryOrChildOf = (
  categoryId: ElementCategoryID,
  parentId: ElementCategoryID,
): boolean => {
  if (categoryId === parentId) {
    return true;
  }
  let parent = getParentCategory(categoryId);
  while (parent) {
    if (parent.id === parentId) {
      return true;
    }
    parent = getParentCategory(parent.id);
  }
  return false;
};
