import React, { useCallback } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Tooltip,
  TypographyProps,
  SxProps,
} from '@mui/material';
import { Comment } from '@mui/icons-material';
import DateText from '../DateText';
import { useCommentsStore } from '../../store/comment/comment.store';
import { IComment } from '../../../../shared/models/comment.interface';
import { NodonTheme } from '../../style';

interface CommentsIconButtonProps {
  comments: IComment[];
}

const CommentsIconButton: React.FC<CommentsIconButtonProps> = ({
  comments,
}) => {
  const setScrollCommentsIntoView = useCommentsStore(
    ({ setScrollCommentsIntoView }) => setScrollCommentsIntoView,
  );
  const { created_at } = comments[0];

  const handleIconClick = useCallback(() => {
    setScrollCommentsIntoView(true);
  }, [setScrollCommentsIntoView]);

  return (
    <Tooltip
      title={
        <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
          <Typography {...textStyles} fontWeight={500}>
            {comments.length} {comments.length > 1 ? 'comments' : 'comment'}
          </Typography>

          <DateText date={created_at} relativeToCurrentTime {...textStyles} />
        </Box>
      }
    >
      <IconButton onClick={handleIconClick}>
        <Comment fontSize="small" sx={iconStyles} />
      </IconButton>
    </Tooltip>
  );
};

const textStyles: TypographyProps = {
  color: '#fff',
  fontSize: 10,
} as const;

const iconStyles: SxProps = {
  color: NodonTheme.palette.neutral.light,
} as const;

export default CommentsIconButton;
