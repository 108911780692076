import React, { useMemo } from 'react';
import { ModelTimestamps } from '../../../shared/models/base.interface';
import { DateTime } from 'luxon';
import { Typography, TypographyProps } from '@mui/material';
import { getTimeSinceEvent } from '../../../shared/helpers/date.helpers';

interface DateTextProps extends TypographyProps {
  date: Date | ModelTimestamps | string | undefined;
  relativeToCurrentTime?: boolean;
}

const DateText: React.FC<DateTextProps> = ({
  date,
  relativeToCurrentTime,
  ...typographyProps
}) => {
  const dateTime = useMemo(() => {
    if (!date) {
      return undefined;
    }
    if (typeof date === 'string') {
      return DateTime.fromISO(date);
    }
    if (date instanceof Date) {
      return DateTime.fromJSDate(date);
    }
    if ('created_at' in date) {
      return DateTime.fromISO(date.created_at);
    }
    throw new Error('Invalid date format');
  }, [date]);

  const formattedDate = dateTime?.toLocaleString(DateTime.DATETIME_MED) ?? '-';

  return (
    <Typography variant="caption" {...typographyProps}>
      {dateTime && relativeToCurrentTime
        ? getTimeSinceEvent(dateTime)
        : formattedDate}
    </Typography>
  );
};

export default DateText;
