import React, { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import {
  Box,
  BoxProps,
  Divider,
  ListItemIcon,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { IElement } from '../../../../../shared/models/project.interface';
import ElementProperties from './Property/ElementProperties';
import { useElementPropertiesUtils } from '../../../hooks/element-properties.hook';
import {
  getAvailableCategories,
  isMainCategory,
} from '../../../../../shared/templates/categories';
import {
  isProductCategory,
  isElementCategoryID,
  isSystemCategory,
} from '../../../../../shared/helpers/element_category_helpers';
import amplitudeLog from '../../../amplitude';
import { ElementCategoryID } from '../../../../../shared/models/element_categories.interface';
import { useBooleanState } from '../../../hooks/hooks';
import { useIsRecipeModified, useRecipe } from '../../../hooks/useRecipes';
import { useApplyElementCategory } from '../../../hooks/element-category.hook';
import { useElementFallbackName } from '../../../hooks/useElementFallbackName.hook';
import { CropSquareSharp } from '@mui/icons-material';
import {
  getElementById,
  hasChildren,
} from '../../../../../shared/helpers/recursive_element_helpers';
import { useAddProductElement } from '../../../store/project';
import ProductSelector from '../../../projects/EditProject/ProductSelector';
import { useIsRootElement } from '../../../hooks/useElement';
import { useIsConfirmed } from '../../../hooks/confirm.hook';
import { useIsReadonly } from '../../../hooks/user.hook';
import { setElementExpanded } from '../../../hooks/expand-elements.hook';

interface ElementCategoryProps extends BoxProps {
  element: IElement;
  filterCategories?: Readonly<ElementCategoryID[]>;
  filterProperties?: string[];
}
const ElementCategory: FC<ElementCategoryProps> = ({
  element,
  filterCategories = [],
  filterProperties = [],
  ...boxProps
}) => {
  const { classes } = useStyles();
  const { category_id } = element;
  const { categoryProperties } = useElementPropertiesUtils(element);

  const confirm = useIsConfirmed();
  const setElementFallbackName = useElementFallbackName();
  const applyElementCategory = useApplyElementCategory();
  const addProductElement = useAddProductElement();
  const isRootElement = useIsRootElement();

  const readonly = useIsReadonly();
  const isRecipeModified = useIsRecipeModified(element);
  const isMainEC = isMainCategory(category_id);
  const recipe = useRecipe(element);

  const [productSelectorVisible, showProductSelector, hideProductSelector] =
    useBooleanState(false);

  const [selectedId, setSelectedId] = useState<ElementCategoryID>(
    ElementCategoryID.None,
  );

  const categories = useMemo(
    () =>
      getAvailableCategories(category_id).filter(
        (category) => !filterCategories.includes(category.id),
      ),
    [category_id, filterCategories],
  );

  const productCategories = useMemo(
    () => [...categories.filter((category) => isProductCategory(category.id))],
    [categories],
  );

  const hasSystemCategories = useMemo(
    () => categories.some((category) => isSystemCategory(category.id)),
    [categories],
  );

  const menuValue = useMemo(() => {
    return isElementCategoryID(category_id) ? category_id : 'none';
  }, [category_id]);

  const confirmDiscardChangesOptions = useMemo(
    () => ({
      title: 'Discard changes?',
      description: `Unsaved changes on recipe "${
        recipe?.name || 'None'
      }" will be lost. This action cannot be undone.`,
      cancellationText: 'Cancel',
      confirmationText: 'Discard changes',
    }),
    [recipe?.name],
  );

  const confirmDiscardContentOptions = useMemo(
    () => ({
      title: 'Discard current content?',
      description: `The current content on element "${
        element.name || element.fallbackName || 'unnamed'
      }" will be lost. This action cannot be undone.`,
      cancellationText: 'Cancel',
      confirmationText: 'Discard content',
    }),
    [element.name, element.fallbackName],
  );

  const handleSelectCategory = useCallback(
    async (id: ElementCategoryID | 'none'): Promise<void> => {
      const categoryID = isElementCategoryID(id) ? id : undefined;

      const updatedProject = await applyElementCategory(element, categoryID);

      // Element will be a new instance so get it from project
      const updatedElement = getElementById(
        updatedProject,
        element.id,
      ) as IElement;

      if (
        updatedElement.elements.length &&
        updatedElement.recipe_id?.includes('auto')
      ) {
        setElementExpanded(updatedElement.id, true);
      }

      setElementFallbackName(updatedElement, id);
    },
    [applyElementCategory, element, setElementFallbackName],
  );

  const onClose = useCallback(
    async (selectedCategoryId?: ElementCategoryID) =>
      selectedId === ElementCategoryID.OtherProduct
        ? showProductSelector()
        : handleSelectCategory(selectedCategoryId ?? selectedId),
    [selectedId, showProductSelector, handleSelectCategory],
  );

  const handleProductSwitch = useCallback(
    (product_id: string) => {
      addProductElement(element, { product_id }, true);
    },
    [addProductElement, element],
  );

  const selectCategory = useCallback(
    async ({
      target: { value },
    }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const selectedCategoryId = value as ElementCategoryID;

      const hasChangedToProductCategory =
        !isProductCategory(category_id) &&
        isProductCategory(selectedCategoryId || 'none');

      const shouldShowConfirmDialog =
        isRecipeModified ||
        (!isProductCategory(category_id) &&
          !element.recipe_id &&
          hasChildren(element) &&
          hasChangedToProductCategory);

      setSelectedId(selectedCategoryId);

      if (shouldShowConfirmDialog) {
        if (
          await confirm(
            isRecipeModified
              ? confirmDiscardChangesOptions
              : confirmDiscardContentOptions,
          )
        ) {
          await onClose(selectedCategoryId);
        }
      } else {
        handleSelectCategory(selectedCategoryId);
      }

      amplitudeLog('Element Category Select', {
        CategoryId: selectedCategoryId,
      });
    },
    [
      category_id,
      element,
      handleSelectCategory,
      isRecipeModified,
      confirm,
      confirmDiscardChangesOptions,
      confirmDiscardContentOptions,
      onClose,
    ],
  );

  return (
    <>
      <Box {...boxProps}>
        {!isMainEC && (
          <TextField
            disabled={readonly}
            data-testid="ElementCategory"
            select
            fullWidth
            size="small"
            value={menuValue}
            onChange={selectCategory}
            SelectProps={{ inputProps: { sx: { display: 'flex' } } }}
            sx={{ paddingBottom: 1 }}
          >
            <MenuItem key="none" value="none" className={classes.menuItem}>
              None
            </MenuItem>

            <Divider variant="middle" />

            {hasSystemCategories && (
              <Box pt={4}>
                <Typography variant="menuTitle">Systems</Typography>
              </Box>
            )}

            {categories.map((category) => {
              return (
                <MenuItem
                  key={category.id}
                  value={category.id}
                  className={classes.menuItem}
                  sx={{
                    display: isSystemCategory(category.id) ? 'flex' : 'none',
                  }}
                >
                  {category.name}
                </MenuItem>
              );
            })}

            {hasSystemCategories && <Divider variant="middle" />}

            {hasSystemCategories && (
              <Box pt={4}>
                <Typography variant="menuTitle">Products</Typography>
              </Box>
            )}

            {productCategories.map((category) => {
              return [
                category.id === ElementCategoryID.Energy && (
                  <Divider key={'divider-' + category.id} variant="middle" />
                ),

                <MenuItem
                  key={category.id}
                  value={category.id}
                  className={classes.menuItem}
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                  disabled={
                    category.id === ElementCategoryID.OtherProduct &&
                    isRootElement(element)
                  }
                >
                  <Box display="flex">
                    <ListItemIcon sx={{ minWidth: 34, alignItems: 'center' }}>
                      <CropSquareSharp
                        fontSize="small"
                        sx={{
                          transform: 'rotate(45deg) scale(0.5)',
                        }}
                      />
                    </ListItemIcon>
                    {category.name}
                  </Box>
                </MenuItem>,
              ];
            })}
          </TextField>
        )}
        {category_id && (
          <ElementProperties
            element={element}
            properties={categoryProperties}
            filterProperties={filterProperties}
          />
        )}
      </Box>
      {productSelectorVisible && (
        <ProductSelector
          parent={element}
          open={productSelectorVisible}
          onClose={hideProductSelector}
          onSave={handleProductSwitch}
        />
      )}
    </>
  );
};

const useStyles = makeStyles()(({ spacing }) => ({
  menuButton: {
    minWidth: 0,
    padding: spacing(2),
  },
  menuItem: {
    margin: 5,
  },
}));

export default ElementCategory;
