import React, { useMemo } from 'react';
import { Box, BoxProps, Button } from '@mui/material';
import {
  IElement,
  OneOfPropertyElements,
} from '../../../../../../shared/models/project.interface';
import { IElementProperty } from '../../../../../../shared/models/element_property.interface';
import { isFullyEditableProperty } from '../../../../../../shared/helpers/element_property_helpers';
import { IElementQuantityProperty } from '../../../../../../shared/models/element_quantities.interface';
import { useToggleState } from '../../../../hooks/hooks';
import ElementProperty from './ElementProperty';

const PROPERTY_LABEL_AND_NAME_MAX_LENGTH = 40;

export type ApplyAutoRecipeFn = <T1, T2>(
  element: IElement,
  newPropertyValue: T1,
  oldPropertyValue: T2,
  update: (modified: { count: T1 | T2 }) => Promise<IElement | undefined>,
) => Promise<void>;

interface ElementPropertiesProps extends BoxProps {
  element: OneOfPropertyElements;

  /**
   * Properties to list.
   * Note that not every property from element might be listed.
   */
  properties: (IElementProperty | IElementQuantityProperty)[];
  filterProperties?: string[];
}

const ElementProperties: React.FC<ElementPropertiesProps> = ({
  element,
  properties,
  filterProperties = [],
  ...boxProps
}) => {
  const [isExpanded, toggleExpanded] = useToggleState(false);
  const visibleProperties = useMemo(
    () =>
      properties.filter(
        (p) => (!p.hidden && !filterProperties.includes(p.name)) || isExpanded,
      ),
    [filterProperties, isExpanded, properties],
  );
  const showExpandButton =
    !filterProperties.length && visibleProperties.length < properties.length;

  // If all properties are not editable, then we don't need to show the delete column.
  const hideDeleteColumn = properties.every((p) => !isFullyEditableProperty(p));

  return (
    <>
      <Box m="0" {...boxProps}>
        {visibleProperties.map((property) => {
          const hideTooltip =
            typeof property.count !== 'string' ||
            property.count.length + property.name.length <
              PROPERTY_LABEL_AND_NAME_MAX_LENGTH;

          return (
            <ElementProperty
              key={property.id}
              property={property}
              element={element}
              hideDeleteColumn={hideDeleteColumn}
              hideTooltip={hideTooltip}
            />
          );
        })}
      </Box>
      {showExpandButton && (
        <Box display="flex" justifyContent="center" mt={3}>
          <Button onClick={toggleExpanded} color="neutral">
            Show hidden properties
          </Button>
        </Box>
      )}
    </>
  );
};

export default ElementProperties;
