import React, { FC, MouseEventHandler, useCallback, useState } from 'react';
import { getSelectedOrganization } from '../store/organization';
import { Alert, Box, IconButton } from '@mui/material';
import { useUIState } from '../store/ui';
import { AppBarConstant } from '../style/constants';
import { makeStyles } from 'tss-react/mui';
import ClearIcon from '@mui/icons-material/Clear';

const GFAQuotaExceededAlert: FC = () => {
  const { classes } = useStyles();
  const selectedOrganization = getSelectedOrganization();
  const [isClosed, setIsClosed] = useState(false);

  const { setAppHeaderOffset } = useUIState('setAppHeaderOffset');

  const handleClose: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.stopPropagation();
      setIsClosed(!isClosed);
      setAppHeaderOffset(AppBarConstant.HEIGHT);
    },
    [isClosed, setAppHeaderOffset],
  );

  return (
    <Alert
      severity="warning"
      variant="standard"
      sx={{ display: isClosed ? 'none' : 'flex' }}
      classes={{
        action: classes.actionWrapperElement,
        root: classes.alertRoot,
      }}
      action={
        <IconButton size="small" onClick={handleClose}>
          <ClearIcon sx={{ fontSize: 16 }} />
        </IconButton>
      }
    >
      <Box>
        You have exceeded {selectedOrganization}&apos;s current Gross Floor Area
        Quota.{' '}
        <a
          href="mailto:alexander@nodon.se?subject=We would like to extend our GFA quota"
          style={{ color: '#623e1c' }}
        >
          Contact us to extend your quota.
        </a>
      </Box>
    </Alert>
  );
};

const useStyles = makeStyles()(({ spacing }) => ({
  actionWrapperElement: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: 0,
    padding: spacing(1),
  },
  alertRoot: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f7d1aa',
    position: 'relative',
  },
}));

export default GFAQuotaExceededAlert;
