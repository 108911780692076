import { SxProps } from '@mui/material';
import { NodonTheme } from './theme';

export enum Element {
  HEIGHT = 62,
}

export enum AppBarConstant {
  HEIGHT = 64,
  SECTION_WIDTH = 0.25,
}

export enum ProjectElementsListConstant {
  VERSION_MENU_MAX_HEIGHT = 400,
}

export enum SidePanelConstant {
  WIDTH_PERCENT = 20,
  WIDTH = 300,
}

export enum BarChartTooltipContainerStyle {
  textAlign = 'center',
  minWidth = '90px',
  borderRadius = '3px',
  backgroundColor = 'rgba(63, 63, 63, 0.95)',
  boxShadow = '0px 1px 10px 0px rgba(0, 0, 0, 0.15)',
  fontWeight = '400',
  fontSize = '10px',
  transition = 'none !important',
}

export enum BarChartTooltipContentStyle {
  display = 'flex',
  justifyContent = 'center',
  whiteSpace = 'nowrap',
  marginRight = '4px',
  fontSize = '12px',
  fontWeight = '700',
}

export const dividerStyles: SxProps = {
  ml: -2,
  mr: -2,
  borderColor: NodonTheme.palette.neutral.lighter,
} as const;

export const projectActionMenuIconStyles = {
  fontSize: 'small',
  color: 'secondary',
} as const;
