import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  FocusEvent,
  useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Controller, useForm, UseFormProps, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import amplitudeLog from '../../amplitude';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputBaseComponentProps,
  MenuItem,
  Select,
  SxProps,
  TextField,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as Yup from 'yup';
import {
  Product,
  ProductID,
} from '../../../../shared/models/product.interface';
import {
  QuantityUnit,
  ConversionFactors,
} from '../../../../shared/models/unit.interface';
import { convertConversionFactors } from '../../../../shared/helpers/conversion_helpers';
import { hasProperties } from '../../../../shared/helpers/object_helpers';
import { useProductsLookup, useProductStoreState } from '../../store/product';
import {
  fromTransportWasteConversionFactors,
  toTransportWasteConversionFactors,
} from '../../../../shared/helpers/calculation_helpers';
import { findFreeName } from '../../../../shared/helpers/string_helpers';
import { useUserId } from '../../hooks/user.hook';
import { useSelectedOrganization } from '../../store/organization';
import { isEmpty } from 'lodash';
import { IElement } from '../../../../shared/models/project.interface';
import EditProductDialogConversionFactorInput from './EditProductDialogConversionFactorInput';
import InputContainer from '../../components/InputContainer';
import GenericProductSelector from './GenericProductSelector';
import { create } from 'zustand';
import { TouchRippleProps } from '@mui/material/ButtonBase/TouchRipple';
import { required } from '../../../../shared/helpers/function_helpers';
import { removeNumberFormatting } from '../../../../shared/helpers/math_helpers';

export type FormProduct = Pick<
  Product,
  | 'id'
  | 'name'
  | 'organizations'
  | 'unit'
  | 'conversion_factors'
  | 'description'
>;

const messages = defineMessages({
  nameIsRequired: {
    id: 'create_product_dalog.name_is_required',
    defaultMessage: 'Name is required',
  },
  nameMustBeUnique: {
    id: 'create_product_dialog.name_must_be_unique',
    defaultMessage: 'The name already exists',
  },
  unitIsRequired: {
    id: 'create_product_dialog.unit_is_required',
    defaultMessage: 'Unit is required',
  },
  emissionValueIsRequired: {
    id: 'create_product_dialog.emission_is_required',
    defaultMessage: 'Emission value is required',
  },
  organizationIsRequired: {
    id: 'create_product_dialog.organizations_is_required',
    defaultMessage: 'Organization is required',
  },
  mustBeNumber: {
    id: 'create_product_dialog.must_be_number',
    defaultMessage: 'Must be a number',
  },
  mustBeGreaterThanZero: {
    id: 'create_product_dialog.must_be_greater_than_zero',
    defaultMessage: 'Must be greater than 0',
  },
  mustBeGreaterOrEqualZero: {
    id: 'create_product_dialog.must_be_greater_or_equal_zero',
    defaultMessage: 'Must be greater than or equal to 0',
  },
  mustBeLessOrEqualHundred: {
    id: 'create_product_dialog.must_be_less_or_equal_hundred',
    defaultMessage: 'Must be less or equal to 100%',
  },
});

const requiredUnits: QuantityUnit[] = [
  'co2e_A1-A3',
  'co2e_A5',
  'co2e_A4',
  'sek_A1-A3',
];

const availableUnits: QuantityUnit[] = [
  ...requiredUnits,
  'kg',
  'm³',
  'm²',
  'm',
  'pcs',
];

/**
 * Return null if the value is empty.
 * Else try to parse different types of values to a number.
 * @param value Parsed number value
 * @param originalValue String value to parse
 * @returns
 */
const valueOrNull = (
  value: number | null | string,
  originalValue: string,
): number | null => {
  // Empty field, return null
  if (!originalValue) {
    return null;
  }
  if (typeof value === 'number' && isFinite(value)) {
    return value;
  }
  const parsed =
    typeof originalValue === 'string'
      ? +removeNumberFormatting(originalValue)
      : null;

  return parsed !== null && (isFinite(parsed) || isNaN(parsed)) ? parsed : null;
};

interface IDialogStoreState {
  /**
   * Provide a product to edit or clone
   */
  product?: Product;

  /**
   * When opening from the EPD menu. Provide the parent element to get the correct settings
   */
  parent?: IElement;

  /**
   * Should the provided product be duplicated
   */
  duplicate?: boolean;

  /**
   * The product promise that is returned when the dialog is opened
   * @param value
   * @returns
   */
  openPromiseResolve?: (
    value: Product | PromiseLike<Product | undefined> | undefined,
  ) => void;
}

const useDialogStore = create<IDialogStoreState>(() => ({}));

const EditProductDialog: React.FC = () => {
  const { product, parent, duplicate } = useDialogStore();
  const intl = useIntl();
  const productsLookup = useProductsLookup();

  const { classes } = useStyles();
  const { createProduct, updateProduct, products } = useProductStoreState(
    'createProduct',
    'updateProduct',
    'products',
  );

  const nameInputRef = useRef<
    EventTarget & (HTMLInputElement | HTMLTextAreaElement)
  >();

  const [selectedGenericProductId, setSelectedGenericProductId] = useState<
    ProductID | undefined
  >();

  const genericProduct = selectedGenericProductId
    ? productsLookup[selectedGenericProductId]
    : undefined;

  const showGenericProductSelector = true;

  const productNames = useMemo(
    () =>
      (duplicate ? products : products.filter((p) => p.id !== product?.id)).map(
        (product) => product.name,
      ),
    [products, duplicate, product?.id],
  );

  const schema = useMemo(
    () =>
      Yup.object({
        name: Yup.string()
          .trim()
          .required(intl.formatMessage(messages.nameIsRequired))
          .notOneOf(
            productNames,
            intl.formatMessage(messages.nameMustBeUnique),
          ),

        unit: Yup.string().required(
          intl.formatMessage(messages.unitIsRequired),
        ),

        description: Yup.string().max(5000),

        conversion_factors: Yup.object(
          availableUnits.reduce((acc, unit) => {
            const isRequired = showGenericProductSelector
              ? 'notRequired'
              : 'required';
            const requiredMsg = showGenericProductSelector ? '' : 'Required';

            const numberSchema = Yup.number()
              .nullable()
              .transform(valueOrNull)
              .typeError(intl.formatMessage(messages.mustBeNumber));

            const zeroIsAllowedNumberSchema = numberSchema
              .moreThan(
                -1,
                intl.formatMessage(messages.mustBeGreaterOrEqualZero),
              )
              // eslint-disable-next-line no-unexpected-multiline
              [isRequired](requiredMsg);

            if (requiredUnits.includes(unit)) {
              if (unit === 'co2e_A5') {
                /* 
                SPILL
                */
                return {
                  ...acc,
                  [unit]: zeroIsAllowedNumberSchema.max(
                    100,
                    intl.formatMessage(messages.mustBeLessOrEqualHundred),
                  ),
                };
              }
              /* 
              REQUIRED FIELDS
              */
              return {
                ...acc,
                [unit]: zeroIsAllowedNumberSchema,
              };
            }
            /* 
            OPTIONAL FIELDS
            */
            return {
              ...acc,
              [unit]: numberSchema.moreThan(
                0,
                intl.formatMessage(messages.mustBeGreaterThanZero),
              ),
            };
          }, {}),
        ),
      }),
    [intl, productNames, showGenericProductSelector],
  );

  const user = required(useUserId());
  const organization = required(useSelectedOrganization());

  const defaults = useMemo<FormProduct>(() => {
    if (product) {
      const { id, unit, organizations } = product;

      const conversion_factors = toTransportWasteConversionFactors(
        product.conversion_factors,
      );

      const description =
        product.source === 'custom' ? product.description : product.name;

      let name = '';

      if (product.source === 'custom') {
        name = product.name;
      }
      if (duplicate) {
        name = findFreeName(productNames, product.name, ' ', 2);
      }

      return {
        id,
        name,
        unit,
        conversion_factors,
        description,
        organizations: organizations ?? [],
      };
    }
    return {
      id: '',
      name: '',
      organizations: [],
      unit: genericProduct?.unit ?? 'kg',
      conversion_factors: genericProduct?.conversion_factors ?? {},
    };
  }, [
    product,
    genericProduct?.unit,
    genericProduct?.conversion_factors,
    duplicate,
    productNames,
  ]);

  const formSettings: UseFormProps<FormProduct> = useMemo(
    () => ({
      defaultValues: defaults,
      resolver: yupResolver(schema),
      mode: 'onChange',
      reValidateMode: 'onChange',
    }),
    [defaults, schema],
  );

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { isValid, isSubmitting, errors, touchedFields, dirtyFields },
    trigger,
  } = useForm<FormProduct>(formSettings);

  const isMissingGenericProductId =
    !product?.generic_id && !selectedGenericProductId;

  const disableSave =
    !isValid ||
    isSubmitting ||
    isMissingGenericProductId ||
    (!duplicate && isEmpty(dirtyFields) && !selectedGenericProductId);

  const dialogTitle = useMemo(() => {
    if (product) {
      return `${duplicate ? 'Duplicate' : 'Edit'} EPD`;
    }
    return 'Create new EPD';
  }, [duplicate, product]);

  const defaultUnit = useWatch({
    control,
    name: 'unit',
    defaultValue: product?.unit ?? 'kg',
  });

  // Get current conversion factors
  const getUserInputConversionFactors = useCallback(() => {
    const formValues = getValues('conversion_factors');

    const conversionFactors = availableUnits.reduce(
      (acc, unit) => {
        const value = removeNumberFormatting(formValues[unit]) as
          | string
          | number
          | undefined;

        return {
          ...acc,
          [unit]:
            value !== undefined && value !== '' && isFinite(+value)
              ? +value
              : undefined,
        };
      },
      { [defaultUnit]: 1 } as ConversionFactors,
    );

    return conversionFactors;
  }, [defaultUnit, getValues]);

  // Get conversion factors that can be submitted (width A4 and A5 converted to kgCO2e/[unit])
  const getSubmittableConversionFactors = useCallback(() => {
    const inputFactors: ConversionFactors = getUserInputConversionFactors();

    // The mandatory and at least 1 optional unit must be set
    if (
      !hasProperties(inputFactors, ...requiredUnits) ||
      Object.keys(inputFactors).length <= 2
    ) {
      throw new Error('No conversion factors');
    }

    return fromTransportWasteConversionFactors({
      ...defaults.conversion_factors,
      ...inputFactors,
    });
  }, [defaults.conversion_factors, getUserInputConversionFactors]);

  useEffect(() => {
    const conversionFactors = getUserInputConversionFactors();

    if (conversionFactors[defaultUnit] !== 1) {
      if (conversionFactors[defaultUnit]) {
        const converted = convertConversionFactors(
          conversionFactors,
          defaultUnit,
        );
        // A4 and A5 should not be converted
        converted.co2e_A4 = conversionFactors.co2e_A4;
        converted.co2e_A5 = conversionFactors.co2e_A5;
        setValue('conversion_factors', converted);
      } else {
        // Should already be 1 if converted
        conversionFactors[defaultUnit] = 1;
        setValue('conversion_factors', conversionFactors);
      }
    }
  }, [defaultUnit, getUserInputConversionFactors, setValue]);

  useEffect(() => {
    if (product) {
      trigger();
    }
  }, [product, trigger]);

  useEffect(() => {
    if (duplicate && Object.entries(touchedFields).length <= 1) {
      nameInputRef.current?.select();
    }
  }, [duplicate, touchedFields]);

  const onSubmit = useCallback(
    async (data: FormProduct) => {
      const userInput = {
        ...data,
        owner: user, // Use active user
        generic_id: selectedGenericProductId ?? product?.generic_id, // Use selected generic product
        conversion_factors: getSubmittableConversionFactors(),
      };

      // Duplicate or edit
      if (product) {
        const newProduct: Product = {
          ...product,
          ...userInput,
        };

        const updatedProduct = duplicate
          ? await createProduct(newProduct)
          : await updateProduct(newProduct);

        amplitudeLog(`Custom Product ${duplicate ? 'Duplicate' : 'Edit'}`, {
          ID: updatedProduct.id,
        });

        return closeEditProductDialog(updatedProduct);
      }

      const createdProduct = await createProduct({
        ...userInput,
        organizations: [organization], // Add to selected organization
      });

      amplitudeLog('Custom Product New', {
        ID: createdProduct.id,
      });

      return closeEditProductDialog(createdProduct);
    },
    [
      selectedGenericProductId,
      user,
      getSubmittableConversionFactors,
      product,
      createProduct,
      organization,
      duplicate,
      updateProduct,
    ],
  );

  const close = useCallback(() => closeEditProductDialog(), []);

  const handleFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) =>
      (nameInputRef.current = e.currentTarget),
    [],
  );

  return (
    <Dialog
      fullWidth
      open={true}
      maxWidth={showGenericProductSelector ? 'md' : 'sm'}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{dialogTitle}</DialogTitle>

        <DialogContent sx={DIALOG_CONTENT_STYLE}>
          {showGenericProductSelector && (
            <Box width={2 / 5}>
              <GenericProductSelector
                element={parent}
                showPrompt={isMissingGenericProductId}
                onProductChange={setSelectedGenericProductId}
              />
            </Box>
          )}
          <Box width={showGenericProductSelector ? 3 / 5 : 1}>
            <Box display="flex" gap={4} justifyContent="space-between">
              <InputContainer title="Name" width={5 / 6}>
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoFocus
                      required
                      autoComplete="off"
                      size="small"
                      placeholder="Type a product name"
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      fullWidth
                      onFocus={handleFocus}
                    />
                  )}
                />
              </InputContainer>

              <InputContainer title="Unit" width={1 / 6}>
                <Controller
                  control={control}
                  name="unit"
                  render={({ field }) => (
                    <Select
                      {...field}
                      classes={{ select: classes.selectInput }}
                      inputProps={SELECT_INPUT_PROPS}
                      fullWidth
                    >
                      {availableUnits
                        .filter((unit) => !requiredUnits.includes(unit))
                        .map((unit) => (
                          <MenuItem key={unit} value={unit}>
                            {unit}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              </InputContainer>
            </Box>
            <Box display="flex" gap={4}>
              <Box width={1 / 2}>
                {availableUnits
                  .filter((unit) => requiredUnits.includes(unit))
                  .map((unit) => {
                    return (
                      <EditProductDialogConversionFactorInput
                        key={unit}
                        control={control}
                        errors={errors}
                        unit={unit}
                        defaultUnit={defaultUnit}
                        requiredUnits={requiredUnits}
                        conversionFactors={genericProduct?.conversion_factors}
                      />
                    );
                  })}
              </Box>
              <Box width={1 / 2}>
                {availableUnits
                  .filter(
                    (unit) => ![...requiredUnits, defaultUnit].includes(unit),
                  )
                  .map((unit) => (
                    <EditProductDialogConversionFactorInput
                      key={unit}
                      control={control}
                      errors={errors}
                      unit={unit}
                      defaultUnit={defaultUnit}
                      requiredUnits={requiredUnits}
                      conversionFactors={genericProduct?.conversion_factors}
                    />
                  ))}
              </Box>
            </Box>
            <Box>
              <InputContainer title="Description" mt={3}>
                <Controller
                  control={control}
                  name="description"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      fullWidth
                      multiline
                      minRows={4.85}
                      maxRows={12}
                      className={classes.description}
                      error={!!errors.description}
                      helperText={errors.description?.message}
                      placeholder={genericProduct?.name}
                    />
                  )}
                />
              </InputContainer>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button TouchRippleProps={RIPPLE_STYLE} onClick={close}>
            Cancel
          </Button>
          <Button
            variant="contained"
            TouchRippleProps={RIPPLE_STYLE}
            onClick={handleSubmit(onSubmit)}
            disabled={disableSave}
          >
            {product && !duplicate ? 'Save' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const DIALOG_CONTENT_STYLE: SxProps<Theme> = {
  display: 'flex',
  gap: 4,
};

const RIPPLE_STYLE: Partial<TouchRippleProps> = {
  style: { top: 'auto', bottom: 0, height: 4 },
};

const SELECT_INPUT_PROPS: InputBaseComponentProps = { sx: { p: '9px 20px' } };

const useStyles = makeStyles()(() => ({
  selectInput: {
    paddingTop: 2,
    paddingBottom: 2,
    backgroundColor: 'red',
  },
  description: {
    fontSize: 14,
  },
}));

export const useIsEditProductDialogOpen = (): boolean => {
  return !!useDialogStore(({ openPromiseResolve }) => openPromiseResolve);
};

/**
 * Close the product dialog. Passing a product means that it's a save operation.
 * @param product
 */
export const closeEditProductDialog = (product?: Product) => {
  const resolve = useDialogStore.getState().openPromiseResolve;
  if (resolve) {
    resolve(product);
    // Clear store
    useDialogStore.setState({}, true);
  }
};

export const openEditProductDialog = (
  props: Omit<IDialogStoreState, 'openPromise'> = {},
): Promise<Product | undefined> => {
  // Make sure the dialog is closed before opening a new one
  closeEditProductDialog();
  const openPromise = new Promise<Product | undefined>((resolve) => {
    useDialogStore.setState({ ...props, openPromiseResolve: resolve }, true);
  }).finally(() => {
    // By clearing the store here the users of the dialog can resolve the promise to close the dialog
    useDialogStore.setState({}, true);
  });

  return openPromise;
};

const ConditionalEditProductDialog: React.FC = () => {
  const isOpen = useIsEditProductDialogOpen();

  return isOpen ? <EditProductDialog /> : null;
};

export default ConditionalEditProductDialog;
