import { useCallback } from 'react';
import { validateRecipe } from '../../../../shared/validation/project.validation';
import { Recipe } from '../../../../shared/models/recipe.interface';
import { getProductsLookup } from '../product';
import { IRecipeStoreState } from './recipe-state.model';
import { useRecipesStore } from './recipe.store';

const recipesSelector = (
  state: IRecipeStoreState,
): IRecipeStoreState['recipes'] => state.recipes;

const recipeLookupSelector = (
  state: IRecipeStoreState,
): IRecipeStoreState['lookup'] => state.lookup;

export const useRecipes = (): IRecipeStoreState['recipes'] =>
  useRecipesStore(recipesSelector);

export const useRecipeLookup = (): IRecipeStoreState['lookup'] =>
  useRecipesStore(recipeLookupSelector);

export const getRecipes = (): IRecipeStoreState['recipes'] =>
  recipesSelector(useRecipesStore.getState());

export const getRecipeLookup = (): IRecipeStoreState['lookup'] =>
  recipeLookupSelector(useRecipesStore.getState());

export const getCreateRecipe = (): IRecipeStoreState['createRecipe'] =>
  useRecipesStore.getState().createRecipe;

const getUpdateRecipe = (): IRecipeStoreState['updateRecipe'] =>
  useRecipesStore.getState().updateRecipe;

/**
 * Create a recipe after validating it
 * @returns
 */
export const useCreateRecipe = (): IRecipeStoreState['createRecipe'] =>
  useCallback((recipe: Recipe, ...rest): Promise<Recipe> => {
    const createRecipe = getCreateRecipe();
    const productRecord = getProductsLookup();
    return createRecipe(validateRecipe(recipe, productRecord), ...rest);
  }, []);

/**
 * Create a recipe locally
 * @returns
 */
export const useCreateRecipeLocally =
  (): IRecipeStoreState['createRecipeLocally'] =>
    useRecipesStore((state) => state.createRecipeLocally);

/**
 * Update a recipe after validating it
 * @returns
 */
export const useUpdateRecipe = (): IRecipeStoreState['updateRecipe'] =>
  useCallback((recipe: Recipe, ...rest): Promise<Recipe> => {
    const updateRecipe = getUpdateRecipe();
    const productRecord = getProductsLookup();
    return updateRecipe(validateRecipe(recipe, productRecord), ...rest);
  }, []);

/**
 * Update a recipe locally
 * @returns
 */
export const useUpdateRecipeLocally =
  (): IRecipeStoreState['updateRecipeLocally'] =>
    useRecipesStore((state) => state.updateRecipeLocally);

/**
 * Delete a recipe
 * @returns
 */
export const useDeleteRecipe = (): IRecipeStoreState['deleteRecipe'] =>
  useRecipesStore((state) => state.deleteRecipe);

/**
 * Delete a recipe locally
 * @returns
 */
export const useDeleteRecipeLocally =
  (): IRecipeStoreState['deleteRecipeLocally'] =>
    useRecipesStore((state) => state.deleteRecipeLocally);
