import React, { FC, useMemo } from 'react';
import BarGraphSection, { Bar } from './BarGraphSection';
import { useIntl } from 'react-intl';
import { useVersionConversionFactorQuantitiesRecord } from '../../../hooks/useCO2Calculations';
import ProjectGFASection from './ProjectGFASection';
import {
  IBuildingVersion,
  Project,
} from '../../../../../shared/models/project.interface';
import { getConversionFactorsById } from '../../../../../shared/helpers/calculation_helpers';
import MapContainer from '../../../projects/EditProject/Settingspage/Map/MapContainer';
import { UnsavedChangesProvider } from '../../../providers/UnsavedChangesProvider';
import GeometrySettings from './GeometrySettings';
import ActivitySettings from '../../../components/activities/ActivitySettings';
import { Box } from '@mui/material';
import NodonAccordion from './NodonAccordion';
import GFAChart from '../../charts/GFAChart';
import ElementProperties from '../Element/Property/ElementProperties';
import { getElementProperties } from '../../../../../shared/helpers/element_property_helpers';
import { getProjectMeta } from '../../../../../shared/helpers/project_helpers';
import { useElementPropertiesUtils } from '../../../hooks/element-properties.hook';

interface InsightsProps {
  project: Project;
  version: IBuildingVersion;
}

const Insights: FC<InsightsProps> = ({ project, version }) => {
  const intl = useIntl();
  const meta = getProjectMeta(project);
  const quantitiesRecord = useVersionConversionFactorQuantitiesRecord();
  const { addProperty } = useElementPropertiesUtils(version);

  const versionTotals = useMemo(
    () => getConversionFactorsById(quantitiesRecord, version?.id),
    [quantitiesRecord, version?.id],
  );
  const cost = versionTotals['sek_A1-A3']; // Total cost to right-panel
  const lcaBars: Bar[] = useMemo(() => {
    return [
      {
        value: versionTotals['co2e_A1-A3'],
        name: intl.formatMessage({
          id: 'insights.lca_phase_a1_a3',
          defaultMessage: 'A1-A3 Manufacturing',
        }),
      },
      {
        value: versionTotals['co2e_A4'],
        name: intl.formatMessage({
          id: 'insights.lca_phase_a4',
          defaultMessage: 'A4 Transportation to site',
        }),
      },
      {
        value: versionTotals['co2e_A5'],
        name: intl.formatMessage({
          id: 'insights.lca_phase_a5',
          defaultMessage: 'A5 Installation at site',
        }),
      },
    ];
  }, [versionTotals, intl]);

  const properties = getElementProperties(version);
  const bta = meta.gfa_building
    ? meta.gfa_building / meta.storeys.length
    : meta.building_footprint.area;

  return (
    <>
      <NodonAccordion summary="Geometry" disablePadding>
        <Box width={1} height={300} display="flex" mb={5}>
          <MapContainer />
        </Box>

        <UnsavedChangesProvider>
          <GeometrySettings project={project} />
        </UnsavedChangesProvider>
      </NodonAccordion>

      <NodonAccordion summary="Activities" hasActivityMenu>
        <ActivitySettings />
      </NodonAccordion>

      <NodonAccordion summary="Properties" onAddClick={addProperty}>
        <ElementProperties element={version} properties={properties} />
      </NodonAccordion>

      <NodonAccordion summary="Project Area">
        <GFAChart project={project} />
        {version && <ProjectGFASection bta={bta} cost={cost} />}
      </NodonAccordion>

      <NodonAccordion summary="Lifecycle">
        <Box mb={25}>
          <BarGraphSection
            heading="Lifecycle"
            bars={lcaBars}
            suffix=" kg CO2e"
            defaultExpanded
          />
        </Box>
      </NodonAccordion>
    </>
  );
};

export default Insights;
