import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { OutlinedTextFieldProps } from '@mui/material';
import amplitudeLog from '../../../amplitude';
import InlineTextField from '../../InlineTextField';
import { IBuildingVersion } from '../../../../../shared/models/project.interface';
import { useUpdateElements } from '../../../store/project/project.hook';
import { useSelectedVersionId, useUIState } from '../../../store/ui';

import { useToggleElementExpanded } from '../../../hooks/expand-elements.hook';
import { isDeactivated } from '../../../../../shared/helpers/element_helpers';
import HorizontalBarCharts from '../../charts/HorizontalBarCharts';
import { Row } from '../Row';
import { RowCell } from '../RowCell';
import {
  ROOT_CELL_WIDTH,
  ROOT_CELL_WIDTH_RESPONSIVE,
  LIST_ITEM_HEIGHT,
  LIST_SPACING,
  CONTENT_CELL_WIDTH,
  CONTENT_CELL_WIDTH_RESPONSIVE,
} from '../list.constants';
import { useBooleanState } from '../../../hooks/hooks';
import {
  useIsKebabMenuOpen,
  useTriggerContextKebabMenu,
} from '../../kebab-menu/kebab-menu.hooks';
import { useListRowStyles } from '../list.style';
import { useNavigateTo } from '../../../hooks/router.hooks';
import { useMouseEventCallback } from '../../../hooks/events.hook';
import BuildingVersionKebabMenu from '../KebabMenus/BuildingVersionKebabMenu';
import ImportElements from '../../ImportElements';
import SortSelect from '../../ProjectElementsList/SortSelect';
import VersionSpoiler from '../VersionSpoiler';
import SelectedIcon from '../SelectedIcon';
import { useBarChart } from '../../../hooks/chart.hook';

interface IBuildingVersionListItemInput {
  version: IBuildingVersion;
  autoFocus?: boolean;
  indentation?: number;
  selectMode?: boolean;
}

const BuilingVersionListItem: React.FC<IBuildingVersionListItemInput> = ({
  version,
  autoFocus,
  indentation = 0,
  selectMode = false,
}) => {
  const { classes: listClasses } = useListRowStyles();
  const { setAddedElementId } = useUIState('setAddedElementId');
  const updateElements = useUpdateElements();
  const toggleElementExpanded = useToggleElementExpanded(version);
  const { name, id } = version;
  const [hover, startHover, endHover] = useBooleanState(false);
  const [isEditingName, startEditingName, stopEditingName] =
    useBooleanState(false);
  const triggerContextMenu = useTriggerContextKebabMenu(id);
  const isKebabMenuOpen = useIsKebabMenuOpen(id);
  const navigateTo = useNavigateTo();
  const deactivated = isDeactivated(version);
  const isSelectedVersion = useSelectedVersionId() === id;
  const { renderTooltip } = useBarChart();

  const nameInputProps: Partial<OutlinedTextFieldProps> = useMemo(
    () => ({
      autoFocus,
      onFocus: (e) => {
        autoFocus && e.currentTarget.select();
        setAddedElementId();
      },
      placeholder: 'Enter Version Name',
      sx: {
        width: '',
      },
    }),
    [autoFocus, setAddedElementId],
  );

  const onRowClick = useMouseEventCallback(
    () => {
      navigateTo({ versionId: id });
    },
    { ignoreInputEvents: true },
  );

  const saveName = useCallback(
    async (name: string) => {
      await updateElements({ id: id, name });
      stopEditingName();
      amplitudeLog('Element Name Set', {
        Name: name,
        ElementID: id,
      });
    },
    [updateElements, id, stopEditingName],
  );

  const handleNameInputClick = useMouseEventCallback(startEditingName);

  // Start editing the name if autoFocus is set
  useEffect(() => {
    autoFocus && startEditingName();
  }, [autoFocus, startEditingName]);

  return (
    <Row
      classes={listClasses}
      deactivated={deactivated}
      height={LIST_ITEM_HEIGHT}
      hover={hover}
      selected={selectMode && isSelectedVersion}
      padding={true}
      spacing={LIST_SPACING}
      onClick={onRowClick}
      onContextMenu={triggerContextMenu}
      onDoubleClick={toggleElementExpanded}
      onMouseLeave={endHover}
      onMouseOver={startHover}
    >
      {/* CONTENT. Group content in a shared cell to make sure we can align bar charts as one */}
      <RowCell
        width={ROOT_CELL_WIDTH.CONTENT}
        responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.CONTENT}
      >
        <Row height={LIST_ITEM_HEIGHT} spacing={LIST_SPACING}>
          {/* Expand icon */}
          <RowCell
            indentation={indentation} // Put indentation on the first item in row
            width={ROOT_CELL_WIDTH.ICON}
          >
            {selectMode && (
              <SelectedIcon active={isSelectedVersion} hover={hover} />
            )}
          </RowCell>

          {/* Name input */}
          <RowCell
            width={CONTENT_CELL_WIDTH.NAME}
            responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.NAME}
          >
            {selectMode ? (
              <InlineTextField
                onClick={handleNameInputClick}
                value={name}
                variant="subtitle1"
                editing={isEditingName}
                onSave={saveName}
                onCancel={stopEditingName}
                textFieldProps={nameInputProps}
              />
            ) : (
              <VersionSpoiler element={version} />
            )}
          </RowCell>

          {!selectMode && (
            <RowCell align="right">
              <ImportElements />
              <SortSelect />
            </RowCell>
          )}
        </Row>
      </RowCell>

      {/* Bar chart */}
      <RowCell
        width={ROOT_CELL_WIDTH.BAR}
        responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.BAR}
      >
        <HorizontalBarCharts
          element={version}
          hover={hover}
          renderTooltip={!selectMode ? renderTooltip : undefined}
        />
      </RowCell>

      {/* Kebab menu */}
      <RowCell width={CONTENT_CELL_WIDTH.ICON} hideOnPrint align="center">
        {(hover || isKebabMenuOpen) && (
          <BuildingVersionKebabMenu version={version} />
        )}
      </RowCell>
    </Row>
  );
};

export default memo(BuilingVersionListItem);
