import axios from 'axios';
import { devtools } from 'zustand/middleware';
import {
  IComment,
  ICommentID,
} from '../../../../shared/models/comment.interface';
import equal from 'fast-deep-equal';
import { ProjectID } from '../../../../shared/models/project.interface';
import { getErrorMessage } from '../utils';
import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

type Error = { message: string; id?: string };

interface CommentsState {
  isLoading: boolean;
  error: Error | undefined;
  comments: IComment[];

  scrollCommentsIntoView: boolean;
  setScrollCommentsIntoView: (value: boolean) => void;

  editingId: ICommentID | undefined;
  setEditingId: (id?: ICommentID) => void;

  /** fetch all comments belonging to a project */
  fetchComments: (id: ProjectID) => Promise<void>;

  createComment: (comment: IComment, errorId: string) => Promise<void>;
  updateComment: (comment: IComment) => Promise<void>;
  deleteComment: (id: ICommentID) => Promise<void>;
}

export const useCommentsStore = createWithEqualityFn<CommentsState>()(
  devtools(
    (set, get) => ({
      editingId: undefined,
      error: undefined,
      isLoading: false,
      scrollCommentsIntoView: false,
      comments: [],
      drafts: {},

      setScrollCommentsIntoView: (value) => {
        if (get().scrollCommentsIntoView !== value) {
          set(() => ({ scrollCommentsIntoView: value }));
        }
      },

      setEditingId: (id) => {
        if (get().editingId !== id) {
          set(() => ({ editingId: id }));
        }
      },

      fetchComments: async (projectId) => {
        set(() => ({ error: undefined, isLoading: true, comments: [] }));

        try {
          const { data } = await axios.get<IComment[]>('/comments', {
            params: { projectId },
          });
          const { comments: currentComments } = get();

          if (!equal(currentComments, data)) {
            set(() => ({ comments: data }));
          }
          set(() => ({ isLoading: false }));
        } catch {
          const message = getErrorMessage('get', 'comments');

          set(() => ({
            error: { message },
            isLoading: false,
          }));

          throw new Error(message);
        }
      },

      createComment: async (comment, errorId) => {
        set(() => ({ error: undefined, isLoading: true }));

        try {
          const { comments } = get();
          const { data } = await axios.post<IComment>('/comments', comment);

          set(() => ({
            isLoading: false,
            editingId: undefined,
            comments: [...comments, data],
          }));
        } catch {
          const message = getErrorMessage('create', 'comment');

          set(() => ({
            error: { id: errorId, message },
            isLoading: false,
          }));

          throw new Error(message);
        }
      },

      updateComment: async (comment) => {
        const { comments } = get();

        set(() => ({
          error: undefined,
          isLoading: true,
          comments: comments.map((c) =>
            c.id === comment.id ? { ...c, message: comment.message } : c,
          ),
        }));

        try {
          const { data } = await axios.put<IComment>('/comments', comment);

          set(() => ({
            isLoading: false,
            editingId: undefined,
            comments: comments.map((c) => (c.id === data.id ? data : c)),
          }));
        } catch {
          const message = getErrorMessage('update', 'comment');

          set(() => ({
            isLoading: false,
            error: { id: comment.id, message },
            comments,
          }));

          throw new Error(message);
        }
      },

      deleteComment: async (id) => {
        set(() => ({ error: undefined, isLoading: true }));

        try {
          const { comments } = get();
          await axios.delete(`/comments/${id}`);

          set(() => ({
            isLoading: false,
            editingId: undefined,
            comments: comments.filter((c) => c.id !== id),
          }));
        } catch {
          const message = getErrorMessage('delete', 'comment');

          set(() => ({
            isLoading: false,
            error: { message },
          }));

          throw new Error(message);
        }
      },
    }),
    { name: 'comments' },
  ),
  shallow,
);
