import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import FormNumberInput from './FormNumberInput';
import { FormInputProps } from './Form';
import { IStorey } from '../../../shared/models/project.interface';
import NodonTextField from './NodonTextField';

const FormInputs: React.FC<FormInputProps> = ({
  index,
  control,
  errors,
  placeholders,
}) => {
  const { classes } = useStoryGridStyles();

  const renderInput = useCallback(
    ({ field }: { field: ControllerRenderProps<IStorey> }) =>
      field.name !== 'name' ? (
        <FormNumberInput
          value={
            field.value === undefined || field.value === null
              ? undefined
              : (field.value as number)
          }
          placeholder={placeholders[field.name]}
          error={!!errors[field.name as keyof IStorey]}
          helperText={errors[field.name as keyof IStorey]?.message}
          unit={field.name === 'gfa' ? 'm²' : 'm'}
          onChange={field.onChange}
        />
      ) : (
        <NodonTextField
          {...field}
          value={field.value ?? ''}
          placeholder={`Storey ${index}`}
          type="text"
          size="small"
          error={!!errors[field.name as keyof IStorey]}
          helperText={errors[field.name as keyof IStorey]?.message}
        />
      ),
    [index, placeholders, errors],
  );

  return (
    <>
      {(Object.keys(placeholders) as (keyof IStorey)[]).map(
        (key, inputIndex) => (
          <Box className={classes.cell} key={inputIndex}>
            <Controller control={control} name={key} render={renderInput} />
          </Box>
        ),
      )}
    </>
  );
};

export const useStoryGridStyles = makeStyles()(() => ({
  cell: {
    alignItems: 'center',
    display: 'flex',
  },
}));

export default FormInputs;
