import { v4 } from 'uuid';
import {
  IElement,
  IElementID,
  OneOfElements,
  OneOfParentElements,
  IElementVersion,
  OneOfListElements,
} from '../models/project.interface';
import {
  OneOfSearchableElements,
  findElementAndParent,
  getChildElements,
  isElement,
} from './recursive_element_helpers';
import {
  IFactoryElement,
  addElementsToParent,
} from './element_factory_helpers';
import { EMPTY_ARRAY, isDefined } from './array_helpers';
import { getId, omit } from './object_helpers';
import { last } from 'lodash';
import { ItemOrItemId, SemiPartial } from '../models/type_helpers.interface';
import { updateChildElements } from './project_helpers';
import { findFreeName } from './string_helpers';

/**
 * Input types for getElementVersionId
 */
export type ElementOrVersionId =
  | OneOfSearchableElements
  | OneOfListElements
  | IElement['versionId']
  | undefined;

/**
 * Get element version id from an element.
 * If the input is already an element version id, it will return the same id.
 * @param elementOrVersionId
 * @returns
 */
export const getElementVersionId = (
  elementOrVersionId?: ElementOrVersionId,
): IElement['versionId'] | undefined => {
  if (typeof elementOrVersionId === 'string') {
    return elementOrVersionId;
  }
  return isElement(elementOrVersionId)
    ? elementOrVersionId.versionId
    : undefined;
};

/**
 * If an element is an element version and thus have a versionId
 * @param element
 * @returns
 */
export const isElementVersionElement = (
  element?: OneOfSearchableElements | OneOfListElements,
): element is IElementVersion => getElementVersionId(element) !== undefined;

/**
 * Test if an is an element version but an inactive one
 * @param element
 * @returns
 */
export const isInactiveElementVersion = (element: OneOfListElements): boolean =>
  !!getElementVersionId(element) && !isActiveElementVersion(element);

/**
 * Check if an element is the active version
 * @param element
 * @returns
 */
export const isActiveElementVersion = (element: OneOfListElements): boolean =>
  isElement(element) && !!element.isActiveVersion;

/**
 * Get all element versions related to a specific element
 * @param searchIn
 * @param elementOrVersionId
 */
export const getElementVersions = (
  searchIn: OneOfSearchableElements,
  elementOrVersionId: ElementOrVersionId,
): IElementVersion[] => {
  const versionId = getElementVersionId(elementOrVersionId);

  // No version exists (save cpu by aborting)
  if (!versionId) {
    return EMPTY_ARRAY as IElementVersion[];
  }

  // Find the parent of the element
  const { parent } = findElementAndParent(
    searchIn,
    (e) => getElementVersionId(e) === versionId,
  );

  // Return all child elements of the parent that are elements and have the same id as the versionId
  return getChildElements(parent)
    .filter(isElementVersionElement)
    .filter((e) => getElementVersionId(e) === versionId);
};

/**
 * Create a new version of an element and add it to the parent
 * @param parent
 * @param original
 * @returns
 */
export const addElementVersion = <R extends OneOfParentElements>(
  parent: OneOfParentElements,
  original: IElement,
): R => {
  const versionId = getElementVersionId(original) || v4();

  // If original doesn't have a versionId, we need to give it one
  if (!getElementVersionId(original)) {
    parent = updateChildElements(parent, {
      id: original.id,
      versionId,
      versionName: generateElementVersionName(
        getElementVersions(parent, versionId),
      ),
    } as SemiPartial<IElement, 'id'>);
  }

  const versionName = generateElementVersionName(
    getElementVersions(parent, versionId),
  );
  const copy: IFactoryElement = omit(
    { ...original, versionId, versionName },
    'id',
  );

  parent = addElementsToParent(parent, copy, false);

  const elementVersions = getElementVersions(parent, versionId);
  const newElementVersion = last(elementVersions);

  if (!newElementVersion || elementVersions.length < 2) {
    throw new Error('There must be at least two versions of an element');
  }

  return setActiveElementVersion(parent, newElementVersion);
};

/**
 * Cleanup obsolete version ids and set the first version as active if no version is active
 * @param parent
 * @returns
 */
export const cleanupElementVersions = <T extends OneOfParentElements>(
  parent: T,
): T => {
  const versionIds = getVersionIdsOfChildren(parent);

  for (const versionId of versionIds) {
    const elementVersions = getElementVersions(parent, versionId);
    const first = elementVersions[0];

    // Only one version => remove versionId
    if (elementVersions.length === 1) {
      parent = updateChildElements(parent, {
        ...first,
        versionId: undefined,
        isActiveVersion: false,
        versionName: undefined,
      } as IElement);
    }

    // No active version selected => select the first
    if (!elementVersions.some(isActiveElementVersion)) {
      parent = setActiveElementVersion(parent, first);
    }
  }
  return parent;
};

/**
 * Set an element as the active version
 * @param parent
 * @param element
 * @returns
 */
export const setActiveElementVersion = <R extends OneOfParentElements>(
  parent: OneOfParentElements,
  elementOrId: ItemOrItemId<OneOfElements>,
): R => {
  const elementId = getId(elementOrId);
  const element = getChildElements(parent).find((e) => getId(e) === elementId);
  const versions = getElementVersions(parent, element);
  const versionId = getElementVersionId(element);

  // Not a version element
  if (!isElement(element) || !versionId) {
    return parent as R;
  }
  // Already active
  if (
    isActiveElementVersion(element) &&
    versions.filter(isActiveElementVersion).length === 1
  ) {
    return parent as R;
  }

  const changes = versions.map((e) => ({
    id: e.id,
    isActiveVersion: e.id === elementId,
  }));

  // Only change parent if elements have changed
  return updateChildElements(parent, ...changes) as R;
};

/**
 * Generate new version ids for all elements in array.
 * Needed when copying elements to a new version, creating elements from a recipes etc
 * @param elements
 * @param regenerateIds If true it will regerate id for all elements even if they already exist
 * @returns
 */
export const generateNewElementVersionIds = <T extends OneOfElements>(
  elements: T[],
  regenerateIds = true,
): T[] => {
  if (!regenerateIds) {
    return elements;
  }
  const idMap = new Map<IElementID, IElementID>();

  const updatedElements = elements.map((element) => {
    const versionId = getElementVersionId(element);

    if (!versionId) {
      return element;
    }

    // Use existing id or generate a new one
    const newVersionId = idMap.get(versionId) ?? v4();
    idMap.set(versionId, newVersionId);

    return {
      ...element,
      versionId: newVersionId,
    } as T;
  });

  // Use the updated elements if any version id was changed
  return idMap.size > 0 ? updatedElements : elements;
};

/**
 * Get all unique version ids in the children of a parent
 * @param parent
 * @returns
 */
const getVersionIdsOfChildren = (
  parent: OneOfElements,
): IElement['versionId'][] => {
  const versionIds = new Set<IElement['versionId']>();
  getChildElements(parent).forEach((e) => {
    const versionId = getElementVersionId(e);
    if (versionId) {
      versionIds.add(versionId);
    }
  });
  return Array.from(versionIds);
};

const generateElementVersionName = (existingVersions: IElement[]): string => {
  const versionNames = existingVersions
    .map((e) => e.versionName)
    .filter(isDefined);

  return findFreeName(versionNames, 'Version 1', ' ');
};
