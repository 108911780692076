import { DependencyList } from 'react';
import shallowEqual, { isDefined } from './array_helpers';
import {
  OptionallyRequired,
  OptionallyRequiredOptions,
} from '../models/type_helpers.interface';

export type AnyFn = (...args: any[]) => any;
export type VoidFn = () => void;

/**
 * Get trigger function that calls provided function with provided arguments
 * Good to use for event handlers like onClick etc
 */
export const getTrigger = <F extends AnyFn, P extends Parameters<F>>(
  fn: F,
  ...args: P
): VoidFn => {
  if (typeof fn !== 'function') {
    throw new TypeError('Expected a function');
  }
  return () => {
    fn(...args);
  };
};

let cacheFactoryValueRecord: Record<string, unknown> = {};
export function cacheFactory<T>(
  factory: () => T,
  key: string,
  deps: DependencyList | undefined = [],
  debug = false,
): T {
  // Don't run this in test mode
  // if (IN_TEST_MODE) {
  //   return factory();
  // }

  // Run factory function first time or if deps have changed
  if (hasChangedDependencies(key, deps, debug)) {
    if (debug) {
      console.time(`cacheFactory[${key}].miss`);
    }
    cacheFactoryValueRecord[key] = factory();
    if (debug) {
      // eslint-disable-next-line no-console
      console.timeEnd(`cacheFactory[${key}].miss`);
    }
  } else if (debug) {
    // eslint-disable-next-line no-console
    console.count(`cacheFactory[${key}].hit`);
  }

  // It should only be undefined if factory returns undefined so cast to T
  return cacheFactoryValueRecord[key] as T;
}

export function cacheFactoryReset(): void {
  cacheFactoryValueRecord = {};
}

let changedDependencyRecord: Record<string, DependencyList | undefined> = {};
export function hasChangedDependencies(
  key: string,
  deps: DependencyList | undefined = [],
  debug?: boolean,
): boolean {
  const prevDeps = changedDependencyRecord[key];
  changedDependencyRecord[key] = deps;
  const hasChanged = !shallowEqual(prevDeps, deps);

  if (hasChanged && debug) {
    for (let i = 0; i < deps.length; i++) {
      const dep = deps[i];
      const prevDep = prevDeps ? prevDeps[i] : undefined;
      if (dep !== prevDep) {
        console.info(
          `hasChangedDependencies[${key}], changed:`,
          prevDep,
          'to:',
          dep,
        );
      }
    }
  }
  return hasChanged;
}

export function hasChangedDependenciesReset(): void {
  changedDependencyRecord = {};
}

/**
 * Optionally require value to be defined and crash if it's not defined
 * @param value Value to check
 * @param requireOrErrorMsg False to not require value, true to throw or string to throw with custom message
 * @returns
 */
export const required = <
  T,
  B extends OptionallyRequiredOptions = true,
  R = OptionallyRequired<T, B>,
>(
  value: T,
  requireOrErrorMsg: B = true as B,
): R => {
  if (requireOrErrorMsg && !isDefined(value)) {
    throw new Error(
      typeof requireOrErrorMsg === 'string'
        ? requireOrErrorMsg
        : 'Value is required',
    );
  }
  return value as any as R;
};
