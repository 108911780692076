import { responsiveFontSizes } from '@mui/material';
import {
  PaletteColor,
  PaletteColorOptions,
  createTheme,
} from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    menuTitle: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    menuTitle?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    menuTitle: true;
  }
}

export const NodonTheme = responsiveFontSizes(
  createTheme({
    zIndex: {
      readonlyBlocker: 9999,
    },
    typography: {
      fontSize: 12,
      fontFamily: 'Roboto',
      fontWeightRegular: 400,
      h1: {
        fontWeight: 500,
        fontSize: 30,
      },
      h2: {
        fontWeight: 500,
        fontSize: 26,
      },
      h3: {
        fontWeight: 500,
        fontSize: 22,
      },
      h4: {
        fontWeight: 500,
        fontSize: 18,
      },
      h5: {
        fontWeight: 500,
        fontSize: 16,
      },
      h6: {
        fontWeight: 500,
        fontSize: 14,
      },
      body1: {
        color: '#515151',
      },
      body2: {
        color: '#515151',
      },
      caption: {
        color: '#A7A7A7',
      },
      menuTitle: {
        color: '#A7A7A7',
        paddingLeft: 10,
      },
    },
    palette: {
      primary: {
        main: '#312884', // violet
        light: '#D5D9E8', // gray
        dark: '#bCC0CF', // dark-gray
      },
      secondary: {
        main: '#1BA1AA', // green
        light: '#F5F5F5', // dark-white
      },
      warning: {
        main: '#F6841A', // orange
      },
      background: {
        default: '#F7F7F7', // light-violet
      },
      action: {
        selected: '#D5D9E8', // gray
      },
      neutral: {
        main: 'rgba(0, 0, 0, 0.6)',
        dark: 'rgba(0, 0, 0, 0.87)',
        light: 'rgba(0, 0, 0, 0.4)',
        lighter: 'rgba(0, 0, 0, 0.2)',
        lightest: 'rgba(0, 0, 0, 0.1)',
        contrastText: '#000',
      },
      styledBackground: {
        main: '#E6E8EF', // white-violet
      },
    },
    components: {
      // Name of the component
      MuiMenuItem: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            paddingTop: 10,
            paddingBottom: 10,
          },
        },
      },
    },
    spacing: 4,
  }),
);

declare module '@mui/material/styles' {
  interface ZIndex {
    readonlyBlocker: number;
  }

  interface Palette {
    neutral: PaletteColor & { lighter: string; lightest: string };
    styledBackground: PaletteColor;
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteColorOptions & { lighter: string; lightest: string };
    styledBackground?: PaletteColorOptions;
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
    styledBackground: true;
  }
}
