import { useCallback, useMemo } from 'react';
import { TEMPORARY_ID } from '../../../shared/constants';
import { isProductCategoryElement } from '../../../shared/helpers/element_category_helpers';
import { getProductsInElement } from '../../../shared/helpers/product_helpers';
import {
  getAllProductElements,
  findElement,
} from '../../../shared/helpers/recursive_element_helpers';
import {
  IElement,
  IProductElement,
} from '../../../shared/models/project.interface';
import { getProducts } from '../store/product';
import { useAddElement, useRemoveElements } from '../store/project';
import { getSelectedVersion } from '../store/ui';
import { useElementById } from './useElement';
import { createElement } from '../../../shared/helpers/element_factory_helpers';
import { useObjectMemo } from './hooks';

// Share the promise to avoid adding multiple times
let addElementPromise: ReturnType<ReturnType<typeof useAddElement>> | undefined;

export const useTempElement = () => {
  const addElement = useAddElement();
  const removeElements = useRemoveElements({ navigate: false });

  const products = getProducts();

  const tempElement = useElementById(TEMPORARY_ID) as IElement | undefined;

  const productElements = getAllProductElements(tempElement);
  const productsInElement = useMemo(
    () =>
      productElements.length
        ? getProductsInElement(productElements[0], products)
        : [],
    [productElements, products],
  );

  const productId = useMemo(() => {
    const productElement = getAllProductElements(tempElement)[0] as
      | IProductElement
      | undefined;

    return productElement?.generic_product_id ?? productElement?.product_id;
  }, [tempElement]);

  const cleanupTempElement = useCallback(async () => {
    addElementPromise = undefined;
    if (tempElement) {
      const project = await removeElements(TEMPORARY_ID);

      if (findElement(project, TEMPORARY_ID)) {
        throw new Error('Temp element not removed');
      }
    }
  }, [removeElements, tempElement]);

  const addTempElement = useCallback(
    async (parent?: IElement): Promise<IElement> => {
      // Prevent adding multiple times
      if (tempElement) {
        return tempElement;
      }

      // Only add if it's not already in progress
      if (!addElementPromise) {
        const productCategoryProps =
          parent && isProductCategoryElement(parent)
            ? {
                category_id: parent.category_id,
                properties: parent.properties,
              }
            : {};

        const elementToAdd = createElement(
          {
            id: TEMPORARY_ID,
            isHidden: true,
            ...productCategoryProps,
          },
          false,
        );
        addElementPromise = addElement(getSelectedVersion(true), elementToAdd, {
          regenerateIds: false,
          navigate: false,
        });
      }
      const element = (await addElementPromise).element as IElement;
      addElementPromise = undefined;
      return element;
    },
    [addElement, tempElement],
  );

  return useObjectMemo({
    productId,
    tempElement,
    tempProductElement: productElements.length ? productElements[0] : undefined,
    tempProduct: productsInElement.length ? productsInElement[0] : undefined,
    addTempElement,
    cleanupTempElement,
  });
};
