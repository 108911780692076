import {
  FilterKeysOfType,
  ArrayOrSingle,
  Merge,
  NotArray,
} from '../models/type_helpers.interface';
import shallowEqual, { asArray, isDefined } from './array_helpers';
import { ListIterator, Many, orderBy } from 'lodash';

/**
 * Helper to get a typesafe key for children in a tree structure
 * Allows only keys of properties that are of the same type (but array) as the tree node itself
 */
export type ChildrenKey<T extends object & NotArray> = FilterKeysOfType<
  Merge<NonNullable<T>>, // If there are different types in T this will make sure we can still select the key from all types
  T[] | undefined
>;

export const treeIterator = <T extends object>(
  tree: ArrayOrSingle<T | undefined>,
  fn: (item: T) => void,
  childrenKey?: ChildrenKey<T>,
) => {
  const items = flattenTree(tree, childrenKey);

  // Go deeper if we have a nestedKey
  items.forEach((item) => {
    fn(item);
  });
};

/**
 * Get parent item in a flat tree. This is good to use for performance reasons
 * @param flatTree
 * @param item
 * @param childrenKey
 * @returns
 */
export const getParentInFlatTree = <T extends object>(
  flatTree: T[],
  item: T,
  childrenKey?: ChildrenKey<T>,
): T | undefined => {
  const index = flatTree.indexOf(item);
  if (!childrenKey || index === -1) {
    return undefined;
  }

  // Only items before the current item can be parents
  // reverse since closer items are at the end of the array
  const possible = flatTree.slice(0, index).reverse();
  return possible.find((parent) => {
    const children = getTreeItemChildren<T>(
      parent,
      childrenKey as ChildrenKey<T> | undefined, // Why is this needed? TS-bug?
    );
    return children.includes(item);
  });
};

/**
 * Get all parents of an item in a flattened tree (excluding itself)
 * @param flatTree
 * @param item
 * @param childrenKey
 * @returns
 */
export const getPathInFlatTree = <T extends object>(
  flatTree: T[],
  item: T,
  childrenKey?: ChildrenKey<T>,
): T[] => {
  const path: T[] = [];
  let parent: T | undefined = item;
  while ((parent = getParentInFlatTree(flatTree, parent, childrenKey))) {
    path.unshift(parent);
  }
  return path;
};

/**
 * Get children of a node in a tree structure
 * @param item
 * @param childrenKey
 * @param sorting
 * @returns
 */
export const getTreeItemChildren = <T extends object>(
  item: T,
  childrenKey?: ChildrenKey<T>,
  sorting?: SortingOption<T>[],
): T[] => {
  const children =
    !!item &&
    typeof childrenKey === 'string' &&
    childrenKey in item &&
    item[childrenKey];
  const defined = (Array.isArray(children) ? children : []) as T[];

  return sort(defined, sorting);
};

/**
 * Make a flat structure from a tree structure.
 * @param items Array of items
 * @param childrenKey Name of the property that contains children
 * @returns
 */
export const flattenTree = <T extends object>(
  tree: ArrayOrSingle<T | undefined>,
  childrenKey?: ChildrenKey<T>,
  sorting?: SortingOption<T>[],
): T[] => {
  const result: T[] = [];
  const queue = [...sort(asArray(tree).filter(isDefined), sorting)]; // Clone to not modify original
  while (queue.length > 0) {
    const current = queue.shift();
    if (!isDefined(current)) {
      continue;
    }
    result.push(current);
    queue.push(...getTreeItemChildren(current, childrenKey, sorting));
  }
  return result;
};

/**
 * Either a key or a function that returns a value to sort by
 */
export type SortByAlternative<T extends object> =
  | FilterKeysOfType<
      Merge<NonNullable<T>>, // If there are different types in T this will make sure we can still select the key from all types
      undefined | null | string | number | boolean // Types that can be sorted
    >
  | ListIterator<T, unknown>;

export type SortingOption<T extends object> = {
  id: string;

  /**
   * Property to or function (for complex sorting) to sort by
   */
  property?: SortByAlternative<T>;

  /**
   * Direction to sort by. Default is 'asc' (ascending: a, b, c, 1, 2, 3 etc)
   */
  direction?: SortDirection;

  /**
   * Turn on/off sorting for this option
   */
  disabled?: boolean;
};

export type SortBy<T extends object> = Many<ListIterator<T, unknown>>;
export type SortDirection = 'asc' | 'desc';

export const sort = <T extends object>(
  items: T[],
  options: SortingOption<T>[] = [],
): T[] => {
  const filtered = options.filter((o) => !o.disabled);

  // No sorting
  if (!filtered.length) {
    return items;
  }

  const properties = filtered.map((o) => o.property) as SortBy<T>[];
  const directions = filtered.map((o) => o.direction ?? 'asc');
  const sorted = orderBy(items, properties, directions) as T[];

  // If sorting hasn't changed anything use the original array
  return shallowEqual(sorted, items) ? items : sorted;
};
