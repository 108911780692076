import { getQuantityFromConversionFactorsRecord } from '../helpers/calculation_helpers';
import { convert } from '../helpers/conversion_helpers';
import { ElementCategoryID } from '../models/element_categories.interface';
import { OneOfParentElements } from '../models/project.interface';
import { ConversionFactorQuantityRecord } from '../models/unit.interface';
import { isCategoryOrChildOf } from '../templates/categories';
import { ActivityId, Eurokod1 } from '../models/activity_class.interface';
import { getEurokod1ById } from './activity_class';

export enum LoadType {
  /**
   * Egenvikt
   */
  DeadWeight = 'deadweight',

  /**
   * Golvbeläggning
   */
  Flooring = 'flooring',

  /**
   * Nyttolast
   */
  Payload = 'payload',

  /**
   * Snövik
   */
  Snow = 'snow',

  /**
   * Wind
   */
  Wind = 'wind',

  /**
   *
   */
  Temperature = 'temperature',

  /**
   * Olyckslast
   */
  Accident = 'accident',
}

/**
 * How long a load is affecting the structure
 */
export enum LoadDuration {
  /**
   * Permanent load is something that never changes,
   * like the weight of the element itself
   */
  Permanent = 'P',

  /**
   * A load that will affect an element for a long time
   * like items at a Warehouse floor
   */
  Long = 'L',

  /**
   * A load that will stay for a quite long time, like snow on a roof
   */
  Medium = 'M',

  /**
   * A load that will be removed after a short time like wind
   */
  Short = 'S',

  /**
   * A temporary load, like from an accident
   */
  Instant = 'I',
}

interface ILoad {
  /**
   * Distributed load over an area(kN/m2)
   */
  distributed: number;

  /**
   * Concentrated load (kN)
   */
  concentrated: number;
}

/**
 * Get how long a load of a certain type is expected to affect the element
 * @param type
 * @param activityId
 * @returns
 */
export const getLoadDuration = (
  type: LoadType,
  activityId: ActivityId,
): LoadDuration => {
  switch (type) {
    case LoadType.DeadWeight:
    case LoadType.Flooring:
      return LoadDuration.Permanent;
    case LoadType.Payload:
      if (activityId === ActivityId.Warehouse) {
        return LoadDuration.Long;
      }
      return LoadDuration.Medium;
    case LoadType.Wind:
      return LoadDuration.Short;
    case LoadType.Accident:
    default:
      return LoadDuration.Medium;
  }
};

const createLoad = (distributed: number, concentrated: number): ILoad => ({
  distributed,
  concentrated,
});

export const getDeadWeight = (
  element: OneOfParentElements,
  quantitiesRecord: ConversionFactorQuantityRecord,
): number => {
  const kgPerM2 = getQuantityFromConversionFactorsRecord(
    quantitiesRecord,
    element.id,
    'kg',
  );

  return convert(kgPerM2 + 10, 'kg', 'kN');
};

// http://www.prefabsystem.se/wp-content/uploads/2016/04/Eurokodhandbok-Allmanna-grunder-och-laster-EKS6.pdf (page 65)
// returns [utbredd last (kN/m2), konc. last (kN)]
export const getRecommendedUsefulLoad = (
  activityId: ActivityId,
  categoryId: ElementCategoryID,
): ILoad | undefined => {
  const eurokod = getEurokod1ById(activityId);

  switch (categoryId) {
    case ElementCategoryID.Balcony:
      return createLoad(3.5, 2);
    case ElementCategoryID.Stairs:
      return createLoad(2, 2);
    // Tak
    case ElementCategoryID.Roof:
    case ElementCategoryID.RoofCladding:
      if (eurokod === Eurokod1.RooftopInaccessible) {
        return createLoad(0.4, 0.1);
      }
  }

  // Bjälklag
  if (isCategoryOrChildOf(categoryId, ElementCategoryID.FramingOfJoists)) {
    switch (eurokod) {
      case Eurokod1.Residential:
        return createLoad(2, 2);
      case Eurokod1.Office:
      case Eurokod1.GatheringTables:
      case Eurokod1.GatheringFixedSeats:
        return createLoad(2.5, 3);
      case Eurokod1.GatheringNoObstacles:
        return createLoad(3, 3);
      case Eurokod1.GatheringPhysicalActivity:
        return createLoad(4, 4);
      case Eurokod1.GatheringCrowded:
        return createLoad(5, 4.5);
      case Eurokod1.BusinessRetail:
        return createLoad(4, 4);
      case Eurokod1.BusinessShoppingCenter:
      case Eurokod1.Industry:
        return createLoad(5, 7);
      case Eurokod1.Vehicle:
        return createLoad(2.5, 20);
      case Eurokod1.VehicleHeavy:
        return createLoad(5, 90);
    }
  }

  throw new Error(
    `Could not get load for activity ${activityId} and category ${categoryId}`,
  );
};
