import {
  OneOfElements,
  IProductElement,
  ElementKind,
  IBuilding,
  IElement,
  OneOfChildElements,
  IBuildingVersion,
  OneOfParentElements,
  ICountAndUnit,
  IRecipeElement,
  Project,
  IElementID,
  ExpressionValue,
  OneOfPropertyElements,
  OneOfElementListElements,
  OneOfListElements,
} from '../models/project.interface';
import { EMPTY_ARRAY, asArray, isDefined, typeFilter } from './array_helpers';
import * as uuid from 'uuid';
import { escapeRegExp, isObject, last } from 'lodash';
import {
  isElementExpressionProperty,
  getElementProperties,
} from './element_property_helpers';
import { ArrayOrSingle, ItemOrItemId } from '../models/type_helpers.interface';
import { PathRecord } from './element_category_helpers';
import { getElementQuantityProperties } from './element_quantity_helpers';
import { Recipe } from '../models/recipe.interface';
import { isRecipe } from './recipe_helpers';
import { getId, hasDefinedProperties } from './object_helpers';
import { isProjectInfoOrFolder } from './project-folder.helpers';

export type OneOfSearchableElements =
  | OneOfElements
  | IBuilding
  | Project
  | Recipe;
type FindFn<T> = (
  el: T,
  path: OneOfParentElements[],
) => boolean | void | undefined;
type ForEachFn<T> = (el: T, path: OneOfParentElements[]) => any;

const getFindFn = <T extends OneOfElements = OneOfElements>(
  fnOrId: FindFn<T> | IElementID,
): FindFn<T> =>
  typeof fnOrId === 'string' ? (el: T) => el.id === fnOrId : fnOrId;

/**
 * Recursive find function works like Array.find
 * but for all elements in element tree. Will include root element (buildingVersion) in the search
 * @param searchIn
 * @param fnOrId Function to find element or id to search for
 * @returns
 */
export const findElement = (
  searchIn: OneOfSearchableElements | undefined,
  fnOrId: FindFn<OneOfElements> | IElementID | undefined,
  path: OneOfParentElements[] = [],
): OneOfElements | undefined => {
  if (!searchIn) {
    return undefined;
  }
  if (!fnOrId) {
    return undefined;
  }
  const fn: FindFn<OneOfElements> = getFindFn(fnOrId);

  if (isOneOfElements(searchIn) && fn(searchIn, path)) {
    return searchIn;
  } else {
    const children = getSearchableChildren(searchIn);
    const childPath = isOneOfParentElements(searchIn)
      ? [...path, searchIn]
      : path;
    // Use a for loop instead of forEach to avoid nested functions
    // Otherwise "return" will not work properly
    for (const child of children) {
      // Search in the current child
      const result = findElement(child, fn, childPath);

      // Abort search if the element has been found
      if (result !== undefined) {
        return result;
      }
    }
  }
};

export const findElementAndParent = (
  searchIn: OneOfSearchableElements | undefined,
  fnOrId: FindFn<OneOfElements>,
): {
  element: OneOfElements | undefined;
  path: OneOfParentElements[];
  parent: OneOfParentElements | undefined;
} => {
  const fn = getFindFn(fnOrId);
  let path: OneOfParentElements[] = [];

  const element = findElement(searchIn, (el, p) => {
    if (fn(el, p)) {
      path = p;
      return true;
    }
  });
  return {
    element,
    path,
    parent: last(path),
  };
};

export const getBuilding = (project: Project): IBuilding => {
  const building = project.buildings[0];

  if (!building) {
    throw new Error('no building found');
  }
  return building;
};

/**
 * Recursive find function works like Array.find
 * but for all elements in element tree including searchIn element
 * @param searchIn
 * @param fn
 * @returns
 */
export const forEachElement = (
  searchIn: OneOfSearchableElements | undefined,
  fn: ForEachFn<OneOfElements>,
  path: OneOfParentElements[] = [],
): void => {
  if (!searchIn) {
    return;
  }
  if (isOneOfElements(searchIn)) {
    fn(searchIn, path);
  }
  const childPath = isOneOfParentElements(searchIn)
    ? [...path, searchIn]
    : path;
  getSearchableChildren(searchIn).forEach((e) =>
    forEachElement(e, fn, childPath),
  );
};

export const filterElements = (
  searchIn: OneOfSearchableElements,
  fn: FindFn<OneOfElements>,
): OneOfElements[] => {
  const elements: OneOfElements[] = [];

  forEachElement(searchIn, (el, path) => {
    if (fn(el, path)) {
      elements.push(el);
    }
  });

  return elements;
};

/**
 * Get all parents of a certain element as an array (excluding self).
 * First item in path is a IBuildingVersion if searchIn is a project, building or version.
 * Return undefined if element is not found.
 * @param root
 * @param elementOrId
 * @returns
 */
export const getPathToElement = <
  T extends true | false | undefined = true,
  R = T extends true
    ? OneOfParentElements[]
    : OneOfParentElements[] | undefined,
>(
  searchIn: OneOfSearchableElements,
  elementOrId: ItemOrItemId<OneOfElements> | undefined,
  throwIfNotFound: T = true as T,
): R => {
  let currentPath: OneOfParentElements[] | undefined;
  if (elementOrId) {
    const elementId = getId(elementOrId);
    findElement(searchIn, (e, path) => {
      // Find by id to avoid comparing objects (will be more tolerant to changes)
      if (e.id === elementId) {
        currentPath = path;
        return true;
      }
    });
  }
  if (throwIfNotFound && !currentPath) {
    throw new Error('Could not get path of element not found');
  }
  return currentPath as R;
};

/**
 * Get element in tree structure by id
 * @param element
 * @param id
 * @returns
 */
export const getElementById = (
  searchIn: OneOfSearchableElements | undefined,
  id: OneOfElements['id'] | undefined,
): OneOfElements | undefined =>
  id ? findElement(searchIn, (e) => e.id === id) : undefined;

/**
 * Similar to getElementById but also returns the path to the element
 * @param searchIn
 * @param id
 * @returns
 */
export const getPathAndElementById = (
  searchIn: OneOfSearchableElements | undefined,
  id: OneOfElements['id'] | undefined,
): ReturnType<typeof findElementAndParent> =>
  findElementAndParent(searchIn, (e) => e.id === id);

/**
 * Get parent of an element if it exists (versions won't have a parent)
 * @param searchIn
 * @param element
 * @returns
 */
export const getParentElement = (
  searchIn: OneOfSearchableElements,
  elementOrId: ItemOrItemId<OneOfElements> | undefined,
): OneOfParentElements | undefined =>
  elementOrId ? last(getPathToElement(searchIn, elementOrId)) : undefined;

export const getBuildingVersionFromChild = (
  project: Project | IBuilding | IBuildingVersion,
  child: OneOfElements,
): IBuildingVersion => {
  const version = getPathToElement(project, child)[0];
  if (!isBuildingVersionElement(version)) {
    throw new Error('Could not find building version for child');
  }
  return version;
};

/**
 * Get Main Category of an element (recursive)
 * @param version
 * @param element
 * @returns
 */
export const getMainCategoryElementAncestor = <T extends OneOfElements>(
  version: IBuildingVersion,
  element: T | undefined,
): IElement | undefined => {
  const parent = getParentElement(version, element);

  if (isElement(parent) && parent?.category_id) {
    return parent;
  } else if (
    isBuildingVersionElement(element) ||
    isBuildingVersionElement(parent) ||
    !parent
  ) {
    return undefined;
  } else {
    return getMainCategoryElementAncestor(version, parent);
  }
};

/**
 * Get all elements with children in a flat array.
 * Will be in the order of the element list ()
 * @param elements
 * @returns
 */
export const flattenElements = <
  T extends OneOfSearchableElements,
  R = T extends OneOfChildElements ? OneOfChildElements : OneOfElements,
>(
  ...elements: (T | undefined)[]
): R[] => {
  const searchIn = getSearchRoot(...elements);
  const elementsWithChildren = searchIn.map((element) => [
    element,
    ...flattenElements(...getChildElements(element)),
  ]);

  return elementsWithChildren.flat() as R[];
};

export const getAllProductElements = (
  ...element: (OneOfSearchableElements | undefined)[]
): IProductElement[] => {
  return flattenElements(...element).filter(isProductElement);
};

export const getAllRecipeElements = (
  recipe?: Recipe,
  ...element: OneOfSearchableElements[]
): IElement[] => {
  return flattenElements(...element)
    .filter(isElement)
    .filter((recipeElement) => recipeElement.recipe_id === recipe?.id);
};

/**
 * Helper to avoid having to if case products all the time
 * @param element
 * @returns
 */
export const isParentElement = <T extends OneOfListElements = IElement>(
  element: OneOfSearchableElements | OneOfListElements | undefined,
): element is OneOfParentElements<T> => {
  return !!element && 'elements' in element && !!element.elements;
};

export const isOneOfParentElements = (
  element?: OneOfSearchableElements | OneOfListElements,
): element is OneOfParentElements => {
  return isElement(element) || isBuildingVersionElement(element);
};

export const isOneOfElements = (
  element?: OneOfSearchableElements | OneOfListElements,
): element is OneOfElements => {
  return isOneOfChildElements(element) || isBuildingVersionElement(element);
};

export const isOneOfChildElements = (
  element?: OneOfSearchableElements | OneOfListElements,
): element is OneOfChildElements => {
  return (
    isElement(element) ||
    isProductElement(element) ||
    isProjectInfoOrFolder(element)
  );
};

export const isOneOfPropertyElements = (
  element?: OneOfSearchableElements,
): element is OneOfPropertyElements =>
  isElement(element) || isBuildingVersionElement(element);

export const isProject = (
  element?: OneOfSearchableElements,
): element is Project => {
  return (
    isObject(element) &&
    'buildings' in element &&
    Array.isArray(element.buildings)
  );
};

export const isBuilding = (
  element?: OneOfSearchableElements,
): element is IBuilding => {
  return (
    isObject(element) &&
    'versions' in element &&
    Array.isArray(element.versions)
  );
};

export const isBuildingVersionElement = (
  element?: OneOfSearchableElements | OneOfListElements,
): element is IBuildingVersion => {
  return getElementKind(element) === ElementKind.Version;
};

export const isProductElement = (
  element?: unknown,
): element is IProductElement => {
  return getElementKind(element) === ElementKind.Product;
};

export const isRecipeElement = (
  element?: OneOfElements,
): element is IRecipeElement => {
  return isElement(element) && !!element.recipe_id;
};

export const isElement = (element?: unknown): element is IElement =>
  getElementKind(element) === ElementKind.Element;

/**
 * TODO: Check if element have count and unit
 * @param element
 * @returns
 */
export const isCountAndUnitElement = <T extends OneOfElementListElements>(
  element?: T,
): element is T & Required<ICountAndUnit> => {
  return hasDefinedProperties(element as IElement, 'count', 'unit');
};

/**
 * Get root children of all versions
 * @param versions
 * @returns
 */
export const getChildElementsOfVersions = (
  ...versions: IBuildingVersion[]
): IElement[] => {
  return versions.reduce((elements, version) => {
    // TODO: Now it should only be IElement as childs of Versions.
    elements.push(...getChildElements(version).filter(isElement));
    return elements;
  }, [] as IElement[]);
};

/**
 * Helper to avoid having to if case products all the time
 * @param element
 * @returns
 */
export const getChildElements = <T extends OneOfListElements>(
  element?: T,
): OneOfChildElements<T>[] => {
  return (
    (element &&
      isParentElement<T>(element) &&
      (element.elements as OneOfChildElements<T>[])) ||
    []
  );
};

export const hasChildren = (element?: OneOfListElements): boolean =>
  getChildElements(element).length > 0;

/**
 * Get root elements that can be used by findElements/flattenElements etc.
 * @param element
 * @returns
 */
const getSearchRoot = (
  ...elements: (OneOfSearchableElements | undefined)[]
): OneOfElements[] =>
  elements
    .filter(isDefined)
    .map((element) => {
      if (isRecipe(element)) {
        return element.elements ?? [];
      }
      return isOneOfElements(element)
        ? [element]
        : getAllBuildingVersions(element);
    })
    .flat();

/**
 * Get children of element, if element is a building or project it will return all versions
 * @param element
 * @returns
 */
const getSearchableChildren = (
  element: OneOfSearchableElements | undefined,
): OneOfElements[] => {
  if (isRecipe(element)) {
    return element.elements ?? [];
  }
  return isOneOfElements(element)
    ? getChildElements(element)
    : getAllBuildingVersions(element);
};

/**
 * Get elements who is parent from the list of elements, IE. Ignoring products
 * Note that it filters on type so an empty array also counts as children.
 * @param element
 */
export const getChildrenWithChildren = (
  element: OneOfElements,
): OneOfParentElements[] => {
  return typeFilter(getChildElements(element), isParentElement);
};

export const getAllBuildingVersions = (
  versionParent:
    | Project
    | IBuilding
    | IBuildingVersion[]
    | IBuildingVersion
    | undefined,
): IBuildingVersion[] => {
  if (!versionParent) {
    return EMPTY_ARRAY as IBuildingVersion[];
  }
  if (Array.isArray(versionParent)) {
    return versionParent;
  }
  if (isBuildingVersionElement(versionParent)) {
    return [versionParent];
  }

  const buildings = isBuilding(versionParent)
    ? [versionParent]
    : versionParent.buildings ?? [];

  const versionsInBuildings = buildings.map((building) => building.versions);

  // Use original array to avoid creating new array if possible, better for useMemo etc
  return versionsInBuildings.length === 1
    ? versionsInBuildings[0]
    : versionsInBuildings.flat();
};

export const getBuildingVersionById = <
  T extends true | false | undefined = false,
  R = T extends true ? IBuildingVersion : IBuildingVersion | undefined,
>(
  projectOrBuilding: Project | IBuilding | IBuildingVersion[] | undefined,
  id: IElementID | undefined,
  throwIfNotFound: T = false as T,
): R => {
  const version = getAllBuildingVersions(projectOrBuilding).find(
    (v) => v.id === id,
  );
  if (throwIfNotFound && !version) {
    throw new Error('Could not find version');
  }
  return version as R;
};

export const regenerateIds = (project: Project): Project => {
  flattenElements(project).forEach((el) => (el.id = uuid.v4()));

  return project;
};

export const getElementKind = (element?: unknown): ElementKind | undefined =>
  isObject(element) && 'kind' in element && typeof element.kind === 'string'
    ? (element.kind as ElementKind)
    : undefined;

export const getAllElementExpressionValues = (
  root: OneOfSearchableElements,
): ExpressionValue[] => {
  const allExpressionValues: ExpressionValue[] = [];

  flattenElements(root).forEach((element: OneOfElements) => {
    if ('count' in element && element.count && 'expression' in element.count) {
      allExpressionValues.push(element.count);
    }
    [...getElementProperties(element), ...getElementQuantityProperties(element)]
      .filter(isElementExpressionProperty)
      .forEach(({ count, fallbackCount }) => {
        if (count !== undefined) {
          allExpressionValues.push(count);
        }
        if (fallbackCount !== undefined) {
          allExpressionValues.push(fallbackCount);
        }
      });
  });

  return allExpressionValues;
};

export const getReplacedExpression = (
  expression: string,
  variablesToReplace: string[],
  replacement: string,
): string =>
  expression.replaceAll(
    new RegExp(
      `(?<=[^a-zåäö0-9_]|^)(${variablesToReplace
        .map(escapeRegExp)
        .join('|')})(?=[^a-zåäö0-9_]|$)`,
      'ig',
    ),
    replacement,
  );

/**
 * Replace variables in expressions.
 * Note: This will modify the project in place so be careful
 * @param project
 * @param replacements
 */
export const replaceExpressionVariables = (
  project: Project | Recipe,
  replacements: { variablesToReplace: string[]; replacement: string }[],
): void => {
  const allElementExpressionValues = getAllElementExpressionValues(project);

  replacements.forEach(({ variablesToReplace, replacement }) =>
    allElementExpressionValues.forEach((value) => {
      value.expression = getReplacedExpression(
        value.expression,
        variablesToReplace,
        replacement,
      );
    }),
  );
};

/**
 * Get how many levels of children an element have
 * @param element
 * @returns
 */
export const getElementChildrenDepth = (element?: OneOfElements): number => {
  let depth = 0;
  forEachElement(element, (_el, path) => {
    depth = Math.max(depth, path.length);
  });
  return depth;
};

/**
 * Get the path down to each product element
 * @param rootElements One or more elements to search from
 * @param getPathToAllElements Also get path for root elements (except version elements)
 * @returns
 */
export const getProductElementPathsRecord = (
  rootElements: ArrayOrSingle<OneOfElements> | undefined,
  getPathToAllElements = false,
): PathRecord => {
  const record = {} as Record<IElementID, OneOfParentElements[]>;

  asArray(rootElements).forEach((root) => {
    forEachElement(root, (el, path) => {
      const hasProductElements = flattenElements(el).some(isProductElement);
      const isRootElement =
        !isBuildingVersionElement(el) && !hasProductElements;

      if ((getPathToAllElements && isRootElement) || isProductElement(el)) {
        record[el.id] = path;
      }
    });
  });

  return record;
};
