import React, { useCallback, useMemo, useState, MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Product } from '../../../../shared/models/product.interface';
import {
  IconButton,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  ListItemIcon,
  Box,
  Tooltip,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { MoreVert, Edit, DeleteForever, FileCopy } from '@mui/icons-material';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import HorizontalBarCharts from '../../components/charts/HorizontalBarCharts';
import { QuantityUnit } from '../../../../shared/models/unit.interface';
import { useProductConversionFactorsRecord } from '../../hooks/useProducts';
import amplitudeLog from '../../amplitude';
import { useSelectedOrganization } from '../../store/organization';
import { itemIsReadonly } from '../../../../shared/helpers/organization_helpers';
import { useIsConfirmed } from '../../hooks/confirm.hook';
import { openEditProductDialog } from './EditProductDialog';

const useStyles = makeStyles()({
  listItem: {
    gap: '2rem',
  },
  listItemText: {
    flex: '0 0 35%',
  },
  descriptionTooltip: {
    maxWidth: 600,
  },
});

interface SelectProductListProps {
  products: Product[];
  productSelectorIsOpen: boolean;
  selectedUnit: QuantityUnit;
  productId?: string;
  onSelect: (product: Product, applyEPD?: boolean) => void;
  onDelete: (product: Product) => void;
}

interface ContextMenuProps {
  onEdit: () => void;
  onDuplicate: () => void;
  onDelete: () => void;
}

// TODO, use new kebab menu
const ContextMenu: React.FC<ContextMenuProps> = ({
  onEdit,
  onDuplicate,
  onDelete,
}) => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | undefined>(
    undefined,
  );
  const handleMenuClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>): void => {
      e.stopPropagation();
      e.preventDefault();
      setMenuAnchor(e.currentTarget);
    },
    [],
  );

  const handleEditClick = useCallback(
    (e: MouseEvent<HTMLLIElement>): void => {
      e.stopPropagation();
      e.preventDefault();
      setMenuAnchor(undefined);
      onEdit();
    },
    [onEdit],
  );

  const handleDuplicateClick = useCallback(
    (e: MouseEvent<HTMLLIElement>): void => {
      e.stopPropagation();
      e.preventDefault();
      setMenuAnchor(undefined);
      onDuplicate();
    },
    [onDuplicate],
  );

  const handleDeleteClick = useCallback(
    (e: MouseEvent<HTMLLIElement>): void => {
      e.stopPropagation();
      e.preventDefault();
      setMenuAnchor(undefined);
      onDelete();
    },
    [onDelete],
  );

  const handleClose = useCallback(
    (event: MouseEvent<HTMLLIElement>, reason: string): void => {
      if (reason === 'backdropClick') {
        event.stopPropagation();
        event.preventDefault();
      }

      setMenuAnchor(undefined);
    },
    [],
  );

  return (
    <>
      <IconButton onClick={handleMenuClick} size="large">
        <MoreVert />
      </IconButton>
      <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={handleClose}>
        <MenuItem onClick={handleEditClick}>
          <ListItemIcon>
            <Edit color="secondary" fontSize="small" />
          </ListItemIcon>
          <FormattedMessage id="product_list.edit" defaultMessage="Edit" />
        </MenuItem>
        <MenuItem onClick={handleDuplicateClick}>
          <ListItemIcon>
            <FileCopy color="secondary" fontSize="small" />
          </ListItemIcon>
          <FormattedMessage
            id="product_list.duplicate"
            defaultMessage="Duplicate"
          />
        </MenuItem>
        <MenuItem onClick={handleDeleteClick}>
          <ListItemIcon>
            <DeleteForever color="secondary" fontSize="small" />
          </ListItemIcon>
          <FormattedMessage id="product_list.delete" defaultMessage="Delete" />
        </MenuItem>
      </Menu>
    </>
  );
};

const SelectProductList: React.FC<SelectProductListProps> = ({
  products,
  productSelectorIsOpen,
  selectedUnit,
  productId,
  onSelect,
  onDelete,
}) => {
  const { classes } = useStyles();
  const selectedOrganization = useSelectedOrganization();

  const confirm = useIsConfirmed();

  const quantityRecord = useProductConversionFactorsRecord(selectedUnit);

  const customMaxCO2e = useMemo(
    () =>
      Math.max(...products.map((p) => quantityRecord[p.id]['co2e_total'] ?? 0)),
    [products, quantityRecord],
  );
  const customMaxCost = useMemo(
    () =>
      Math.max(...products.map((p) => quantityRecord[p.id]['sek_A1-A3'] ?? 0)),
    [products, quantityRecord],
  );

  const handleDeleteProduct = useCallback(
    async (product: Product) => {
      if (
        !(await confirm({
          title: `Delete ${product.name || 'product'}`,
          description: `Are you sure you want to delete ${
            product.name || 'this product'
          }?`,
          cancellationText: 'Cancel',
          confirmationText: 'Delete',
        }))
      ) {
        return;
      }
      onDelete(product);
      amplitudeLog('Custom Product Delete');
    },
    [confirm, onDelete],
  );

  const editProduct = useCallback(
    async (product: Product, duplicate = false) => {
      const modifiedProduct = await openEditProductDialog({
        product,
        duplicate,
      });
      if (modifiedProduct) {
        onSelect(modifiedProduct);
      }
    },
    [onSelect],
  );

  const ProductRenderer = useCallback(
    ({ index, style }: ListChildComponentProps) => {
      const product = products[index];
      const productQuantity = quantityRecord[product.id] ?? {};
      const productCO2e = productQuantity['co2e_total'] ?? 0;
      const productCost = productQuantity['sek_A1-A3'] ?? 0;

      return (
        <Tooltip
          title={product.description}
          placement="bottom-start"
          disableInteractive
          classes={{ tooltip: classes.descriptionTooltip }}
        >
          <ListItem
            button
            selected={product.id === productId}
            style={style}
            className={classes.listItem}
            key={index}
            onClick={() => onSelect(product, true)}
          >
            <ListItemText
              primary={product.name}
              className={classes.listItemText}
            />
            {productCO2e > 0 && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                height="100%"
              >
                <HorizontalBarCharts
                  co2e={productCO2e}
                  cost={productCost}
                  customMaxCO2e={customMaxCO2e}
                  customMaxCost={customMaxCost}
                  selectedUnit={selectedUnit}
                />
              </Box>
            )}
            {product.source === 'custom' &&
              !itemIsReadonly(selectedOrganization, product) && (
                <ContextMenu
                  onDelete={() => handleDeleteProduct(product)}
                  onEdit={() => editProduct(product)}
                  onDuplicate={() => editProduct(product, true)}
                />
              )}
          </ListItem>
        </Tooltip>
      );
    },
    [
      products,
      quantityRecord,
      classes.descriptionTooltip,
      classes.listItem,
      classes.listItemText,
      productId,
      customMaxCO2e,
      customMaxCost,
      selectedUnit,
      selectedOrganization,
      onSelect,
      handleDeleteProduct,
      editProduct,
    ],
  );

  const fixedSizeRenderer: (size: Size) => React.ReactElement = useCallback(
    ({ height, width }) => {
      return (
        <FixedSizeList
          height={height || '0'}
          width={width || '0'}
          itemSize={55}
          itemCount={products.length}
          itemData={products}
        >
          {ProductRenderer}
        </FixedSizeList>
      );
    },
    [ProductRenderer, products],
  );

  if (!productSelectorIsOpen) {
    return null;
  }

  return <AutoSizer>{fixedSizeRenderer}</AutoSizer>;
};

export default SelectProductList;
