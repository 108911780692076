import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

export interface ISnackbarOptions {
  errorMessage?: string;
  logError?: boolean;
  successMessage?: string;
}

type ErrorSnackbarCallback = <T = void>(
  callback: () => T,
  options?: ISnackbarOptions,
) => T;

type PromiseSnackbarCallback = <T = void>(
  promiseOrCallback: (() => Promise<T>) | Promise<T>,
  options?: ISnackbarOptions,
) => Promise<T>;

export function usePromiseSnackbar(): PromiseSnackbarCallback {
  const { enqueueSnackbar } = useSnackbar();

  return useCallback(
    async <T = void,>(
      promiseOrCallback: (() => Promise<T>) | Promise<T>,
      options?: ISnackbarOptions,
    ): Promise<T> => {
      try {
        // If providing a callback we can catch errors happening in the callback as well (like validation errors)
        const promise =
          typeof promiseOrCallback === 'function'
            ? promiseOrCallback()
            : promiseOrCallback;
        const response = await promise;
        const message = options?.successMessage;

        if (message) {
          enqueueSnackbar(message, {
            variant: 'success',
            autoHideDuration: 5000,
          });
        }
        return response;
      } catch (error) {
        const errorMessage = getErrorResponseMessage(error);
        const userMessage = options?.errorMessage || errorMessage;

        if (options?.logError && errorMessage) {
          console.error(errorMessage);
        }

        enqueueSnackbar(userMessage, {
          variant: 'error',
          autoHideDuration: 5000,
        });

        return Promise.reject(error);
      }
    },
    [enqueueSnackbar],
  );
}

/**
 * Show a snackbar with the error message if the callback throws an error
 * @returns
 */
export function useErrorSnackbar(): ErrorSnackbarCallback {
  const { enqueueSnackbar } = useSnackbar();

  return useCallback(
    <T = void,>(callback: () => T, options?: ISnackbarOptions): T => {
      try {
        return callback();
      } catch (error) {
        const message = options?.errorMessage || getErrorResponseMessage(error);

        enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 5000,
        });

        // Make sure to bubble error for sentry to capture
        throw error;
      }
    },
    [enqueueSnackbar],
  );
}

const getErrorResponseMessage = (error: unknown): string => {
  let responseMessage = 'Something went wrong';

  if (error instanceof AxiosError) {
    const { request } = error;

    if (!request || (request.response as string).startsWith('<')) {
      return responseMessage;
    }
    const { error: serverErrorMessage } = JSON.parse(request.response) as {
      error: string;
    };
    responseMessage = serverErrorMessage;
  }
  if (error instanceof Error && error.name === 'Error') {
    if (error.message.includes('Missing Refresh Token')) {
      location.reload();
      return 'Your session has experied.';
    }
    responseMessage = error.message;
  }
  return responseMessage;
};
