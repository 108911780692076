import { Box, List, ListItem, ListItemText } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  ActiveDataPoint,
  ActiveElement,
  ArcElement,
  Chart,
  ChartData,
  ChartEvent,
  ChartOptions,
  ScriptableContext,
} from 'chart.js';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Doughnut } from 'react-chartjs-2';
import { formatThousands } from '../../../../shared/helpers/math_helpers';
import { useTrigger } from '../../hooks/hooks';
import { useVersionVariables } from '../../hooks/useElementExpressionVariables';
import { Project } from '../../../../shared/models/project.interface';
import { isEqual } from 'lodash';

Chart.register(ArcElement);

interface ChartContentData {
  title: string;
  mainContent: string;
  subtitle: string;
  unit?: string;
}

interface GFAChartProps {
  project: Project;
}

const GFAChart: FC<GFAChartProps> = ({ project }) => {
  const { classes } = useStyles();
  const chartRef = useRef<Chart<'doughnut'>>();

  const {
    gfa,
    gfa_activities,
    la_apartments,
    gfa_stairwells,
    gfa_facades,
    gfa_above_ground,
    gfa_below_ground,
    getActivity,
  } = useVersionVariables();

  const defaultContent = getDefaultContent(gfa_activities);

  const [fixedContent, setFixedContent] = useState<
    ChartContentData | undefined
  >(undefined);

  const [previewContent, setPreviewContent] = useState<
    ChartContentData | undefined
  >(defaultContent);

  const [activeElementPoint, setActiveElementPoint] =
    useState<ActiveDataPoint>();

  const [activeHoverElementPoint, setActiveHoverElementPoint] =
    useState<ActiveDataPoint>();

  const getChartElementContent = useCallback(
    (element: ActiveElement, gfa: number): ChartContentData | undefined => {
      if (!element || !chartRef?.current) {
        return undefined;
      }

      const { datasetIndex, index: dataIndex } = element;
      const { datasets, labels } = chartRef.current.data;

      if (!datasets || !labels) {
        return emptyContent;
      }
      const label = (labels as string[][])[datasetIndex][dataIndex];
      const value = datasets[datasetIndex].data[dataIndex];

      return getContent(label, typeof value === 'number' ? value : 0, gfa);
    },
    [chartRef],
  );

  const handleChartEvent = useCallback(
    (
      elements: ActiveElement[],
      gfa: number,
      previousContent: ChartContentData | undefined,
      setContent: (content: ChartContentData | undefined) => void,
    ): void => {
      const element = elements[0];

      const content = getChartElementContent(element, gfa);

      if (!isEqual(content, previousContent)) {
        setContent(content);
      }
    },
    [getChartElementContent],
  );

  const handleInnerContentClick = useCallback(() => {
    setFixedContent(undefined);
    setActiveElementPoint(undefined);
  }, []);

  const handleGraphMouseOut = useTrigger(setPreviewContent, undefined);

  const handleGraphClick = useCallback(
    (event: ChartEvent, elements: ActiveElement[]) => {
      const activeElement = elements[0];

      if (!activeElement) {
        setActiveElementPoint(undefined);
        setFixedContent(undefined);
        return;
      }

      const { datasetIndex, index } = activeElement;
      const newActiveElementPoint = {
        datasetIndex,
        index,
      };

      setActiveElementPoint(
        isEqual(activeElementPoint, newActiveElementPoint)
          ? undefined
          : newActiveElementPoint,
      );

      setPreviewContent(undefined);
    },
    [activeElementPoint],
  );

  const handleGraphHover = useCallback(
    (event: ChartEvent, elements: ActiveElement[]) => {
      const activeElement = elements[0];

      if (!activeElement) {
        setPreviewContent(undefined);
        setActiveHoverElementPoint(undefined);
        return;
      }

      const { datasetIndex, index } = activeElement;
      const newActiveHoverElementPoint = { datasetIndex, index };

      if (!isEqual(activeHoverElementPoint, newActiveHoverElementPoint)) {
        handleChartEvent(elements, gfa, previewContent, setPreviewContent);

        setActiveHoverElementPoint(newActiveHoverElementPoint);
      }
    },
    [activeHoverElementPoint, gfa, previewContent, handleChartEvent],
  );

  const handleArcBorderWidth = useCallback(
    (ctx: ScriptableContext<'doughnut'>) => {
      const isActiveArcElement =
        ctx.datasetIndex === activeElementPoint?.datasetIndex &&
        ctx.dataIndex === activeElementPoint.index;

      return isActiveArcElement ? 2 : 1;
    },
    [activeElementPoint],
  );

  const handleArcBorderColor = useCallback(
    (ctx: ScriptableContext<'doughnut'>) => {
      const isActiveArcElement =
        ctx.datasetIndex === activeElementPoint?.datasetIndex &&
        ctx.dataIndex === activeElementPoint.index;

      if (isActiveArcElement) {
        return activeElementPoint.datasetIndex === 0
          ? outerBorderColors[activeElementPoint.index]
          : innerBorderColors[activeElementPoint.index];
      }
      return '#fff';
    },
    [activeElementPoint],
  );

  const renderChartContent = useCallback(() => {
    if (previewContent) {
      return <ChartContent {...previewContent} />;
    }
    if (fixedContent) {
      return (
        <ChartContent {...fixedContent} onClick={handleInnerContentClick} />
      );
    }
    return <ChartContent {...defaultContent} />;
  }, [defaultContent, fixedContent, handleInnerContentClick, previewContent]);

  useEffect(() => {
    const chart = chartRef.current;

    if (!activeElementPoint) {
      setFixedContent(undefined);
    }

    if (chart && activeElementPoint) {
      chart.setActiveElements([activeElementPoint]);
      const elements = chart.getActiveElements();

      handleChartEvent(elements, gfa, fixedContent, setFixedContent);
    }
  }, [activeElementPoint, fixedContent, gfa, project, handleChartEvent]);

  useEffect(() => {
    if (
      previewContent?.title === defaultContent.title &&
      previewContent.mainContent !== defaultContent.mainContent
    ) {
      setPreviewContent(defaultContent);
    }
  }, [defaultContent, previewContent]);

  const gfaData: ChartData<'doughnut'> = useMemo(
    () => ({
      labels: [outerLabels, innerLabels],
      datasets: [
        {
          label: 'outer',
          data: [
            la_apartments,
            gfa_stairwells,
            getActivity('garage').gfa ?? 0,
            gfa_facades,
            gfa -
              la_apartments -
              gfa_stairwells -
              (getActivity('garage').gfa ?? 0) -
              gfa_facades,
          ],
          datalabels: {
            display: false,
          },
          backgroundColor: outerBgColors,
        },
        {
          label: 'inner',
          data: [gfa_above_ground, gfa_below_ground],
          datalabels: {
            display: false,
          },
          backgroundColor: innerBgColors,
        },
      ],
    }),
    [
      gfa,
      gfa_above_ground,
      gfa_below_ground,
      gfa_facades,
      gfa_stairwells,
      la_apartments,
      getActivity,
    ],
  );

  const options: ChartOptions<'doughnut'> = useMemo(
    () => ({
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      cutout: '80%',
      onHover: handleGraphHover,
      onClick: handleGraphClick,
      layout: {
        padding: 8,
      },
      elements: {
        arc: {
          borderAlign: 'inner',
          borderWidth: handleArcBorderWidth,
          borderColor: handleArcBorderColor,
        },
      },
    }),
    [
      handleArcBorderColor,
      handleArcBorderWidth,
      handleGraphClick,
      handleGraphHover,
    ],
  );

  return (
    <Box className={classes.chartContainer} onMouseOut={handleGraphMouseOut}>
      {renderChartContent()}
      <Doughnut
        ref={chartRef}
        data={gfaData}
        options={options}
        className={classes.chart}
      />
    </Box>
  );
};

const ChartContent: FC<
  ChartContentData & {
    onClick?: () => void;
  }
> = ({ title, mainContent, subtitle, unit, onClick }) => {
  const { classes } = useStyles();

  return (
    <List className={classes.contentContainer} onClick={onClick}>
      <ListItem className={classes.listItem}>
        <ListItemText
          primary={title}
          classes={{
            root: classes.listItemText,
            primary: classes.contentTitle,
          }}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <ListItemText
          primary={mainContent}
          secondary={unit ? 'm2' : ''}
          classes={{
            root: classes.contentMain,
            multiline: classes.listItemText,
            primary: classes.contentMainPrimary,
            secondary: classes.contentMainSeconday,
          }}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <ListItemText
          primary={subtitle}
          classes={{
            root: classes.listItemText,
            primary: classes.contentSubtitle,
          }}
        />
      </ListItem>
    </List>
  );
};

const useStyles = makeStyles()(({ spacing, palette }) => ({
  chartContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: '75%',
  },

  chart: {
    zIndex: 1,
  },

  contentContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: '50%',
  },

  listItem: {
    textAlign: 'center',
    padding: 0,
  },

  listItemText: {
    margin: 0,
  },

  contentTitle: {
    fontSize: 12,
    fontWeight: 500,
  },

  contentMain: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
    gap: spacing(2),
  },

  contentMainPrimary: {
    fontSize: 36,
    lineHeight: 1.2,
    fontWeight: 500,
  },

  contentMainSeconday: {
    fontWeight: 500,
  },

  contentSubtitle: {
    fontSize: 10,
    fontWeight: 500,
    color: palette.text.secondary,
  },
}));

const emptyContent: ChartContentData = {
  title: '',
  mainContent: '',
  subtitle: '',
};

const getDefaultContent = (gfa_activities: number): ChartContentData => ({
  title: 'Gross Floor Area',
  mainContent: isNaN(gfa_activities) ? '0' : formatThousands(gfa_activities),
  subtitle: 'm²',
});

const getContent = (
  label: string,
  datasetValue: number,
  gfaValue: number,
): ChartContentData => {
  const quota = datasetValue / gfaValue;
  const formatedQuota = (quota * 100).toFixed();

  return {
    title: label,
    mainContent: `${formatThousands(datasetValue)}`,
    subtitle: `${formatedQuota} %`,
    unit: 'm2',
  };
};

const outerLabels = [
  'Living Area',
  'GFA Stairwells',
  'GFA Garage',
  'GFA Facades',
  'GFA Misc.',
];

const innerLabels = ['GFA Above Ground', 'GFA Below Ground'];

const outerBgColors = [
  'rgba(255, 197, 2, 1)',
  'rgba(248, 62, 107, 1)',
  'rgba(86, 54, 53, 1)',
  'rgba(207, 145, 90, 1)',
  'rgba(149, 178, 188, 1)',
];

const outerBorderColors = [
  'rgba(213, 165, 0, 1)',
  'rgba(155, 39, 67, 1)',
  'rgba(86, 3, 0, 1)',
  'rgba(109, 77, 48, 1)',
  'rgba(84, 100, 106, 1)',
];

const innerBgColors = ['rgb(172, 172, 172, 0.5)', 'rgb(85, 85, 85, 1)'];
const innerBorderColors = ['rgb(172, 172, 172, 1)', 'rgb(0, 0, 0, 1)'];

export default GFAChart;
