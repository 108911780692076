import React, { useRef, useCallback } from 'react';
import { Button, ClickAwayListener, Tooltip } from '@mui/material';
import {
  IBuildingVersion,
  IElement,
} from '../../../../shared/models/project.interface';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { VERSION_MENU_KEY } from '../../keys';
import { EllipsisText } from '../EllipsisText';
import {
  getElementVersionId,
  isElementVersionElement,
} from '../../../../shared/helpers/element-version.helpers';
import {
  useElementVersions,
  useIsExpandedElementVersion,
  useToggleElementVersion,
} from '../../hooks/element-version.hook';
import { isBuildingVersionElement } from '../../../../shared/helpers/recursive_element_helpers';
import { getElementName } from '../../../../shared/helpers/element_helpers';
import {
  isElementExpanded,
  setElementExpanded,
  toggleElementExpanded,
} from '../../hooks/expand-elements.hook';

interface VersionSpoilerProps {
  element: IBuildingVersion | IElement;
  disabled?: boolean;
}

const VersionSpoiler: React.FC<VersionSpoilerProps> = ({
  element,
  disabled,
}) => {
  const elementVersionId = getElementVersionId(element);
  const isElementVersionSpoiler = !isBuildingVersionElement(element);

  const toggleElementVersion = useToggleElementVersion(elementVersionId);

  const isElementVersionExpanded =
    useIsExpandedElementVersion(elementVersionId);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const isVersionExpanded = isElementExpanded(VERSION_MENU_KEY);

  const handleClick = useCallback(() => {
    if (isElementVersionSpoiler) {
      toggleElementVersion();
    } else {
      toggleElementExpanded(VERSION_MENU_KEY);
    }
  }, [isElementVersionSpoiler, toggleElementVersion]);

  const handleOutsideClick = useCallback(() => {
    if (isVersionExpanded) {
      setElementExpanded(VERSION_MENU_KEY, false);
    }
  }, [isVersionExpanded]);

  const elementVersions = useElementVersions(element);
  const title = isElementVersionSpoiler ? 'element' : 'project';

  return (
    <>
      {elementVersions.length > 1 || !isElementVersionSpoiler ? (
        <ClickAwayListener onClickAway={handleOutsideClick}>
          <Tooltip
            title={
              isVersionExpanded
                ? `Hide versions of ${title}`
                : `Show versions of ${title}`
            }
            placement="right"
          >
            <Button
              ref={buttonRef}
              style={{
                maxWidth: '100%',
                textTransform: 'none',
                minWidth: '100px',
              }}
              color="neutral"
              onClick={handleClick}
              endIcon={
                isElementVersionExpanded ? (
                  <KeyboardArrowUp />
                ) : (
                  <KeyboardArrowDown />
                )
              }
              disabled={disabled}
            >
              <EllipsisText>{getDisplayName(element)}</EllipsisText>
            </Button>
          </Tooltip>
        </ClickAwayListener>
      ) : null}
    </>
  );
};

const getDisplayName = (element: IElement | IBuildingVersion) =>
  isElementVersionElement(element)
    ? element.versionName
    : getElementName(element);

export default VersionSpoiler;
