import React, { CSSProperties, useCallback, MouseEvent } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

export const NODON_SELECT_ID = 'nodon-select';
export const NODON_SELECT_OPEN_ID = 'nodon-select-open';

interface INodonSelectOption {
  label: string;
  value: string;
}

interface NodonSelectProps {
  buttonLabel: string;
  options:
    | (string | INodonSelectOption)[]
    | readonly (string | INodonSelectOption)[];
  fullWidth?: boolean;
  disabled?: boolean;
  parentContainerIsButton?: boolean;
  className?: string;
  buttonStyles?: CSSProperties;
  onChange: (value: string) => void;
}

const NodonSelect: React.FC<NodonSelectProps> = ({
  className,
  buttonLabel,
  options,
  onChange,
  fullWidth,
  disabled,
  buttonStyles,
  parentContainerIsButton,
}) => {
  const { classes, cx } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleButtonClick = useCallback(
    (e: MouseEvent<HTMLInputElement>) => setAnchorEl(e.currentTarget),
    [],
  );

  const handleMenuClose = useCallback(() => setAnchorEl(null), []);

  return (
    <>
      <Button
        className={cx(className, classes.buttonRoot, NODON_SELECT_ID)}
        color="secondary"
        fullWidth={fullWidth}
        onClick={handleButtonClick}
        disabled={disabled || false}
        component={parentContainerIsButton ? 'span' : 'button'}
        style={buttonStyles}
        endIcon={anchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      >
        {buttonLabel}
      </Button>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        PopoverClasses={{
          paper: cx(NODON_SELECT_ID, NODON_SELECT_OPEN_ID),
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={getValue(option)}
            value={getValue(option)}
            onClick={(e) => {
              onChange(getValue(option));
              setAnchorEl(null);
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {getLabel(option)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const useStyles = makeStyles()(() => ({
  buttonRoot: {
    textTransform: 'none',
    minWidth: 48,
  },
}));

const getLabel = (value: string | INodonSelectOption) =>
  typeof value === 'string' ? value : value.label;

const getValue = (value: string | INodonSelectOption) =>
  typeof value === 'string' ? value : value.value;

export default NodonSelect;
