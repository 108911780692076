import { isEqual, isObject } from 'lodash';
import {
  DeepPartial,
  ItemOrItemId,
  PartialRecord,
  PrimitiveType,
  ValidIdItem,
} from '../models/type_helpers.interface';
import shallowEqual from './array_helpers';

/**
 * Create a new object including a subset of propetries from an object
 * @param obj
 * @param keys
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function pick<T extends object, K extends keyof T>(
  obj: T,
  ...keys: K[]
): Pick<T, K> {
  return keys.reduce(
    (result, prop) => {
      if (prop in obj) {
        result[prop] = obj[prop];
      }
      return result;
    },
    {} as Pick<T, K>,
  );
}

/**
 * Create a new object including a subset of propetries from an object
 * @param obj
 * @param keys
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function filterObject<T extends object, K extends keyof T>(
  obj: T,
  predicate: (value: T[K], key: K) => boolean | undefined,
): Partial<T> {
  if (!isObject(obj)) {
    throw new Error('Not an object');
  }

  return Object.entries(obj).reduce((result, [key, value]) => {
    if (predicate(value, key as K)) {
      result[key as K] = value;
    }
    return result;
  }, {} as Partial<T>);
}

/**
 * Clone an object and excluding a set of properties from the original
 * @param obj
 * @param keys
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function omit<T extends object, K extends keyof T>(
  obj: T,
  ...keys: (K | undefined)[]
): Omit<T, K> {
  const result = { ...obj };
  keys.filter(isDefined).forEach((key) => {
    delete result[key];
  });
  return result;
}

/**
 * Get new object only containing non-undefined properties
 * @param obj
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function omitUndefined<T extends object>(obj: T): Partial<T> {
  if (typeof obj !== 'object') {
    throw new Error('Not an object');
  }
  const result = { ...obj } as Partial<T>;
  getKeys(result).forEach((key) => {
    const value = key in result && result[key];
    if (!isDefined(value)) {
      delete result[key];
    }
  });
  return result;
}

/**
 * Get new object only containing properties not false, null, 0, undefined or empty string
 * @param obj
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function omitFalsy<T extends object>(obj: T): Partial<T> {
  if (typeof obj !== 'object') {
    throw new Error('Not an object');
  }
  const result = { ...obj } as Partial<T>;
  getKeys(result).forEach((key) => {
    const value = key in result && result[key];
    if (!value) {
      delete result[key];
    }
  });
  return result;
}

/**
 * Check if properties exist on object
 * @param obj
 * @param keys
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function hasProperties<T extends object, K extends keyof T>(
  obj: T | undefined,
  ...keys: K[]
): obj is T & Pick<T, K> {
  if (!isObject(obj)) {
    return false;
  }
  return keys.every((key) => key in obj);
}

/**
 * Check if properties exist on object and is defined (not undefined or null)
 * @param obj
 * @param keys
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function hasDefinedProperties<T extends object, K extends keyof T>(
  obj: Partial<T> | undefined,
  ...keys: K[]
): obj is T & Required<Pick<T, K>> {
  return (
    hasProperties(obj, ...keys) && keys.every((key) => isDefined(obj[key]))
  );
}

/**
 * Check if properties exist on object and is defined
 * @param obj
 * @param keys
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function getMissingProperties<T extends object, K extends keyof T>(
  obj: Partial<T> | undefined,
  ...keys: K[]
): K[] {
  return keys.filter((key) => !isDefined(obj?.[key]));
}

/**
 * Check if properties exist on object and is truty (not false, null, 0, undefined or empty string)
 * @param obj
 * @param keys
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function hasTruthyProperties<T extends object, K extends keyof T>(
  obj: Partial<T>,
  ...keys: K[]
): obj is T & Required<Pick<T, K>> {
  return hasProperties(obj, ...keys) && keys.every((key) => !!obj[key]);
}

/**
 * Detect if subset of an object is the same as the original property
 * @param element
 * @param changes
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const isPartialEqual = <T extends object>(
  element: T,
  changes: Partial<T>,
): boolean => {
  return isEqual(changes, pick(element, ...getKeys(changes)));
};

const isDefined = <T>(value: T | undefined | null): value is T =>
  value !== undefined && value !== null;

/**
 * Typesafe version of Object.keys. Will sort keys alphabetically as default
 * @param obj
 * @param sort Sort keys alphabetically (easier to compare)
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const getKeys = <T extends object>(
  obj: T | null | undefined,
  sort = true,
): Array<keyof T> => {
  const keys = isDefined(obj) ? (Object.keys(obj) as Array<keyof T>) : [];
  return sort ? keys.sort() : keys;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const enumToRecord = <T extends object>(
  enumObj: T,
): Record<keyof T, T[keyof T]> => {
  if (typeof enumObj !== 'object' || !enumObj) {
    throw new Error('Not an enum');
  }
  return getKeys(enumObj).reduce(
    (acc, key) => {
      // Remove the numeric keys from the enum
      if (typeof key === 'string' && !isFinite(+key)) {
        acc[key] = enumObj[key];
      }
      return acc;
    },
    {} as Record<keyof T, T[keyof T]>,
  );
};

/**
 * Map a record to a new record and allow filtering of items.
 * @param record Record to map/filter
 * @param valueMap Function to map values, return undefined to filter out
 * @returns
 */
export const mapFilterRecord = <
  T extends Record<K, T[K]> | Partial<Record<K, T[K]>>,
  K extends keyof T,
  R,
>(
  record: T,
  valueMap: (item: T[K], key: K) => R | undefined,
): Record<K, R> => {
  const clone = {} as Record<K, R>;

  getKeys(record).forEach((key) => {
    const value = valueMap(record[key] as T[K], key as K);

    if (value !== undefined) {
      clone[key as K] = value;
    }
  });
  return clone;
};

/**
 * Similar to Object.assign but only modifies what's changed.
 * @param original
 * @param changes A subset of the original object to change. Try to keep as small as possible
 * @returns
 */
export const applyChanges = <T>(original: T, changes: DeepPartial<T>): T => {
  if (changes === original) {
    return original;
  }

  // Update primitives & functions right away
  if (isPrimitive(changes) || typeof changes === 'function') {
    return changes as T;
  }
  // Overwrite completely if type changed
  if (
    Array.isArray(original) !== Array.isArray(changes) ||
    typeof original !== typeof changes
  ) {
    return changes as T;
  } else if (Array.isArray(changes)) {
    const array = Array.isArray(original) ? (original as unknown[]) : [];

    const updated = changes.map((change, i) =>
      applyChanges(array[i], change as DeepPartial<T>),
    );

    return (shallowEqual(array, updated) ? array : updated) as T;
  } else if (typeof changes === 'object') {
    const keys = getKeys(changes);

    // Only update if needed to not trigger to many rerenders of views
    if (changes && keys.length > 0) {
      keys.forEach((key) => {
        const change = changes[key] as DeepPartial<T[keyof T]>;
        const current = isObject(original) ? original[key] : undefined;
        const updated = applyChanges(current, change);

        // Equals, no need to update
        if (current !== updated) {
          original = { ...original, [key]: updated };
        }
      });
    }
  }
  return original;
};

export const isPrimitive = (value: unknown): value is PrimitiveType => {
  const type = typeof value;
  return !value || (type !== 'object' && type !== 'function');
};

export const getId = <T extends ValidIdItem>(
  itemOrId: ItemOrItemId<T>,
): T['id'] => (isPrimitive(itemOrId) ? itemOrId : itemOrId.id);

export const compareObjectsWithAllKeys = <T extends Record<string, unknown>>(
  a: T,
  b: T,
): string[] =>
  Array.from(new Set([...Object.keys(a), ...Object.keys(b)])).filter(
    (key) => !isEqual(a[key], b[key]),
  );

export const getNumber = <
  T extends PartialRecord<K, T[K] | undefined>,
  K extends keyof T,
>(
  obj: T,
  key: K,
  defaultValue = 0,
): number => {
  const value = obj[key];
  return typeof value === 'number' && isFinite(value) ? value : defaultValue;
};
