import { QuantityUnit } from '../models/unit.interface';
import {
  ElementCountRecord,
  ExpressionValue,
  ICountAndUnit,
} from '../models/project.interface';
import { formatThousands, isNumeric, toNumber } from './math_helpers';
import { hasDefinedProperties } from './object_helpers';
import { isObject } from 'lodash';
import { FactoryCountExpression } from '../models/element_factory_helpers.interface';

/**
 * Valid values to create a new ExpressionValue
 */
export type ExpressionValueFactoryType =
  | number
  | string
  | ExpressionValue
  | Partial<ExpressionValue>;

/**
 * Test if value is valid values to create a new ExpressionValue
 */
export const isExpressionValueFactoryType = (
  value: unknown,
): value is ExpressionValueFactoryType =>
  (typeof value === 'number' && isFinite(value)) ||
  typeof value === 'string' ||
  isExpressionValue(value);

/**
 * Create a new ExpressionValue based on a
 * number string or preexisting ExpressionValue
 */
export const createExpression = (
  value: ExpressionValueFactoryType = 0,
  expression?: string,
): ExpressionValue => {
  // Expression already exist so use a clone of that
  if (isExpressionValue(value)) {
    return { ...value };
  }

  if (isObject(value)) {
    value = value.expression ?? value.resolved ?? toNumber(value.formatted, 0);
  }

  // Numeric values like '12' or 12
  if (isNumeric(value)) {
    return {
      expression: expression ?? String(value),
      resolved: +value,
      formatted: formatThousands(+value),
    };
  }

  // String expressions like 'a + b'
  if (typeof value === 'string') {
    return {
      expression: value,
      resolved: 0,
      formatted: '0',
    };
  }

  throw new Error('Can only create expressions from numbers or strings.');
};

/**
 * Unwrap any numbers or strings to create a new ExpressionValue
 * @param countFactory
 * @param allowUndefinedCount
 * @returns
 */
export const resolveFactoryCount = <
  T extends {
    count?: FactoryCountExpression;
    fallbackCount?: FactoryCountExpression;
  },
  R = Omit<T, 'count'> & { count: ExpressionValue },
>(
  countFactory: T,
  allowUndefinedCount = false,
): R => {
  if (
    typeof countFactory.count === 'boolean' ||
    typeof countFactory.fallbackCount === 'boolean'
  ) {
    return countFactory as unknown as R;
  }
  if (isExpressionValue(countFactory.count)) {
    return countFactory as unknown as R;
  }
  if (
    countFactory.count === undefined &&
    countFactory.fallbackCount !== undefined
  ) {
    return {
      ...countFactory,
      fallbackCount: createExpression(countFactory.fallbackCount),
    } as R;
  }
  // If count is undefined and we allow undefined count
  if (
    allowUndefinedCount &&
    'count' in countFactory &&
    countFactory.count === undefined
  ) {
    return { ...countFactory, count: undefined } as R;
  }
  return { ...countFactory, count: createExpression(countFactory.count) } as R;
};

export const createCountAndUnit = (
  defaults: { count?: FactoryCountExpression; unit?: QuantityUnit } = {},
): ICountAndUnit => {
  const { count, unit } = defaults;
  if (count && unit) {
    return {
      count: createExpression(count as number),
      unit,
    };
  }
  return {
    count: createExpression(1),
    unit: 'pcs',
  };
};

export const createEmptyExpression = (): ExpressionValue => ({
  expression: '',
  formatted: '',
  resolved: 0,
});

export const createCountRecord = (
  unit: QuantityUnit,
  count = 0,
): ElementCountRecord => {
  return { [unit]: createExpression(count) };
};

export const isExpressionValue = (value: unknown): value is ExpressionValue =>
  isObject(value) &&
  hasDefinedProperties(
    value as ExpressionValue,
    'expression',
    'formatted',
    'resolved',
  );
