import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { OutlinedTextFieldProps } from '@mui/material';
import amplitudeLog from '../../../amplitude';
import InlineTextField from '../../InlineTextField';
import { selectableUnitHarmonizer } from '../../../../../shared/helpers/unit_helpers';
import ElementRecipe from '../../SidePanel/Element/ElementRecipe';
import { getRecipeId } from '../../../../../shared/helpers/recipe_helpers';
import ExpressionInput from '../../ExpressionInput';
import { getExpression } from '../../../../../shared/helpers/expression_solving_helpers';
import { IElement } from '../../../../../shared/models/project.interface';
import { useUpdateElements } from '../../../store/project/project.hook';
import { useIsSelected } from '../../../store/ui/ui.hook';
import {
  useElementExpressionVariablesById,
  useUpdateExpression,
} from '../../../hooks/useElementExpressionVariables';
import { useUIState } from '../../../store/ui';
import { getDefaultElementQuantityProperty } from '../../../../../shared/helpers/element_quantity_helpers';
import { useElementFallbackName } from '../../../hooks/useElementFallbackName.hook';
import { useIsReadonly } from '../../../hooks/user.hook';
import ElementPropertyExpression from '../../SidePanel/Element/Property/ElementPropertyExpression';

import VersionSpoiler from '../../ElementList/VersionSpoiler';
import { useSetActiveElementVersion } from '../../../hooks/element-version.hook';
import { useToggleElementExpanded } from '../../../hooks/expand-elements.hook';
import { isDeactivated } from '../../../../../shared/helpers/element_helpers';
import HorizontalBarCharts from '../../charts/HorizontalBarCharts';
import ExpandIcon from '../ExpandIcon';
import { Row } from '../Row';
import { RowCell } from '../RowCell';
import {
  ROOT_CELL_WIDTH,
  ROOT_CELL_WIDTH_RESPONSIVE,
  LIST_ITEM_HEIGHT,
  LIST_SPACING,
  CONTENT_CELL_WIDTH,
  CONTENT_CELL_WIDTH_RESPONSIVE,
} from '../list.constants';
import ElementKebabMenu from '../KebabMenus/ElementKebabMenu';
import { useBooleanState } from '../../../hooks/hooks';
import {
  useIsKebabMenuOpen,
  useTriggerContextKebabMenu,
} from '../../kebab-menu/kebab-menu.hooks';
import { useListRowStyles } from '../list.style';
import { useNavigateTo } from '../../../hooks/router.hooks';
import { useMouseEventCallback } from '../../../hooks/events.hook';
import CreateSiblingButton, {
  useShouldShowCreateSiblingButton,
} from '../../CreateSiblingButton';
import { hasChildren } from '../../../../../shared/helpers/recursive_element_helpers';
import { isProductCategoryElement } from '../../../../../shared/helpers/element_category_helpers';
import MainCategoryIconButton from '../MainCategoryIconButton';
import { useParentMainCategoryId } from '../../../hooks/main-categories.hook';
import { useBarChart } from '../../../hooks/chart.hook';
import { useSortedElementCommments } from '../../../store/comment/comments.hook';
import CommentsIconButton from '../../Comments/CommentsIconButton';
import VisibilityIcon from '../VisibilityIcon';

interface IElementListItemInput {
  element: IElement;
  elementVersionId?: string;
  autoFocus?: boolean;
  indentation?: number;
}

const ElementListItem: React.FC<IElementListItemInput> = ({
  element,
  elementVersionId,
  autoFocus,
  indentation = 0,
}) => {
  const { classes: listClasses } = useListRowStyles();

  const { setAddedElementId } = useUIState('setAddedElementId');

  const { renderTooltip } = useBarChart();
  const updateElements = useUpdateElements();
  const setElementFallbackName = useElementFallbackName();
  const changeElementVersion = useSetActiveElementVersion();
  const navigateTo = useNavigateTo();
  const updateExpression = useUpdateExpression(element);
  const toggleElementExpanded = useToggleElementExpanded(element);
  const triggerContextMenu = useTriggerContextKebabMenu(element.id);

  const comments = useSortedElementCommments(element.id);
  const variables = useElementExpressionVariablesById(element.id);
  const parentMainCategoryId = useParentMainCategoryId(element);

  const readonly = useIsReadonly();
  const selected = useIsSelected(element);
  const deactivated = isDeactivated(element);
  const showCreateSiblingButton = useShouldShowCreateSiblingButton(element);
  const isKebabMenuOpen = useIsKebabMenuOpen(element.id);

  // Only allow expand if ProductCategoryElements and element has children
  const isExpandAllowed =
    hasChildren(element) &&
    (!isProductCategoryElement(element) || element.count.resolved);

  const [hover, startHover, endHover] = useBooleanState(false);

  const [isEditingName, startEditingName, stopEditingName] =
    useBooleanState(false);

  const defaultQuantityProperty = useMemo(
    () =>
      getDefaultElementQuantityProperty(element.quantity, element.category_id),
    [element.quantity, element.category_id],
  );

  const nameInputProps: Partial<OutlinedTextFieldProps> = useMemo(
    () => ({
      autoFocus,
      onFocus: (e) => {
        autoFocus && e.currentTarget.select();
        setAddedElementId();
      },
    }),
    [autoFocus, setAddedElementId],
  );

  const expressionInputValue = useMemo(
    () => getExpression(element.count),
    [element.count],
  );

  const onRowClick = useMouseEventCallback(
    () => {
      navigateTo({ elementId: element.id });
    },
    { ignoreInputEvents: true },
  );

  const saveName = useCallback(
    async (name: string) => {
      if (elementVersionId) {
        await updateElements({ id: element.id, versionName: name });
        // Set the edited version as active
        await changeElementVersion(element.id);
      } else if (!name) {
        setElementFallbackName(element, undefined, name);
      } else {
        updateElements({ id: element.id, name, fallbackName: '' });
      }

      stopEditingName();
      amplitudeLog('Element Name Set', {
        Name: name,
        ElementID: element.id,
      });
    },
    [
      elementVersionId,
      stopEditingName,
      element,
      updateElements,
      changeElementVersion,
      setElementFallbackName,
    ],
  );

  const handleNameInputClick = useMouseEventCallback(() => {
    if (selected || !elementVersionId) {
      startEditingName();
    }
  });

  // Start editing the name if autoFocus is set
  useEffect(() => {
    autoFocus && startEditingName();
  }, [autoFocus, startEditingName]);

  const elementName = element && 'name' in element ? element.name : '';

  return (
    <>
      <Row
        classes={listClasses}
        deactivated={deactivated}
        height={LIST_ITEM_HEIGHT}
        hover={hover}
        onClick={onRowClick}
        onContextMenu={triggerContextMenu}
        onDoubleClick={toggleElementExpanded}
        onMouseLeave={endHover}
        onMouseOver={startHover}
        padding={true}
        selected={selected}
        spacing={LIST_SPACING}
      >
        {/* CONTENT. Group content in a shared cell to make sure we can align bar charts as one */}
        <RowCell
          width={ROOT_CELL_WIDTH.CONTENT}
          responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.CONTENT}
        >
          <Row height={LIST_ITEM_HEIGHT} spacing={LIST_SPACING}>
            {/* Main category icon */}
            <RowCell width={ROOT_CELL_WIDTH.ICON}>
              {parentMainCategoryId && (
                <MainCategoryIconButton id={parentMainCategoryId} />
              )}
            </RowCell>

            <RowCell
              width={ROOT_CELL_WIDTH.ICON}
              indentation={indentation} // Put indentation on the first item in row
            >
              {hover && <VisibilityIcon element={element} />}
            </RowCell>

            {/* Expand icon */}
            <RowCell width={ROOT_CELL_WIDTH.ICON}>
              <ExpandIcon element={element} disabled={!isExpandAllowed} />
            </RowCell>

            {/* Name input */}
            <RowCell
              width={CONTENT_CELL_WIDTH.NAME}
              responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.NAME}
            >
              <InlineTextField
                onClick={handleNameInputClick}
                value={
                  elementVersionId && element.versionName
                    ? element.versionName
                    : elementName
                }
                defaultValue={element.fallbackName}
                variant="subtitle1"
                editing={isEditingName}
                onSave={saveName}
                onCancel={stopEditingName}
                textFieldProps={nameInputProps}
                disabled={readonly || !selected}
                isDeactivated={element.isDeactivated}
              />
            </RowCell>

            {/* Version spoiler */}
            {!elementVersionId && (
              <RowCell
                width={CONTENT_CELL_WIDTH.ACTIONS}
                responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.ACTIONS}
              >
                <VersionSpoiler
                  element={element}
                  disabled={element.isDeactivated}
                />
              </RowCell>
            )}

            {/* Recipe */}
            {getRecipeId(element) && !elementVersionId && (
              <RowCell
                align="right"
                width={CONTENT_CELL_WIDTH.ACTIONS}
                responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.ACTIONS}
              >
                <ElementRecipe element={element} isSelectViewOnly />
              </RowCell>
            )}

            {/* Expression input */}
            <RowCell
              align="right"
              width={CONTENT_CELL_WIDTH.EXPRESSION}
              responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.EXPRESSION}
            >
              {defaultQuantityProperty ? (
                <ElementPropertyExpression
                  property={defaultQuantityProperty}
                  element={element}
                />
              ) : (
                <ExpressionInput
                  id={element.id}
                  expressionValue={expressionInputValue}
                  unit={selectableUnitHarmonizer(element.unit)}
                  variables={variables}
                  disabled={readonly || !!elementVersionId}
                  onSave={updateExpression}
                />
              )}
            </RowCell>
          </Row>
        </RowCell>

        {/* Bar chart */}
        <RowCell
          width={ROOT_CELL_WIDTH.BAR}
          responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.BAR}
        >
          <HorizontalBarCharts
            element={element}
            hover={hover}
            renderTooltip={renderTooltip}
          />
        </RowCell>

        <RowCell width={CONTENT_CELL_WIDTH.ICON}>
          {comments.length > 0 && <CommentsIconButton comments={comments} />}
        </RowCell>

        {/* Kebab menu */}

        <RowCell width={CONTENT_CELL_WIDTH.ICON} hideOnPrint align="center">
          {(hover || isKebabMenuOpen) && (
            <ElementKebabMenu
              element={element}
              elementVersionId={elementVersionId}
            />
          )}
        </RowCell>
      </Row>
      {showCreateSiblingButton && (
        <CreateSiblingButton element={element} indentation={indentation} />
      )}
    </>
  );
};

export default memo(ElementListItem);
