import { useMemo } from 'react';
import { PopoverPosition } from '@mui/material';
import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

interface MenuStoreState {
  /**
   * Unique identified for any instance that uses this I.E. element.id
   */
  id?: string | number;
  anchorPosition?: PopoverPosition;
}

export const useMenuStore = createWithEqualityFn<MenuStoreState>(
  () => ({}),
  shallow,
);

/**
 * Close kebab menu
 * @returns
 */
export const closeKebabMenu = () => useMenuStore.setState({}, true);

export const openKebabMenu = (state: Required<MenuStoreState>) =>
  useMenuStore.setState({ ...state, id: state.id.toString() });

export const isKebabMenuOpen = (id: MenuStoreState['id']) => {
  const state = useMenuStore.getState();
  return isIdOpen(state, id);
};

const isIdOpen = (state: MenuStoreState, id: MenuStoreState['id']): boolean =>
  !!id && state.id === id.toString() && !!state.anchorPosition;

/**
 * Is kebab menu open or not
 * @param id
 * @returns
 */
export const useIsKebabMenuOpen = (id: MenuStoreState['id']): boolean => {
  return !!useKebabMenuPosition(id);
};

/**
 * Return anchor position for kebab menu if open
 * @param id
 * @returns
 */
export const useKebabMenuPosition = (
  id: MenuStoreState['id'],
): PopoverPosition | undefined => {
  const state = useMenuStore();
  if (isIdOpen(state, id)) {
    return state.anchorPosition;
  }
};

export const useTriggerContextKebabMenu = (
  id: MenuStoreState['id'],
): React.MouseEventHandler<HTMLElement> =>
  useMemo(() => {
    if (!id) {
      return () => {};
    }
    return (e) => {
      const anchorPosition = kebabMenuMouseHandler(e);
      if (anchorPosition && !isKebabMenuOpen(id)) {
        openKebabMenu({ anchorPosition, id });
      }
    };
  }, [id]);

export const kebabMenuMouseHandler = (
  e: React.MouseEvent<Element, globalThis.MouseEvent>,
): PopoverPosition | undefined => {
  if (e) {
    e.stopPropagation();
    e.preventDefault();
    return { top: e.clientY, left: e.clientX };
  }
};
