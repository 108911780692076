import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
  KeyboardEvent,
} from 'react';
import { Box, Button, OutlinedInputProps, Typography } from '@mui/material';
import NodonTextField from '../NodonTextField';
import { useBooleanState } from '../../hooks/hooks';
import { makeStyles } from 'tss-react/mui';
import { useCommentsStore } from '../../store/comment/comment.store';
import {
  COMMENT_MAX_LENGTH,
  NEW_COMMENT_ID_PREFIX,
} from '../../../../shared/constants';
import {
  getDraft,
  setDraft,
  useStoreDraftComment,
  useFocusCommentInput,
} from '../../store/comment/comments.hook';

interface CommentMessageProps {
  id: string;
  message: string;
  onSave: (message: string) => void;
}

const CommentMessage: React.FC<CommentMessageProps> = ({
  id,
  message,
  onSave,
}) => {
  const { classes } = useStyles();

  const inputProps = useMemo<OutlinedInputProps>(
    () => ({
      classes: {
        root: classes.outlinedInput,
        notchedOutline: classes.fieldsetBorder,
        disabled: classes.disabled,
      },
      inputProps: { maxLength: COMMENT_MAX_LENGTH },
    }),
    [classes],
  );

  const { error, setEditingId, editingId } = useCommentsStore(
    ({ error, setEditingId, editingId }) => ({
      error,
      setEditingId,
      editingId,
    }),
  );

  const [buttonsVisible, showButtons, hideButtons] = useBooleanState();
  const [inputValue, setInputValue] = useState(getDraft(id) || message);
  const [localError, setLocalError] = useState('');

  useStoreDraftComment(id, message, inputValue);
  const inputRef = useFocusCommentInput(id, message);

  const isNewComment = id.startsWith(NEW_COMMENT_ID_PREFIX);
  const isEditing = editingId === id;

  const handleSave = useCallback(() => {
    onSave(inputValue);
    setDraft(id, undefined);
  }, [id, inputValue, onSave]);

  const handleCancel = useCallback(() => {
    setEditingId(undefined);
    setDraft(id, undefined);
  }, [id, setEditingId]);

  const handleInputKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { key, shiftKey, currentTarget } = event;

      if (key === 'Escape') {
        currentTarget.blur();
        handleCancel();
      }
      if (key === 'Enter' && shiftKey === false) {
        if (!inputValue.trim() || inputValue.length > COMMENT_MAX_LENGTH) {
          event.preventDefault();
          return;
        }
        currentTarget.blur();
        handleSave();
      }
    },
    [handleCancel, handleSave, inputValue],
  );

  const handleInputChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      if (buttonsVisible) {
        setInputValue(value);
        setLocalError(
          value.length >= COMMENT_MAX_LENGTH ? 'Comment is too long' : '',
        );
      }
    },
    [buttonsVisible],
  );

  const handleInputClick = useCallback(() => {
    if (isNewComment && !isEditing) {
      setEditingId(id);
    }
  }, [id, isEditing, isNewComment, setEditingId]);

  useEffect(() => {
    if (error?.id === id) {
      setLocalError(error.message);
    }
  }, [error, id]);

  useEffect(() => {
    if (isEditing) {
      setInputValue(getDraft(id) || message);
      showButtons();
    }
    if (!isEditing) {
      setInputValue(message);
      setLocalError('');
      hideButtons();
    }
  }, [hideButtons, id, isEditing, message, showButtons]);

  return (
    <Box width={1} display="flex" flexDirection="column">
      <NodonTextField
        disabled={!isNewComment && !isEditing}
        multiline
        size="small"
        placeholder={message ? '' : 'Add a comment...'}
        variant="outlined"
        inputRef={inputRef}
        InputProps={inputProps}
        value={inputValue}
        onChange={handleInputChange}
        onClick={handleInputClick}
        customOnKeyDown={handleInputKeyDown}
      />

      <Box display={buttonsVisible ? 'flex' : 'none'} gap={3} pt={5} pb={4}>
        <Button
          size="small"
          variant="outlined"
          className={classes.button}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          size="small"
          variant="contained"
          className={classes.button}
          onClick={handleSave}
          disabled={
            !inputValue ||
            inputValue === message ||
            inputValue.length > COMMENT_MAX_LENGTH
          }
        >
          Save
        </Button>
      </Box>
      {localError && (
        <Typography variant="caption" color="error" pt={2} textAlign="center">
          {localError}
        </Typography>
      )}
    </Box>
  );
};

const useStyles = makeStyles()(({ spacing }) => ({
  fieldsetBorder: { border: 0 },
  disabled: {
    '&.Mui-disabled': {
      WebkitTextFillColor: 'rgb(0 0 0 / 80%)',
    },
  },
  outlinedInput: {
    paddingRight: 0,
    paddingLeft: 0,
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  },
  button: { flex: 1 },
}));

export default CommentMessage;
