import { create } from 'zustand';
import { Recipe, RecipeID } from '../../../../shared/models/recipe.interface';
import { devtools } from 'zustand/middleware';
import { toLookup } from '../../../../shared/helpers/utils.helpers';
import axios, { AxiosRequestConfig } from 'axios';
import { nodonRecipes } from '../../../../shared/templates/nodon_recipes';
import { isNodonRecipeID } from '../../../../shared/helpers/recipe_helpers';
import {
  isElementSelectProperty,
  isElementSwitchProperty,
} from '../../../../shared/helpers/element_property_helpers';
import { IRecipeStoreState } from './recipe-state.model';
import { reloadApp } from '../utils';
import { initHmrStore, getHmrStoreState } from '../../helpers/vite.helpers';
import { modelTimestampReviver } from '../../../../shared/helpers/date.helpers';

const STORE_NAME = 'recipes';

const requestConfig: AxiosRequestConfig = {
  transformResponse: (data: any): any => {
    if ((data as string).startsWith('<')) {
      return;
    }
    return JSON.parse(data, modelTimestampReviver) as Recipe[];
  },
};

const validateRecipe = (recipe: Recipe): void => {
  if (!recipe.properties) {
    throw new Error('Recipe properties are missing');
  }
  if (!recipe.elements) {
    throw new Error('Recipe elements are missing');
  }
  if (isNodonRecipeID(recipe.id)) {
    throw new Error(`Cannot do changes to a Nodon Recipe`);
  }
  if (recipe.properties.some(isElementSelectProperty)) {
    throw new Error(`Can't save a recipe with a select property`);
  }
  if (recipe.properties.some(isElementSwitchProperty)) {
    throw new Error(`Can't save a recipe with a switch property`);
  }
};

/**
 * Store for recipes. Avoid using this store directly, instead use the hooks in recipe.hooks.ts
 */
export const useRecipesStore = create<IRecipeStoreState>()(
  devtools(
    (set) => ({
      recipes: [],
      lookup: {},
      fetching: false,
      fetched: false,
      ...getHmrStoreState(STORE_NAME),

      fetchRecipes: () => {
        set(() => ({ fetching: true, fetched: false, error: undefined }));
        return axios
          .get<Recipe[]>(`recipes`, {
            ...requestConfig,
          })
          .then(({ data }) => {
            const recipes = [...nodonRecipes, ...data];
            set(() => ({
              recipes,
              lookup: toLookup(recipes),
              fetching: false,
              fetched: true,
            }));
          })
          .catch((err) => {
            set(() => ({
              fetching: false,
              fetched: false,
              error: err,
            }));
            return Promise.reject(err);
          });
      },

      createRecipe: (recipe) => {
        validateRecipe(recipe);

        return axios
          .post<Recipe>(`recipes`, recipe, requestConfig)
          .then(({ data }) => {
            set((state) => ({
              recipes: [...state.recipes.filter((r) => r.id !== data.id), data],
              lookup: { ...state.lookup, [data.id]: data },
            }));
            return data;
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      },

      createRecipeLocally: (recipe) =>
        set((state) => ({
          recipes: [...state.recipes.filter((r) => r.id !== recipe.id), recipe],
          lookup: { ...state.lookup, [recipe.id]: recipe },
        })),

      cloneRecipe: (recipe, destinationOrganization, overwrite) => {
        validateRecipe(recipe);

        return axios
          .put<Recipe[]>(`recipes/clone`, recipe, {
            ...requestConfig,
            params: { overwrite, destinationOrganization },
          })
          .then(({ data }) => {
            return data;
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      },

      updateRecipe: (recipe) => {
        validateRecipe(recipe);

        return axios
          .put<Recipe>(`/recipes`, recipe, requestConfig)
          .then(({ data }) => {
            set((state) => ({
              recipes: [...state.recipes.filter((r) => r.id !== data.id), data],
              lookup: { ...state.lookup, [data.id]: data },
            }));
            return data;
          })
          .catch((err) => {
            reloadApp(err.response?.status);
            return Promise.reject(err);
          });
      },

      updateRecipeLocally: (recipe: Recipe) =>
        set((state) => ({
          recipes: [...state.recipes.filter((r) => r.id !== recipe.id), recipe],
          lookup: { ...state.lookup, [recipe.id]: recipe },
        })),

      deleteRecipe: (id: RecipeID) => {
        return axios
          .delete<Recipe>(`/recipes/${id}`, requestConfig)
          .then(({ data }) => {
            set((state) => {
              delete state.lookup[id];
              return {
                recipes: state.recipes.filter((r) => r.id !== data.id),
                lookup: state.lookup,
              };
            });
            return data;
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      },

      deleteRecipeLocally: (id: RecipeID) =>
        set((state) => {
          delete state.lookup[id];
          return {
            recipes: state.recipes.filter((r) => r.id !== id),
            lookup: state.lookup,
          };
        }),
    }),
    { name: STORE_NAME },
  ),
);

initHmrStore(STORE_NAME, useRecipesStore);
