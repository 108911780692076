import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import amplitudeLog from '../../../amplitude';
import {
  getSelectableUnitsInConversionFactors,
  selectableUnitHarmonizer,
} from '../../../../../shared/helpers/unit_helpers';
import ExpressionInput from '../../ExpressionInput';
import {
  ExpressionValue,
  IProductElement,
} from '../../../../../shared/models/project.interface';
import { useUpdateElements } from '../../../store/project/project.hook';
import { useIsSelected } from '../../../store/ui/ui.hook';
import { useElementExpressionVariablesById } from '../../../hooks/useElementExpressionVariables';
import { useIsReadonly } from '../../../hooks/user.hook';

import { useToggleElementExpanded } from '../../../hooks/expand-elements.hook';
import { isDeactivated } from '../../../../../shared/helpers/element_helpers';
import HorizontalBarCharts from '../../charts/HorizontalBarCharts';
import { Row } from '../Row';
import { RowCell } from '../RowCell';
import {
  ROOT_CELL_WIDTH,
  ROOT_CELL_WIDTH_RESPONSIVE,
  LIST_ITEM_HEIGHT,
  LIST_SPACING,
  CONTENT_CELL_WIDTH,
  CONTENT_CELL_WIDTH_RESPONSIVE,
} from '../list.constants';
import { useBooleanState } from '../../../hooks/hooks';
import {
  useIsKebabMenuOpen,
  useTriggerContextKebabMenu,
} from '../../kebab-menu/kebab-menu.hooks';
import { useListRowStyles } from '../list.style';
import { useNavigateTo } from '../../../hooks/router.hooks';
import { useMouseEventCallback } from '../../../hooks/events.hook';
import CreateSiblingButton, {
  useShouldShowCreateSiblingButton,
} from '../../CreateSiblingButton';
import { useProductSwitch } from '../../../hooks/product-switch.hook';
import { useSelectedVersionProduct } from '../../../hooks/useProducts';
import { applyCalculatedConversionFactors } from '../../../../../shared/helpers/conversion_helpers';
import { SelectableQuantityUnit } from '../../../../../shared/models/unit.interface';
import ProductSelector from '../../../projects/EditProject/ProductSelector';
import { IExpressionInputPanelOutput } from '../../ExpressionInputPanel';
import ProductSelectorButton from '../../ProductSelectorButton';
import ProductElementKebabMenu from '../KebabMenus/ProductElementKebabMenu';

interface IProductElementListItemInput {
  element: IProductElement;
  indentation?: number;
}

const ProductElementListItem: React.FC<IProductElementListItemInput> = ({
  element,
  indentation = 0,
}) => {
  const { classes: listClasses } = useListRowStyles();
  const readonly = useIsReadonly();
  const { id, product_id, generated: isGenerated } = element;

  const updateElements = useUpdateElements();
  const toggleElementExpanded = useToggleElementExpanded(element);
  const variables = useElementExpressionVariablesById(id);
  const selected = useIsSelected(element);

  const [hover, startHover, endHover] = useBooleanState(false);

  const triggerContextMenu = useTriggerContextKebabMenu(id);
  const isKebabMenuOpen = useIsKebabMenuOpen(id);
  const navigateTo = useNavigateTo();
  const deactivated = isDeactivated(element);
  const showCreateSiblingButton = useShouldShowCreateSiblingButton(element);

  const {
    isProductSelectorOpen,
    openProductSelector,
    closeProductSelector,
    switchProducts,
  } = useProductSwitch(element);

  const [unit, setUnit] = useState(element.unit);
  const [count, setCount] = useState<ExpressionValue>(element.count);

  const product = useSelectedVersionProduct(product_id);

  const conversionFactors = useMemo(() => {
    const factors = product?.conversion_factors;
    return factors ? applyCalculatedConversionFactors(factors) : {};
  }, [product?.conversion_factors]);

  const selectableUnits: SelectableQuantityUnit[] = useMemo(
    () => getSelectableUnitsInConversionFactors(conversionFactors),
    [conversionFactors],
  );

  useEffect(() => {
    setUnit(element.unit);
  }, [element.unit]);

  useEffect(() => {
    setCount(element.count);
  }, [element.count]);

  const handleOnSave = useCallback(
    async (updatedProductElement: IProductElement) =>
      updateElements(updatedProductElement),
    [updateElements],
  );

  const handleProductClick = useCallback(() => {
    if (isGenerated) {
      return;
    }
    if (selected) {
      openProductSelector();
    } else {
      closeProductSelector();
    }
    amplitudeLog('Product Switch', {
      ProductID: product?.id,
    });
  }, [
    isGenerated,
    selected,
    product?.id,
    openProductSelector,
    closeProductSelector,
  ]);

  const onExpressionInputSave = useCallback(
    ({ expressionValue, unit }: IExpressionInputPanelOutput) => {
      if (!expressionValue) {
        throw new Error('Provided value must be an ExpressionValue');
      }

      setCount(expressionValue);
      amplitudeLog('Product Value Set', {
        ProductID: product?.id,
      });

      handleOnSave({
        ...element,
        count: expressionValue,
        unit: unit ?? element.unit,
      });
    },
    [product?.id, element, setCount, handleOnSave],
  );

  const onRowClick = useMouseEventCallback(
    () => {
      navigateTo({ elementId: id });
    },
    { ignoreInputEvents: true },
  );

  return (
    <>
      <Row
        classes={listClasses}
        deactivated={deactivated}
        height={LIST_ITEM_HEIGHT}
        hover={hover}
        onClick={onRowClick}
        onContextMenu={triggerContextMenu}
        onDoubleClick={toggleElementExpanded}
        onMouseLeave={endHover}
        onMouseOver={startHover}
        padding={true}
        selected={selected}
        spacing={LIST_SPACING}
      >
        {/* CONTENT. Group content in a shared cell to make sure we can align bar charts as one */}
        <RowCell
          width={ROOT_CELL_WIDTH.CONTENT}
          responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.CONTENT}
        >
          <Row height={LIST_ITEM_HEIGHT} spacing={LIST_SPACING}>
            {/* Expand icon */}
            <RowCell
              indentation={indentation} // Put indentation on the first item in row
              width={ROOT_CELL_WIDTH.ICON}
            ></RowCell>

            {/* Name input */}
            <RowCell
              width={CONTENT_CELL_WIDTH_RESPONSIVE.NAME}
              responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.NAME}
            >
              <ProductSelectorButton
                onClick={handleProductClick}
                selected={selected ?? false}
                error={!product.id}
                productName={product.name}
                disabled={readonly || isGenerated}
                isDeactivated={
                  !!('isDeactivated' in parent && parent.isDeactivated)
                }
              />
            </RowCell>

            {/* Expression input */}
            <RowCell
              align="right"
              width={CONTENT_CELL_WIDTH_RESPONSIVE.EXPRESSION}
              responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.EXPRESSION}
            >
              <ExpressionInput
                id={id}
                expressionValue={count}
                unit={selectableUnitHarmonizer(unit)}
                selectableUnits={selectableUnits}
                variables={variables}
                disabled={readonly || isGenerated}
                onSave={onExpressionInputSave}
              />
            </RowCell>
          </Row>
        </RowCell>
        {/* </Box>
        </Box> */}

        {/* Bar chart */}
        <RowCell
          width={ROOT_CELL_WIDTH.BAR}
          responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.BAR}
        >
          <HorizontalBarCharts element={element} hover={hover} />
        </RowCell>

        {/* Kebab menu */}
        <RowCell width={CONTENT_CELL_WIDTH.ICON} hideOnPrint align="center">
          {(hover || isKebabMenuOpen) && (
            <ProductElementKebabMenu element={element} />
          )}
        </RowCell>
      </Row>
      {showCreateSiblingButton && (
        <CreateSiblingButton element={element} indentation={indentation} />
      )}
      {isProductSelectorOpen && !isGenerated && (
        <ProductSelector
          open={isProductSelectorOpen}
          onClose={closeProductSelector}
          onSave={switchProducts}
          productId={product.id}
        />
      )}
    </>
  );
};

export default memo(ProductElementListItem);
