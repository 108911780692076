import { Product } from './product.interface';
import { IElementID } from './project.interface';

export enum FactorUnitLabels {
  co2e = 'kgCO2e',
  cost = 'kSEK',
  perGFA = 'per GFA',
}

export type CostEmissionFactorUnitLabel = 'kgCO2e' | 'kSEK';

/**
 * Units coming from imported files and have been aaround for a long time
 */
const originalUnits = ['m', 'm²', 'm³', 'kg', 'pcs'] as const;

/**
 * Additional units that used in specific custom products (hr: hours)
 */
const basicUnits = ['hr'] as const;

/**
 * Unit that is a ratio of other units.
 */
const ratioUnits = ['kg/m³', 'kg/m²', '%'] as const;

/**
 * Geometric units that are used in the UI.
 */
const geometricUnits = ['°'] as const;

/**
 * Thermal transmittance / Värmegenomgångskoefficienten: u = W/(m2⋅K)
 */
const thermalConductivityUnits = ['W/mK', 'u'] as const;

/**
 * Units that is calculated from another unit.
 * 100mm = 0.1m³ (per m²)
 */
export const calculatedUnits = ['mm', 'kWh', 'l'] as const;
export type CalculatedUnit = (typeof calculatedUnits)[number];

/**
 * Cost units that are available in ConversionFactors
 */
export const costUnits = ['sek_A1-A3', 'sek_B1-B7'] as const;
export type CostUnit = (typeof costUnits)[number];

export const energyUnits = ['MJ', 'kWh'] as const;
export type EnergyUnit = (typeof energyUnits)[number];

/**
 * CO2 values that are available in ConversionFactors
 */
export const co2eUnits = [
  'co2e_A1-A3',
  'co2e_A4',
  'co2e_A5',
  'co2e_total',
] as const;
export type CO2eUnit = (typeof co2eUnits)[number];

/**
 * All available units. Some units are connected to the building lifecycle.
 * Read more here: https://oneclicklca.zendesk.com/hc/en-us/articles/360015064999-Life-Cycle-Stages
 */
export const quantityUnits = [
  ...originalUnits,
  ...basicUnits,
  ...energyUnits,
  ...calculatedUnits,
  ...costUnits,
  ...co2eUnits,
  ...ratioUnits,
  ...geometricUnits,
  ...thermalConductivityUnits,
] as const;

/**
 * Unit enum including CO2e and cost units
 */
export type QuantityUnit = (typeof quantityUnits)[number];

export const selectableQuantityUnits = [
  ...originalUnits,
  ...energyUnits,
  ...calculatedUnits,
  ...ratioUnits,
  ...basicUnits,
] as const;

/**
 * Units selectable in the dropdowns
 */
export type SelectableQuantityUnit = (typeof selectableQuantityUnits)[number];

/**
 * A record containing any kind of factors
 */
export type ConversionFactors = Partial<Record<QuantityUnit, number>>;

/**
 * ConversionFactors with enforced co2e values
 */
export type CO2eConversionFactors = ConversionFactors &
  Record<CO2eUnit, number>;

/**
 * Record of quantities for each element
 */
export type ConversionFactorQuantityRecord = Record<
  IElementID,
  CO2eConversionFactors
>;

/**
 * Empty ConversionFactors with always defined co2 values
 */
export const emptyConversionFactors: Readonly<CO2eConversionFactors> = {
  'co2e_A1-A3': 0,
  co2e_A4: 0,
  co2e_A5: 0,
  co2e_total: 0,
};

/**
 * Helper type to not having to pass products around to all functions requiring only conversion_factors
 */
export type ProductOrConversionFactors =
  | Pick<Product, 'conversion_factors'>
  | ConversionFactors;
