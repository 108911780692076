import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  ChangeEvent,
  KeyboardEvent,
  FocusEvent,
  MouseEvent,
  FC,
} from 'react';

import {
  OutlinedInputProps,
  OutlinedTextFieldProps,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Variant } from '@mui/material/styles/createTypography';
import { formatToExpressionVariable } from '../../../shared/helpers/expression_variables_helpers';
import {
  isValidVariableName,
  isAvailableName,
} from '../../../shared/helpers/mathjs';
import { useEllipsesStyles } from '../style/ellipsis';
import { useBooleanState } from '../hooks/hooks';
import { useIsReadonly } from '../hooks/user.hook';
import NodonTextField from './NodonTextField';

const useStyles = makeStyles<void, 'disabled' | 'noborder'>()(
  ({ palette: { text, neutral } }, _params, classes) => ({
    root: {
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: neutral.lighter,
      },
    },
    noborder: {
      borderColor: 'transparent',
    },
    disabled: {
      pointerEvents: 'none',

      '&.Mui-disabled': {
        WebkitTextFillColor: neutral.main,
      },
      [`.${classes.noborder}`]: {
        borderColor: 'transparent !important',
      },
    },
    deactivated: {
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: 13,
        right: 0,
        transform: 'translateY(-50%)',
        height: 2,
        background: text.secondary,
      },
    },
    deactivatedLabel: {
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: 0,
        right: 0,
        transform: 'translateY(-50%)',
        height: 2,
        background: text.secondary,
      },
    },
    pointer: {
      cursor: 'pointer',
    },
    tabStyles: {
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: '0.875rem',
    },
    overrides: {
      width: '100%',
    },
  }),
);

interface InlineTextFieldProps {
  value: string;
  /** controls if border is visible and editing is possible. focuses the input on changing from anything -> true */
  defaultValue?: string;
  editing?: boolean;
  tabInput?: boolean;
  displayBorder?: boolean;
  textFieldProps?: Partial<OutlinedTextFieldProps>;
  variant?: Variant;
  error?: boolean;
  disabled?: boolean;
  isDeactivated?: boolean;
  onCancel?: () => void;
  onClick?: (e: MouseEvent) => void;
  onSave?: (value: string) => void;
  setNameIsInvalid?: (valid: boolean) => void;
}

const noBubble = (e: FocusEvent | MouseEvent | KeyboardEvent): void => {
  e.preventDefault();
  e.stopPropagation();
};

const getWidthOfText = (
  text: string,
  styles = {
    fontFamily: 'sans-serif',
    fontSize: 16,
    fontWeight: 400,
  },
) => {
  const element = document.createElement('div');
  const styleKeys = Object.keys(styles);
  for (let i = 0, n = styleKeys.length; i < n; ++i) {
    element.style[styleKeys[i] as unknown as number] =
      styles[styleKeys[i] as 'fontFamily'];
  }
  element.style.display = 'inline-block';
  element.innerHTML = text;
  document.body.appendChild(element);
  const width = element.offsetWidth;
  document.body.removeChild(element);
  return width + 4;
};

const InlineTextField: FC<InlineTextFieldProps> = ({
  value,
  defaultValue,
  editing,
  tabInput,
  displayBorder,
  textFieldProps,
  variant,
  error,
  disabled,
  isDeactivated,
  onCancel,
  onClick,
  onSave,
  setNameIsInvalid,
}) => {
  const { classes, cx } = useStyles();
  const { typography } = useTheme();
  const ellipsis = useEllipsesStyles();
  const readonly = useIsReadonly();

  const [isBlured, setBlur, setUnblur] = useBooleanState();
  const [strikeThroughWidth, setStrikeThroughWidth] = useState<number>();
  const [localValue, setLocalValue] = useState(value);

  const inputRef = useRef<HTMLInputElement | undefined>(undefined);

  const trimmedValue = localValue.trim();

  const variantStyle: Partial<typeof Typography> = useMemo(() => {
    if (!variant) {
      return {};
    }

    const { fontFamily, fontWeight, fontSize, lineHeight, letterSpacing } =
      typography[variant];
    return { fontFamily, fontWeight, fontSize, lineHeight, letterSpacing };
  }, [typography, variant]);

  // useEffect(() => {
  //   console.log('editing', editing);
  //   if (!!editing && inputRef.current && !disabled) {
  //     console.log('inputRef.current', inputRef.current);
  //     inputRef.current.focus();
  //   }
  // }, [editing, disabled]);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    if (isDeactivated) {
      setStrikeThroughWidth(getWidthOfText(value));
    }
    // Do not add anything to this dependency array, so it only runs once!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (setNameIsInvalid) {
      const expressionName = formatToExpressionVariable(localValue);
      const nameIsValid =
        isValidVariableName(expressionName) && isAvailableName(expressionName);

      setNameIsInvalid(!nameIsValid);
    }
  }, [setNameIsInvalid, localValue]);

  useEffect(() => {
    inputRef.current?.blur();
    setUnblur();
  }, [isBlured, setUnblur]);

  const handleClick = useCallback(
    (e: MouseEvent<HTMLInputElement>) => {
      if (editing) {
        noBubble(e);
      }
      if (onClick && !disabled) {
        onClick(e);
      }
    },
    [disabled, editing, onClick],
  );

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
  }, []);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (editing && (e.key === 'Escape' || e.key === 'Enter')) {
        return noBubble(e);
      }
    },
    [editing],
  );

  const handleKeyUp = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (editing) {
        if (e.key === 'Enter' && trimmedValue !== value) {
          noBubble(e);
          if (onSave) {
            onSave(trimmedValue);
          }
          setBlur();
        } else if (e.key === 'Escape') {
          noBubble(e);
          setLocalValue(value);
          if (onCancel) {
            onCancel();
          }
          setBlur();
        }
      }
    },
    [editing, onCancel, onSave, setBlur, trimmedValue, value],
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (editing && !disabled) {
        noBubble(e);
        if (trimmedValue !== value) {
          if (onSave) {
            onSave(trimmedValue);

            if (!isDeactivated || localValue === '') {
              return;
            }
            setStrikeThroughWidth(getWidthOfText(localValue));
          }
        } else if (onCancel) {
          onCancel();
        }
      }
    },
    [
      disabled,
      editing,
      onCancel,
      onSave,
      trimmedValue,
      value,
      localValue,
      isDeactivated,
    ],
  );

  const inputProps: Partial<OutlinedInputProps> = useMemo(
    () => ({
      readOnly: !editing || disabled,
      classes: {
        root: classes.root,
        notchedOutline:
          !displayBorder && !editing ? classes.noborder : undefined,
        disabled: classes.disabled,
        input: cx(
          !displayBorder && !editing ? classes.pointer : undefined,
          tabInput && classes.tabStyles,
          !editing && ellipsis.classes.ellipsis,
          disabled && classes.disabled,
        ),
      },
      style: variantStyle,
    }),
    [
      classes.disabled,
      classes.noborder,
      classes.pointer,
      classes.root,
      classes.tabStyles,
      cx,
      disabled,
      displayBorder,
      editing,
      ellipsis.classes.ellipsis,
      tabInput,
      variantStyle,
    ],
  );

  return (
    <NodonTextField
      disabled={!!disabled || readonly}
      label={value ? undefined : defaultValue}
      inputRef={inputRef}
      variant="outlined"
      size="small"
      {...textFieldProps}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      onBlur={handleBlur}
      onClick={handleClick}
      InputLabelProps={{
        className: cx(isDeactivated && classes.deactivatedLabel),
      }}
      className={cx(
        classes.overrides,
        isDeactivated && localValue !== '' && classes.deactivated,
      )}
      sx={
        isDeactivated && localValue !== ''
          ? {
              '&::after': {
                width: editing ? 0 : strikeThroughWidth,
              },
            }
          : {}
      }
      InputProps={inputProps}
      value={localValue}
      error={error}
    />
  );
};

export default InlineTextField;
