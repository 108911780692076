import { MouseEvent } from 'react';
import { hasParentElementOfClass } from '../../client/src/helpers/dom.helpers';
import { isOneOf } from './array_helpers';

export type MouseCallback = (event?: MouseEvent) => unknown;

export interface IEventHandlerOptions {
  /**
   * Stop the event from propagating (IE. reach event listeners on parent elements)
   */
  stopPropagation?: boolean;

  /**
   * Prevent default behavior of the event like showing a context menu
   */
  preventDefault?: boolean;

  /**
   * Ignore input events like clicks on input elements that might happen inside the target element
   */
  ignoreInputEvents?: boolean;

  /**
   * Ignore clicks in modal, popover, tooltip, etc.
   */
  ignoreModalEvents?: boolean;
}

const INPUT_TAGS = ['INPUT', 'TEXTAREA', 'SELECT'];
const MODAL_CLASSES = ['MuiModal-root', 'MuiPopover-root', 'MuiDialog-root'];

export const eventHandler = <E extends KeyboardEvent | MouseEvent, R>(
  callback?: (e: E) => R,
  options: IEventHandlerOptions = {},
): ((e: E) => R | undefined) => {
  return (e: E): R | undefined => {
    if (e) {
      // Apply defaults
      const {
        stopPropagation = true,
        preventDefault = false,
        ignoreInputEvents = false,
        ignoreModalEvents = false,
      } = options;

      if (ignoreInputEvents && isInputEvent(e)) {
        return;
      }
      if (ignoreModalEvents && isModalEvent(e)) {
        return;
      }
      if (stopPropagation) {
        e.stopPropagation();
      }
      if (preventDefault) {
        e.preventDefault();
      }
      if (callback) {
        return callback(e);
      }
    }
  };
};

export const keyboardEventHandler = <R>(
  callback?: (e: KeyboardEvent) => R,
  options: IEventHandlerOptions = {},
): ((e: KeyboardEvent) => R | undefined) => eventHandler(callback, options);

export const mouseEventHandler = <R>(
  callback?: (e: MouseEvent) => R,
  options: IEventHandlerOptions = {},
): ((e: MouseEvent) => R | undefined) => eventHandler(callback, options);

/**
 * Detect if the user is interacting with an input element.
 * Some deselction/selecting logic should be ignored if the user is interacting with an input.
 * @param e
 * @returns
 */
export const isInputEvent = (e: MouseEvent | KeyboardEvent): boolean => {
  const target = e?.target as HTMLInputElement | undefined;

  // Don't trigger anything if the user is interacting with an input
  return isOneOf(INPUT_TAGS, target?.tagName) && !target.disabled;
};

export const isModalEvent = (e: MouseEvent | KeyboardEvent): boolean =>
  hasParentElementOfClass(e.target as HTMLElement, ...MODAL_CLASSES);
