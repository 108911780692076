import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import InlineTextField from '../../InlineTextField';
import { IProjectInfo } from '../../../../../shared/models/project.interface';
import { useUIState } from '../../../store/ui';
import { useIsReadonly } from '../../../hooks/user.hook';

import { useToggleElementExpanded } from '../../../hooks/expand-elements.hook';
import { isDeactivated } from '../../../../../shared/helpers/element_helpers';

import { LIST_ITEM_HEIGHT } from '../list.constants';
import { useBooleanState } from '../../../hooks/hooks';
import {
  useIsKebabMenuOpen,
  useTriggerContextKebabMenu,
} from '../../kebab-menu/kebab-menu.hooks';
import { useListRowStyles } from '../list.style';
import { useNavigateTo } from '../../../hooks/router.hooks';
import { useMouseEventCallback } from '../../../hooks/events.hook';
import RestrictProjectButton from '../../RestrictProjectButton';
import amplitudeLog from '../../../amplitude';
import { useProjectState } from '../../../store/project';
import {
  Box,
  OutlinedTextFieldProps,
  SxProps,
  Tooltip,
  Typography,
} from '@mui/material';
import ProjectInfoKebabMenu from '../KebabMenus/ProjectInfoKebabMenu';
import ProjectOwnerMenu from '../../ProjectOwnerMenu';
import ExpressionTypography from '../../ExpressionTypography';
import ExpandIcon from '../ExpandIcon';
import DateText from '../../DateText';
import ProjectSelectorRow from '../../project-selector/ProjectSelectorRow';

interface IProjectInfoListItemInput {
  project: IProjectInfo;
  indentation?: number;
  autoFocus?: boolean;
}

const ProjectInfoListItem: React.FC<IProjectInfoListItemInput> = ({
  project,
  indentation = 0,
  autoFocus,
}) => {
  const { classes: listClasses } = useListRowStyles();
  const readonly = useIsReadonly();
  const { setAddedElementId, setShowProjectSelector } = useUIState(
    'setAddedElementId',
    'setShowProjectSelector',
    'addedElementId',
  );

  const navigateTo = useNavigateTo();
  const toggleElementExpanded = useToggleElementExpanded(project);
  const triggerContextMenu = useTriggerContextKebabMenu(project.id);

  const { name, id } = project;
  const deactivated = isDeactivated(project);
  const isKebabMenuOpen = useIsKebabMenuOpen(id);

  const preventClose = useRef(false);

  const [hover, startHover, endHover] = useBooleanState(false);
  const [isEditingName, startEditingName, stopEditingName] =
    useBooleanState(false);

  const onRowClick = useMouseEventCallback(
    () => {
      if (!isEditingName && preventClose.current) {
        preventClose.current = false;
        return;
      }
      if (!preventClose.current) {
        setShowProjectSelector(false);
        navigateTo({ projectId: id });
      }
    },
    { ignoreInputEvents: true, ignoreModalEvents: true },
  );

  const { updateProjectDetails } = useProjectState('updateProjectDetails');

  const nameInputProps: Partial<OutlinedTextFieldProps> = useMemo(
    () => ({
      autoFocus,
      onFocus: (e) => {
        autoFocus && e.currentTarget.select();
        setAddedElementId();
      },
    }),
    [autoFocus, setAddedElementId],
  );

  const saveName = useCallback(
    async (name: string) => {
      stopEditingName();
      await updateProjectDetails({
        projectId: parseInt(id),
        data: { name },
      });

      amplitudeLog('Element Name Set', {
        Name: name,
        ElementID: id,
      });
    },
    [updateProjectDetails, id, stopEditingName],
  );

  const editName = useCallback(() => {
    startEditingName();
    preventClose.current = true;
  }, [startEditingName]);

  const rowItems = useMemo(
    () => ({
      lockIcon: <RestrictProjectButton hover={hover} project={project} />,
      expandIcon: <ExpandIcon element={project} disabled />,
      name: isEditingName ? (
        <InlineTextField
          variant="subtitle1"
          value={name}
          editing={isEditingName}
          disabled={readonly}
          textFieldProps={nameInputProps}
          onSave={saveName}
          onCancel={stopEditingName}
        />
      ) : (
        <Tooltip title="Open project">
          <Typography variant="body1" sx={projectNameStyles}>
            {name}
          </Typography>
        </Tooltip>
      ),
      date: (
        <Tooltip title="Updated at">
          <Box>
            <DateText date={project.updated_at} />
          </Box>
        </Tooltip>
      ),
      expression: (
        <Tooltip title="Gross floor area">
          <Box>
            <ExpressionTypography value={project.gfa} unit={'m²'} />
          </Box>
        </Tooltip>
      ),
      owner: <ProjectOwnerMenu projectInfo={project} />,
      kebabMenu: (isKebabMenuOpen || hover) && (
        <ProjectInfoKebabMenu project={project} onEditName={editName} />
      ),
    }),
    [
      editName,
      hover,
      isEditingName,
      isKebabMenuOpen,
      name,
      nameInputProps,
      project,
      readonly,
      saveName,
      stopEditingName,
    ],
  );

  // Start editing the name if autoFocus is set
  useEffect(() => {
    autoFocus && startEditingName();
  }, [autoFocus, startEditingName]);

  return (
    <ProjectSelectorRow
      classes={listClasses}
      deactivated={deactivated}
      height={LIST_ITEM_HEIGHT}
      hover={hover}
      onClick={onRowClick}
      onContextMenu={triggerContextMenu}
      onDoubleClick={toggleElementExpanded}
      onMouseLeave={endHover}
      onMouseOver={startHover}
      indentation={indentation}
      items={rowItems}
    />
  );
};

const projectNameStyles: SxProps = {
  flex: 1,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
} as const;

export default memo(ProjectInfoListItem);
