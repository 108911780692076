import { DateTime } from 'luxon';
import { ElementCategoryID } from '../models/element_categories.interface';
import { ElementKind, IBuildingVersion } from '../models/project.interface';

/*
Instructions to replace template:

1. Go to the Network tab in the browser's dev tools and click on the 'projects'
   request. In the preview of the response, select the project of interest and
   copy the 'versions' property.

2. Replace 'versionsTemplate' with the new array (project.versions) consisting of
   the new version objects.

3. Format the document by pressing shift+alt+F (Windows) or
   shift+option+F (Mac).

4. The timestamp properties (i.e. createdAt and updatedAt) are currently set
   with string values and those have to be converted to a 'DateTime' type,
   since these properties are of type 'DateTime'. This can be done like this:

   Highlight 'createdAt' (note: spelled with camelcase instead of underscore
   to prevent these lines to be deleted) property in one of the product objects
   and press ctrl+shift+L (Windows) or cmd+shift+L (Mac) to select all
   occurrences of this property.

   Replace the timestamp string (e.g. '2021-09-07T14:06:26.381+02:00') with
   DateTime.fromISO('2021-09-07T14:06:26.381+02:00') in order to convert the string
   to a DateTime type.

   Add .toString() after to retain the string type.

   Press escape to deselect all occurrences.

   Repeat the same steps for the 'updatedAt' properties.

5. Replace kind: [version|element|product] with the ElementKind.[Element|Product|Version],

6. Remove all properties with n-u-l-l (note: spelled with dashes to prevent
   these lines to be deleted) values by selecting all occurrences of n-u-l-l
   and then delete these lines.
*/

// TODO: Remove this template
export const versionTemplate: Readonly<IBuildingVersion> = {
  id: 'f02d3c23-cad7-4963-b72e-cd049b72d75b',
  name: 'Version 1',
  products: {
    'boverket_sv-SE_6000000003': {
      id: 'boverket_sv-SE_6000000003',
      name: 'Glasull, lösull, väggar',
      unit: 'kg',
      source: 'Boverket',
      categories: {
        BK04: {
          Mineralull: true,
        },
        Boverket: {
          Isolering: true,
        },
      },
      created_at: DateTime.fromISO('2022-03-16T19:44:21.437+01:00').toString(),
      updated_at: DateTime.fromISO('2022-03-16T19:46:26.338+01:00').toString(),
      characteristics: {},
      conversion_factors: {
        kg: 1,
        'm³': 0.03333333333333333,
        co2e_A4: 0.0345,
        co2e_A5: 0.012,
        'co2e_A1-A3': 1.2,
      },
      external_identifiers: {
        SS: 'EN 13162',
      },
    },
    'boverket_sv-SE_6000000004': {
      id: 'boverket_sv-SE_6000000004',
      created_at: DateTime.fromISO('2022-03-16T18:44:21.437Z').toString(),
      updated_at: DateTime.fromISO('2023-01-19T10:17:13.088Z').toString(),
      external_identifiers: { SS: 'EN 13162' },
      source: 'Boverket',
      name: 'Glasull, lösull, bjälklag',
      categories: { BK04: { Mineralull: true }, Boverket: { Isolering: true } },
      unit: 'kg',
      characteristics: {},
      conversion_factors: {
        kg: 1,
        'm³': 0.038461538461538464,
        co2e_A4: 0.0345,
        co2e_A5: 0.0116,
        'sek_A1-A3': 8.48,
        'co2e_A1-A3': 0.9,
      },
    },
    'boverket_sv-SE_6000000006': {
      id: 'boverket_sv-SE_6000000006',
      name: 'Glasull, ljudisolering ',
      unit: 'kg',
      source: 'Boverket',
      categories: {
        BK04: {
          Mineralull: true,
        },
        Boverket: {
          Isolering: true,
        },
      },
      created_at: DateTime.fromISO('2022-03-16T19:44:21.437+01:00').toString(),
      updated_at: DateTime.fromISO('2022-03-16T19:46:26.338+01:00').toString(),
      characteristics: {},
      conversion_factors: {
        kg: 1,
        'm³': 0.07142857142857142,
        co2e_A4: 0.0345,
        co2e_A5: 0.10413,
        'co2e_A1-A3': 1.4875,
      },
      external_identifiers: {
        SS: 'EN 13162',
      },
    },
    'boverket_sv-SE_6000000007': {
      id: 'boverket_sv-SE_6000000007',
      name: 'Sågad vara, u 16 %, barrträ',
      unit: 'kg',
      source: 'Boverket',
      categories: {
        BK04: {
          'Sågat virke': true,
        },
        Boverket: {
          Trävaror: true,
        },
      },
      created_at: DateTime.fromISO('2022-03-16T19:44:21.437+01:00').toString(),
      updated_at: DateTime.fromISO('2022-03-16T19:46:26.338+01:00').toString(),
      characteristics: {},
      conversion_factors: {
        kg: 1,
        'm³': 0.002197802197802198,
        co2e_A4: 0.0158,
        co2e_A5: 0.00863,
        'co2e_A1-A3': 0.0863,
      },
      external_identifiers: {
        SS: 'EN 14915:2013+A2:2020',
      },
    },
    'boverket_sv-SE_6000000016': {
      id: 'boverket_sv-SE_6000000016',
      name: 'Gipsskiva, brandskiva',
      unit: 'kg',
      source: 'Boverket',
      categories: {
        BK04: {
          'Gipsskivor vägg': true,
        },
        Boverket: {
          Byggskivor: true,
        },
      },
      created_at: DateTime.fromISO('2022-03-16T19:44:21.437+01:00').toString(),
      updated_at: DateTime.fromISO('2022-03-16T19:46:26.338+01:00').toString(),
      characteristics: {},
      conversion_factors: {
        kg: 1,
        'm³': 0.0012048192771084338,
        co2e_A4: 0.0232,
        co2e_A5: 0.0384,
        'co2e_A1-A3': 0.32,
      },
      external_identifiers: {
        SS: 'EN 14190:2014',
      },
    },
    'boverket_sv-SE_6000000019': {
      id: 'boverket_sv-SE_6000000019',
      created_at: DateTime.fromISO('2022-03-16T18:44:21.437Z').toString(),
      updated_at: DateTime.fromISO('2023-01-19T10:17:13.088Z').toString(),
      external_identifiers: { SS: 'EN 14190:2014' },
      source: 'Boverket',
      name: 'Gipsskiva, golvskiva',
      categories: {
        BK04: { 'Gipsskivor vägg': true },
        Boverket: { Byggskivor: true },
      },
      unit: 'kg',
      characteristics: {},
      conversion_factors: {
        kg: 1,
        'm³': 0.0008928571428571428,
        co2e_A4: 0.0232,
        co2e_A5: 0.0383,
        'sek_A1-A3': 3.966269841269841,
        'co2e_A1-A3': 0.237,
      },
    },
    'boverket_sv-SE_6000000020': {
      id: 'boverket_sv-SE_6000000020',
      name: 'Gipsskiva, standardskiva',
      unit: 'kg',
      source: 'Boverket',
      categories: {
        BK04: {
          'Gipsskivor vägg': true,
        },
        Boverket: {
          Byggskivor: true,
        },
      },
      created_at: DateTime.fromISO('2022-03-16T19:44:21.437+01:00').toString(),
      updated_at: DateTime.fromISO('2022-03-16T19:46:26.338+01:00').toString(),
      characteristics: {},
      conversion_factors: {
        kg: 1,
        'm³': 0.0014084507042253522,
        co2e_A4: 0.0232,
        co2e_A5: 0.03406,
        'co2e_A1-A3': 0.2838,
      },
      external_identifiers: {
        SS: 'EN 14190:2014',
      },
    },
    'boverket_sv-SE_6000000151': {
      id: 'boverket_sv-SE_6000000151',
      name: 'Konstruktionsstål, obearbetad, skrotbaserad',
      unit: 'kg',
      source: 'Boverket',
      categories: {
        BK04: {
          Balk: true,
        },
        Boverket: {
          'Stål och andra metaller': true,
        },
      },
      created_at: DateTime.fromISO('2022-03-16T19:44:21.437+01:00').toString(),
      updated_at: DateTime.fromISO('2022-03-16T19:46:26.338+01:00').toString(),
      characteristics: {},
      conversion_factors: {
        kg: 1,
        'm³': 0.00012738853503184712,
        co2e_A4: 0.0795,
        co2e_A5: 0.05625,
        'co2e_A1-A3': 1.125,
      },
      external_identifiers: {
        SS: 'EN 10219',
      },
    },
    'boverket_sv-SE_6000000168': {
      id: 'boverket_sv-SE_6000000168',
      name: 'Limträ, u 12 %, gran',
      unit: 'kg',
      source: 'Boverket',
      categories: {
        BK04: {
          Limträpelare: true,
        },
        Boverket: {
          Trävaror: true,
        },
      },
      created_at: DateTime.fromISO('2022-03-16T19:44:21.437+01:00').toString(),
      updated_at: DateTime.fromISO('2022-03-16T19:46:26.338+01:00').toString(),
      characteristics: {},
      conversion_factors: {
        kg: 1,
        'm³': 0.002304147465437788,
        co2e_A4: 0.0345,
        co2e_A5: 0.00663,
        'co2e_A1-A3': 0.1325,
      },
      external_identifiers: {
        SS: 'EN 14080:2013',
      },
    },
    'boverket_sv-SE_6000000169': {
      id: 'boverket_sv-SE_6000000169',
      name: 'Formskiva',
      unit: 'kg',
      source: 'Boverket',
      categories: {
        BK04: {
          Byggplywood: true,
        },
        Boverket: {
          Byggskivor: true,
        },
      },
      created_at: DateTime.fromISO('2022-03-16T19:44:21.437+01:00').toString(),
      updated_at: DateTime.fromISO('2022-03-16T19:46:26.338+01:00').toString(),
      characteristics: {},
      conversion_factors: {
        kg: 1,
        'm³': 0.0014705882352941176,
        co2e_A4: 0.0645,
        co2e_A5: 0.0805,
        'co2e_A1-A3': 0.805,
      },
      external_identifiers: {
        SS: 'EN 636:2012+A1:2015',
      },
    },
  },
  elements: [
    {
      kind: ElementKind.Element,
      id: '5ef489fb-a144-4d27-ba3d-291e348eb26e',
      name: 'Stomme',
      properties: [],
      unit: 'pcs',
      count: { resolved: 1, formatted: '1', expression: '1' },
      elements: [
        {
          id: 'd3dbc1dc-d7d9-47f4-a5ef-8aaf5cc85aed',
          name: 'Bjälklag',
          category_id: ElementCategoryID.FramingOfJoists,
          unit: 'm²',
          count: {
            resolved: 4000,
            formatted: '4 000',
            expression: 'gfa',
          },
          recipe_id: 'auto',
          properties: [],
          kind: ElementKind.Element,
          elements: [
            {
              kind: ElementKind.Product,
              id: '7052df3c-1e45-42a4-b847-42e761d59180',
              unit: 'm³',
              product_id: 'boverket_sv-SE_6000000004',
              count: { resolved: 0.15, formatted: '0.15', expression: '.15' },
            },
            {
              kind: ElementKind.Product,
              id: '9ea81d04-67be-46a2-87c4-1fd2227659a2',
              unit: 'm³',
              product_id: 'boverket_sv-SE_6000000016',
              count: {
                resolved: 0.015,
                formatted: '0.015',
                expression: '.015',
              },
            },
            {
              kind: ElementKind.Product,
              id: '3abac576-df12-4d22-b54a-599d80ab9398',
              unit: 'm³',
              product_id: 'boverket_sv-SE_6000000019',
              count: {
                resolved: 0.013,
                formatted: '0.013',
                expression: '.013',
              },
            },
            {
              kind: ElementKind.Product,
              id: 'ee89d2c7-3782-4e55-b3eb-f04c79992b19',
              unit: 'm³',
              product_id: 'boverket_sv-SE_6000000020',
              count: {
                resolved: 0.013,
                formatted: '0.013',
                expression: '.013',
              },
            },
            {
              kind: ElementKind.Product,
              id: 'b2d383b6-c9ce-4c1d-a160-7667b22de881',
              unit: 'm³',
              product_id: 'boverket_sv-SE_6000000168',
              count: { resolved: 0.07, formatted: '0.07', expression: '.07' },
            },
          ],
        },
        {
          id: 'fd657494-873a-401d-88d6-d80a09ceb7a4',
          name: 'Innerväggar',
          category_id: ElementCategoryID.PartingWall,
          unit: 'm²',
          count: {
            resolved: 2000,
            formatted: '2 000',
            expression: 'gfa/2',
          },
          recipe_id: 'auto',
          properties: [],
          kind: ElementKind.Element,
          elements: [
            {
              kind: ElementKind.Product,
              id: '36c9c67b-38f0-44ef-baa7-417e5519b195',
              unit: 'm³',
              product_id: 'boverket_sv-SE_6000000006',
              count: {
                resolved: 0.035,
                formatted: '0.035',
                expression: '0.035',
              },
            },
            {
              kind: ElementKind.Product,
              id: '009cb94a-ab4f-4d07-afa2-bed0ffa0db20',
              unit: 'm³',
              product_id: 'boverket_sv-SE_6000000007',
              count: {
                resolved: 0.00315,
                formatted: '0.00315',
                expression: '.00315',
              },
            },
            {
              kind: ElementKind.Product,
              id: '9a2b91af-8f87-4423-b7e2-5e72c4bcfc9a',
              unit: 'm³',
              product_id: 'boverket_sv-SE_6000000016',
              count: {
                resolved: 0.03,
                formatted: '0.03',
                expression: '2*.015',
              },
            },
            {
              kind: ElementKind.Product,
              id: 'dfbade32-2cdb-4dba-9122-43a25241d61d',
              unit: 'm³',
              product_id: 'boverket_sv-SE_6000000020',
              count: {
                resolved: 0.026,
                formatted: '0.026',
                expression: '2*.013',
              },
            },
            {
              kind: ElementKind.Product,
              id: '0c76a5c4-4b1b-43cb-a382-69fa46aaf24f',
              unit: 'm³',
              product_id: 'boverket_sv-SE_6000000168',
              count: {
                resolved: 0.102,
                formatted: '0.102',
                expression: '.102',
              },
            },
          ],
        },
        {
          id: '506e9fc3-e5e8-4587-85f9-9fe275199393',
          name: 'Ytterväggar',
          category_id: ElementCategoryID.Facades,
          unit: 'm²',
          count: {
            resolved: 3600,
            formatted: '3 600',
            expression: 'exteral_gwa_above_ground',
          },
          recipe_id: 'auto',
          properties: [],
          kind: ElementKind.Element,
          elements: [
            {
              kind: ElementKind.Product,
              id: '40358718-0333-4d60-8c4f-6337c22080cf',
              unit: 'm³',
              product_id: 'boverket_sv-SE_6000000003',
              count: { resolved: 0.22, formatted: '0.22', expression: '.22' },
            },
            {
              kind: ElementKind.Product,
              id: '097b44a8-5df0-417a-b468-25dcbb2841e6',
              unit: 'm³',
              product_id: 'boverket_sv-SE_6000000007',
              count: {
                resolved: 0.025,
                formatted: '0.025',
                expression: '.025',
              },
            },
            {
              kind: ElementKind.Product,
              id: 'bd36144b-e60f-4fad-8ea8-71a728aba7e4',
              unit: 'm³',
              product_id: 'boverket_sv-SE_6000000020',
              count: {
                resolved: 0.013,
                formatted: '0.013',
                expression: '.013',
              },
            },
          ],
        },
      ],
    },
    {
      kind: ElementKind.Element,
      id: '929c0df9-1008-4b91-9ba1-cee212519d14',
      name: 'Fasad',
      properties: [],
      unit: 'pcs',
      count: { resolved: 1, formatted: '1', expression: '1' },
      elements: [
        {
          id: '05200490-df5b-44f3-8abc-5cf578e69fd5',
          name: 'Fasad',
          category_id: ElementCategoryID.Facade,
          unit: 'm²',
          count: {
            resolved: 3600,
            formatted: '3 600',
            expression: 'exteral_gwa_above_ground',
          },
          recipe_id: 'auto',
          properties: [],
          kind: ElementKind.Element,
          elements: [
            {
              kind: ElementKind.Product,
              id: 'c854ab70-43e5-4e35-8086-b6f105def427',
              unit: 'm³',
              product_id: 'boverket_sv-SE_6000000007',
              count: {
                resolved: 0.027,
                formatted: '0.027',
                expression: '.027',
              },
            },
            {
              kind: ElementKind.Product,
              id: 'f1d251c5-a49d-49f9-b174-11c2b4331a7f',
              unit: 'kg',
              product_id: 'boverket_sv-SE_6000000151',
              count: {
                resolved: 0.032,
                formatted: '0.032',
                expression: '.032',
              },
            },
            {
              kind: ElementKind.Product,
              id: 'f81bb37e-1903-4064-98ee-e6ea42a9cd9e',
              unit: 'm³',
              product_id: 'boverket_sv-SE_6000000169',
              count: { resolved: 0.03, formatted: '0.03', expression: '.03' },
            },
          ],
        },
      ],
    },
  ],
  kind: ElementKind.Version,
};

export default versionTemplate;
