import React, {
  KeyboardEvent,
  forwardRef,
  useCallback,
  FocusEvent,
  useMemo,
} from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { useAppContext } from '../store/ui';

type ExtendedTextFieldProps = TextFieldProps & {
  customOnKeyDown?: (
    event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void | undefined;
};

const NodonTextField: React.FC<ExtendedTextFieldProps> = forwardRef(
  ({ customOnKeyDown, ...textFieldProps }, ref) => {
    const appContext = useAppContext();

    const handleFocus = useCallback(
      (e: FocusEvent<HTMLInputElement>) => {
        if (textFieldProps.onFocus) {
          textFieldProps.onFocus(e);
        }
        appContext({ inputFocused: true });
      },
      [textFieldProps, appContext],
    );

    const handleBlur = useCallback(
      (e: FocusEvent<HTMLInputElement>) => {
        if (textFieldProps.onBlur) {
          textFieldProps.onBlur(e);
        }
        appContext({ inputFocused: false });
      },
      [textFieldProps, appContext],
    );

    const inputProps: TextFieldProps['InputProps'] = useMemo(
      () => ({
        ...textFieldProps.InputProps,
        inputProps: {
          ...textFieldProps.InputProps?.inputProps,
          onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => {
            // TODO: Why this and not just onKeyDown below?
            if (customOnKeyDown) {
              customOnKeyDown(event);
              return;
            }

            if (event.key === 'Enter') {
              event.currentTarget.blur();
              event.preventDefault();
              event.stopPropagation();
              return;
            }
            if (textFieldProps.InputProps?.inputProps?.onKeyDown) {
              textFieldProps.InputProps?.inputProps?.onKeyDown(event);
            }
          },
        },
      }),
      [customOnKeyDown, textFieldProps.InputProps],
    );

    return (
      <TextField
        {...textFieldProps}
        ref={ref}
        InputProps={inputProps}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    );
  },
);

export default NodonTextField;
