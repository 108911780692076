import React, { FC } from 'react';
import { Button, Tooltip } from '@mui/material';
import {
  getMainCategory,
  isMainCategory,
} from '../../../../shared/templates/categories';
import { useMainCategoryElementNavigation } from '../../hooks/router.hooks';
import { useMouseEventCallback } from '../../hooks/events.hook';
import { mainCategoryIcons } from '../Icon';

interface MainCategoryIconButtonProps {
  id: Parameters<typeof getMainCategory>[0];
}

const MainCategoryIconButton: FC<MainCategoryIconButtonProps> = ({ id }) => {
  const mainCategoryNavigation = useMainCategoryElementNavigation();
  const mainCategory = getMainCategory(id);
  const isMain = isMainCategory(id);

  const handleClick = useMouseEventCallback(() => {
    if (isMain) {
      mainCategoryNavigation(id);
    }
  });

  if (!isMain) {
    return null;
  }

  return (
    <Tooltip title={mainCategory?.name} disableInteractive placement="right">
      <Button sx={{ minWidth: 40, height: 40 }} onClick={handleClick}>
        {mainCategoryIcons[id]}
      </Button>
    </Tooltip>
  );
};

export default MainCategoryIconButton;
