import React, { FC, useEffect, useMemo, useState } from 'react';
import { Drawer, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Insights from './Insights';
import Element from './Element';
import { isElement } from '../../../../shared/helpers/recursive_element_helpers';
import ProjectSettings from '../ProjectSettings';
import { useProjectState } from '../../store/project';
import { useUIState } from '../../store/ui';
import { SidePanelConstant } from '../../style/constants';
import ReadonlyBlocker from '../ReadonlyBlocker';
import { useDrawerPaperProps } from '../../hooks/styles.hook';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
  },

  drawer: {
    width: SidePanelConstant.WIDTH,
    flexShrink: 0,
  },

  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

interface SidePanelProps {
  panelPosition: 'left' | 'right';
}

const SidePanel: FC<SidePanelProps> = ({ panelPosition }) => {
  const { classes } = useStyles();

  const { project } = useProjectState('project', 'isLoading');
  const [isNewProject, setIsNewProject] = useState(false);
  const { selectedPage, selectedVersion, selectedElement, showSettings } =
    useUIState(
      'selectedPage',
      'selectedVersion',
      'selectedElement',
      'showSettings',
    );

  useEffect(() => {
    setIsNewProject(true);
  }, [project?.id]);

  const content = useMemo(() => {
    if (!selectedVersion) {
      return undefined;
    }
    if (showSettings) {
      return (
        <ProjectSettings
          project={project}
          isNewProject={isNewProject}
          setIsNewProject={setIsNewProject}
        />
      );
    }
    if (selectedPage === 'elements' && isElement(selectedElement)) {
      return (
        <ReadonlyBlocker>
          <Element selectedElement={selectedElement} />
        </ReadonlyBlocker>
      );
    }
    return <Insights project={project} version={selectedVersion} />;
  }, [
    isNewProject,
    project,
    selectedElement,
    selectedPage,
    selectedVersion,
    showSettings,
  ]);

  return (
    <Drawer
      variant="permanent"
      anchor={panelPosition}
      className={classes.drawer}
      sx={DRAWER_SX}
      PaperProps={useDrawerPaperProps(DRAWER_PAPER_SX)}
    >
      {content}
    </Drawer>
  );
};

const DRAWER_SX = { displayPrint: 'none' } as const;

const DRAWER_PAPER_SX = {
  width: SidePanelConstant.WIDTH,
  paddingBottom: '48px',
  overflowX: 'hidden',
  zIndex: 900,
} as const;

export default SidePanel;
