import { StrengthType } from '../construction.interface';
import {
  ConstructionTimberStrengthClass,
  isWoodStrengthClass,
  OneOfWoodStrengthClass,
  WoodStrengthClass,
  WoodType,
} from '../wood';
import constructionTimberTable from './material-properties/wood-construction-timber';
import glulamTable from './material-properties/wood-glulam';

/**
 * Get
 * @param woodType
 * @param woodStrengthClass
 * @param property
 * @returns
 */
export const getWoodTableValue = (
  woodType: WoodType,
  woodStrengthClass: OneOfWoodStrengthClass,

  // TODO, This must be mapped to some enum (the same in the tables
  property?: StrengthType | string,
): number => {
  return isWoodStrengthClass(woodStrengthClass)
    ? getWoodValue(woodType, woodStrengthClass, property)
    : getConstructionTimberValue(woodStrengthClass, property);
};

/**
 * Get table value from wood table
 * @param woodStrengthClass
 * @param property
 * @returns
 */
const getWoodValue = (
  woodType: WoodType,
  woodStrengthClass: WoodStrengthClass,

  // TODO, This must be mapped to some enum (the same in the tables
  property?: string,
): number => {
  const rows = !property
    ? glulamTable
    : glulamTable.filter(
        (row) => row.woodType === woodType && row.strengthType === property,
      );

  const value = rows.length > 0 && rows[0][woodStrengthClass];
  if (typeof value === 'number') {
    return value;
  }
  throw new Error(
    `Could not find strength value wood[${woodStrengthClass}] with property "${
      property ?? 'undefined'
    }"`,
  );
};

/**
 * Get table value from construction timber table
 * @param woodStrengthClass
 * @param property
 * @returns
 */
const getConstructionTimberValue = (
  woodStrengthClass: ConstructionTimberStrengthClass,

  // TODO, This must be mapped to some enum (the same in the tables
  property?: string,
): number => {
  const rows = !property
    ? constructionTimberTable
    : constructionTimberTable.filter((row) => row.strengthType === property);

  const value = rows.length > 0 && rows[0][woodStrengthClass];
  if (typeof value === 'number') {
    return value;
  }
  throw new Error(
    `Could not find strength value constructionTimber[${woodStrengthClass}] with property "${
      property ?? 'undefined'
    }"`,
  );
};
