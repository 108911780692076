import { Product } from '../../../../shared/models/product.interface';
import { createWithEqualityFn } from 'zustand/traditional';
import { devtools } from 'zustand/middleware';
import axios from 'axios';
import { toLookup } from '../../../../shared/helpers/utils.helpers';
import { IProductStoreState } from './product-state.model';
import { reloadApp } from '../utils';
import { shallow } from 'zustand/shallow';
import { getSelectedOrganization } from '../organization';
import { createCustomProduct } from '../../../../shared/helpers/product-factory.helpers';
import { initHmrStore, getHmrStoreState } from '../../helpers/vite.helpers';
import { validateProduct } from '../../../../shared/validation/product.validation';
import genericProducts from '../../../../shared/generic_products';

const STORE_NAME = 'products';

export const useProductStore = createWithEqualityFn<IProductStoreState>()(
  devtools(
    (set, get) => ({
      products: [],
      productsLookup: {},
      isLoading: false,
      isUpdating: false,
      isDeleting: false,
      isCreating: false,
      ...getHmrStoreState(STORE_NAME),

      fetchProducts: () => {
        set(() => ({ isLoading: true }));
        return axios.get<Product[]>('products').then(({ data }) => {
          const products = [...genericProducts, ...data];

          set(() => ({
            isLoading: false,
            products: products,
            productsLookup: toLookup(products),
          }));
          return data;
        });
      },
      createProduct: async (productPartial) => {
        const organization = getSelectedOrganization(true);

        // Users can only create custom products
        const product = createCustomProduct({
          ...productPartial,
          organizations: [organization],
        });

        if (get().productsLookup[product.id]) {
          throw new Error('Product already exists');
        }
        set(() => ({ isCreating: true }));

        const { data } = await axios.post<Product>(`products`, product);
        set(({ products, productsLookup }) => ({
          isCreating: false,
          products: [...products, data],
          productsLookup: {
            ...productsLookup,
            [data.id]: data,
          },
        }));
        return data;
      },
      createProductLocally: (product) =>
        set(({ products, productsLookup }) => ({
          products: [...products, product],
          productsLookup: {
            ...productsLookup,
            [product.id]: product,
          },
        })),
      updateProduct: (product: Product) => {
        validateProduct(product);
        set(() => ({ isUpdating: true }));

        return axios
          .put<Product>(`/products`, product)
          .then(({ data }) => {
            set(({ products, productsLookup }) => ({
              isUpdating: false,
              products: products.map((product) =>
                product.id === data.id ? data : product,
              ),
              productsLookup: {
                ...productsLookup,
                [data.id]: data,
              },
            }));
            return data;
          })
          .catch((err) => {
            reloadApp(err.response?.status as number);
            return Promise.reject(err);
          });
      },
      updateProductLocally: (product: Product) =>
        set(({ products, productsLookup }) => ({
          products: products.map((p) => (p.id === product.id ? product : p)),
          productsLookup: {
            ...productsLookup,
            [product.id]: product,
          },
        })),
      deleteProduct: (id: string) => {
        set(() => ({ isDeleting: true }));

        return axios.delete(`/products/${id}`).then(() => {
          set(({ products, productsLookup }) => ({
            isDeleting: false,
            products: products.filter((product) => product.id !== id),
            productsLookup: toLookup(
              Object.values(productsLookup).filter(
                (product) => product.id !== id,
              ),
            ),
          }));
        });
      },
      deleteProductLocally: (id: string) =>
        set(({ products, productsLookup }) => ({
          isDeleting: false,
          products: products.filter((product) => product.id !== id),
          productsLookup: toLookup(
            Object.values(productsLookup).filter(
              (product) => product.id !== id,
            ),
          ),
        })),
    }),
    { name: STORE_NAME },
  ),
  shallow,
);

initHmrStore(STORE_NAME, useProductStore);
