import React, { ReactNode } from 'react';
import { IRowInput, Row } from '../ElementList/Row';
import { RowCell } from '../ElementList/RowCell';
import {
  LIST_ITEM_HEIGHT,
  LIST_SPACING,
  ROOT_CELL_WIDTH,
  ROOT_CELL_WIDTH_RESPONSIVE,
  CONTENT_CELL_WIDTH_RESPONSIVE,
  CONTENT_CELL_WIDTH,
} from '../ElementList/list.constants';
import { Typography } from '@mui/material';

interface RowItems {
  lockIcon?: ReactNode;
  expandIcon?: ReactNode;
  name: ReactNode;
  date: ReactNode;
  expression: ReactNode;
  owner: ReactNode;
  kebabMenu?: ReactNode;
}

interface ProjectSelectorRowProps extends IRowInput {
  items: RowItems;
  indentation?: number;
}

const ProjectSelectorRow: React.FC<ProjectSelectorRowProps> = ({
  items: { lockIcon, expandIcon, name, date, expression, owner, kebabMenu },
  indentation,
  ...other
}) => {
  return (
    <Row spacing={LIST_SPACING} padding {...other}>
      <RowCell
        width={ROOT_CELL_WIDTH.CONTENT}
        responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.CONTENT}
      >
        <Row height={LIST_ITEM_HEIGHT} spacing={LIST_SPACING}>
          <RowCell width={ROOT_CELL_WIDTH.ICON} indentation={indentation}>
            <LabelOrComponent item={lockIcon} />
          </RowCell>

          <RowCell width={ROOT_CELL_WIDTH.ICON}>
            <LabelOrComponent item={expandIcon} />
          </RowCell>

          <RowCell
            width={CONTENT_CELL_WIDTH_RESPONSIVE.NAME}
            responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.NAME}
          >
            <LabelOrComponent item={name} />
          </RowCell>

          <RowCell width={CONTENT_CELL_WIDTH.DATE} align="right">
            <LabelOrComponent item={date} />
          </RowCell>

          <RowCell
            width={CONTENT_CELL_WIDTH_RESPONSIVE.EXPRESSION}
            responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.EXPRESSION}
            align="right"
          >
            <LabelOrComponent item={expression} />
          </RowCell>
        </Row>
      </RowCell>
      <RowCell
        width={ROOT_CELL_WIDTH.BAR}
        responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.BAR}
        align="right"
      >
        <LabelOrComponent item={owner} />
      </RowCell>

      <RowCell width={CONTENT_CELL_WIDTH.ICON}>
        <LabelOrComponent item={kebabMenu} />
      </RowCell>
    </Row>
  );
};

const LabelOrComponent: React.FC<{ item: ReactNode }> = ({ item }) => {
  return typeof item === 'string' ? (
    <Typography variant="h6">{item}</Typography>
  ) : (
    item
  );
};

export default ProjectSelectorRow;
