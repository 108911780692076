import React, { useCallback, useMemo } from 'react';
import { IProjectInfo } from '../../../../../shared/models/project.interface';
import {
  useProjectState,
  useUpdateProjectAndFolderLocations,
} from '../../../store/project';
import { useFolders } from '../../../store/folder';
import ProjectActionMenu, {
  ProjectActionMenuItem,
} from '../../ProjectActionMenu';
import { IProjectFolder } from '../../../../../shared/models/folder.interface';
import { Edit } from '@mui/icons-material';
import { projectActionMenuIconStyles } from '../../../style/constants';

interface IProductElementKebabMenuInput {
  project: IProjectInfo | IProjectFolder; // TODO: Separate into two components
  onEditName: () => void;
}

const ProjectInfoKebabMenu: React.FC<IProductElementKebabMenuInput> = ({
  project,
  onEditName,
}) => {
  const { projectsLookup } = useProjectState('projectsLookup');
  const updateProjectAndFolderLocations = useUpdateProjectAndFolderLocations();

  const folders = useFolders();

  const handleDeleteProject = useCallback(async () => {
    const itemsInParentFolderWithConsecutiveIndices = [
      ...folders,
      ...Object.values(projectsLookup),
    ]
      .filter(
        ({ id, parent_id }) =>
          id !== project.id && parent_id === project.parent_id,
      )
      .sort((a, b) => a.location - b.location)
      .map((item, index) => ({ ...item, location: index }));

    return updateProjectAndFolderLocations(
      itemsInParentFolderWithConsecutiveIndices,
    );
  }, [
    folders,
    project.id,
    project.parent_id,
    projectsLookup,
    updateProjectAndFolderLocations,
  ]);

  const extraItems = useMemo<ProjectActionMenuItem[]>(
    () => [
      {
        id: 'rename',
        defaultMessage: 'Edit name',
        icon: <Edit {...projectActionMenuIconStyles} />,
        onClick: onEditName,
      },
    ],
    [onEditName],
  );

  return (
    <ProjectActionMenu
      projectId={Number(project.id)}
      projectName={project.name}
      locked={!!project.locked}
      archived={!!project.archived}
      onDeleteProjectListItem={handleDeleteProject}
      extraItems={extraItems}
    />
  );
};

export default ProjectInfoKebabMenu;
