export const ROOT_CELL_WIDTH = {
  ICON: 40,
  BAR: 200,
  CONTENT: 200,
} as const;

export const CONTENT_CELL_WIDTH = {
  ICON: 40,
  ICON_LOCK: 16,
  NAME: 200,
  RECIPE: 100,
  EXPRESSION: 165,
  UNIT: 60,
  ACTIONS: 200,
  DATE: 100,
} as const;

/**
 * Root widths (these are related to each other).
 * So if content grows 300px bar grows 100px
 */
export const ROOT_CELL_WIDTH_RESPONSIVE = {
  CONTENT: 3,
  BAR: 1,
  ICON: 0,
} as const;

/**
 * How much each cell should grow in relation to the others in content cell
 */
export const CONTENT_CELL_WIDTH_RESPONSIVE = {
  ICON: 0,
  ICON_LOCK: 0,
  NAME: 2,
  RECIPE: 1,
  EXPRESSION: 1.2,
  UNIT: 0,
  ACTIONS: 1,
} as const;

/**
 * Size in pixels of one indentation level in the list
 */
export const LIST_INDENTATION_SIZE = 20;

export const LIST_ITEM_HEIGHT = 62;

/**
 * Spacing in the list (in UI units, not pixels)
 */
export const LIST_SPACING = 2;
