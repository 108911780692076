import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { VerticalAlignBottom } from '@mui/icons-material';

interface ImportElementsButtonProps {
  onClick: () => void;
}

const ImportElementsButton: React.FC<ImportElementsButtonProps> = ({
  onClick,
}) => {
  return (
    <Tooltip title={'Import elements'} placement="right">
      <IconButton
        style={{ padding: '5px', marginRight: '8px' }}
        color="inherit"
        onClick={onClick}
        size="large"
      >
        <VerticalAlignBottom />
      </IconButton>
    </Tooltip>
  );
};

export default ImportElementsButton;
