import { canWrite } from '../../../shared/helpers/user.helpers';
import { useMatch } from 'react-router-dom';
import { NODON_SHARED_URL_PATH_FULL_PATTERN } from '../../../shared/constants/router.constants';
import { getProject, getProjectsLookup, useProject } from '../store/project';
import { IProjectInfo } from '../../../shared/models/project.interface';
import { isAdminGroupMember } from '../../../shared/helpers/user.helpers';
import { UserState, useUserStore } from '../store/user/user.store';
import { useSelectedOrganization } from '../store/organization';
import { User } from '../../../shared/models/user.interface';

const userSelector = (state: UserState) => state.user;

export const useUser = (): User | undefined => useUserStore(userSelector);

export const useIsReadonly = (): boolean => {
  const user = useUser();
  const project = useProject();
  const organization = useSelectedOrganization();
  const sharedMatch = useMatch('/shared/*');

  if (sharedMatch) return true;
  if (!user || !organization) return false;

  return !canWrite(user.id, organization, project);
};

export const useUserId = (): string => {
  const user = useUser();
  const sharedMatch = useMatch(NODON_SHARED_URL_PATH_FULL_PATTERN);

  if (!user && !sharedMatch) {
    throw new Error('User not logged in');
  }
  return sharedMatch ? 'SHARED' : user?.id ?? '';
};

export const useUserEmail = (): string => {
  const user = useUser();
  return user?.name ?? '';
};

export const useUserDomain = (): string => {
  const email = useUserEmail();
  return email.indexOf('@') ? email.split('@')[1] : '';
};

export const useUserIsOwner = (id = getProject().id): boolean => {
  const user = useUser();
  const project = getProjectsLookup()[id] as IProjectInfo | undefined;

  return user?.id === project?.owner;
};

export const useUserIsAdmin = (): boolean => {
  const user = useUser();
  return user ? isAdminGroupMember(user) : false;
};

const usersSelector = (state: UserState) => state.users;
export const useUsers = (): User[] => useUserStore(usersSelector);

const lookupSelector = (state: UserState) => state.lookup;
export const useUserLookup = (): UserState['lookup'] =>
  useUserStore(lookupSelector);

/**
 * Get user without causing re-renders
 * @returns
 */
export const getUser = (): User | undefined =>
  userSelector(useUserStore.getState());
