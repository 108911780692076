export interface IPosition {
  x: number;
  y: number;
}

export interface ISize {
  width: number;
  height: number;
}

export interface IRect extends IPosition, ISize {}

const defaultPosition: Readonly<IPosition> = { x: 0, y: 0 };
const defaultSize: Readonly<ISize> = { width: 0, height: 0 };
const defaultRect: Readonly<IRect> = { ...defaultPosition, ...defaultSize };

export const getRectFromDragEvent = (
  event: React.DragEvent<HTMLElement>,
): IRect => {
  const target = getDraggableParent(event.target as HTMLDivElement);
  const native = event.nativeEvent;

  if (!target) {
    return { ...defaultRect };
  }

  return {
    x: native.x,
    y: native.y,
    width: target?.clientWidth || 0,
    height: target?.clientHeight || 0,
  };
};

const getDraggableParent = (child: HTMLElement): HTMLElement | undefined => {
  let parent: HTMLElement | null = child;
  while ((parent = parent?.parentElement)) {
    if (parent?.getAttribute('draggable')) {
      return parent;
    }
  }
};

export const getRectFromElement = (element?: HTMLElement): IRect => {
  if (!element) {
    return { ...defaultRect };
  }

  return {
    x: element?.clientLeft,
    y: element?.clientTop,
    width: element?.clientWidth,
    height: element?.clientHeight,
  };
};

export const getCenter = (rect: IRect): IPosition => ({
  x: rect.x + rect.width / 2,
  y: rect.y + rect.height / 2,
});

/**
 * Returns the relative position of a point within a rectangle.
 * @param rect The rectangle to get the relative position within.
 * @param pos The position to get the relative position of.
 * @returns The relative position of the point within the rectangle.
 * @example getRelativePositionWithinRect({ x: 0, y: 0, width: 100, height: 100 }, { x: 50, y: 50 }) // { x: 0.5, y: 0.5 }
 * @example getRelativePositionWithinRect({ x: 0, y: 0, width: 100, height: 100 }, { x: 0, y: 0 }) // { x: 0, y: 0 }
 * @example getRelativePositionWithinRect({ x: 0, y: 0, width: 100, height: 100 }, { x: 100, y: 100 }) // { x: 1, y: 1 }
 */
export const getRelativePositionWithinRect = (
  rect: IRect,
  pos: IPosition,
): IPosition => {
  const x = (pos.x - rect.x) / rect.width;
  const y = (pos.y - rect.y) / rect.height;

  return { x, y };
};

export const getIntersection = (a: IRect, b: IRect): IRect => {
  const x = Math.max(a.x, b.x);
  const y = Math.max(a.y, b.y);
  const width = Math.min(a.x + a.width, b.x + b.width) - x;
  const height = Math.min(a.y + a.height, b.y + b.height) - y;

  if (width <= 0 || height <= 0) return { ...defaultRect };

  return { x, y, width, height };
};

export const mergeRectangles = (a: IRect, b: IRect): IRect => {
  const x = Math.min(a.x, b.x);
  const y = Math.min(a.y, b.y);
  const width = Math.max(a.x + a.width, b.x + b.width) - x;
  const height = Math.max(a.y + a.height, b.y + b.height) - y;

  return { x, y, width, height };
};

export const degreesToRadians = (degrees: number): number =>
  degrees * (Math.PI / 180);

export const isHorizontalOverlap = <T extends Pick<IRect, 'x' | 'width'>>(
  a: T,
  b: T,
): boolean => a.x < b.x + b.width && a.x + a.width > b.x;

export const isVerticalOverlap = <T extends Pick<IRect, 'y' | 'height'>>(
  a: T,
  b: T,
): boolean => a.y < b.y + b.height && a.y + a.height > b.y;

export const spaceEvenlyHorizontally = <T extends Pick<IRect, 'x' | 'width'>>(
  items: T[],
  start?: number,
  end?: number,
): T[] => {
  if (items.length < 2) {
    return items;
  }

  const sorted = [...items].sort((a, b) => a.x - b.x);
  const first = sorted[0];
  const last = sorted[sorted.length - 1];
  const startPos = start ?? first.x;
  const endPos = end ?? last.x + last.width - startPos;
  const totalWidth = sorted.reduce((acc, item) => acc + item.width, 0);
  const spacing = (endPos - startPos - totalWidth) / (sorted.length - 1);

  let prevPos = startPos - spacing;
  return sorted.map((item) => {
    const x = prevPos + spacing;
    const distributed = {
      ...item,
      x,
    };
    prevPos = x + item.width;
    return distributed;
  });
};
