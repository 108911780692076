import React, { useCallback, useState } from 'react';
import ImportElementsButton from './ImportElementsButton';
import ImportElementsDialog from './ImportElementsDialog';
import amplitudeLog from '../../amplitude';
import { getElementGroupsFromFiles } from '../../helpers/import_helpers';
import axios from 'axios';
import { useElementMapping } from '../../providers/ElementMappingProvider';
import { getProject } from '../../store/project/project.hook';
import { getSelectedVersion } from '../../store/ui/ui.hook';
import {
  ElementKind,
  IElement,
} from '../../../../shared/models/project.interface';
import { addElements } from '../../../../shared/helpers/project_helpers';
import { useVersionVariables } from '../../hooks/useElementExpressionVariables';
import { useIsReadonly, useUserId } from '../../hooks/user.hook';
import { useUpdateProject } from '../../store/project';
import { usePromiseSnackbar } from '../../hooks/snackbar.hook';
const ImportElements: React.FC = () => {
  const readonly = useIsReadonly();
  const versionVariables = useVersionVariables();
  const userName = useUserId();
  const { fetchElementMappings } = useElementMapping();
  const updateProject = useUpdateProject();
  const promiseSnackbar = usePromiseSnackbar();

  const [open, setOpen] = useState(false);

  const importElements = useCallback(
    async (files: File[]) => {
      setOpen(false);

      const project = getProject();
      const version = getSelectedVersion();

      if (!version) {
        throw new Error('No selected version.');
      }

      amplitudeLog('Elements Import', {
        Count: files.length,
      });

      const elementMap = await fetchElementMappings();
      const elements: IElement[] = (
        await getElementGroupsFromFiles(files, versionVariables)
      ).map((group) => ({
        ...group,
        elements: group.elements.map((el) => {
          if (el.kind !== ElementKind.Element) {
            throw new Error('Unexpected element kind');
          }
          return {
            // take product mapping from history
            ...el,
            elements: elementMap[el.name] || [],
          };
        }),
      }));

      await updateProject(
        addElements(project, version, { regenerateIds: true }, ...elements),
      );

      // Send raw files to API for storage
      const formData = new FormData();
      formData.append('owner', userName);
      files.forEach((file) => formData.append('files', file));

      if (project.id) {
        formData.append('project_id', `${project.id}`);
      }
      await axios.post('/files', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
    },
    [userName, fetchElementMappings, versionVariables, updateProject],
  );

  const handleImport = useCallback(
    (files: File[]) => {
      promiseSnackbar(importElements(files), {
        successMessage: `Elements imported successfully`,
      }).catch((err) => console.error(err.name, err));
    },
    [importElements, promiseSnackbar],
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  if (readonly) {
    return null;
  }
  return (
    <div>
      <ImportElementsButton onClick={handleOpen} />

      {/* Only render if open by performance reasons */}
      {open && (
        <ImportElementsDialog
          title="Import elements"
          content="Drag a CSV, Excel or Bidcon file here to import its elements"
          open={open && !readonly}
          onImport={handleImport}
          onClose={handleClose}
        />
      )}
    </div>
  );
};

export default ImportElements;
