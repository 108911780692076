import React, { ReactNode, FC, useMemo, useCallback } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Theme,
  IconButton,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ActivityMenu from '../../../components/activities/ActivityMenu';
import { NodonTheme, useFlexStyles, useMarginStyles } from '../../../style';
import { Add } from '@mui/icons-material';
import {
  setElementExpanded,
  useIsElementExpanded,
} from '../../../hooks/expand-elements.hook';

interface NodonAccordionProps {
  children?: ReactNode;
  summary: string;
  disablePadding?: boolean;
  hasActivityMenu?: boolean;

  /**
   * Provide a callback to show plus icon on the right side of the summary
   * @returns
   */
  onAddClick?: () => void;
}

const NodonAccordion: FC<NodonAccordionProps> = ({
  children,
  summary,
  disablePadding,
  hasActivityMenu,
  onAddClick,
}) => {
  const { classes, cx } = useStyles();
  const { classes: flex } = useFlexStyles();
  const { classes: margin } = useMarginStyles();

  const expanded = useIsElementExpanded(summary);

  const setExpanded = useCallback(
    (expanded: boolean) => setElementExpanded(summary, expanded),
    [summary],
  );

  const toggleExpandAccordion = useCallback(
    () => setExpanded(!expanded),
    [expanded, setExpanded],
  );

  const addIcon = useMemo(() => {
    if (onAddClick) {
      return (
        <IconButton
          onClick={(e) => {
            setExpanded(true);
            e.stopPropagation();
            onAddClick();
          }}
          className={cx(flex.displayFlex, margin.leftAuto)}
        >
          <Add />
        </IconButton>
      );
    }
    return null;
  }, [cx, flex.displayFlex, margin.leftAuto, onAddClick, setExpanded]);

  return (
    <Accordion
      sx={{
        zIndex: !expanded ? NodonTheme.zIndex.readonlyBlocker + 1 : 'inherit',
      }}
      className={cx(classes.root, flex.displayFlex, flex.justifyContentStart)}
      expanded={expanded}
      elevation={0}
      onChange={toggleExpandAccordion}
      classes={{
        expanded: classes.expanded,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{
          content: classes.header,
          expanded: classes.expanded,
        }}
      >
        <Typography className={cx(classes.label)}>{summary}</Typography>
        {hasActivityMenu && <ActivityMenu setExpanded={setExpanded} />}
        {addIcon}
      </AccordionSummary>

      <AccordionDetails
        className={cx(
          classes.details,
          disablePadding && classes.disablePadding,
        )}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    top: 1,
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 5,
    },
    '&:before': {
      display: 'none',
    },
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderRadius: 0,
    '&:last-of-type': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 0,
    marginBottom: 0,
  },

  label: {
    fontSize: theme.typography.pxToRem(15),
    lineHeight: '48px',
    fontWeight: theme.typography.fontWeightRegular,
    display: 'flex',
    alignItems: 'center',
  },

  expanded: {
    '&.Mui-expanded': {
      backgroundColor: 'whitesmoke',
      minHeight: 'unset',
      marginTop: 0,
      marginBottom: 0,
    },
  },

  details: {
    display: 'flex',
    flexDirection: 'column',
  },

  disablePadding: {
    padding: 0,
  },
}));

export default NodonAccordion;
