import { pick } from 'lodash';
import {
  CO2eConversionFactors,
  ConversionFactors,
  emptyConversionFactors,
} from '../models/unit.interface';
import { getKeys, omitUndefined } from './object_helpers';
import { ArrayOrRecord } from '../models/type_helpers.interface';
import { isDefined } from './array_helpers';

const applyCO2eTotal = (
  factors: CO2eConversionFactors,
): CO2eConversionFactors => {
  return { ...factors, co2e_total: getCO2eTotalFromConversionFactors(factors) };
};

export const createConversionFactors = (
  defaults: Partial<ConversionFactors> | number = 0,
): CO2eConversionFactors => {
  return applyCO2eTotal({
    ...emptyConversionFactors,
    ...(typeof defaults === 'number'
      ? { 'co2e_A1-A3': defaults }
      : omitUndefined(defaults)),
  });
};

export const addMissingConversionFactors = (
  completeFactors: ConversionFactors,
  incompleteFactors: ConversionFactors,
): ConversionFactors => {
  return createConversionFactors({
    ...omitUndefined(completeFactors),
    ...omitUndefined(incompleteFactors),
  });
};

/**
 * Sum all CO2e factors and return the total.
 * @param factors
 * @returns
 */
export const getCO2eTotalFromConversionFactors = (
  factors: ConversionFactors,
): number => {
  return Object.values(
    pick(factors, 'co2e_A1-A3', 'co2e_A4', 'co2e_A5'),
  ).reduce((sum, v) => sum + (v || 0), 0);
};

/**
 * Get summed conversion factors from a record of ConversionFactors
 * @param record
 * @returns
 */
export const sumConversionFactorRecord = (
  record: ArrayOrRecord<ConversionFactors>,
): ReturnType<typeof sumConversionFactors> =>
  sumConversionFactors(...Object.values(record));

/**
 * Sum all CO2e factors and return the total.
 * @param factors
 * @returns
 */
export const sumConversionFactors = (
  ...factors: (ConversionFactors | undefined)[]
): CO2eConversionFactors => {
  const conversionFactors: CO2eConversionFactors = {
    ...emptyConversionFactors,
  };
  factors.filter(isDefined).forEach((f) => {
    getKeys(f).forEach((key) => {
      conversionFactors[key] = (conversionFactors[key] || 0) + (f[key] || 0);
    });
  });
  return conversionFactors;
};
