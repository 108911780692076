import React, { FC, useMemo } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { Control, FieldErrors, Controller } from 'react-hook-form';
import {
  ConversionFactors,
  QuantityUnit,
} from '../../../../shared/models/unit.interface';
import { FormProduct } from './EditProductDialog';
import InputContainer from '../../components/InputContainer';
import { formatThousands } from '../../../../shared/helpers/math_helpers';
import { toTransportWasteConversionFactors } from '../../../../shared/helpers/calculation_helpers';

const INPUT_QUANTITY_NAMES: Partial<Record<QuantityUnit, string>> = {
  'co2e_A1-A3': 'Global Warming Potential (A1-A3)',
  co2e_A4: 'Transport',
  co2e_A5: 'Spill',
  'sek_A1-A3': 'Cost (A1-A3)',
  kg: 'Weight',
  'm³': 'Volume',
  'm²': 'Area',
  m: 'Length',
  pcs: 'Pieces',
};

const INPUT_END_ADORNMENTS: Partial<Record<QuantityUnit, string>> = {
  'co2e_A1-A3': 'kgCO2e',
  co2e_A4: 'km',
  co2e_A5: '%',
  'sek_A1-A3': 'SEK',
};

interface ConversionFactorInputProps {
  control: Control<FormProduct>;
  errors: Partial<FieldErrors<FormProduct>>;
  unit: QuantityUnit;
  defaultUnit: QuantityUnit;
  requiredUnits: QuantityUnit[];
  conversionFactors?: ConversionFactors;
}

const EditProductDialogConversionFactorInput: FC<
  ConversionFactorInputProps
> = ({
  control,
  errors,
  unit,
  defaultUnit,
  requiredUnits,
  conversionFactors,
}) => {
  const title = `${INPUT_QUANTITY_NAMES[unit] ?? unit} ${
    unit !== 'co2e_A4' && unit !== 'co2e_A5' ? 'per ' + defaultUnit : ''
  }`;

  const errorMessage = errors.conversion_factors?.[unit]?.message;

  const placeholder = useMemo(() => {
    const factor = conversionFactors
      ? toTransportWasteConversionFactors(conversionFactors)[unit]
      : undefined;

    if (factor) {
      return formatThousands(factor);
    }
    return requiredUnits.includes(unit) ? '' : 'optional';
  }, [conversionFactors, requiredUnits, unit]);

  const inputProps = useMemo(
    () => ({
      endAdornment: (
        <InputAdornment position="end">
          {INPUT_END_ADORNMENTS[unit] ?? unit}
        </InputAdornment>
      ),
    }),
    [unit],
  );

  return (
    <InputContainer title={title} mt={3}>
      <Controller
        control={control}
        name={`conversion_factors.${unit}`}
        render={({ field }) => {
          return (
            <TextField
              {...field}
              size="small"
              fullWidth
              value={field.value ?? ''}
              placeholder={placeholder}
              error={!!errorMessage}
              helperText={errorMessage}
              InputProps={inputProps}
            />
          );
        }}
      />
    </InputContainer>
  );
};

export default EditProductDialogConversionFactorInput;
