import { isObject, mapValues } from 'lodash';
import { countOccurrences } from './string_helpers';

const LARGE_NUMBER = 999999999;

export const roundToDecimals = (v: number, decimals = 2): number =>
  Math.round(v * Math.pow(10, decimals)) / Math.pow(10, decimals);

export const formatThousands = (value: number): string => {
  if (!isFinite(value)) {
    return '' + value;
  }

  // Don't show too small decimal value. They often show up like 1.2345e-7
  if (value !== 0 && Math.abs(value) < 0.0001) {
    return '~0';
  }
  if (value > LARGE_NUMBER || value < -LARGE_NUMBER) {
    return value.toExponential(3);
  }

  value =
    Math.abs(value) >= 100 ? Math.round(value) : Number(value.toPrecision(2));

  let str = value.toString();
  let str_arr: string[];
  const is_negative = value < 0;

  if (is_negative) {
    //Remove minus sign if negative.
    str = str.substr(1);
  }
  const separated = str.split('.'); //Separate decimals; only group the value before the decimal point.
  str_arr = separated[0].split('');
  str = '';
  while (str_arr.length > 3) {
    str =
      ' ' +
      str_arr[str_arr.length - 3] +
      str_arr[str_arr.length - 2] +
      str_arr[str_arr.length - 1] +
      str;
    str_arr = str_arr.slice(0, str_arr.length - 3);
  }
  str = str_arr.join('') + str;
  str += separated.length > 1 ? '.' + separated[1] : '';
  if (is_negative) {
    //Add minus sign if negative.
    str = '-' + str;
  }

  return str;
};

/**
 * Test if a string can be converted into a number
 * @param str
 * @returns
 */
export const isNumeric = (str: string | number): boolean =>
  typeof str === 'string' || typeof str === 'number' ? isFinite(+str) : false;

/**
 * Limit a value between a min and max.
 * If min or max is not provided that limit is ignored.
 * @param num
 * @param min
 * @param max
 * @returns
 */
export const clamp = (
  num: number,
  min: number | undefined,
  max: number | undefined,
): number => {
  if (min !== undefined && max !== undefined && min > max) {
    throw new Error('Min cannot be greater than max');
  }
  if (min !== undefined) {
    num = Math.max(num, min);
  }
  if (max !== undefined) {
    num = Math.min(num, max);
  }

  return num;
};

/**
 * Get highest value of  an array of objects containing a number property.
 * Note that is will only check numbers and ignore other properties
 */
export function highest(values: number[]): number;
export function highest<
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends object,
  K extends keyof T,
>(values: T[], key: K): number;
export function highest<
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends object | number,
  K extends keyof T,
>(values: T[], key?: K): number {
  return values.reduce((acc, curr) => {
    const value = typeof curr === 'number' ? curr : key && curr[key];
    return typeof value === 'number' ? Math.max(acc, value) : acc;
  }, 0);
}

/**
 * Sum an array of objects containing a number property.
 * Note that is will only sum numbers and ignore other properties
 */
export function sum(values: (number | undefined)[]): number;
export function sum(object: object): number;
export function sum<
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends object,
>(values: T[], keyOrMapFn?: (value: T) => number): number;
export function sum<
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends object,
  K extends keyof T,
>(values: T[], key: K): number;
export function sum<
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends object | number,
  K extends keyof T,
>(values: T[] | object, keyOrMapFn?: K | ((value: T) => number)): number {
  // If key is a function then we are mapping the values
  if (typeof keyOrMapFn === 'function') {
    return sum(Object.values(values).map(keyOrMapFn));
  }
  if (!Array.isArray(values) && isObject(values)) {
    return sum(Object.values(values).filter(Number.isFinite)); // Filter out non-finite values like strings, NaN, etc
  }
  return (values as T[]).reduce((acc, curr) => {
    const value =
      typeof curr === 'number' ? curr : keyOrMapFn && curr[keyOrMapFn];
    return acc + (typeof value === 'number' && isFinite(value) ? value : 0);
  }, 0);
}

/**
 * Multiply an array of numbers or objects containing a number property.
 * Note that is will only multiply numbers and ignore other properties
 */
export function multiply(values: number[]): number;
export function multiply<
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends object,
  K extends keyof T,
>(values: T[], key: K): number;
export function multiply<
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends object | number,
  K extends keyof T,
>(values: T[], key?: K): number {
  return values.reduce((acc, curr) => {
    const value = typeof curr === 'number' ? curr : key && curr[key];
    return acc * (typeof value === 'number' ? value : 1);
  }, 1);
}

/**
 * Make sure the type is used as number.
 * @param value The value to convert to number
 * @param defaultValue
 * @returns
 */
export const getValueAsNumber = (
  value: boolean | string | number | undefined,
  defaultValue = 0,
): number => {
  return value !== undefined && value !== null && isFinite(+value)
    ? +value
    : defaultValue;
};

/**
 * Multiply provided properties in an object by a factor.
 * If a property is not a number, it is kept unmodified.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function multiplyProperties<T extends object, K extends keyof T>(
  obj: T,
  factor: number,
  ...include: K[]
): T {
  if (!obj && typeof obj !== 'object') {
    return obj;
  }

  const result = mapValues(obj, (value, key) => {
    return typeof value === 'number' && include.includes(key as K)
      ? value * factor
      : value;
  });

  return result as T;
}

/**
 * Get all properties in an object as numbers
 * @param object
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const toNumberObject = <T extends object, K extends keyof T>(
  object: T,
  defaultValue?: number,
): Record<K, number> => {
  return mapValues(object, (value) => toNumber(value, defaultValue));
};

/**
 * Convert value to number or return default value if it can't be done.
 * Will also make sure a number value can't be NaN, Infinity or -Infinity.
 * @param value
 * @param defaultValue
 * @returns
 */
export const toNumber = (value: unknown, defaultValue = 0): number => {
  // Remove formatting to support strings like '1 000'
  if (typeof value === 'string') {
    value = removeNumberFormatting(value);
  }

  switch (typeof value) {
    case 'string':
    case 'boolean':
    case 'number': {
      const converted = +value;
      return isFinite(converted) ? converted : toNumber(defaultValue);
    }
  }
  return toNumber(defaultValue);
};

/**
 * Make number parsable
 * Will turn 1 000 into 1000, 1,000.00 into 1000.00
 * and 1,000,000.12 into 1000000.12
 * @param str
 * @returns
 */
export const removeNumberFormatting = <T = string>(input: T): T => {
  // Cases we shouldn't try to fix (can only remove formatting from strings looking like valid numbers)
  if (typeof input !== 'string' || !input || !/^-?[\d.,\s]+$/g.test(input)) {
    return input;
  }

  let str: string = input;

  // Remove all commas if there is a dot or there are more than one comma
  if (
    (str.includes('.') && str.lastIndexOf(',') < str.lastIndexOf('.')) ||
    countOccurrences(str, ',') > 1
  ) {
    str = str.replaceAll(',', '');
  }
  str = str
    .replace(/\s/g, '') // Remove spaces
    .replace(/(?<=\d),(?=\d)/g, '.'); // Replace comma between numbers with dot

  // Remove all dots except the first one
  while (countOccurrences(str, '.') > 1) {
    str = str.replace('.', '');
  }
  return str as T;
};

export const getMedianValue = (array: number[], position = 0.5): number => {
  const sorted = [...array].sort((a, b) => b - a).reverse();
  const middleIndex = (sorted.length - 1) * position;

  // Exactly on a number
  if (middleIndex % 1 === 0) {
    return sorted[Math.round(middleIndex)];
  } else {
    return (
      (sorted[Math.floor(middleIndex)] + sorted[Math.ceil(middleIndex)]) / 2
    );
  }
};

export const factorToPercentage = (factor: number): number => factor * 100;

export const percentageToFactor = (percentage: number): number =>
  percentage / 100;
