import React, { ReactNode, useMemo } from 'react';
import { Box, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { clamp } from 'lodash';

export type BarSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type BarColor = 'default' | 'green' | 'red';

interface BarGraphProps {
  children?: ReactNode;
  /**
   * A number between 0 and 1. Can overfill if the value is above 1.
   * Then it will show an indicator at 1 instead
   */
  value: number;

  /**
   * Toggle showing a background behind the bar, extending the width of the element.
   */
  background?: boolean;
  size?: BarSize;

  /**
   * Color of BarChart.
   * 'default' is purple.
   * 'auto' tries to find the best color based on the value.
   */
  color?: BarColor;
}

const useStyles = makeStyles()(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    flexGrow: 1,
    width: '100%',
  },

  background: {
    background: 'rgba(77, 98, 109, 0.1)',
  },

  indicator: {
    background: '#2C1611',
    opacity: 0.3,
    position: 'absolute',
    top: '50%',
    transform: 'translateX(0) translateY(-50%)',
    width: '1px',
    height: '300%',
  },

  label: {
    position: 'absolute',
    left: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
  },

  empty: {},

  filled: {
    position: 'relative',
    backgroundColor: '#2E2C7F',
  },

  colorGreen: {
    backgroundColor: '#70AD9D',
  },

  colorRed: {
    backgroundColor: '#DE6C53',
  },
}));

const sizeMultiplier = { xs: 1, sm: 2, md: 4, lg: 8, xl: 16 };

const BarGraph: React.FC<BarGraphProps> = ({
  children,
  value,
  size,
  background,
  color,
}) => {
  const { spacing } = useTheme();
  const { classes, cx } = useStyles();
  const grow = !isNaN(value) ? clamp(value, 0, 1) : 0;
  const colorClass: string = useMemo(() => {
    switch (color) {
      case 'green':
        return classes.colorGreen;
      case 'red':
        return classes.colorRed;
      default:
        return '';
    }
  }, [color, classes]);
  return (
    <Box
      className={cx(classes.container, background && classes.background)}
      style={{ height: spacing(sizeMultiplier[size || 'sm']) }}
    >
      <Box
        className={cx(classes.filled, colorClass)}
        style={{
          flexGrow: grow,
        }}
      >
        <Box className={classes.label}>{children}</Box>
      </Box>
      <Box className={classes.empty} style={{ flexGrow: 1 - grow }} />
      {value > 1 && (
        <Box
          className={classes.indicator}
          style={{
            left: (grow / value) * 100 + '%',
          }}
        ></Box>
      )}
    </Box>
  );
};

export default BarGraph;
