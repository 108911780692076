import {
  ElementKind,
  IBuildingVersion,
  OneOfElements,
  OneOfParentElements,
  Project,
  IBuilding,
  ProjectMetadata,
  IProjectInfo,
  OneOfChildElements,
  IElement,
  OneOfListElements,
} from '../models/project.interface';
import {
  applyChanges,
  getId,
  getKeys,
  isPartialEqual,
  omit,
  omitUndefined,
  pick,
} from './object_helpers';
import {
  getAllBuildingVersions,
  getBuilding,
  getChildElements,
  getElementById,
  getParentElement,
  isBuildingVersionElement,
  isProductElement,
  flattenElements,
  isElement,
  getPathToElement,
} from './recursive_element_helpers';
import { isDefined } from './array_helpers';
import { SemiPartial } from '../models/type_helpers.interface';
import {
  createElementFromPartial,
  createElementOfType,
  IFactoryVersion,
  isFactoryVersion,
  isOneOfChildFactoryElements,
  OneOfFactoryElements,
} from './element_factory_helpers';
import { produce } from 'immer';
import { Product, ProductRecord } from '../models/product.interface';
import {
  getElementCategory,
  updateElementCategoryProperties,
} from './element_category_helpers';
import { preventNewElementValidationErrors } from '../validation/project.validation';
import { DateTime } from 'luxon';
import { autoUpdateElementQuantity } from './auto-quantity.helpers';
import { getProductsInElement, hasEqualProductsIds } from './product_helpers';
import {
  ExpressionVariablesRecord,
  applyProductVariables,
} from './expression_variables_helpers';
import { isEqual } from 'lodash';
import { findFreeName } from './string_helpers';
import { sortInsert } from './sort_helpers';
import { cleanupElementVersions } from './element-version.helpers';
import { IInsertSortPlacement } from '../models/sort.interface';
import { getElementCategoryId } from './element_helpers';

export const DUMMY_RECIPE_ID = 'DUMMY_RECIPE_ID';

export const getUniqueProjectName = (
  names: string[],
  name?: string,
): string => {
  // check if empty string
  name = name || 'Project';

  return names.includes(name) ? findFreeName(names, name, ' ', 2) : name;
};

/**
 * Get a version by id and throw error if it not exist.
 * If you wanna check if a version exist or not use Array.some instead.
 * @param project
 * @param id
 * @param throwIfNotFound Defaults to false
 */
export const getVersionById = <
  T extends true | false | undefined = true,
  R = T extends true ? IBuildingVersion : IBuildingVersion | undefined,
>(
  project: Project,
  id: string,
  throwIfNotFound: T = true as T,
): R => {
  const version = getAllBuildingVersions(project).find((v) => v.id === id);

  if (!version && throwIfNotFound) {
    throw new Error('could not find version');
  }
  return version as R;
};

export const getProjectMeta = (project: Project): ProjectMetadata =>
  getBuilding(project).meta;

/**
 * Update properties in building. Currently there is only one building
 * Note that the properties of the building you want to update is enough to pass.
 */
export const updateProjectProperties = (
  project: Project,
  projectChanges: Partial<Project>,
): Project => {
  return produce(project, (draft) => {
    Object.assign(draft, projectChanges);
  });
};

/**
 * Update properties in building. Currently there is only one building
 * Note that the properties of the building you want to update is enough to pass.
 */
export const updateBuildingProperties = (
  project: Project,
  buildingChanges: Partial<IBuilding>,
): Project => {
  return produce(project, (draft) => {
    const draftBuilding = getBuilding(draft);
    Object.assign(draftBuilding, buildingChanges);
  });
};

export const updateProjectMetadata = (
  project: Project,
  metaUpdates: Partial<ProjectMetadata>,
): Project => {
  const meta = getProjectMeta(project);
  return updateBuildingProperties(project, {
    meta: applyChanges(meta, metaUpdates),
  });
};

/**
 * Update properties in a specific version.
 * Note that the properties of the version you want to update is enough to pass.
 * TODO: Is this really needed? Can't updateElements be used instead?
 */
export const updateBuildingVersionProperties = (
  project: Project,
  ...versionChanges: Array<SemiPartial<IBuildingVersion, 'id'>>
): Project => {
  return produce(project, (draft) => {
    versionChanges.forEach((changes) => {
      const version = getVersionById(project, changes.id);
      const draftVersion = getVersionById(draft, changes.id);
      if (!version || !draftVersion) {
        throw new Error('Could not find version to update');
      }
      // Only update if needed to not trigger to many rerenders of views
      if (hasChanges(version, changes)) {
        Object.assign(draftVersion, changes);
      }
    });
  });
};

/**
 * Update a product in a version.
 * Add product if it doesn't exist.
 * @param project
 * @param version
 * @param updatedProduct
 * @returns
 */
export const updateProductInVersion = (
  project: Project,
  version: IBuildingVersion,
  updatedProduct: Product,
): Project => {
  const id = updatedProduct.id;
  const { products: versionProducts } = version;

  // Don't update if the product is the same
  if (isEqual(updatedProduct, versionProducts[id])) {
    return project;
  }

  const updatedProjectProducts: Record<string, Product> = {
    ...versionProducts,
    [id]: omitUndefined(updatedProduct) as Product,
  };

  const updatedVersion: IBuildingVersion = {
    ...version,
    products: updatedProjectProducts,
  };

  return updateBuildingVersionProperties(project, updatedVersion);
};

export const removeVersionFromProject = (
  project: Project,
  versionId: string,
): Project => {
  return produce(project, (draft) => {
    const building = getBuilding(draft);
    building.versions = building.versions.filter((v) => v.id !== versionId);
    return draft;
  });
};

/**
 * Add new versions to project.
 * Can create a new or create a copy of an existing version
 * @param project
 * @param baseVersion Opional partials of versions to base the new versons on
 * @returns
 */
export const addVersionToProject = (
  project: Project,
  regenerateIds: boolean,
  ...baseVersions: Partial<IFactoryVersion>[]
): Project => {
  // Make sure to always have at least one base version
  if (baseVersions.length === 0) {
    baseVersions = [{}];
  }

  return produce(project, (draft) => {
    const building = getBuilding(draft);

    baseVersions.forEach((baseVersion) => {
      const name = findFreeName(
        building.versions.map((v) => v.name),
        baseVersion.name ?? 'Version 1',
        ' ', // delimier
        2, // startNum
      );
      const version = createElementOfType(
        ElementKind.Version,
        {
          ...baseVersion,
          name,
        },
        regenerateIds,
      );
      building.versions = [...building.versions, version];
    });
  });
};

type AddElementsOptions = {
  regenerateIds?: boolean;
  placement?: IInsertSortPlacement;
  sibling?: OneOfChildElements;
};

/**
 * Add a new element to a element.
 * Can create a new or create a copy of an existing version
 * @param project
 * @param baseVersion
 * @returns
 */
export const addElements = (
  project: Project,
  parent: OneOfParentElements,
  { regenerateIds = false, placement = 'first', sibling }: AddElementsOptions,
  ...elementBases: OneOfFactoryElements[]
): Project => {
  const versionBases = elementBases.filter(isFactoryVersion);
  const childElementBases = elementBases.filter(isOneOfChildFactoryElements);

  const version = isBuildingVersionElement(parent)
    ? parent
    : getPathToElement(project, parent)[0];

  if (!isBuildingVersionElement(version)) {
    throw new Error('No building version found for parent element');
  }

  // Add any versions first
  if (versionBases.length > 0) {
    project = addVersionToProject(project, regenerateIds, ...versionBases);
  }

  if (childElementBases.length === 0) {
    return project;
  }

  return produce(project, (draft) => {
    // Create an array of new elements to add from the partials
    const newElements = elementBases.map((e) => {
      if (e.kind === ElementKind.Version) {
        throw new Error('Cant create version elements, use addVersion instead');
      }
      const exists = getChildElements(parent).some(
        (child) => child.id === e.id,
      );

      // TODO: Move to enricher
      const categoryID = getElementCategoryId(e);

      const categoryName = categoryID
        ? `${getElementCategory(categoryID)?.name ?? 'None'} 1`
        : undefined;

      let name, fallbackName;

      if (isElement(e) && e.name) {
        name = exists ? e.name : getNewNameOfElement(parent, e);
      } else {
        name = undefined;
      }
      if (!exists && isElement(e) && e.fallbackName) {
        fallbackName = e.fallbackName;
      } else {
        fallbackName = getNewFallbackName(version, categoryName);
      }

      return createElementFromPartial(
        { ...e, name, fallbackName },
        regenerateIds,
      );
    });
    const draftVersion = getVersionById(draft, version.id);
    const draftParent = getElementById(draftVersion, parent.id);
    const draftSibling = getElementById(
      draftVersion,
      sibling?.id,
    ) as OneOfChildElements;

    if (!draftParent) {
      throw new Error('Could not find parent');
    }
    if (isProductElement(draftParent)) {
      throw new Error(`Can't add elements to products`);
    }

    draftParent.elements = sortInsert(
      placement,
      getChildElements(draftParent),
      draftSibling,
      ...newElements,
    );

    preventNewElementValidationErrors(draftParent, parent, false);
  });
};

export const replaceBuildingVersion = (
  project: Project,
  version: IBuildingVersion,
): Project => {
  const prevVersion = getVersionById(project, version.id, false);

  if (!prevVersion || prevVersion === version) {
    return project;
  }

  return produce(project, (draft) => {
    const building = getBuilding(draft);
    building.versions = building.versions.map((v) =>
      v.id === version.id ? version : v,
    );
  });
};

/**
 * Update elements with one or more changes.
 * Note. Passing undefined properties will update the property with an undefined value.
 * @param project
 * @param elementChanges
 * @returns
 */
export const updateElements = <T extends OneOfElements>(
  project: Project,
  ...elementChanges: SemiPartial<T, 'id'>[]
): Project => {
  return produce(project, (draft) => {
    const building = getBuilding(project);
    const draftBuilding = getBuilding(draft);

    elementChanges.forEach((changes) => {
      const element = getElementById(building, changes.id) as T;
      const draftElement = getElementById(draftBuilding, changes.id) as T;

      if (!element || !draftElement) {
        throw new Error('Could not find element to update');
      }
      // Only update if needed to not trigger to many rerenders of views
      if (hasChanges(element, changes as Partial<T>)) {
        Object.assign(draftElement, changes);
      }
    });
  });
};

/**
 * Remove elements from the project
 * @param project
 * @param baseVersion
 * @returns
 */
export const removeElements = (
  project: Project,
  version: IBuildingVersion,
  ...elementIds: string[]
): Project => {
  return produce(project, (draft) => {
    const building = getBuilding(draft);
    const draftVersion = getVersionById(draft, version.id);

    elementIds.forEach((id) => {
      const element = getElementById(building, id);

      if (isBuildingVersionElement(element)) {
        // Don't remove the last version
        if (building.versions.length <= 1) {
          return;
        }

        building.versions = building.versions.filter((v) => v.id !== id);
        return;
      }

      const draftParent = getParentElement(draftVersion, id);
      if (!draftParent?.elements) {
        throw new Error('Could not find parent element to remove');
      }
      draftParent.elements = draftParent.elements.filter((e) => e.id !== id);

      // Remove obsolete element versions
      draftParent.elements = cleanupElementVersions(draftParent).elements;
    });
  });
};

/**
 * Get a new name for the element and increases the number if it already exists
 * @param parent
 * @param elementBase
 * @returns
 */
export const getNewNameOfElement = (
  parent: OneOfListElements,
  elementBase: { kind: ElementKind; name?: string },
): string | undefined => {
  // Products doesn't have names
  if (elementBase.kind === ElementKind.Product) {
    return undefined;
  }

  const defaultName =
    elementBase.kind === ElementKind.Version ? 'Version 1' : 'Element 1';

  return findFreeName(
    getChildElements(parent)
      .map((el) => ('name' in el ? el.name : undefined))
      .filter(isDefined),
    elementBase.name ?? defaultName,
    ' ', // delimier
    2, // startNum
  );
};

/**
 * Get all names amd fallbackNmaes from the version
 * @param version
 * @returns
 */
const getNamesAndFallbackNames = (version: IBuildingVersion) => {
  const flattenedElements = flattenElements(version);

  const names = flattenedElements
    .map((el) => ('name' in el ? el.name : undefined))
    .filter(isDefined);

  const fallbackNames = flattenedElements
    .map((el) => ('fallbackName' in el ? el.fallbackName : undefined))
    .filter(isDefined);

  const set = new Set([...names, ...fallbackNames]);

  return [...set];
};

/**
 * Get a new name for the element and increases the number if it already exists
 * @param version
 * @returns
 */
export const getNewFallbackName = (
  version: IBuildingVersion,
  baseName = 'Element 1',
): string | undefined => {
  return findFreeName(
    getNamesAndFallbackNames(version),
    baseName,
    ' ', // delimier
    1, // startNum
  );
};

/**
 * Test if the element has any changes
 * @param element
 * @param changes
 * @param keys If keys are provided, only those keys will be checked for changes
 * @returns
 */
const hasChanges = <T extends OneOfElements>(
  element: T,
  changes: Partial<T>,
  ...keys: (keyof T)[]
): boolean =>
  element !== changes && // Don't check properties if the element is the same as the changes
  !isPartialEqual(element, keys.length ? pick(changes, ...keys) : changes);

/**
 * Get all changed elements
 * (ignoring changes to element.children array since that always change when a child changes)
 * @param nextProject
 * @param prevProject
 * @returns
 */
export const getModifiedElements = <T extends Project | IBuildingVersion>(
  next: T,
  prev: T | undefined,
  onlyRootElements = true,
): OneOfElements[] => {
  if (next === prev) {
    return [];
  }

  const nextVersions = getAllBuildingVersions(next);
  const prevVersions = getAllBuildingVersions(prev);

  // Going through elements in all versions are expensive, so first check which versions have changed
  const changedVersions = nextVersions.filter((nextVersion) => {
    const prevVersion = prevVersions.find((v) => v.id === nextVersion.id);
    return !prevVersion || prevVersion !== nextVersion;
  });

  const nextElements = flattenElements(
    ...changedVersions.flatMap((v) => v.elements),
  );
  const prevElements = flattenElements(
    ...prevVersions
      .filter((v) => changedVersions.some((c) => c.id === v.id))
      .flatMap((v) => v.elements),
  );

  const changedElements = nextElements.filter((next) => {
    const prev = prevElements.find((p) => p.id === next.id);
    if (!prev) {
      return true;
    }
    if (prev === next) {
      return false;
    }

    // If a child has been added or removed, the element has changed
    if (!hasEqualProductsIds(next, prev)) {
      return true;
    }

    // Ignore children when checking for changes since they always change when a child changes
    const keys = getKeys(next).filter(
      (k) => (k as keyof IElement) !== 'elements',
    );

    return hasChanges(next, prev, ...keys);
  });

  // If onlyRootElements is true, only the top element of the changes, not the changed children
  if (onlyRootElements) {
    const children = changedElements.flatMap((e) => getChildElements(e));
    return changedElements.filter((e) => !children.includes(e));
  }

  return changedElements;
};

export const isProjectsEqual = (a: Project, b: Project): boolean =>
  isEqual(omit(a, 'updated_at'), omit(b, 'updated_at'));

export const sortProjectsByLastUpdated = (
  projects: IProjectInfo[],
): IProjectInfo[] => {
  return [...projects].sort(
    (a, b) =>
      DateTime.fromISO(b.updated_at).valueOf() -
      DateTime.fromISO(a.updated_at).valueOf(),
  );
};

/**
 * Convert Project to ProjectInfo
 * @param project
 * @returns
 */
export const projectToProjectInfo = (project: Project): IProjectInfo => ({
  ...omit(project, 'template', 'buildings'),
  id: String(project.id), // TODO: NEEDED?
  kind: ElementKind.ProjectInfo,
  parent_id: project.parent_id === undefined ? null : project.parent_id,
  location: project.location ?? 0,
  versionIds: getAllBuildingVersions(project).map(getId),
  gfa:
    getProjectMeta(project).gfa_building === undefined
      ? (getProjectMeta(project).building_footprint?.area ?? 0) *
        (getProjectMeta(project).storeys?.length ?? 0)
      : getProjectMeta(project)?.gfa_building ?? 0,
});

/**
 * Will apply category properties and quantity properties to an element
 * and update children accordingly
 * @param element
 * @param path
 * @returns
 */
export const enrichElementStructure = <T extends OneOfElements>(
  element: T,
  path: OneOfParentElements[] = [],
  variablesRecord: ExpressionVariablesRecord = {},
  productRecord: ProductRecord = {},
): T => {
  if (isElement(element)) {
    let updatedElement: IElement = updateElementCategoryProperties(element);

    // // Products might have changed in the above call
    variablesRecord = updateProductIdsInVariablesRecord(
      updatedElement,
      variablesRecord,
      productRecord,
    );

    // Apply auto or clear quantities if possible
    updatedElement = autoUpdateElementQuantity(
      updatedElement,
      path,
      variablesRecord,
    );

    // Update element if it has changes
    if (updatedElement !== element) {
      element = updatedElement as T;
    }
  }

  // Recursively update children
  if (isElement(element) || isBuildingVersionElement(element)) {
    const updatedChildren = element.elements.map((child) =>
      enrichElementStructure(
        child,
        [...path, element as OneOfParentElements],
        variablesRecord,
        productRecord,
      ),
    );

    // Update element if children has changed
    element = applyChanges(element, {
      elements: updatedChildren,
    });
  }

  return element;
};

const updateProductIdsInVariablesRecord = (
  element: OneOfElements,
  variablesRecord: ExpressionVariablesRecord,
  productRecord: ProductRecord = {},
): ExpressionVariablesRecord => {
  if (getKeys(productRecord).length === 0) {
    return variablesRecord;
  }
  const vars = variablesRecord[element.id];

  if (vars) {
    // Get all products in the element (IN ORDER!!!)
    const products = getProductsInElement(element, productRecord);
    const updatedVars = applyProductVariables(vars, products);
    if (updatedVars !== vars) {
      return {
        ...variablesRecord,
        [element.id]: updatedVars,
      };
    }
  }
  return variablesRecord;
};

/**
 * Apply a set of changes to a child element and return the updated parent
 * Will return the same parent if no changes are made
 * @param parent
 * @param elementChanges An array of changes to apply to the child elements
 * @returns
 */
export const updateChildElements = <
  T extends OneOfChildElements,
  R extends OneOfParentElements<T>,
>(
  parent: R,
  ...elementChanges: SemiPartial<T, 'id'>[]
): R => {
  const elements = getChildElements(parent).map((e) => {
    const changes = elementChanges.find((c) => c.id === e.id);
    return changes ? applyChanges(e, changes) : e;
  });

  return applyChanges(parent, { elements });
};
