import { last, uniqBy } from 'lodash';
import { ElementCategoryID } from '../models/element_categories.interface';
import {
  ElementPropertySource,
  IElementExpressionProperty,
  IElementProperty,
  IElementSelectProperty,
  IElementSwitchProperty,
} from '../models/element_property.interface';
import { ProductRecord } from '../models/product.interface';
import {
  IBuildingVersion,
  IElement,
  OneOfElements,
  OneOfParentElements,
  Project,
  ProjectMetadata,
} from '../models/project.interface';
import { Recipe } from '../models/recipe.interface';
import { isCategoryOrChildOf } from '../templates/categories/template_category_helpers';
import { findDuplicates } from '../helpers/array_helpers';
import {
  getElementName,
  isElementWithGeneratedChildren,
  isGeneratedProductElement,
} from '../helpers/element_helpers';
import {
  getElementProperties,
  getElementPropertiesByCategorySource,
  getElementPropertiesByRecipeSource,
  getElementSourceId,
  getElementPropertySourceId,
  IPropertiesOrPropertyParent,
  isElementExpressionProperty,
  isElementSelectProperty,
  isElementSwitchProperty,
  getElementPropertySource,
  getPropertyCount,
} from '../helpers/element_property_helpers';
import { isExpressionValue } from '../helpers/expression_factory_helpers';
import { hasTruthyProperties } from '../helpers/object_helpers';
import {
  getProjectProductsRecord,
  getMissingProductIdsInVersion,
  getObsoleteProductIdsInVersion,
} from '../helpers/product_helpers';
import { getProjectMeta } from '../helpers/project_helpers';
import {
  getProductIdsInRecipes,
  isAutoRecipeId,
} from '../helpers/recipe_helpers';
import {
  OneOfSearchableElements,
  findElementAndParent,
  flattenElements,
  getAllBuildingVersions,
  getChildElements,
  isBuildingVersionElement,
  isElement,
  isOneOfElements,
  isProductElement,
} from '../helpers/recursive_element_helpers';
import { isValidVariableName } from '../helpers/mathjs';
import { isElementQuantityProperty } from '../helpers/element_quantity_helpers';
import { IN_TEST_MODE } from '../constants/test.constants';
import {
  getElementVersions,
  isActiveElementVersion,
  isElementVersionElement,
} from '../helpers/element-version.helpers';
import { isProjectInfoOrFolder } from '../helpers/project-folder.helpers';

// The default for verbose should be false in test mode
const VERBOSE_DEFAULT = !IN_TEST_MODE;

/**
 * console.error if verbose is true
 * @param verbose
 * @param args
 * @returns returns wherther or not the error was logged
 */
const optionalError = (verbose: boolean, ...args: any[]): string => {
  if (verbose) {
    console.error(...args);
  }
  return args.join(', ');
};

/**
 * Test if project is valid or not
 * @param project
 * @param verbose
 * @returns
 */
export const isValidProject = (
  project: Project | undefined,
  verbose = VERBOSE_DEFAULT,
): project is Project => {
  if (!project) {
    return false;
  }
  if (!isValidMetadata(getProjectMeta(project), verbose)) {
    return false;
  }
  const versions = getAllBuildingVersions(project);

  if (versions.length === 0) {
    return false;
  }

  const elements = flattenElements(project);
  const elementIds = elements.map((e) => e.id);
  const duplicateIds = findDuplicates(elementIds);

  // Check that all elements is elements
  if (!elements.every(isOneOfElements)) {
    optionalError(verbose, 'Invalid element type in project');
    return false;
  }

  // Check that the same versionId is not used in multiple parents etc
  if (!isValidElementVersions(project, verbose)) {
    optionalError(verbose, 'Invalid element versions in project');
    return false;
  }

  // Ids must be unique within project
  if (duplicateIds.length) {
    const names = elements
      .filter((e) => duplicateIds.includes(e.id))
      .map((e) => getElementName(e, undefined, 'ProductElement: ' + e.id));

    optionalError(
      verbose,
      'Duplicate element ids in project. Elements: ',
      names,
    );
    return false;
  }

  const versionValid = versions.every((v) => isValidVersion(v, verbose));

  return versionValid;
};

/**
 * Throw an error if project is not valid.
 * Returns the project if it is valid so that it can be used in a chain.
 * @param project
 */
export const validateProject = (
  project: Project | undefined,
  verbose?: boolean,
): Project => {
  if (!isValidProject(project, verbose)) {
    throw new Error('Project is not valid');
  }
  return project;
};

/**
 * Throw an error if project is not valid only if the previous project was valid.
 * This validation let the user continue to work on the project even if it is not valid but will prevent the introduction of invalid changes
 * Returns the project if it is valid so that it can be used in a chain.
 * @param project The updated project
 * @param previousProject The project before the change
 */
export const preventNewProjectValidationErrors = (
  project: Project,
  previousProject: Project,
  verbose?: boolean,
): Project => {
  if (
    !isValidProject(project, verbose) &&
    isValidProject(previousProject, false)
  ) {
    throw new Error('Project is not valid');
  }
  return project;
};

export const validateRecipeProducts = (
  project: Project,
  usedRecipes: Recipe[],
  verbose = VERBOSE_DEFAULT,
): Project => {
  if (!isValidRecipeProducts(project, usedRecipes, verbose)) {
    throw new Error('Products in recipe are not valid');
  }
  return project;
};

/**
 * Make sure all products used in recipes are defined in the project.
 * @param project
 * @param usedRecipes
 * @returns
 */
export const isValidRecipeProducts = (
  project: Project,
  usedRecipes: Recipe[],
  verbose = VERBOSE_DEFAULT,
): boolean => {
  const recipeProductIds = getProductIdsInRecipes(...usedRecipes);
  const productRecord = getProjectProductsRecord(project);
  const missing = recipeProductIds.filter((id) => !productRecord[id]);

  if (verbose && missing.length > 0) {
    console.error('Missing product ids', missing);
  }
  return missing.length === 0;
};

/**
 * Test if version is valid or not.
 * @param version
 * @param verbose
 * @returns
 */
export const isValidVersion = (
  version: IBuildingVersion | undefined,
  verbose = VERBOSE_DEFAULT,
): boolean => {
  if (!version) {
    return false;
  }

  // Check that we don't have any product elements as direct children to the version
  if (version.elements.some((element) => isProductElement(element))) {
    return false;
  }

  const elements = flattenElements(...version.elements);

  // Check that all elements in the version are valid
  if (!elements.every((e) => isValidElement(e, verbose))) {
    return false;
  }

  // Check that we map all productElements to a product
  const missing = getMissingProductIdsInVersion(version);
  const obsolete = getObsoleteProductIdsInVersion(version);

  if (verbose && missing.length > 0) {
    console.error(`Missing product ids in version ${version.name}`, missing);
    return false;
  }
  if (verbose && obsolete.length > 0) {
    console.warn(`Obsolete product ids in version ${version.name}`, obsolete);
  }

  // Check that all products are valid
  return missing.length === 0;
};

export const validateElement = <T extends OneOfElements>(
  element: OneOfElements,
  verbose = VERBOSE_DEFAULT,
): T => {
  if (!isValidElement(element, verbose)) {
    throw new Error('Element is not valid');
  }
  return element as T;
};

export const preventNewElementValidationErrors = (
  element: OneOfElements,
  previousElement: OneOfElements,
  verbose = VERBOSE_DEFAULT,
): OneOfElements => {
  if (
    !isValidElement(element, verbose) &&
    isValidElement(previousElement, verbose)
  ) {
    throw new Error('Element is not valid');
  }
  return element;
};

export const isValidElement = (
  element: OneOfElements,
  verbose = VERBOSE_DEFAULT,
): boolean => {
  if (isProjectInfoOrFolder(element)) {
    return false;
  }

  if (isBuildingVersionElement(element)) {
    return isValidVersion(element, verbose);
  }

  if (!isExpressionValue(element.count)) {
    optionalError(verbose, 'Invalid count', element.count);
    return false;
  }

  if (isProductElement(element)) {
    if (!hasTruthyProperties(element, 'id', 'product_id')) {
      optionalError(
        verbose,
        'Product element is missing id or product_id',
        element,
      );
      return false;
    }
  } else if (isElement(element)) {
    if (!hasTruthyProperties(element, 'id', 'elements')) {
      optionalError(
        verbose,
        'Element is missing id or elements property',
        element,
      );
      return false;
    }
    if (!isValidElementProperties(element, verbose)) {
      return false;
    }
    if (!isValidElementQuantityProperties(element, verbose)) {
      return false;
    }
    const children = getChildElements(element);
    const validGenerated = isElementWithGeneratedChildren(element)
      ? children.every(isGeneratedProductElement)
      : !children.some(isGeneratedProductElement);

    if (!validGenerated) {
      optionalError(
        verbose,
        "Can't mix generated and not generated elements",
        element,
      );
      return false;
    }
  } else {
    optionalError(verbose, 'Invalid type for element', element);
    return false;
  }
  return true;
};

export const validateRecipe = (
  recipe: Recipe,
  productRecord: ProductRecord,
  verbose = VERBOSE_DEFAULT,
): Recipe => {
  if (!isValidRecipe(recipe, productRecord, verbose)) {
    throw new Error('Recipe is not valid');
  }
  return recipe;
};

export const isValidRecipe = (
  recipe: Recipe,
  productRecord: ProductRecord,
  verbose = VERBOSE_DEFAULT,
): boolean => {
  // Make sure old recipe format is not allowed to be saved
  if (!Array.isArray(recipe.elements)) {
    throw new Error('Elements must be an array');
  }
  if (!hasTruthyProperties(recipe, 'id', 'name')) {
    throw new Error('Elements is missing name or id');
  }
  const product_ids = getProductIdsInRecipes(recipe);

  const missing = product_ids.filter((id) => !productRecord[id]);

  if (verbose && missing.length > 0) {
    optionalError(
      verbose,
      `Missing product ids in recipe "${recipe.name}"`,
      missing,
    );
    return false;
  }
  const elements = flattenElements(...recipe.elements);

  if (!elements.length) {
    optionalError(verbose, `Recipe "${recipe.name}" has no elements`);
    return false;
  }

  // Make sure all elements in the recipe are valid
  if (!elements.every((e) => isValidElement(e, verbose))) {
    return false;
  }
  return missing.length === 0;
};

const isValidPropertySource = (
  element: OneOfElements,
  property: IElementProperty,
  verbose: boolean,
): boolean => {
  const source = getElementPropertySource(property);

  // Undefined source is valid
  if (!source) {
    return true;
  }

  const sourceId = getElementSourceId(element, source);
  const propertySourceId = getElementPropertySourceId(property);
  const isValidSourceID =
    source === ElementPropertySource.Category
      ? isCategoryOrChildOf(
          sourceId as ElementCategoryID,
          propertySourceId as ElementCategoryID,
        )
      : propertySourceId === sourceId;

  if (!isValidSourceID) {
    optionalError(
      verbose,
      `Invalid ${source} id ${propertySourceId + ''}`,
      property,
    );
  }
  return isValidSourceID;
};

export const validatePropertySource = <T extends IElementProperty>(
  element: OneOfElements,
  property: T,
  verbose = VERBOSE_DEFAULT,
): T => {
  if (!isValidPropertySource(element, property, verbose)) {
    throw new Error('Property source is not valid');
  }
  return property;
};

const isValidExpressionProperty = (
  property: IElementExpressionProperty,
  verbose = VERBOSE_DEFAULT,
): boolean => {
  const { min, max } = property;
  const count = getPropertyCount(property);

  // Must be an Expression
  if (!isExpressionValue(count)) {
    optionalError(
      verbose,
      'Property of type expression has no count or fallbackCount',
      property,
    );
    return false;
  }

  // Min value
  if (typeof min === 'number' && count.resolved < min) {
    optionalError(verbose, `Property value below min`);
    return false;
  }

  // Max value
  if (typeof max === 'number' && count.resolved > max) {
    optionalError(verbose, `Property value above max`);
    return false;
  }

  return true;
};

export const isValidSelectPropertyCount = (
  count: unknown,
): count is IElementSelectProperty['count'] =>
  typeof count === 'string' || (typeof count === 'number' && isFinite(count));

const isValidSelectProperty = (
  property: IElementSelectProperty,
  verbose = VERBOSE_DEFAULT,
): boolean => {
  const { count, options } = property;

  if (!options?.length) {
    optionalError(verbose, 'Property of type select has no options', property);
    return false;
  }
  if (!isValidSelectPropertyCount(property.count)) {
    optionalError(verbose, 'Invalid count for type select', property);
    return false;
  }
  if (!options.some((option) => count === option.value)) {
    optionalError(
      verbose,
      'Property count is not part of options list',
      property,
    );
    return false;
  }

  return true;
};

export const isValidSwitchPropertyCount = (
  count: unknown,
): count is IElementSwitchProperty['count'] => typeof count === 'boolean';

const isValidSwitchProperty = (
  property: IElementSwitchProperty,
  verbose = VERBOSE_DEFAULT,
): boolean => {
  if (!isValidSwitchPropertyCount(property.count)) {
    optionalError(verbose, 'Invalid count for type switch', property);
    return false;
  }

  return true;
};

export const isValidProperty = (
  property: IElementProperty,
  verbose = VERBOSE_DEFAULT,
): boolean => {
  if (typeof property.id !== 'string' || !property.id) {
    optionalError(verbose, 'Property has no id', property);
    return false;
  }
  if (property.recipe_id && property.category_id) {
    optionalError(
      verbose,
      'Property has both recipe_id and category_id',
      property,
    );
    return false;
  }
  if (isAutoRecipeId(property.recipe_id)) {
    optionalError(verbose, 'Property cannot have auto recipe id', property);
    return false;
  }
  if (!isValidVariableName(property.name)) {
    optionalError(verbose, 'Invalid property name', property);
    return false;
  }

  // Expression
  if (isElementExpressionProperty(property)) {
    return isValidExpressionProperty(property, verbose);
  }
  // Select
  else if (isElementSelectProperty(property)) {
    return isValidSelectProperty(property, verbose);
  }
  // Switch
  else if (isElementSwitchProperty(property)) {
    return isValidSwitchProperty(property, verbose);
  }
  // Invalid property type
  else {
    optionalError(verbose, 'Invalid property type', property);
    return false;
  }
};

export const validateProperty = <T extends IElementProperty>(
  property: T,
  verbose = VERBOSE_DEFAULT,
): T => {
  if (!isValidProperty(property, verbose)) {
    throw new Error('Invalid property');
  }
  return property;
};

export const validateElementProperties = (
  properties?: IElementProperty[],
  verbose = VERBOSE_DEFAULT,
): IElementProperty[] => {
  if (!isValidElementProperties(properties, verbose)) {
    throw new Error('Element properties are not valid');
  }
  return properties || [];
};

export const isValidElementProperties = (
  elementOrProps?: IPropertiesOrPropertyParent,
  verbose = VERBOSE_DEFAULT,
): boolean => {
  if (!elementOrProps) {
    return true;
  }

  const properties = getElementProperties(elementOrProps);

  // All properties must be valid
  for (const property of properties) {
    if (!isValidProperty(property, verbose)) {
      return false;
    }
    // Test that sources match if element
    if (
      isElement(elementOrProps) &&
      !isValidPropertySource(elementOrProps, property, verbose)
    ) {
      return false;
    }
  }

  const duplicateIds = findDuplicates(properties.map(({ id }) => id));

  // Can't have duplicate ids
  if (duplicateIds.length) {
    optionalError(verbose, 'Duplicate property ids', duplicateIds);
    return false;
  }

  const recipeProperties = getElementPropertiesByRecipeSource(elementOrProps);
  const categoryProperties =
    getElementPropertiesByCategorySource(elementOrProps);

  // Names must be unique within recipe
  if (uniqBy(recipeProperties, 'name').length !== recipeProperties.length) {
    optionalError(
      verbose,
      'Duplicate property name in recipe properties',
      properties,
    );
    return false;
  }
  // Names must be unique within category
  if (uniqBy(categoryProperties, 'name').length !== categoryProperties.length) {
    optionalError(
      verbose,
      'Duplicate property name in category properties',
      properties,
    );
    return false;
  }

  return true;
};

const isValidElementQuantityProperties = (
  element: OneOfElements,
  verbose = VERBOSE_DEFAULT,
): boolean => {
  const quantity = isElement(element) && element.quantity;

  // Quantity is not required
  if (!quantity) {
    return true;
  }
  // Sometimes we use an array of quantities. But make sure we never save it like that
  if (Array.isArray(quantity)) {
    optionalError(verbose, 'Quantity cannot be an array', quantity);
    return false;
  }

  const properties = Object.values(quantity);
  for (const property of properties) {
    const name = property.name;
    if (!isElementQuantityProperty(property)) {
      optionalError(verbose, 'Invalid quantity property', name);
      return false;
    }
  }
  return isValidElementProperties(properties, verbose);
};

const isValidMetadata = (
  meta: ProjectMetadata | undefined,
  verbose = VERBOSE_DEFAULT,
): boolean => {
  if (!meta) {
    optionalError(verbose, 'meta not defined');
    return false;
  }
  if (!Array.isArray(meta.storeys)) {
    optionalError(verbose, 'storeys is not an array');
    return false;
  }
  return true;
};

const isValidElementVersions = (
  searchIn: OneOfSearchableElements,
  verbose = VERBOSE_DEFAULT,
): boolean => {
  const versionIdToParentIdMap = new Map<
    NonNullable<IElement['versionId']>,
    OneOfParentElements['id']
  >();

  const { element } = findElementAndParent(searchIn, (element, path) => {
    // Only check element versions
    if (!isElementVersionElement(element)) {
      return false;
    }

    const parent = last(path);
    const { versionId, versionName } = element;

    if (versionId) {
      // Must have parent
      if (!parent) {
        optionalError(verbose, 'Element version has no parent', element);
        return true;
      }
      if (!versionName) {
        optionalError(verbose, 'Element version has no versionName', element);
        return true;
      }

      const versionParentId = versionIdToParentIdMap.get(versionId);

      if (versionParentId) {
        // Can't have the same versionId in multiple parents
        if (versionParentId !== parent.id) {
          optionalError(
            verbose,
            'Element version has multiple parents',
            element,
          );
          return true;
        }
        // All checks below are already checked (once per unique versionId) so we can skip the rest
        return false;
      }

      const elementVersions = getElementVersions(parent, versionId);

      // Check that there are at least 2 versions of the element in parent
      if (elementVersions.length < 2) {
        optionalError(
          verbose,
          'An element versions must have at least 2 versions',
          element,
        );
        return true;
      }

      if (elementVersions.filter(isActiveElementVersion).length !== 1) {
        optionalError(
          verbose,
          'Element versions must have exactly one active version',
          element,
        );
        return true;
      }

      versionIdToParentIdMap.set(versionId, parent.id);
    }
  });

  return !element;
};

export const validateFiniteNumber = (value: unknown): number => {
  if (typeof value !== 'number' || !isFinite(value) || isNaN(value)) {
    throw new Error('Value must be a valid number');
  }
  return value;
};
