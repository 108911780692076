import { ProductRecord } from './product.interface';
import { OneOfListElements } from './project.interface';
import { ConversionFactorQuantityRecord } from './unit.interface';

export const sortByAlternatives = {
  name: 'Name',
  co2: 'kgCO2e',
  cost: 'Cost',
  position: 'Position',
};

export type SortByAlternative = keyof typeof sortByAlternatives;

export type SortByNameOptions = {
  sortBy: 'name';
  productLookup: ProductRecord;
  quantitiesRecord?: ConversionFactorQuantityRecord;
};
export type SortByConversionFactorsOptions = {
  sortBy: 'co2' | 'cost';
  quantitiesRecord: ConversionFactorQuantityRecord;
  productLookup?: ProductRecord;
};
export type SortByPositionOptions = {
  sortBy: 'position';
  quantitiesRecord?: ConversionFactorQuantityRecord;
  productLookup?: ProductRecord;
};
export type SortOptions =
  | SortByNameOptions
  | SortByConversionFactorsOptions
  | SortByPositionOptions;

export type ElementSortFn<T extends OneOfListElements> = (a: T, b: T) => number;

export type IInsertSortPlacement = 'before' | 'after' | 'last' | 'first';
