import { useCallback, useEffect } from 'react';
import { TEMPORARY_ID } from '../../../shared/constants';
import {
  IElement,
  OneOfElementListChildren,
  OneOfElementListElements,
  OneOfParentElements,
} from '../../../shared/models/project.interface';
import {
  getSelectedVersion,
  useSelectedVersion,
  useSelectedVersionProducts,
} from '../store/ui';
import {
  useMainCategoryElements,
  useSelectedMainCategoryElement,
} from './element-category.hook';
import { createFilterStore } from './filter.hook';
import { useSortBy } from './sort.hook';
import {
  getElementName,
  isGeneratedProductElement,
} from '../../../shared/helpers/element_helpers';
import {
  SortByAlternative,
  getPathInFlatTree,
} from '../../../shared/helpers/tree.helpers';
import { useVersionConversionFactorQuantitiesRecord } from './useCO2Calculations';
import { nameMatchTrim } from '../../../shared/helpers/string_helpers';
import { isBuildingVersionElement } from '../../../shared/helpers/recursive_element_helpers';
import { EMPTY_ARRAY } from '../../../shared/helpers/array_helpers';

const {
  setFilter,
  setSorting,
  useFilteredChildren,
  useFilterSortItemsOnChange,
  useFlattenedItems,
  getFlattenedItems,
} = createFilterStore<OneOfElementListChildren>({
  childrenKey: 'elements',
  filters: [
    // No inactivated element versions
    // {
    //   id: 'inactive-elements-version-filter',
    //   function: (item) => !isInactiveElementVersion(item),
    // },
    // No generated elements with 0 count
    {
      id: 'generated-products-filter',
      function: (item) =>
        isGeneratedProductElement(item) ? !!item.count.resolved : true,
    },
    // Never show temporary elements
    {
      id: 'temporary-elements-filter',
      function: ({ id }) => id !== TEMPORARY_ID,
    },
  ],
});

/**
 * Get all elements in the selected version in order
 */
export const useSortedFlattenedElements = useFlattenedItems;

/**
 * Most performant way to get the path to an element
 * @param element
 * @returns
 */
export const getPathFromFlattenedElements = (
  element: OneOfElementListElements,
): OneOfParentElements[] => {
  const version = getSelectedVersion();
  if (!version) {
    return EMPTY_ARRAY as OneOfParentElements[];
  }
  return [
    version,
    ...(getPathInFlatTree(
      getFlattenedItems(),
      element,
      'elements',
    ) as IElement[]),
  ];
};

export const useInitElementsSortFilter = () => {
  const version = useSelectedVersion();

  // Trigger filtering when version elements change
  useFilterSortItemsOnChange(version?.elements ?? []);
  useInitElementsFilter();
  useInitElementsSorting();
};

const useInitElementsFilter = () => {
  const mainCategoryElements = useMainCategoryElements();

  // Use id to trigger less filter updates
  const selectedMainCategoryElementId = useSelectedMainCategoryElement()?.id;
  const hasMainCategoryElements = mainCategoryElements.length > 0;

  // Remove root elements if the version has main category elements
  useEffect(() => {
    setFilter({
      id: 'root-elements-filter',
      function: (item, path) => path.length > 0,
      disabled: !hasMainCategoryElements,
    });
  }, [hasMainCategoryElements]);

  // If main category element is selected, only show that element's children
  useEffect(() => {
    setFilter({
      id: 'selected-mc-filter',
      function: (item, path) => path[0]?.id === selectedMainCategoryElementId,
      disabled: !selectedMainCategoryElementId,
    });
  }, [selectedMainCategoryElementId]);
};

const useInitElementsSorting = () => {
  const direction = useSortDirection();
  const sortFn = useElementSortFn();

  useEffect(() => {
    setSorting([{ id: 'element-sort', property: sortFn, direction }]);
  }, [direction, sortFn]);
};

const useSortDirection = () => {
  const sortBy = useSortBy();
  return sortBy === 'name' ? 'asc' : 'desc';
};

const useElementSortFn = <
  T extends OneOfElementListElements,
>(): SortByAlternative<T> => {
  const sortBy = useSortBy();
  const products = useSelectedVersionProducts();
  const quantitiesRecord = useVersionConversionFactorQuantitiesRecord();

  return useCallback(
    (element: T) => {
      if (sortBy === 'name') {
        return nameMatchTrim(getElementName(element, products));
      }
      if (sortBy === 'co2') {
        return quantitiesRecord[element.id]?.co2e_total ?? 0;
      }
      if (sortBy === 'cost') {
        return quantitiesRecord[element.id]?.['sek_A1-A3'] ?? 0;
      }
      return 0;
    },
    [products, quantitiesRecord, sortBy],
  );
};

export const useFilteredElementChildren = (
  element?: OneOfElementListElements,
) => {
  return useFilteredChildren(
    isBuildingVersionElement(element) ? undefined : element, // version is root element
  );
};
