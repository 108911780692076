import {
  ArrayOrSingle,
  FilterKeysOfType,
  Merge,
} from '../../../shared/models/type_helpers.interface';
import shallowEqual from '../../../shared/helpers/array_helpers';
import {
  ChildrenKey,
  SortingOption,
  flattenTree,
  getParentInFlatTree,
  getPathInFlatTree,
  getTreeItemChildren,
  sort,
} from '../../../shared/helpers/tree.helpers';
import { useShallow } from 'zustand/react/shallow';
import { searchFilter } from '../../../shared/helpers/string_helpers';
import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

const DEBUG = false;

interface IFilterOptionBase {
  id: string;
  disabled?: boolean;
}

/**
 * Finter function that takes a value and a path (all parents to the elements) and returns a boolean
 */
type FilterFn<T> = (value: T, path: T[]) => boolean | undefined;

/**
 * Function filter
 */
interface IFilterFunctionOption<T extends object> extends IFilterOptionBase {
  function: FilterFn<T>;
}

/**
 * Search filter
 */
interface IFilterSearchOption<T extends object> extends IFilterOptionBase {
  /**
   * Keys to search in. Must have string values
   */
  searchIn: FilterKeysOfType<Merge<T>, string | undefined>[];

  /**
   * Current search query. Undefined or empty string will not filter.
   * Space separated words will be treated as an AND search.
   */
  query?: string;
}

/**
 * Different types of filter options
 */
type IFilterOption<T extends object> =
  | IFilterFunctionOption<T>
  | IFilterSearchOption<T>;

/**
 * Input options for the filter store
 */
interface IFilterStoreOptions<T extends object, K = ChildrenKey<T>> {
  childrenKey?: K;
  filters?: IFilterOption<T>[];
  sorting?: SortingOption<T>[];
  initialValues?: ArrayOrSingle<T | undefined>;
}

/**
 * Return type of the filter store
 */
export interface IFilterStore<T extends object> {
  /**
   * Pass in root tree or array to to trigger
   * sorting and filtering of tree on change.
   */
  useFilterSortItemsOnChange: (newTree: ArrayOrSingle<T | undefined>) => void;

  setSorting: (sorting?: SortingOption<T>[]) => void;

  /**
   * Overwrite filters with a new array of filters
   */
  setFilters: (filters?: IFilterOption<T>[]) => void;

  /**
   * Add a new filter. If a filter with the same id already exists, it will be replaced.
   */
  setFilter: (newFilter: IFilterOption<T>) => void;

  /**
   * Current filters
   */
  getFilters: () => Readonly<IFilterOption<T>[]>;

  getFilteredItems: () => T[];

  useFilteredChildren: (item?: T) => T[];

  getFilteredChildren: (item?: T) => T[];

  /**
   * Get all items in the tree as a flat array
   */
  getFlattenedItems: () => T[];

  useFlattenedItems: () => T[];

  /**
   * Get parent of an item
   * @param item
   */
  getParent: (item: T) => T | undefined;
}

/**
 * Create a new filter store that can sort and filter a tree or array of items
 * @param localStorageKey
 * @param options Store options
 * @returns
 */
export const createFilterStore = <T extends object>({
  childrenKey,
  filters = [],
  initialValues,
  sorting = [],
}: IFilterStoreOptions<T> = {}): IFilterStore<T> => {
  // Previous sorting. Used to check if sorting has changed
  let prevSorting: SortingOption<T>[] = [];

  const store = createWithEqualityFn<{
    tree: ArrayOrSingle<T | undefined>;
    flat: T[];
    filtered: T[];
  }>(() => ({ tree: [], flat: [], filtered: [] }), shallow);

  const getTree = () => store.getState().tree;
  const getFlat = () => store.getState().flat;

  const setSorting = (newSorting: SortingOption<T>[] = []) => {
    if (!shallowEqual(newSorting, sorting)) {
      sorting = newSorting;
      set(store.getState().tree);
    }
  };

  // Change filters
  const setFilters = (newFilters?: IFilterOption<T>[]) => {
    filters = newFilters ?? [];
    filter();
  };

  // Set a single filter
  const setFilter = (newFilter: IFilterOption<T>) => {
    filters = [...filters.filter((f) => f.id !== newFilter.id), newFilter];
    filter();
  };

  const getFilters = () => filters;

  const getFilteredItems = () => store.getState().filtered;

  const filter = (): T[] => {
    const filtered = filterItems(getFlat(), childrenKey, filters);
    store.setState({ filtered });
    return filtered;
  };

  // Set the tree and flatten it if sorting or tree has changed
  const set = (newTree: ArrayOrSingle<T | undefined> = []): void => {
    if (
      !shallowEqual(newTree, getTree()) ||
      !shallowEqual(sorting, prevSorting)
    ) {
      const flat = flattenTree(newTree, childrenKey, sorting);
      store.setState({ tree: newTree, flat });
      filter();
    }
    prevSorting = sorting;
  };

  const useFilterSortItemsOnChange = (
    newTree: ArrayOrSingle<T | undefined> = [],
  ) => {
    set(newTree);
  };

  // Get all filtered chidlren of an item. If no item is provided, return top level items (without parents)
  const filterChildren = (items: T[], item?: T): T[] => {
    // If no item is provided, return top level items (without parents)
    if (!item) {
      return sort(
        // Sort is needed since we are filtering top level items
        items.filter(
          (item) =>
            !items.some((parent) =>
              getTreeItemChildren(parent, childrenKey).includes(item),
            ),
        ),
        sorting,
      );
    }

    const allChildren = getTreeItemChildren(item, childrenKey);
    const filteredItems = getFilteredItems();
    return sort(
      allChildren.filter((child) => filteredItems.includes(child)),
      sorting,
    );
  };

  const useFilteredChildren = (item?: T) => {
    return store(
      useShallow(({ filtered }) => {
        return filterChildren(filtered, item);
      }),
    );
  };

  const useFlattenedItems = () => store((state) => state.flat);

  const getFilteredChildren = (item?: T) =>
    filterChildren(getFilteredItems(), item);

  if (initialValues) {
    set(initialValues);
  }

  const getParent = (item: T): T | undefined =>
    getParentInFlatTree(getFlat(), item, childrenKey);

  return {
    useFilteredChildren,
    useFilterSortItemsOnChange,
    setSorting,
    setFilters,
    setFilter,
    getFilters,
    getFilteredItems,
    getFilteredChildren,
    getFlattenedItems: getFlat,
    useFlattenedItems,
    getParent,
  };
};

const filterDebug = <T extends object>(
  response: boolean | undefined,
  item: T,
  filter: IFilterOption<T>,
): boolean | undefined => {
  if (DEBUG && !response) {
    console.info(`Item filtered by "${filter.id}"`, item, filter);
  }
  return response;
};

/**
 * Handle filtering of items
 * @param flatTree Already flattened tree
 * @param childrenKey Key to children
 * @param filters Filters to apply
 * @returns
 */
const filterItems = <T extends object>(
  flatTree: T[],
  childrenKey: ChildrenKey<T> | undefined,
  filters: IFilterOption<T>[],
): T[] => {
  const activeFilters = filters.filter((filter) => !filter.disabled);
  // No filters => show everything
  if (!activeFilters.length) {
    return flatTree;
  }

  // Filter items
  const filtered = flatTree.filter((item) => {
    const path = getPathInFlatTree(flatTree, item, childrenKey);
    return activeFilters.every((filter) => {
      const fn = 'function' in filter && filter.function;
      const searchIn = 'searchIn' in filter && filter.searchIn;
      // Search filter
      if (searchIn) {
        const searchData = searchIn.map((key) => item[key] ?? '').join(' ');
        return filterDebug(
          searchFilter(searchData, filter.query),
          item,
          filter,
        );
      }
      // Function filter
      if (fn) {
        return filterDebug(fn(item, path), item, filter);
      }
      return true;
    });
  });
  return filtered;
};
