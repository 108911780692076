import React, { FC } from 'react';
import { MoreHoriz } from '@mui/icons-material';
import {
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip,
} from '@mui/material';

import { FormattedMessage } from 'react-intl';
import { mouseEventHandler } from '../../../../shared/helpers/event_helpers';
import {
  useKebabMenuPosition,
  openKebabMenu,
  closeKebabMenu,
  kebabMenuMouseHandler,
} from './kebab-menu.hooks';
import { useMouseEventCallback } from '../../hooks/events.hook';

export interface IMenuItemData {
  onClick: (...args: any[]) => Promise<any> | void | undefined;
  icon: JSX.Element;
  id: string;
  defaultMessage: string;
  disabled?: boolean;
}

interface IKebabMenuInput {
  id: string | number;
  items: IMenuItemData[];
  tooltipTitle?: string;
}

export const KebabMenu: FC<IKebabMenuInput> = ({ id, items, tooltipTitle }) => {
  if (!id) {
    throw new Error('KebabMenu: key is required');
  }
  const position = useKebabMenuPosition(id);
  const isOpen = !!position;
  const open: IKebabIconInput['onClick'] = useMouseEventCallback(
    (e) => {
      const anchorPosition = kebabMenuMouseHandler(e);
      if (anchorPosition) {
        openKebabMenu({ anchorPosition, id });
      }
    },
    { stopPropagation: true },
  );

  return (
    <Box
      width={20}
      display="flex"
      alignItems="center"
      justifyContent="center"
      m="6px 8px"
    >
      <>
        <KebabIcon
          disabled={items.length === 0}
          tooltipTitle={tooltipTitle}
          onClick={open}
        />
        <Menu
          open={isOpen}
          anchorReference="anchorPosition"
          anchorPosition={position}
          onClose={closeKebabMenu}
        >
          {items.map(({ onClick, icon, id, defaultMessage, disabled }) => (
            <MenuItem
              onClick={mouseEventHandler(async () => {
                await onClick();
                closeKebabMenu();
              })}
              disabled={disabled}
              key={id}
            >
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText>
                <FormattedMessage id={id} defaultMessage={defaultMessage} />
              </ListItemText>
            </MenuItem>
          ))}
        </Menu>
      </>
    </Box>
  );
};

interface IKebabIconInput {
  tooltipTitle?: string;
  onClick: React.MouseEventHandler<HTMLElement>;
  disabled?: boolean;
}

const KebabIcon: FC<IKebabIconInput> = ({
  tooltipTitle,
  onClick,
  disabled,
}) => (
  <Tooltip title={tooltipTitle}>
    <IconButton
      size="small"
      color={disabled ? 'secondary' : 'default'}
      onClick={onClick}
      component="span"
      disabled={disabled}
    >
      <MoreHoriz color="secondary" />
    </IconButton>
  </Tooltip>
);
