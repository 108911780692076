import { useCallback, useMemo } from 'react';
import {
  IElement,
  IElementID,
  OneOfElements,
  OneOfParentElements,
  Project,
} from '../../../shared/models/project.interface';
import { useUpdateElements } from '../store/project';
import {
  addElementVersion,
  setActiveElementVersion,
  getElementVersions,
  getElementVersionId,
  ElementOrVersionId,
} from '../../../shared/helpers/element-version.helpers';
import { getSelectedVersion, useSelectedVersionId } from '../store/ui';
import { getParentElement } from '../../../shared/helpers/recursive_element_helpers';
import { ItemOrItemId } from '../../../shared/models/type_helpers.interface';
import { useParentElement } from './useElement';
import { createLocalStorageStore } from '../helpers/local-storage.helpers';

const { useStore: useExpandedVersionIds, set: setExpandedElementVersionIds } =
  createLocalStorageStore<IElementID[]>('expanded_element_versions', []);

const {
  useStore: useBuildingVersionsWithExpandedElementVersions,
  set: setBuildingVersionsWithExpandedElementVersions,
} = createLocalStorageStore<IElementID[]>(
  'expanded_versions_with_element_versions',
  [],
);

const getParent = (
  elementOrId: ItemOrItemId<OneOfElements>,
): OneOfParentElements => {
  const selectedVersion = getSelectedVersion(true);
  const parent = getParentElement(selectedVersion, elementOrId);

  if (!parent) {
    throw new Error('No parent element found, could not add element version');
  }
  return parent;
};

/**
 * Get all element versions related to provided element
 * @param element
 * @returns
 */
export const useElementVersions = (element: OneOfElements) => {
  const parent = useParentElement(element);
  const versionId = getElementVersionId(element);

  return useMemo(
    () => (parent ? getElementVersions(parent, versionId) : []),
    [versionId, parent],
  );
};

export const useAddElementVersion = () => {
  const updateElements = useUpdateElements();

  return useCallback(
    async (original: IElement): Promise<Project> => {
      const parent = getParent(original);
      const modifiedParent = addElementVersion(parent, original);

      return updateElements(modifiedParent as IElement);
    },
    [updateElements],
  );
};

export const useSetActiveElementVersion = () => {
  const updateElements = useUpdateElements();

  return useCallback(
    async (elementOrId: ItemOrItemId<OneOfElements>) => {
      const parent = getParent(elementOrId);
      const modifiedParent = setActiveElementVersion(parent, elementOrId);

      return updateElements(modifiedParent as IElement);
    },
    [updateElements],
  );
};

/**
 * Check if an element version is expanded or not
 * @param elementOrVersionId
 * @returns
 */
export const useIsExpandedElementVersion = (
  elementOrVersionId?: ElementOrVersionId,
): boolean => {
  const versionId = getElementVersionId(elementOrVersionId);

  const expandedElementVersions = useExpandedVersionIds();

  return versionId ? !!expandedElementVersions?.includes(versionId) : false;
};

export const useHasExpandedElementVersions = () => {
  const expandedBuildingVersions =
    useBuildingVersionsWithExpandedElementVersions();
  const selectedVersionId = useSelectedVersionId();
  return (
    !!selectedVersionId &&
    !!expandedBuildingVersions?.includes(selectedVersionId)
  );
};

export const useToggleElementVersion = (
  elementOrVersionId?: ElementOrVersionId,
) => {
  const isExpanded = useIsExpandedElementVersion(elementOrVersionId);
  const collapse = useCollapseElementVersion();
  const expand = useExpandElementVersion();

  return useCallback(
    () =>
      isExpanded ? collapse(elementOrVersionId) : expand(elementOrVersionId),

    [collapse, elementOrVersionId, expand, isExpanded],
  );
};

export const useExpandElementVersion = () => {
  const expandedElementVersions = useExpandedVersionIds();
  const expandedBuildingVersions =
    useBuildingVersionsWithExpandedElementVersions();

  return useCallback(
    (elementOrVersionId: ElementOrVersionId) => {
      const versionId = getElementVersionId(elementOrVersionId);
      if (versionId && !expandedElementVersions?.includes(versionId)) {
        setExpandedElementVersionIds([
          ...(expandedElementVersions ?? []),
          versionId,
        ]);
      }
      const selectedVersionId = getSelectedVersion(true)?.id;

      if (
        selectedVersionId &&
        !expandedBuildingVersions?.includes(selectedVersionId)
      ) {
        setBuildingVersionsWithExpandedElementVersions([
          ...(expandedBuildingVersions ?? []),
          selectedVersionId,
        ]);
      }
    },
    [expandedBuildingVersions, expandedElementVersions],
  );
};

export const useCollapseElementVersion = () => {
  const expandedElementVersions = useExpandedVersionIds();
  const expandedBuildingVersions =
    useBuildingVersionsWithExpandedElementVersions();

  return useCallback(
    (elementOrVersionId: ElementOrVersionId) => {
      const versionId = getElementVersionId(elementOrVersionId);

      if (versionId && expandedElementVersions?.includes(versionId)) {
        setExpandedElementVersionIds(
          expandedElementVersions.filter((id) => id !== versionId),
        );
      }

      const selectedVersionId = getSelectedVersion(true)?.id;
      if (
        selectedVersionId &&
        expandedBuildingVersions?.includes(selectedVersionId)
      ) {
        setBuildingVersionsWithExpandedElementVersions(
          expandedBuildingVersions.filter((id) => id !== selectedVersionId),
        );
      }
    },
    [expandedBuildingVersions, expandedElementVersions],
  );
};
