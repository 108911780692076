import React, { useCallback } from 'react';
import { Box, IconButton } from '@mui/material';
import { useUIState } from '../../../store/ui';
import { Clear } from '@mui/icons-material';
import { useMainCategoryElementNavigation } from '../../../hooks/router.hooks';

const ElementClose: React.FC = () => {
  const { setShowSettings } = useUIState('setShowSettings');
  const mainCategoryNavigation = useMainCategoryElementNavigation(false);

  const handleCloseClick = useCallback(() => {
    setShowSettings(false);
    mainCategoryNavigation();
  }, [mainCategoryNavigation, setShowSettings]);

  return (
    <Box p={2}>
      <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton size="small" onClick={handleCloseClick}>
          <Clear color="disabled" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ElementClose;
