import { isDefined } from '../helpers/array_helpers';
import { getPropertyCountNumberByName } from '../helpers/element_property_helpers';
import { sum } from '../helpers/math_helpers';
import { getKeys } from '../helpers/object_helpers';
import {
  getBuilding,
  isBuildingVersionElement,
} from '../helpers/recursive_element_helpers';
import { ElementCategoryID } from '../models/element_categories.interface';
import { ElementPropertyName } from '../models/element_property.interface';
import {
  IElement,
  Project,
  IBuildingVersion,
} from '../models/project.interface';
import { Recipe } from '../models/recipe.interface';
import { ConversionFactorQuantityRecord } from '../models/unit.interface';
import {
  ActivityId,
  MainActivityClass,
} from '../models/activity_class.interface';
import { getActivityById, getMainActivityClassById } from './activity_class';
import { BeamType, BeamUsage } from './beam';
import { ProtectionClass, StrengthType } from './construction.interface';
import {
  getAcceptableDeflection,
  getInstantDeflection,
  getTotalDeflectionOverTime,
} from './deflection';
import { getKMod } from './kmod';
import {
  getDeadWeight,
  getLoadDuration,
  getRecommendedUsefulLoad,
  LoadDuration,
  LoadType,
} from './payload';
import { getProtectionClass } from './protection_class';
import { getWoodTableValue } from './tables/table_helpers';
import {
  getBeamWoodType,
  getKCr,
  getPartialCoefMaterial,
  isOneOfWoodStrengthClass,
  numberToClimateClass,
  OneOfWoodStrengthClass,
  WoodClimateClass,
  WoodType,
} from './wood';

type CombinationFactors = [number, number, number];
type LoadRecord = Record<LoadType, number>;

interface IElementLoad {
  type: LoadType;

  /**
   * kN/m2
   */
  characteristicLoad: number;

  duration: LoadDuration;

  combinationFactors: CombinationFactors;
}

// ref. sid 32 BYGGKONSTRUKTION
function getPartialCoefProtection(protectionClass: ProtectionClass): number {
  switch (protectionClass) {
    case 1:
      return 0.83;
    case 2:
      return 0.91;
    default:
      return 1;
  }
}

// ref. sid 78 BYGGKONSTRUKTION
const getLoadCombinationFactors = (
  type: LoadType,
  characteristic: number,
  activityId: ActivityId,
): CombinationFactors => {
  switch (type) {
    case LoadType.Payload:
      return getLoadFactorsPayload(activityId);
    case LoadType.Snow:
      return getLoadFactorsSnow(characteristic);
    case LoadType.Wind:
      return [0.3, 0.2, 0];
    case LoadType.Temperature:
      return [0.6, 0.5, 0];
  }
  return [0, 0, 0];
};

const getLoadFactorsPayload = (activityId: ActivityId): CombinationFactors => {
  const mainClass = getMainActivityClassById(activityId);
  const dataTable: CombinationFactors[] = [
    [0.7, 0.5, 0.3],
    [0.7, 0.5, 0.3],
    [0.7, 0.7, 0.6],
    [0.7, 0.7, 0.6],
  ];
  switch (mainClass) {
    case MainActivityClass.Residential:
      return dataTable[0];
    case MainActivityClass.IndustryOffice:
      return dataTable[1];
    case MainActivityClass.Gathering:
      return dataTable[2];
    default:
      return [0, 0, 0];
  }
};

const getLoadFactorsSnow = (
  characteristicValue: number,
): CombinationFactors => {
  const dataTable: CombinationFactors[] = [
    [0.8, 0.6, 0.2],
    [0.7, 0.4, 0.2],
    [0.6, 0.3, 0.1],
  ];
  if (characteristicValue >= 3) return dataTable[0];
  if (characteristicValue >= 2) return dataTable[1];
  if (characteristicValue >= 1) return dataTable[2];
  else return [0, 0, 0];
};

/**
 * Get all loads as an array. Removes any non-positive loads
 * @param loads
 * @param activityId
 * @returns
 */
const getElementLoads = (
  loads: LoadRecord,
  activityId: ActivityId,
): IElementLoad[] => {
  return getKeys(loads)
    .map((type) => {
      const load = loads[type];

      if (load > 0) {
        return {
          characteristicLoad: load,
          type,
          duration: getLoadDuration(type, activityId),
          combinationFactors: getLoadCombinationFactors(type, load, activityId),
        };
      }
    })
    .filter(isDefined);
};

/**
 * Get characteristic (typical) loads for an element in kN/m2
 * @returns
 */
const getCharacteristicLoads = (
  activityId: ActivityId,
  categoryId: ElementCategoryID,
  element: IElement,
  quantitiesRecord: ConversionFactorQuantityRecord,
): LoadRecord => {
  // TODO: better numbers
  return {
    [LoadType.DeadWeight]: getDeadWeight(element, quantitiesRecord),
    [LoadType.Payload]:
      getRecommendedUsefulLoad(activityId, categoryId)?.distributed ?? 0,
    [LoadType.Snow]: 0,
    [LoadType.Wind]: 0,
    [LoadType.Temperature]: 0,
    [LoadType.Accident]: 0,
    [LoadType.Flooring]: 0,
  };
};

// Dim lastkombination brott sid 75.
const getDimLoadCombination = (
  loads: IElementLoad[],
  protectionClass: ProtectionClass,
  serviceability: boolean,
): number => {
  const variableLoads = loads.filter(
    (load) => load.duration !== LoadDuration.Permanent,
  );
  const permanentLoads = loads.filter(
    (load) => load.duration === LoadDuration.Permanent,
  );
  const permanentLoad = sum(permanentLoads, 'characteristicLoad');

  const loadCombinations = [];
  if (serviceability) {
    for (let i = 0; i < variableLoads.length; i++) {
      // Karakteristisk
      let sumVarLoads = 0;
      for (let j = 0; j < variableLoads.length; j++) {
        const factor = i === j ? 1 : variableLoads[j].combinationFactors[0];
        sumVarLoads += factor * variableLoads[j].characteristicLoad;
      }
      loadCombinations.push(permanentLoad + sumVarLoads);
      // Frekvent
      sumVarLoads = 0;
      for (let j = 0; j < variableLoads.length; j++) {
        const factor =
          i === j
            ? variableLoads[j].combinationFactors[1]
            : variableLoads[j].combinationFactors[2];
        sumVarLoads += factor * variableLoads[j].characteristicLoad;
      }
      loadCombinations.push(permanentLoad + sumVarLoads);
      // Kvasipermanent
      sumVarLoads = 0;
      for (let j = 0; j < variableLoads.length; j++) {
        const factor = i === j ? 0 : variableLoads[j].combinationFactors[2];
        sumVarLoads += factor * variableLoads[j].characteristicLoad;
      }
      loadCombinations.push(permanentLoad + sumVarLoads);
    }
  } else {
    const partialCoef = getPartialCoefProtection(protectionClass);
    // STR-A:
    loadCombinations.push(partialCoef * 1.35 * permanentLoad);
    // STR-B & EQU:
    for (let i = 0; i < variableLoads.length; i++) {
      let sumVarLoads = 0;
      for (let j = 0; j < variableLoads.length; j++) {
        const factor = i === j ? 1 : variableLoads[j].combinationFactors[0];
        sumVarLoads += 1.5 * factor * variableLoads[j].characteristicLoad;
      }
      // STR-B:
      loadCombinations.push(partialCoef * (1.2 * permanentLoad + sumVarLoads));
      // EQU:
      loadCombinations.push(1.1 * permanentLoad + partialCoef * sumVarLoads);
    }
  }
  // vilken kombination är dimensionerande:
  return Math.max(...loadCombinations);
};

const getLoadCombinationPerMeterBeam = (
  dimLoadCombination: number,
  centerToCenterMM: number,
): number => (dimLoadCombination * centerToCenterMM) / 1000;

// Tvärkraft (dimmensionerande)
const getDimShearForce = (
  dimLoadCombination: number,
  beamType: BeamType,
  span: number,
): number => {
  // fritt upplagd: = qd * L / 2
  if (beamType === BeamType.FreelyLaid) {
    return (dimLoadCombination * span) / 2;
  }
  throw new Error('Not implemented');
};

const getDimBendingMoment = (
  dimLoadCombination: number,
  beamType: BeamType,
  span: number,
): number => {
  // fritt upplagd: = qd_brott * L^2 / 8
  if (beamType === BeamType.FreelyLaid) {
    return (dimLoadCombination * Math.pow(span, 2)) / 8;
  }
  throw new Error('Not implemented');
};

/**
 * Böjmotstånd balktvärsnitt - W
 * @param widthMM
 * @param heightMM
 * @returns
 */
const getCrossSectionDeflectionResistance = (
  widthMM: number,
  heightMM: number,
): number => ((widthMM / 1000) * Math.pow(heightMM / 1000, 2)) / 6;

/**
 * I - böjtröghetsmomentet
 * https://sv.wikipedia.org/wiki/B%C3%B6jtr%C3%B6ghetsmoment
 * @returns
 */
const getSecondMomentOfArea = (widthMM: number, heightMM: number): number =>
  ((widthMM / 1000) * Math.pow(heightMM / 1000, 3)) / 12;

export const getStrength = (
  woodType: WoodType,
  woodStrengthClass: OneOfWoodStrengthClass,
  strengthType: StrengthType,
): number => getWoodTableValue(woodType, woodStrengthClass, strengthType);

export const getDimStrength = (
  charateristicStrength: number,
  kmod: number,
  partialCoef: number,
): number => (charateristicStrength * kmod) / partialCoef;

/**
 * Slutlig elasticitetsmodul - E_mean,fin
 */
export const getFinalEMean = (eMean: number, kdef: number): number =>
  eMean / (1 + kdef);

/**
 * Get shear force capacity in kN
 * A * Dim-skjuvHållf / 1.5
 */
const getShearForceCapacity = (
  effectiveWidth: number,
  heightMM: number,
  dimShearStrength: number,
): number =>
  (1000 * (effectiveWidth * (heightMM / 1000) * dimShearStrength)) / 1.5;

const getSviktByConcentratedLoad = (
  span: number,
  eMean: number,
  secondMomentOfArea: number,
): number =>
  (1000 * Math.pow(span, 3)) / (48 * eMean * secondMomentOfArea) / 1000;

const getSviktByPayload = (
  payload: number,
  centerToCenterMM: number,
  span: number,
  eMeanFinal: number,
  secondMomentOfArea: number,
): number =>
  (((5 * payload * centerToCenterMM) / 1000) * Math.pow(span, 4)) /
  (384 * eMeanFinal * secondMomentOfArea);

const getAcceptableSvikt = (span: number): number => (span * 1000) / 600;
/**
 *
 * @param resistance
 * @param dimDeflection
 * @returns kNm
 */
const getBendingMomentCapacity = (
  deflectionResistance: number,
  dimDeflectionStrength: number,
): number => deflectionResistance * dimDeflectionStrength * 1000;

export const getKDef = (
  woodType: WoodType,
  climateClass: WoodClimateClass,
): number => {
  const dataTable = [0.6, 0.8, 2];
  if (
    woodType === WoodType.Glulam ||
    woodType === WoodType.ConstructionTimber
  ) {
    const kdef = dataTable[climateClass - 1];
    if (kdef) {
      return kdef;
    }
  }
  throw new Error(
    `K_def not found for woodClass: ${woodType} and climateClass: ${climateClass}`,
  );
};

/**
 * Get effective beam width in meter
 * @returns
 */
const getEffectiveBeamWidth = (
  woodType: WoodType,
  climateClass: WoodClimateClass,
  charShearStrength: number,
  beamWidthMM: number,
): number =>
  getKCr(woodType, climateClass, charShearStrength) * (beamWidthMM / 1000);

// Böjmoment (dim)
export const dimTorque = (): number => {
  throw new Error('Not implemented');
};

// TODO fix type
// eslint-disable-next-line
export const getResults = (
  project: Project,
  element: IElement | IBuildingVersion,
  recipe: Recipe,
  quantitiesRecord: ConversionFactorQuantityRecord,
) => {
  if (isBuildingVersionElement(element)) {
    return;
  }

  const building = getBuilding(project);
  const meta = building.meta;
  const categoryId = element.category_id;
  const activityId = meta.activity_id;

  if (!activityId || !categoryId) {
    console.error('Activity or category not set');
    return;
  }

  const woodType = getBeamWoodType(categoryId, recipe.id);
  const centerToCenterMM = getPropertyCountNumberByName(
    element,
    ElementPropertyName.CenterToCenter,
  );
  const beamWidthMM = getPropertyCountNumberByName(
    element,
    ElementPropertyName.BeamWidth,
  );
  const beamHeightMM = getPropertyCountNumberByName(
    element,
    ElementPropertyName.BeamHeight,
  );
  const woodStrengthClass = getPropertyCountNumberByName(
    element,
    ElementPropertyName.WoodClass,
  );
  const climateClass = numberToClimateClass(
    getPropertyCountNumberByName(element, ElementPropertyName.ClimateClass),
  );

  const span =
    getPropertyCountNumberByName(element, ElementPropertyName.Span) ?? 10;

  // TODO MAke more dynamic
  const beamType = BeamType.FreelyLaid;

  if (
    typeof woodType !== 'number' ||
    !centerToCenterMM ||
    !beamWidthMM ||
    !beamHeightMM ||
    !span ||
    !isOneOfWoodStrengthClass(woodStrengthClass)
  ) {
    return;
  }

  const activity = getActivityById(activityId);
  const protectionClass = getProtectionClass();

  const shearStrength = getStrength(
    woodType,
    woodStrengthClass,
    StrengthType.Shear,
  );
  const deflectionStrength = getStrength(
    woodType,
    woodStrengthClass,
    StrengthType.DeflectionParallel,
  );

  const characteristicLoads = getCharacteristicLoads(
    activityId,
    categoryId,
    element,
    quantitiesRecord,
  );
  const elementLoads = getElementLoads(characteristicLoads, activityId);
  const dimLoadCombination = getDimLoadCombination(
    elementLoads,
    protectionClass,
    false,
  );
  const dimLoadCombinationPerMeter = getLoadCombinationPerMeterBeam(
    dimLoadCombination,
    centerToCenterMM,
  );
  const dimLoadCombinationServiceability = getDimLoadCombination(
    elementLoads,
    protectionClass,
    true,
  );
  const dimLoadCombinationServiceabilityPerMeter =
    getLoadCombinationPerMeterBeam(
      dimLoadCombinationServiceability,
      centerToCenterMM,
    );

  const partialCoefMaterial = getPartialCoefMaterial(woodType);
  const kdef = getKDef(woodType, climateClass);
  const partialCoefProtection = getPartialCoefProtection(protectionClass);
  const eMean = getStrength(
    woodType,
    woodStrengthClass,
    StrengthType.ElasticModulusParallel,
  );
  const eMeanFinal = getFinalEMean(eMean, kdef);

  const secondMomentOfArea = getSecondMomentOfArea(beamWidthMM, beamHeightMM);

  const kmod = getKMod(
    climateClass,
    woodType,
    ...elementLoads.map((l) => l.duration),
  );

  const dimShearStrength = getDimStrength(
    shearStrength,
    kmod,
    partialCoefMaterial,
  );
  const dimDeflectionStrength = getDimStrength(
    deflectionStrength,
    kmod,
    partialCoefMaterial,
  );

  const instantDeflection = getInstantDeflection(
    dimLoadCombinationServiceabilityPerMeter,
    span,
    eMean,
    kdef,
  );
  const creepingDeflection = 0;
  const overDeflection = 0;

  const acceptableDeflection = getAcceptableDeflection(
    BeamUsage.FloorBeam,
    span,
  );

  const totalDeflection = getTotalDeflectionOverTime(
    instantDeflection,
    creepingDeflection,
    overDeflection,
  );

  const sviktConcentrated = getSviktByConcentratedLoad(
    span,
    eMean,
    secondMomentOfArea,
  );
  const sviktPayload = getSviktByPayload(
    characteristicLoads.payload,
    centerToCenterMM,
    span,
    eMeanFinal,
    secondMomentOfArea,
  );
  const sviktAcceptable = getAcceptableSvikt(span);

  const kcr = getKCr(woodType, climateClass, shearStrength);
  const effectiveBeamWidth = getEffectiveBeamWidth(
    woodType,
    climateClass,
    shearStrength,
    beamWidthMM,
  );

  const deflectionResistance = getCrossSectionDeflectionResistance(
    beamWidthMM,
    beamHeightMM,
  );

  const dimShearForce = getDimShearForce(dimLoadCombination, beamType, span);

  const dimBendingMoment = getDimBendingMoment(
    dimLoadCombination,
    beamType,
    span,
  );

  const shearForceCapacity = getShearForceCapacity(
    effectiveBeamWidth,
    beamHeightMM,
    dimShearStrength,
  );
  const bendingMomentCapacity = getBendingMomentCapacity(
    deflectionResistance,
    dimDeflectionStrength,
  );

  return {
    activityClass: activity.class,
    protectionClass,
    woodType,
    kdef,
    partialCoefMaterial,
    partialCoefProtection,
    woodStrengthClass,
    shearStrength,
    climateClass,
    characteristicLoads,
    dimLoadCombination,
    dimLoadCombinationPerMeter,
    dimLoadCombinationServiceability,
    dimLoadCombinationServiceabilityPerMeter,
    kmod,
    kcr,
    eMean,
    eMeanFinal,
    dimShearStrength,
    secondMomentOfArea,
    instantDeflection,
    creepingDeflection,
    overDeflection,
    totalDeflection,
    acceptableDeflection,
    deflectionStrength,
    dimDeflectionStrength,
    effectiveBeamWidth,
    deflectionResistance,
    dimShearForce,
    shearForceCapacity,
    dimBendingMoment,
    bendingMomentCapacity,
    sviktAcceptable,
    sviktConcentrated,
    sviktPayload,
  };
};

// svikt
