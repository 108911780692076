import { useCallback } from 'react';
import { ConfirmOptions, useConfirm } from 'material-ui-confirm';

export const useIsConfirmed = (): ((
  options?: ConfirmOptions | string,
) => Promise<boolean>) => {
  const confirm = useConfirm();

  return useCallback(
    async (options) => {
      try {
        await confirm({
          ...toConfirmOptions(options),
          cancellationButtonProps: {
            TouchRippleProps: { style: { top: 'auto', bottom: 0, height: 4 } },
          },
          confirmationButtonProps: {
            ref: (ref) => ref?.focus(),
            variant: 'contained',
            TouchRippleProps: { style: { top: 'auto', bottom: 0, height: 4 } },
          },
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    [confirm],
  );
};

const toConfirmOptions = (
  options?: ConfirmOptions | string,
): ConfirmOptions | undefined =>
  typeof options === 'string' ? { description: options } : options;
