import React, {
  FC,
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
  FocusEvent,
} from 'react';
import { Box, IconButton, TextField, TextFieldProps } from '@mui/material';
import { Clear, Search } from '@mui/icons-material';
import { useDebounce } from '../hooks/hooks';

interface SearchFieldProps {
  value: string;
  onChange: (str: string) => void;
  debounce?: number;
}

const SearchField: FC<
  SearchFieldProps & Omit<TextFieldProps, 'onChange' | 'value'>
> = ({ onChange, debounce, value, ...props }) => {
  const [searchTerm, setSearchTerm] = useState(value);
  const debouncedSearchTerm = useDebounce(searchTerm, debounce || 300);
  useEffect(() => {
    onChange(debouncedSearchTerm);
  }, [debouncedSearchTerm, onChange]);

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setSearchTerm(e.target.value);
    },
    [setSearchTerm],
  );

  const handleClear = useCallback(() => setSearchTerm(''), [setSearchTerm]);

  const handleFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => e.target.select(),
    [],
  );

  const inputProps = useMemo(() => {
    return {
      endAdornment: (
        <IconButton size="small" onClick={handleClear}>
          <Clear />
        </IconButton>
      ),
      startAdornment: (
        <Box pr={1}>
          <Search />
        </Box>
      ),
    };
  }, [handleClear]);

  return (
    <TextField
      {...props}
      value={searchTerm}
      onChange={handleOnChange}
      InputProps={inputProps}
      autoFocus
      onFocus={handleFocus}
    />
  );
};

export default SearchField;
