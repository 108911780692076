import React, { useEffect } from 'react';
import { IElement } from '../../../../shared/models/project.interface';
import ElementCategory from '../../components/SidePanel/Element/ElementCategory';
import {
  systemCategoryIds,
  ElementCategoryID,
} from '../../../../shared/models/element_categories.interface';
import { ConcretePropertyName } from '../../../../shared/templates/categories/concrete/concrete.model';
import { ProductCategoryPropertyName } from '../../../../shared/templates/categories/processor.model';
import { useTempElement } from '../../hooks/temp-element.hook';
import { useOnce } from '../../hooks/hooks';
import Loading from '../../components/Loading';
import { useUnmount } from 'usehooks-ts';
import { ProductID } from '../../../../shared/models/product.interface';
import { Box, Typography } from '@mui/material';

const filterCategories = [...systemCategoryIds, ElementCategoryID.Labour];
const filterProperties = [
  ProductCategoryPropertyName.ReusedContent,
  ConcretePropertyName.ReinforcementSteel,
  ConcretePropertyName.StainlessReinforcement,
];

interface GenericProductSelectorProps {
  element?: IElement;
  showPrompt?: boolean;
  onProductChange?: (product_id?: ProductID) => void;
}

const GenericProductSelector: React.FC<GenericProductSelectorProps> = ({
  element,
  showPrompt,
  onProductChange,
}) => {
  const { tempElement, addTempElement, cleanupTempElement, productId } =
    useTempElement();

  // Add temp element on mount
  useOnce(() => addTempElement(element));

  // Cleanup temp element on unmount
  useUnmount(cleanupTempElement);

  useEffect(() => {
    if (onProductChange) {
      onProductChange(productId);
    }
  }, [onProductChange, productId]);

  if (!tempElement) {
    return <Loading></Loading>;
  }

  return (
    <Box>
      <Typography variant="subtitle2">Product Category</Typography>
      <ElementCategory
        element={tempElement}
        filterCategories={filterCategories}
        filterProperties={filterProperties}
      />
      {showPrompt && (
        <Typography variant="caption" color="error" pl={3.5}>
          Select a cateogry in order to save changes
        </Typography>
      )}
    </Box>
  );
};

export default GenericProductSelector;
