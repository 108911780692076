import {
  ConversionFactors,
  QuantityUnit,
  quantityUnits,
  SelectableQuantityUnit,
  selectableQuantityUnits,
  CO2eUnit,
  co2eUnits,
} from '../models/unit.interface';
import { applyCalculatedConversionFactors } from './conversion_helpers';
import { getKeys } from './object_helpers';

export const isCO2eUnit = (unit: unknown): unit is CO2eUnit => {
  return co2eUnits.includes(unit as CO2eUnit);
};

/**
 * Check if unit is a valid QuantityUnit
 * @param unit
 * @returns
 */
export const isQuantityUnit = (unit: unknown): unit is QuantityUnit =>
  quantityUnits.includes(unit as QuantityUnit);

export const isSelectableQuantityUnit = (
  unit: unknown,
): unit is SelectableQuantityUnit => {
  return selectableQuantityUnits.includes(unit as SelectableQuantityUnit);
};

/**
 * Convert external units into Quantity Units
 * @param quantity
 * @param raw_unit
 * @returns
 */
export const unitHarmonizer = (
  quantity: number,
  raw_unit: string,
): { quantity: number; unit: QuantityUnit } | null => {
  switch (raw_unit?.toLowerCase()) {
    case 'm3':
    case 'm^3':
    case 'm³':
      return {
        quantity,
        unit: 'm³',
      };
    case 'm2':
    case 'm^2':
    case 'm²':
      return {
        quantity,
        unit: 'm²',
      };
    case 'omg': // seen as 'Kopiering', might mean 'Omgångar' ???
    case 'st':
    case 'stk':
    case 'pcs':
      return {
        quantity,
        unit: 'pcs',
      };
    case 'm':
      return {
        quantity,
        unit: 'm',
      };
    case 'kg':
      return {
        quantity,
        unit: 'kg',
      };
    case 'ton':
      return {
        quantity: quantity * 1000,
        unit: 'kg',
      };
    case 'l':
      return {
        quantity: quantity / 1000,
        unit: 'm³',
      };
    case 'kwh':
      return {
        quantity: quantity * 3.6,
        unit: 'MJ',
      };
    case 'mj':
      return {
        quantity,
        unit: 'MJ',
      };
    default:
      return isQuantityUnit(raw_unit)
        ? {
            quantity,
            unit: raw_unit,
          }
        : null;
  }
};

/**
 * Convert external units into Quantity Units
 * @param raw_unit string
 * @returns SelectableQuantityUnit | undefined
 */
export const selectableUnitHarmonizer = (
  raw_unit: string,
): SelectableQuantityUnit | undefined => {
  switch (raw_unit?.toLowerCase()) {
    case 'm3':
    case 'm^3':
    case 'm³':
      return 'm³';
    case 'm2':
    case 'm^2':
    case 'm²':
      return 'm²';
    case 'kwh':
      return 'kWh';
    case 'mj':
      return 'MJ';
    default:
      return isSelectableQuantityUnit(raw_unit) ? raw_unit : undefined;
  }
};

export const getSelectableUnitsInConversionFactors = (
  conversionFactors: ConversionFactors,
): SelectableQuantityUnit[] =>
  getKeys(applyCalculatedConversionFactors(conversionFactors)).filter(
    isSelectableQuantityUnit,
  );
