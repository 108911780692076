import { sortBy } from 'lodash';
import genericProducts from '../../../generic_products';
import {
  IElementCategory,
  ElementCategoryID,
} from '../../../models/element_categories.interface';
import {
  ElementPropertyInputType,
  IFactorySelectProperty,
} from '../../../models/element_property.interface';
import { DEFAULT_QUANTITY_PROPERTIES } from '../../../models/element_quantities.interface';
import { createProductTreeProcessor } from '../processor';
import { pick } from '../../../helpers/object_helpers';

export const energyProducts = genericProducts.filter(
  ({ categories }) => categories.Boverket?.['Energy and fuel'],
);

const sorted = sortBy(energyProducts, 'name');

const selectProperty: IFactorySelectProperty = {
  name: 'products',
  type: ElementPropertyInputType.Select,
  count: sorted[0].name,
  options: sorted.map(({ name }) => name),
};

const processor = createProductTreeProcessor({
  levelProperties: [selectProperty],
  productTree: energyProducts.reduce(
    (acc, { id, name }) => ({ ...acc, [name]: id }),
    {},
  ),
});

export const energy: IElementCategory = {
  ...processor,
  id: ElementCategoryID.Energy,
  name: 'Energy',
  color: 'rgba(255, 197, 2, 1)',
  defaultCount: 'energy',
  defaultUnit: 'MJ',
  getQuantityProperties: () => pick(DEFAULT_QUANTITY_PROPERTIES, 'energy'),
};
