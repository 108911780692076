import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import {
  IElement,
  IProductElement,
} from '../../../../../../../shared/models/project.interface';
import { useSelectedVersion } from '../../../../../store/ui';
import ExpressionInput from '../../../../ExpressionInput';
import { getExpression } from '../../../../../../../shared/helpers/expression_solving_helpers';
import { selectableUnitHarmonizer } from '../../../../../../../shared/helpers/unit_helpers';
import ProductSelector from '../../../../../projects/EditProject/ProductSelector';
import {
  useProductsLookup,
  useProductStoreState,
} from '../../../../../store/product';
import {
  setEPDIdsAppliedToGenericProduct,
  useEPDIdsAppliedToGenericProduct,
  useProductSwitch,
} from '../../../../../hooks/product-switch.hook';
import { makeStyles } from 'tss-react/mui';
import { EllipsisText } from '../../../../EllipsisText';
import EPDMenu from './EPDMenu';
import { useUpdateProductInVersion } from '../../../../../hooks/useProducts';
import { ProductID } from '../../../../../../../shared/models/product.interface';
import { openEditProductDialog } from '../../../../../projects/EditProject/EditProductDialog';
import { required } from '../../../../../../../shared/helpers/function_helpers';
import { isDefined } from '../../../../../../../shared/helpers/array_helpers';

const PRODUCT_NAME_MAX_LENGTH = 30;

interface ElementChildAutoGeneratedProps {
  parent: IElement;
  productElement: IProductElement;
}

const ElementChildAutoGenerated: React.FC<ElementChildAutoGeneratedProps> = ({
  parent,
  productElement,
}) => {
  const { classes } = useStyles();
  const { updateProduct } = useProductStoreState('updateProduct');
  const selectedVersion = required(useSelectedVersion());

  const { count, unit, product_id, generic_product_id } = productElement;
  const genericProductId = generic_product_id ?? product_id;

  const productsLookup = useProductsLookup();
  const updateProductInVersion = useUpdateProductInVersion();

  const {
    isProductSelectorOpen,
    openProductSelector,
    closeProductSelector,
    switchProducts,
    clearEPD,
  } = useProductSwitch(productElement);

  const genericProduct = useMemo(
    () =>
      selectedVersion.products[genericProductId] ??
      productsLookup[genericProductId],
    [productsLookup, genericProductId, selectedVersion.products],
  );

  const appliedEPD = useMemo(
    () =>
      generic_product_id ? selectedVersion.products[product_id] : undefined,
    [generic_product_id, product_id, selectedVersion.products],
  );

  const epdIdsAppliedToGenericProduct =
    useEPDIdsAppliedToGenericProduct(genericProductId);

  const mappedEPDs = useMemo(() => {
    const epdsMappedInLocalstorage =
      epdIdsAppliedToGenericProduct?.map((id) => productsLookup[id]) ?? [];

    const epdsMappedInDatabase = Object.values(productsLookup).filter(
      ({ generic_id }) => generic_id === genericProductId,
    );

    const unique = new Set([
      ...epdsMappedInLocalstorage,
      ...epdsMappedInDatabase,
    ]);

    return [...unique].filter(isDefined);
  }, [epdIdsAppliedToGenericProduct, genericProductId, productsLookup]);

  const [epdMenuValue, setEPDMenuValue] = useState(appliedEPD?.id ?? '');

  const removeEPDMapping = useCallback(
    (id: ProductID) => {
      // remove EPD mapping from localstorage
      const filteredIds =
        epdIdsAppliedToGenericProduct?.filter((epdId) => epdId !== id) ?? [];
      setEPDIdsAppliedToGenericProduct(genericProductId, filteredIds);

      // remove EPD mapping from element
      if (id === appliedEPD?.id) clearEPD();

      const epd = mappedEPDs.find((epd) => epd.id === id);
      if (!epd?.generic_id) return;

      const unmappedEPD = { ...epd, generic_id: undefined };

      // remove EPD mapping from product in version.products
      updateProductInVersion(unmappedEPD);

      // remove EPD mapping from product in database
      if (epd.generic_id === genericProductId) {
        updateProduct(unmappedEPD);
      }
    },
    [
      appliedEPD?.id,
      clearEPD,
      genericProductId,
      mappedEPDs,
      updateProduct,
      updateProductInVersion,
      epdIdsAppliedToGenericProduct,
    ],
  );

  const createNewProduct = useCallback(async () => {
    const product = await openEditProductDialog({
      parent: parent,
      duplicate: true,
    });

    // If not cancelled
    if (product) {
      switchProducts(product.id);
    }
  }, [parent, switchProducts]);

  const selectEPD = useCallback(
    async (id: string) => {
      if (id === 'other') return openProductSelector();
      if (id === 'new') return createNewProduct();
      if (id === 'none') {
        setEPDMenuValue('');
        return clearEPD();
      }
      // menuItem is the id of the selected product
      switchProducts(id)
        .then(() => setEPDMenuValue(id))
        .catch(() => setEPDMenuValue(appliedEPD?.id ?? ''));
    },
    [
      appliedEPD?.id,
      clearEPD,
      createNewProduct,
      openProductSelector,
      switchProducts,
    ],
  );

  const handleProductSelectorSave = useCallback(
    (pid: string, gid?: string, isSelectedEPD = false) => {
      if (isSelectedEPD || genericProductId === gid) {
        switchProducts(pid);
        closeProductSelector();
      }
    },
    [closeProductSelector, genericProductId, switchProducts],
  );

  useEffect(() => {
    if (appliedEPD) {
      setEPDMenuValue(appliedEPD.id);
    }
  }, [appliedEPD]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography width="60%" variant="body2" className={classes.text}>
          <EllipsisText
            showTooltip={genericProduct.name.length > PRODUCT_NAME_MAX_LENGTH}
          >
            {genericProduct.name}
          </EllipsisText>
        </Typography>
        <Box width="40%" display="flex" justifyContent="flex-end">
          <ExpressionInput
            id={productElement.id}
            expressionValue={getExpression(count)}
            unit={selectableUnitHarmonizer(unit)}
            disabled
          />
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        width="100%"
      >
        <EPDMenu
          menuValue={epdMenuValue}
          epds={mappedEPDs}
          onMenuChange={selectEPD}
          onRemoveMappingClick={removeEPDMapping}
        />
      </Box>

      {isProductSelectorOpen && (
        <ProductSelector
          keepOpen
          open={isProductSelectorOpen}
          parent={parent}
          productId={appliedEPD?.id}
          defaultSource="custom"
          onSave={handleProductSelectorSave}
          onClose={closeProductSelector}
        />
      )}
    </>
  );
};

const useStyles = makeStyles()(({ palette }) => ({
  text: {
    display: 'flex',
    fontSize: 13,
    fontWeight: 500,
  },
  discrete: { color: palette.text.disabled },
}));

export default ElementChildAutoGenerated;
