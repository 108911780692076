import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  IconButton,
  OutlinedTextFieldProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { OneOfPropertyElements } from '../../../../../../shared/models/project.interface';
import { Delete } from '@mui/icons-material';
import InlineTextField from '../../../InlineTextField';

import { IElementProperty } from '../../../../../../shared/models/element_property.interface';
import { useIsAddedProperty, useUIState } from '../../../../store/ui';
import { useElementPropertiesUtils } from '../../../../hooks/element-properties.hook';
import { useBooleanState, useTrigger } from '../../../../hooks/hooks';
import {
  getPropertyDisplayName,
  isElementExpressionProperty,
  isElementSelectProperty,
  isElementSwitchProperty,
  isFullyEditableProperty,
} from '../../../../../../shared/helpers/element_property_helpers';
import { makeStyles } from 'tss-react/mui';
import {
  getElementQuantityFormattedName,
  isElementQuantityRepeatingChildName,
} from '../../../../../../shared/helpers/element_quantity_helpers';
import ElementPropertySelectInput from './ElementPropertySelect';
import ElementPropertySwitchInput from './ElementPropertySwitch';
import ElementPropertyExpression from './ElementPropertyExpression';
import {
  ElementQuantityName,
  IconQuantityName,
  iconQuantityNames,
} from '../../../../../../shared/models/element_quantities.interface';
import { quantityIcons } from '../../../Icon';

interface ElementPropertyProps {
  property: IElementProperty;
  element: OneOfPropertyElements;
  hideDeleteColumn?: boolean;
  hideNameColumn?: boolean;
  hideTooltip?: boolean;
}

const ElementProperty: FC<ElementPropertyProps> = ({
  property,
  element,
  hideDeleteColumn,
  hideNameColumn,
  hideTooltip,
}) => {
  const { cx, classes } = useStyles();
  const { id } = property;

  const { setAddedPropertyId } = useUIState('setAddedPropertyId');
  const { removeProperty, updateProperty } = useElementPropertiesUtils(element);

  const deleteProperty = useTrigger(removeProperty, id);
  const autoFocus = useIsAddedProperty(property);
  const isEditableProperty = isFullyEditableProperty(property);

  const [isEditingName, beginEditingName, endEditingName] =
    useBooleanState(autoFocus);

  const [nameIsInvalid, setNameIsInvalid] = useState(false);

  const [inputErrorMessage, setInputErrorMessage] = useState<
    string | undefined
  >();

  const errorMessage =
    nameIsInvalid && isEditableProperty ? 'Name is invalid' : inputErrorMessage;

  const tooltipTitle = getElementQuantityFormattedName(property.name);

  const isRepeatingChild = isElementQuantityRepeatingChildName(property.name);

  const isIconProperty = iconQuantityNames.includes(
    property.name as IconQuantityName,
  );

  const savePropertyName = useCallback(
    async (name: string) => {
      if (!nameIsInvalid || isEditableProperty) {
        if (!nameIsInvalid) {
          await updateProperty({ ...property, name });
        }
        endEditingName();
      }
    },
    [
      endEditingName,
      isEditableProperty,
      nameIsInvalid,
      property,
      updateProperty,
    ],
  );

  useEffect(() => {
    if (autoFocus) {
      beginEditingName();
    }
  }, [autoFocus, beginEditingName]);

  const textFieldProps = useMemo<Partial<OutlinedTextFieldProps>>(
    () => ({
      autoFocus,
      onFocus: (e) => {
        autoFocus && e.currentTarget.select();
        setAddedPropertyId();
      },
    }),
    [autoFocus, setAddedPropertyId],
  );

  const input = useMemo(() => {
    /* 
    If the name is being edited, 
    make it readonly in case the value input is clicked before the name has been saved.
    */
    const editableProperty = isEditingName
      ? { ...property, readonly: true }
      : property;

    if (isElementExpressionProperty(editableProperty)) {
      return (
        <ElementPropertyExpression
          key={editableProperty.id}
          property={editableProperty}
          element={element}
          onErrorMessageChange={setInputErrorMessage}
        />
      );
    } else if (isElementSelectProperty(editableProperty)) {
      return (
        <ElementPropertySelectInput
          key={property.id}
          property={editableProperty}
          element={element}
        />
      );
    } else if (isElementSwitchProperty(editableProperty)) {
      return (
        <ElementPropertySwitchInput
          key={editableProperty.id}
          property={editableProperty}
          element={element}
        />
      );
    }
  }, [element, isEditingName, property]);

  return (
    <>
      <Tooltip
        title={isIconProperty && tooltipTitle}
        placement="left"
        disableInteractive
      >
        <Box
          className={cx(
            classes.container,
            hideDeleteColumn ? classes.hideDeleteColumn : null,
            hideNameColumn ? classes.hideNameColumn : null,
            isRepeatingChild ? classes.child : null,
            isIconProperty ? classes.showIcon : null,
          )}
        >
          {/* NAME INPUT */}
          {!hideNameColumn && !isIconProperty ? (
            <InlineTextField
              value={getPropertyDisplayName(property)}
              variant="body2"
              editing={isEditingName}
              disabled={!isEditableProperty}
              onClick={beginEditingName}
              onCancel={endEditingName}
              onSave={savePropertyName}
              error={!!nameIsInvalid}
              setNameIsInvalid={setNameIsInvalid}
              displayBorder={false}
              textFieldProps={textFieldProps}
            />
          ) : (
            <Box display="flex">
              {quantityIcons[property.name as ElementQuantityName]}
            </Box>
          )}

          {/* INPUT */}
          <Box className={classes.input}>
            <Tooltip
              title={!hideTooltip && !isIconProperty && tooltipTitle}
              placement="right"
              disableInteractive
            >
              <Box
                width="100%"
                display="flex"
                justifyContent={isIconProperty ? 'flex-start' : 'flex-end'}
              >
                {input}
              </Box>
            </Tooltip>
          </Box>

          {/* DELETE ICON */}
          {!hideDeleteColumn && (
            <Box className={classes.deleteIcon}>
              {isEditableProperty && (
                <Tooltip title="Delete property">
                  <IconButton size="small" onClick={deleteProperty}>
                    <Delete fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )}
        </Box>
      </Tooltip>

      {/* ERROR MESSAGE */}
      {errorMessage && (
        <Typography
          className={classes.errorMessage}
          color="error"
          variant="caption"
        >
          {errorMessage}
        </Typography>
      )}
    </>
  );
};

export const useStyles = makeStyles<void, 'deleteIcon'>()(
  ({ spacing }, _params, classes) => ({
    container: {
      // display: 'grid',
      // gridAutoFlow: 'column',
      // gridTemplateColumns: 'auto auto 30px',

      // wrap: 'nowrap',
      // alignItems: 'center',
      // justifyContent: 'space-between',
      // gap: spacing(1),

      display: 'flex',

      minWidth: 0,
      paddingTop: spacing(1),

      [`&:hover .${classes.deleteIcon}`]: {
        opacity: 1,
      },
    },
    hideDeleteColumn: {
      // gridTemplateColumns: 'auto',
    },
    hideNameColumn: {
      // display: 'block',
    },
    showIcon: {
      width: '50%',
      gridTemplateColumns: '20px auto',
      justifyContent: 'start',
      alignItems: 'center',
      gap: spacing(2),
    },
    child: {
      paddingLeft: spacing(3),
      backgroundColor: 'rgba(0, 0, 0, 0.05)',

      '&:last-of-type': {
        borderRadius: '5px',
      },
    },
    input: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    deleteIcon: {
      opacity: 0,
      transition: 'opacity 0.2s ease',
    },
    errorMessage: {
      gridRow: 2,
      gridColumn: '1 / -1',
    },
  }),
);

export default ElementProperty;
