import React, { FC, ReactNode } from 'react';
import { useIsReadonly } from '../hooks/user.hook';
import { Box } from '@mui/material';
import { NodonTheme } from '../style';

const ReadonlyBlocker: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const readonly = useIsReadonly();

  if (readonly) {
    return (
      <>
        <Box sx={READONLY_BLOCKER_STYLE} />
        {children}
      </>
    );
  }
  return children;
};

const READONLY_BLOCKER_STYLE: React.CSSProperties = {
  position: 'absolute',
  inset: 0,
  marginTop: 15,
  zIndex: NodonTheme.zIndex.readonlyBlocker,
} as const;

export default ReadonlyBlocker;
