import sizeof from 'object-sizeof';
import {
  IElementID,
  Project,
  ProjectMetadata,
} from '../../shared/models/project.interface';
import { compareObjectsWithAllKeys, getId } from './object_helpers';
import { last } from 'lodash';
import {
  getModifiedElements,
  getProjectMeta,
  getVersionById,
  isProjectsEqual,
} from './project_helpers';

const TEMPORAL_CHANGE_ANIMATION_LENGTH = 1500;
const MAX_UNDO_REDO_STATES = 20;
const UNDO_REDO_BYTE_LIMIT = 20000000;

export const undoRedoAnimation = {
  animation: `temporal-change ${TEMPORAL_CHANGE_ANIMATION_LENGTH}ms 1`,
  '@keyframes temporal-change': {
    '0%': {
      backgroundColor: 'initial',
    },
    '50%': {
      backgroundColor: '#d5d9e8',
    },
    '100%': {
      backgroundColor: 'initial',
    },
  },
} as const;

export type UndoRedoActionType = 'undo' | 'redo';

export interface IUndoRedoState {
  project: Project;

  /**
   * To highlight the element that was reset
   */
  changedElementId?: string;

  /**
   * For Geometry and Activities we use a selector instead of an id to highlight the changes
   */
  selector?: string;
}

/**
 * This state is used to decide if anything have changed or not and if we should add undo/redo state to the history
 */
export interface IUndoRedoInputState {
  project: Project;
  selectedVersionId?: IElementID;
}

export interface ITemporalStates {
  currentAction?: UndoRedoActionType;
  currentState?: IUndoRedoState;
  pastStates: IUndoRedoState[];
  futureStates: IUndoRedoState[];
}

interface IMetadataChange {
  id?: string;
  activityIndex?: number;
}

const projectTemporal: ITemporalStates = {
  pastStates: [],
  futureStates: [],
};

/**
 * Get changes to metadata
 * @param prevProject
 * @param updatedProject
 * @returns
 */
export const getMetadataChange = (
  metaPrev: ProjectMetadata,
  metaCurrent: ProjectMetadata,
): IMetadataChange => {
  if (metaCurrent === metaPrev) {
    return {};
  }

  const changedSettings = compareObjectsWithAllKeys(
    metaPrev as unknown as Record<string, unknown>,
    metaCurrent as unknown as Record<string, unknown>,
  );

  if (
    metaPrev.activities &&
    metaCurrent.activities &&
    metaPrev.activities.length !== metaCurrent.activities.length
  ) {
    return { id: 'activities' };
  }

  if (!changedSettings || changedSettings.length === 0) {
    return {};
  }

  if (changedSettings.includes('storeys') && changedSettings.length > 1) {
    if (changedSettings.includes('building_footprint')) {
      return { id: 'gfa_and_perimeter' };
    }

    if (changedSettings.includes('gfa_building')) {
      return { id: 'gfa_building' };
    }

    return { id: 'storeys' };
  }

  if (changedSettings.includes('activities')) {
    const activityIndex = metaPrev.activities?.findIndex((activity, index) => {
      const changedActivityKeys = compareObjectsWithAllKeys(
        activity as unknown as Record<string, unknown>,
        (metaCurrent.activities?.[index] ?? {}) as unknown as Record<
          string,
          unknown
        >,
      );

      return changedActivityKeys.length;
    });

    if (activityIndex !== undefined && activityIndex >= 0) {
      return { id: 'activities', activityIndex };
    }
  }

  return { id: changedSettings[0] };
};

export const getRedoState = (): IUndoRedoState | undefined =>
  projectTemporal.futureStates?.[0];

export const getUndoState = (): IUndoRedoState | undefined =>
  last(projectTemporal.pastStates);

/**
 * Remove the latest undo state and return it
 */
const removeLatestUndoState = (): IUndoRedoState | undefined =>
  projectTemporal.pastStates.pop();

/**
 * Remove the latest redo state and return it
 */
const removeLatestRedoState = (): IUndoRedoState | undefined =>
  projectTemporal.futureStates.shift();

const addUndoState = (state: IUndoRedoState) => {
  const { futureStates, pastStates } = projectTemporal;
  const length = pastStates.length + futureStates.length;
  const size = sizeof(projectTemporal);

  // Remove the oldest state if the byte or or count limit is reached
  if (size >= UNDO_REDO_BYTE_LIMIT || length >= MAX_UNDO_REDO_STATES) {
    pastStates.shift();
  }
  return pastStates.push(state);
};

const addRedoState = (state: IUndoRedoState): void => {
  const { futureStates, pastStates } = projectTemporal;
  const length = pastStates.length + futureStates.length;
  const size = sizeof(projectTemporal);

  // Remove the oldest state if the byte or or count limit is reached
  if (size >= UNDO_REDO_BYTE_LIMIT || length >= MAX_UNDO_REDO_STATES) {
    futureStates.pop();
  }
  futureStates.unshift(state);
};

export const shouldPreventOldGFASubmitAfterCoordinationChange = (
  dirtyValue?: number,
  isDirty?: boolean,
): boolean => {
  const undoState = getUndoState();

  if (undoState) {
    const pastMeta = getProjectMeta(undoState.project);
    const pastGFA = pastMeta.gfa_building;

    if (isDirty && dirtyValue !== undefined && dirtyValue === pastGFA) {
      return true;
    }
  }

  return false;
};

const getActionType = (
  updatedProject: Project,
): UndoRedoActionType | undefined => {
  const undoState = getUndoState();
  const redoState = getRedoState();

  if (undoState && isProjectsEqual(undoState.project, updatedProject)) {
    return 'undo';
  }
  if (redoState && isProjectsEqual(redoState.project, updatedProject)) {
    return 'redo';
  }
};

/**
 * Store new undo/redo states
 * @param prevProject
 * @param updatedProject
 * @returns
 */
export const updateUndoRedoStates = (
  prev: IUndoRedoInputState,
  next: IUndoRedoInputState,
): void => {
  const nextVersionId = next.selectedVersionId;
  const prevVersionId = prev.selectedVersionId;

  // Reset history if the user has changed version
  if (!nextVersionId || !prevVersionId || nextVersionId !== prevVersionId) {
    return resetUndoRedoHistory();
  }

  // No changes at all
  if (isProjectsEqual(prev.project, next.project)) {
    return;
  }

  const actionType = getActionType(next.project);
  const changedElementId = getRecentlyUndoRedoElementId(
    prev.project,
    next.project,
    nextVersionId,
  );

  const newState: IUndoRedoState = {
    project: next.project,
    changedElementId,
  };

  // If the project was restored by an undo
  if (actionType === 'undo') {
    removeLatestUndoState();
    return addRedoState(newState);
  }
  // Restored by a redo
  if (actionType === 'redo') {
    removeLatestRedoState();
  }

  addUndoState(newState);
};

/**
 * Get an elementId or the id of the dom-element in settings panel.
 * Used to highlight the element that was changed by undo/redo
 * @param prevProject
 * @param nextProject
 * @param selectedVersionId
 * @returns
 */
const getRecentlyUndoRedoElementId = (
  prevProject: Project,
  nextProject: Project,
  selectedVersionId: IElementID,
): string | undefined => {
  const updatedVersion = getVersionById(nextProject, selectedVersionId);
  const prevVersion = getVersionById(prevProject, selectedVersionId);

  const metaChange = getMetadataChange(
    getProjectMeta(prevProject),
    getProjectMeta(nextProject),
  );

  if (metaChange.id) {
    return typeof metaChange.activityIndex === 'number'
      ? `activity_${metaChange.activityIndex}`
      : metaChange.id;
  }

  // Metadata changes triggers a lot of elements to change so ignore them
  const modifiedIds = getModifiedElements(updatedVersion, prevVersion).map(
    getId,
  );

  return modifiedIds[0];
};

export const resetUndoRedoHistory = (): void => {
  projectTemporal.pastStates = [];
  projectTemporal.futureStates = [];
};
