import { IProjectFolder } from '../models/folder.interface';
import {
  ElementKind,
  IProjectInfo,
  OneOfProjectListElements,
} from '../models/project.interface';
import { ItemOrItemId } from '../models/type_helpers.interface';
import { getId } from './object_helpers';
import { getElementKind } from './recursive_element_helpers';

export const isProjectInfoOrFolder = (
  element: unknown,
): element is IProjectInfo | IProjectFolder =>
  isProjectInfo(element) || isProjectFolder(element);

export const isProjectInfo = (element?: unknown): element is IProjectInfo =>
  getElementKind(element) === ElementKind.ProjectInfo;

export const isProjectFolder = (element?: unknown): element is IProjectFolder =>
  getElementKind(element) === ElementKind.ProjectFolder;

export const getProjectParentFolder = (
  list: OneOfProjectListElements[],
  item: OneOfProjectListElements,
): IProjectFolder | undefined => {
  const parentId = item.parent_id;
  const parent = parentId
    ? list.find((item) => item.id === parentId)
    : undefined;
  if (parent && !isProjectFolder(parent)) {
    throw new Error('Parent is not a folder');
  }
  return parent;
};

/**
 * Get path to project/folder based on parent_id.
 * @param list
 * @param itemOrId
 * @returns Array of parent folders starting from the root folder
 */
export const getProjectFolderPath = (
  list: OneOfProjectListElements[],
  itemOrId: ItemOrItemId<OneOfProjectListElements>,
): IProjectFolder[] => {
  const id = getId(itemOrId);
  const result: IProjectFolder[] = [];
  let current = list.find((item) => item.id === id);

  while (current) {
    if (isProjectFolder(current)) {
      // If it's already added to the result, it means we have infinite recursion (circular reference)
      if (result.includes(current)) {
        throw new Error('Infinite folder recursion');
      }
      result.unshift(current);
    }

    current = getProjectParentFolder(list, current);
  }

  return result;
};

/**
 * Make a tree of folders and projects based on parent_id.
 * @param items
 * @returns
 */
export const getProjectFolderTree = (
  items: OneOfProjectListElements[],
): OneOfProjectListElements[] => {
  const rootItems: OneOfProjectListElements[] = [];
  const projects = items.filter(isProjectInfo);
  // Make sure to create new folders to not modify the original data and to trigger rerenders throughout the tree
  const folders: IProjectFolder[] = items
    .filter(isProjectFolder)
    .map((folder) => ({ ...folder, elements: [] }));

  [...projects, ...folders].forEach((item) => {
    const parent = getProjectParentFolder(folders, item);
    if (parent) {
      parent.elements!.push(item);
    } else {
      rootItems.push(item);
    }
  });

  folders.forEach(({ elements }) => elements!.sort(folderSortFn));

  return rootItems.sort(folderSortFn);
};

const folderSortFn = (
  a: OneOfProjectListElements,
  b: OneOfProjectListElements,
) => {
  const aKind = getElementKind(a);
  const bKind = getElementKind(b);
  // Sort the same kind after updated_at
  if (aKind === bKind) {
    // Projects => ewest first
    if (aKind === ElementKind.ProjectInfo) {
      return a.created_at > b.created_at ? -1 : 1;
    }
    // Folders => alphabetically
    else if (aKind === ElementKind.ProjectFolder) {
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    }
  }

  // Folders should appear before projects
  return aKind === ElementKind.ProjectFolder ? -1 : 1;
};
