import React, { ChangeEvent, FC, useCallback, useMemo } from 'react';
import {
  MenuItem,
  OutlinedInputProps,
  TextField,
  Typography,
  useTheme,
  Divider,
} from '@mui/material';
import {
  IElement,
  OneOfPropertyElements,
} from '../../../../../../shared/models/project.interface';
import { IElementSelectProperty } from '../../../../../../shared/models/element_property.interface';
import { FormattedMessage } from 'react-intl';
import { useElementPropertiesUtils } from '../../../../hooks/element-properties.hook';
import { makeStyles } from 'tss-react/mui';
import { isElementQuantitySelectProperty } from '../../../../../../shared/helpers/element_quantity_helpers';
import { useUpdateQuantity } from '../../../../hooks/quantity-properties.hook';
import { ConcreteType } from '../../../../../../shared/templates/categories/concrete/concrete.model';
import { BoardType } from '../../../../../../shared/templates/categories/gypsum/gypsum.model';
import { InsulationMaterial } from '../../../../../../shared/templates/categories/insulation/insulation.model';
import { WoodType } from '../../../../../../shared/templates/categories/wood/wood.model';
import { useIsReadonly } from '../../../../hooks/user.hook';
import { isElement } from '../../../../../../shared/helpers/recursive_element_helpers';
import { otherNodonProduct } from '../../../../../../shared/templates/categories/other';

interface ElementPropertySelectProps {
  property: IElementSelectProperty;
  element: OneOfPropertyElements;
}

const ElementPropertySelectInput: FC<ElementPropertySelectProps> = ({
  property,
  element,
}) => {
  const { classes } = useStyles();
  const { count } = property;

  const { updateProperty } = useElementPropertiesUtils(element);
  const updateQuantity = useUpdateQuantity();

  const readonly = useIsReadonly();

  const update = useCallback(
    async (
      modified: Partial<IElementSelectProperty>,
    ): Promise<IElement | undefined> => {
      if (isElement(element) && isElementQuantitySelectProperty(property)) {
        await updateQuantity(element, [
          {
            ...modified,
            name: property.name,
          },
        ]);
      } else {
        return updateProperty({ ...property, ...modified }).then(
          ({ element }) => element,
        );
      }
    },
    [updateQuantity, updateProperty, property, element],
  );

  const savePropertyCount = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;

      if (newValue && newValue !== count) {
        update({ count: newValue });
      }
    },
    [count, update],
  );

  const { typography } = useTheme();

  const variantStyle: Partial<typeof Typography> = useMemo(() => {
    const { fontFamily, fontWeight, fontSize, lineHeight, letterSpacing } =
      typography['body2'];
    return { fontFamily, fontWeight, fontSize, lineHeight, letterSpacing };
  }, [typography]);

  const inputProps: OutlinedInputProps = useMemo(() => {
    return {
      classes: {
        input: classes.input,
        notchedOutline: classes.noborder,
      },
      style: variantStyle,
    };
  }, [classes.input, classes.noborder, variantStyle]);

  return (
    <TextField
      disabled={readonly}
      select
      size="small"
      inputProps={{ multiple: false }}
      variant="outlined"
      placeholder="Not set"
      value={count}
      onChange={savePropertyCount}
      InputProps={inputProps}
    >
      {property.options?.map((option) => {
        return [
          // Add a divider before certain options
          dividerItems.includes(
            option.value as (typeof dividerItems)[number],
          ) && <Divider key={'divider-' + option.label} />,

          <MenuItem value={option.value} key={option.label}>
            <FormattedMessage
              id={`element_property_select.option_label-${option.label}`}
              defaultMessage={option.label}
            />
          </MenuItem>,

          otherNodonProduct && option.value === otherNodonProduct.name && (
            <Divider key={otherNodonProduct.id} />
          ),
        ];
      })}
    </TextField>
  );
};

const dividerItems = [
  ConcreteType.Other,
  ConcreteType.BalconyAndStairs,
  ConcreteType.Columns,
  BoardType.CelluloseFibre,
  InsulationMaterial.EPS,
  WoodType.OSB,
];

const useStyles = makeStyles()(({ spacing }) => ({
  input: {
    padding: spacing(2),
  },
  noborder: {
    borderColor: 'transparent',
  },
}));

export default ElementPropertySelectInput;
