import { ModelTimestamps } from './base.interface';
import { CO2eConversionFactors, QuantityUnit } from './unit.interface';
import { OneOfChildElements } from './project.interface';
import {
  IElementProperty,
  IFactoryProperty,
} from './element_property.interface';
import { ElementCategoryID } from './element_categories.interface';
import { Replace } from './type_helpers.interface';
import { OneOfChildFactoryElements } from '../helpers/element_factory_helpers';

export type RecipeID = string;
export type UnitConversions = Partial<Record<QuantityUnit, number>>;

/**
 * Set of IDs on recipes currently hardcoded by us
 */
export enum NodonRecipeID {
  FramingGlulam = 'framing-glulam',
  FramingConstructionTimber = 'framing-construction-timber',
}

export interface Recipe extends ModelTimestamps {
  id: RecipeID | NodonRecipeID;
  name: string;
  owner?: string;
  organizations?: string[];
  unit: QuantityUnit;
  description?: string;

  elements: OneOfChildElements[];
  properties: IElementProperty[];

  /**
   * The ID of the element category this recipe belongs to.
   */
  category_id: ElementCategoryID;

  /**
   * A snapshot of the properties of the element category at the time of creation.
   * Can be used to filter recipes based on the properties of the element category.
   */
  category_property_value_record: Record<string, string | number | boolean>;

  results?: CO2eConversionFactors;
}

export type RecipeRecord = Record<RecipeID, Recipe>;

export type IFactoryRecipe = Partial<
  Replace<
    Recipe,
    { properties: IFactoryProperty[]; elements: OneOfChildFactoryElements[] }
  >
>;
