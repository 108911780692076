import React, { memo, useCallback, useMemo } from 'react';
import { ListItem } from '@mui/material';
import {
  ElementKind,
  IElement,
  IElementID,
  OneOfElements,
  OneOfListElements,
} from '../../../../shared/models/project.interface';
import { HideOutsideViewport } from './../HideOutsideViewport';
import Draggable from './../drag-and-drop/Draggable';
import Droppable, { DropType } from './../drag-and-drop/Droppable';
import { useMoveElements } from '../../hooks/move-elements.hook';
import {
  isBuildingVersionElement as isBuildingVersionElementFn,
  getElementById,
  isElement as isElementFn,
  isProductElement as isProductElementFn,
} from '../../../../shared/helpers/recursive_element_helpers';
import { getSelectedVersion, useUIState } from '../../store/ui';
import { useNavigateTo } from '../../hooks/router.hooks';
import {
  isElementExpanded,
  setElementExpanded,
} from '../../hooks/expand-elements.hook';
import {
  isProjectFolder,
  isProjectInfo,
  isProjectInfoOrFolder,
} from '../../../../shared/helpers/project-folder.helpers';
import ElementListItem from './ListItems/ElementListItem';
import { LIST_ITEM_HEIGHT } from './list.constants';
import {
  useAllowedDropTypes,
  useIsDraggableElement,
} from '../drag-and-drop/drag-and-drop.hook';
import BuildingVersionListItem from './ListItems/BuildingVersionListItem';
import ProductElementListItem from './ListItems/ProductElementListItem';
import ProjectInfoListItem from './ListItems/ProjectInfoListItem';
import ProjectFolderListItem from './ListItems/ProjectFolderListItem';
import { useProjectSelectorDrop } from '../../hooks/droppable.hook';

interface INestedElementItemProps<T extends OneOfListElements> {
  element: OneOfElements<T>;
  indentation: number;
  selectMode?: boolean;
}

const NestedElementItem = <T extends OneOfListElements>({
  element,
  indentation,
  selectMode,
}: INestedElementItemProps<T>) => {
  const isProjectListItem = isProjectInfoOrFolder(element);

  const { moveElementsTo: moveElements, isMoveElementsAllowed } =
    useMoveElements();
  const {
    recentlyUndoRedoElementId,
    observedElementIds,
    addObservedElementIds,
  } = useUIState(
    'recentlyUndoRedoElementId',
    'renamingId',
    'observedElementIds',
    'addObservedElementIds',
  );

  const draggable = useIsDraggableElement(element);
  const allowedDrops = useAllowedDropTypes(element);
  const navigateTo = useNavigateTo();

  // TODO: Why this for version?
  const isRecentlyChanged = !!(
    recentlyUndoRedoElementId &&
    ((recentlyUndoRedoElementId === 'version' &&
      element?.kind === ElementKind.Version) ||
      recentlyUndoRedoElementId === element?.id)
  );
  const virtualScroll = true;
  const expanded = isElementExpanded(element);
  const onProjectDrop = useProjectSelectorDrop();

  const handleElementDrop = useCallback(
    async (itemId: string, moveToId?: string | null, moveType?: DropType) => {
      if (!moveToId || !moveType) {
        throw new Error('No target element id');
      }

      await moveElements(moveToId, moveType, itemId);

      if (moveType === 'inside' && !expanded) {
        const version = getSelectedVersion();
        const parent = getElementById(version, moveToId);
        const elementsInParent =
          (parent as IElement | undefined)?.elements ?? [];

        addObservedElementIds(
          elementsInParent.reduce(
            (acc, { id }) =>
              ({
                ...acc,
                [id]: true,
              }) as Record<IElementID, boolean>,
            {},
          ) ?? {},
        );
        setElementExpanded(moveToId, true);
      }

      navigateTo({ elementId: itemId });
    },
    [moveElements, expanded, navigateTo, addObservedElementIds],
  );

  const validateDrop = useCallback(
    (itemId: string, moveToId?: string, moveType?: DropType) =>
      isMoveElementsAllowed(moveToId, moveType, itemId),
    [isMoveElementsAllowed],
  );

  const component = useMemo(() => {
    if (isProjectFolder(element)) {
      return (
        <ProjectFolderListItem folder={element} indentation={indentation} />
      );
    }
    if (isProjectInfo(element)) {
      return (
        <ProjectInfoListItem project={element} indentation={indentation} />
      );
    }
    if (isBuildingVersionElementFn(element)) {
      return (
        <BuildingVersionListItem version={element} selectMode={selectMode} />
      );
    }
    if (isElementFn(element)) {
      return <ElementListItem element={element} indentation={indentation} />;
    }
    if (isProductElementFn(element)) {
      return <ProductElementListItem element={element} />;
    }
    return <></>;
  }, [element, indentation, selectMode]);

  return (
    <HideOutsideViewport
      alwaysVisible={!virtualScroll || observedElementIds[element?.id ?? '']}
      height={LIST_ITEM_HEIGHT}
      scrollTo={isRecentlyChanged}
    >
      <Droppable
        onDrop={isProjectListItem ? onProjectDrop : handleElementDrop}
        allowDropInside={allowedDrops.inside}
        allowDropAbove={allowedDrops.above}
        allowDropBelow={allowedDrops.below}
        indentation={indentation}
        dropId={element?.id}
        validateFn={isProjectListItem ? SKIP_VALIDATION : validateDrop}
      >
        <Draggable dragId={element?.id} disabled={!draggable}>
          <ListItem disablePadding disableGutters>
            {component}
          </ListItem>
        </Draggable>
      </Droppable>
    </HideOutsideViewport>
  );
};

// TODO: Should validate project list items
const SKIP_VALIDATION = () => true;

export default memo(NestedElementItem);
