import React, { FC } from 'react';
import { IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Check } from '@mui/icons-material';

interface SelectedIconData {
  active?: boolean;
  hover: boolean;
}

const SelectedIcon: FC<SelectedIconData> = ({ active, hover }) => {
  const { classes, cx } = useStyles();

  return (
    <IconButton
      size="large"
      disabled={active}
      sx={{
        color: '#515151!important',
        '&:hover': { backgroundColor: 'transparent' },
      }}
    >
      <Check
        fontSize="small"
        classes={{
          root: cx(
            !active && classes.inactive,
            !active && hover && classes.hover,
          ),
        }}
      />
    </IconButton>
  );
};

const useStyles = makeStyles()(() => ({
  inactive: {
    visibility: 'hidden',
  },

  hover: {
    visibility: 'visible',
    opacity: '0.3',
  },
}));

export default SelectedIcon;
