import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import ElementRecipe from './ElementRecipe';
import { IElement } from '../../../../../shared/models/project.interface';
import ElementCategory from './ElementCategory';
import { useResults } from '../../../hooks/useResults';
import ElementResult from './ElementResults';
import ElementChildren from './ElementChildren/ElementChildren';
import ElementQuantities from './ElementQuantities';
import ElementClose from './ElementClose';
import { isProductCategory } from '../../../../../shared/helpers/element_category_helpers';
import ElementComments from './ElementComments';
import Results from './ElementChildren/Results';

interface ElementProps {
  selectedElement: IElement;
}

const Element: React.FC<ElementProps> = ({ selectedElement }) => {
  const [localElement, setLocalElement] = useState(selectedElement);
  const results = useResults();

  useEffect(() => {
    setLocalElement(selectedElement);
  }, [selectedElement]);

  if (!localElement) {
    return null;
  }

  return (
    <Box p={2} mb={25}>
      <ElementClose />

      <ElementCategory element={selectedElement} pb={2} />

      <ElementQuantities element={selectedElement} />

      {isProductCategory(selectedElement.category_id) ? null : (
        <ElementRecipe element={selectedElement} />
      )}

      <ElementResult results={results}></ElementResult>

      <ElementChildren element={selectedElement} />

      <Results elementId={selectedElement.id} factor="co2e_total" />
      <Results elementId={selectedElement.id} factor="sek_A1-A3" />

      <ElementComments elementId={selectedElement.id} />
    </Box>
  );
};

export default Element;
