import { getElementCategoryId } from './element_helpers';
import {
  getRepeatingCountValueByName,
  getElementQuantityCountValueByName,
  ThicknessUnit,
  getThicknessUnit,
} from './element_quantity_helpers';
import { ISize, spaceEvenlyHorizontally } from './geometry_helpers';
import { ElementCategoryID } from '../models/element_categories.interface';
import { IElement } from '../models/project.interface';

export interface ILayer {
  height: number;
  y: number;
  element: IElement;
  categoryId?: ElementCategoryID;
  x: number;
  width: number;

  spacing?: number;
}

export const getLayersFromElements = (
  element: IElement,
  childElements: IElement[],
  viewport: ISize,
): ILayer[] => {
  if (!viewport.width || !viewport.height) {
    return [];
  }

  const unit = getThicknessUnit(element);

  let y = 0;

  const groups = getElementLayerGroups(
    childElements.filter((e) => getThickness(unit, e)),
  );
  const totalHeight = getTotalThickness(unit, groups);

  const finalLayers: ILayer[] = groups.flatMap((elements) => {
    // Height of this entire layer/group
    const maxHeight = getPixels(
      getMaxThickness(unit, ...elements),
      totalHeight,
      viewport.height,
    );
    let startOffset = 0;

    const layers: ILayer[] = elements.flatMap((element) => {
      const categoryId = getElementCategoryId(element);
      const repeatingLayers: ILayer[] = [];

      // Only one element in group, no need to repeat
      if (
        elements.length === 1 &&
        !getRepeatingCountValueByName(element, 'repeating_item_spacing')
      ) {
        return {
          element,
          height: maxHeight,
          width: viewport.width,
          y,
          x: 0,
          categoryId,
        };
      }

      const height = getPixels(
        getThickness(unit, element),
        totalHeight,
        viewport.height,
      );

      // TODO: Make width and spacing become always 0 if repeating_items is false
      const width = getPixels(
        getRepeatingCountValueByName(element, 'repeating_item_thickness'),
        totalHeight,
        viewport.height,
      );
      const spacing = getPixels(
        getRepeatingCountValueByName(element, 'repeating_item_spacing'),
        totalHeight,
        viewport.height,
      );

      if (width && spacing) {
        for (
          let x = startOffset;
          x + width <= viewport.width;
          x += width + spacing
        ) {
          repeatingLayers.push({
            element,
            height,
            y,
            categoryId,
            x,
            width,
            spacing,
          });
        }
        startOffset += width;
      }
      return repeatingLayers;
    });
    y += maxHeight;
    return spaceEvenlyHorizontally(
      sortLayersHorizontally(layers),
      0,
      viewport.width,
    );
  });

  return finalLayers;
};

const sortLayersHorizontally = (layers: ILayer[]): ILayer[] =>
  [...layers].sort((a, b) => {
    if (a.x === b.x) {
      return (a.spacing ?? 0) - (b.spacing ?? 0);
    }
    return a.x - b.x;
  });

export const getThickness = (
  unit: ThicknessUnit,
  ...elements: IElement[]
): number => {
  return elements.reduce((acc, element) => {
    const quantity = getElementQuantityCountValueByName(element, unit);
    return acc + (quantity ?? 0);
  }, 0);
};

const getTotalThickness = (unit: ThicknessUnit, groups: IElement[][]): number =>
  groups.reduce((acc, group) => acc + getMaxThickness(unit, ...group), 0);

export const getMaxThickness = (
  unit: ThicknessUnit,
  ...elements: IElement[]
): number =>
  Math.max(
    ...elements.map((e) => getElementQuantityCountValueByName(e, unit) ?? 0),
  );

const getPixels = (
  thickness: number | undefined,
  totalThickness: number,
  viewportHeight: number,
): number =>
  totalThickness && thickness
    ? (viewportHeight * thickness) / totalThickness
    : 0;

export const getElementLayerGroups = (sortedElements: IElement[]) => {
  const groups: IElement[][] = [];
  let currentGroup: IElement[] = [];

  sortedElements.forEach((element) => {
    if (!currentGroup.length) {
      currentGroup.push(element);
      return;
    }

    if (hasRemainingSpacing(...currentGroup, element)) {
      currentGroup.push(element);
    } else {
      // Group is finalized, store & make new currentGroup
      groups.push(currentGroup);
      currentGroup = [element];
    }
  });

  if (currentGroup.length) {
    groups.push(currentGroup);
  }
  return groups;
};

const filterRepeatingElements = (elements: IElement[]): IElement[] =>
  elements.filter((e) =>
    getElementQuantityCountValueByName(e, 'repeating_items'),
  );

const hasRemainingSpacing = (...elements: IElement[]): boolean => {
  const repeating = filterRepeatingElements(elements);

  // If any element is not repeating no space is left
  if (repeating.length !== elements.length) {
    return false;
  }

  const items = elements
    .map((element) => {
      const width = getRepeatingCountValueByName(
        element,
        'repeating_item_thickness',
      );
      const spacing = getRepeatingCountValueByName(
        element,
        'repeating_item_spacing',
      );
      return { width, spacing, element, fill: width / (width + spacing) };
    })
    .sort((a, b) => a.spacing - b.spacing);

  const totalFill = items.reduce((acc, item) => acc + item.fill, 0);
  const cantFitSpacing = items.some((itemA) =>
    items.some((itemB) => itemA !== itemB && itemA.width > itemB.spacing),
  );

  return totalFill <= 1 && !cantFitSpacing;
};
