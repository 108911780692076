import React from 'react';
import { ElementQuantityName } from '../../../../shared/models/element_quantities.interface';
import {
  MainCategoryId,
  ElementCategoryID,
} from '../../../../shared/models/element_categories.interface';

import LenghtIcon from './icons/length.svg?react';
import WidthIcon from './icons/width.svg?react';
import HeightIcon from './icons/height.svg?react';
import SideAreaIcon from './icons/side-area.svg?react';
import TopAreaIcon from './icons/top-area.svg?react';
import SectionAreaIcon from './icons/section-area.svg?react';
import VolumeIcon from './icons/volume.svg?react';
import FillIcon from './icons/fill.svg?react';
import MassIcon from './icons/mass.svg?react';
import FacadesIcon from './icons/facades.svg?react';
import FloorsIcon from './icons/floors.svg?react';
import FoundationIcon from './icons/foundation.svg?react';
import OtherIcon from './icons/other.svg?react';
import RoofIcon from './icons/roof.svg?react';
import WallsIcon from './icons/walls.svg?react';
import Installations from './icons/installations.svg?react';

export const quantityIcons: Partial<
  Record<ElementQuantityName, React.ReactNode>
> = {
  length: <LenghtIcon />,
  width: <WidthIcon />,
  height: <HeightIcon />,
  area_side: <SideAreaIcon />,
  area_top: <TopAreaIcon />,
  area_section: <SectionAreaIcon />,
  volume: <VolumeIcon />,
  fill: <FillIcon />,
  mass: <MassIcon />,
};

export const mainCategoryIcons: Record<MainCategoryId, React.ReactNode> = {
  [ElementCategoryID.FACADES]: <FacadesIcon />,
  [ElementCategoryID.ROOF]: <RoofIcon />,
  [ElementCategoryID.INTERNAL_WALLS]: <WallsIcon />,
  [ElementCategoryID.FLOORS]: <FloorsIcon />,
  [ElementCategoryID.FOUNDATION]: <FoundationIcon />,
  [ElementCategoryID.INSTALLATIONS]: <Installations />,
  [ElementCategoryID.MAIN_OTHER]: <OtherIcon />,
};
