/**
 * reload page if client version doesn't match server version
 * (ie after releasing a new version of the app)
 * */
export const reloadApp = (responseStatus: number): void => {
  if (responseStatus === 409) {
    location.reload();
  }
};

export const getErrorMessage = (
  action: 'get' | 'create' | 'update' | 'delete',
  resource: string,
): string => `Could not ${action} ${resource}`;
