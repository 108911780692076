import React, { FC } from 'react';
import { activitySchemas } from '../../schemas/activities.schema';
import { ActivityFormInputProps } from '../../../../shared/models/activities.interface';
import ActivityForm from '../../components/activities/ActivityForm';
import ActivityFormInputs from '../../components/activities/ActivityFormInputs';
import { useActivities } from '../../hooks/activities.hook';

export const ActivitySettings: FC<Record<string, unknown>> = () => {
  const { activities, autoValues } = useActivities();

  return (
    <>
      {activities.map((activity, index) => (
        <ActivityForm
          data-id={activity.type}
          key={activity.id}
          index={index}
          schema={activitySchemas[activity.type]}
          values={activity}
          autoValues={autoValues[activity.id]}
        >
          {(props: ActivityFormInputProps) => <ActivityFormInputs {...props} />}
        </ActivityForm>
      ))}
    </>
  );
};

export default ActivitySettings;
