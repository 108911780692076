import { isOneOf } from '../../../../shared/helpers/array_helpers';
import { isProductCategoryElement } from '../../../../shared/helpers/element_category_helpers';
import { isGeneratedProductElement } from '../../../../shared/helpers/element_helpers';
import {
  isProjectFolder,
  isProjectInfo,
} from '../../../../shared/helpers/project-folder.helpers';
import {
  getChildElements,
  getElementKind,
  isBuildingVersionElement,
  isElement,
  isProductElement,
} from '../../../../shared/helpers/recursive_element_helpers';
import {
  ElementKind,
  OneOfListElements,
} from '../../../../shared/models/project.interface';
import { isMainCategoryElement } from '../../../../shared/templates/categories';
import { useIsElementExpanded } from '../../hooks/expand-elements.hook';
import { useObjectMemo } from '../../hooks/hooks';
import { useMainCategoryIconId } from '../../hooks/main-categories.hook';
import { useSortBy } from '../../hooks/sort.hook';
import { useIsReadonly } from '../../hooks/user.hook';
import { useUIState } from '../../store/ui';

const draggableKinds: ElementKind[] = [
  ElementKind.Element,
  ElementKind.Product,
  ElementKind.ProjectFolder,
  ElementKind.ProjectInfo,
];

/**
 * All elements that can be dragged
 * @param element
 * @returns
 */
export const useIsDraggableElement = (
  element: OneOfListElements | undefined,
) => {
  const kind = getElementKind(element);
  const readonly = useIsReadonly();
  const { renamingId } = useUIState('renamingId');

  // Can't drag if renaming something or in readonly mode
  if (renamingId || readonly) {
    return false;
  }

  if (isOneOf(draggableKinds, kind)) {
    // Product elements are draggable only if they are not generated
    if (isProductElement(element)) {
      return !isGeneratedProductElement(element);
    }
    // Main categories are not draggable
    return !isMainCategoryElement(element);
  }
  return false;
};

interface IAllowedDropTypes {
  above: boolean;
  below: boolean;
  inside: boolean;
}
const dropTypeDefaults: IAllowedDropTypes = Object.freeze({
  above: false,
  below: false,
  inside: false,
});

export const useAllowedDropTypes = (
  element: OneOfListElements,
): IAllowedDropTypes => {
  const isSortedByPosition = useSortBy() === 'position';
  const isExpanded =
    useIsElementExpanded(element) && getChildElements(element).length > 0;

  const { selectedElementCategoryId } = useUIState('selectedElementCategoryId');

  //
  const showMainCategoryIcon = !!useMainCategoryIconId(element);

  // These types can have children
  const isParent = isElement(element) || isProjectFolder(element);

  const dropTypes: IAllowedDropTypes = {
    inside: isParent && !isProductCategoryElement(element),
    above: isSortedByPosition && !selectedElementCategoryId,
    below: isSortedByPosition && !isExpanded && !showMainCategoryIcon,
  };

  // Unsupported types
  const unsupported =
    isGeneratedProductElement(element) ||
    isBuildingVersionElement(element) ||
    isProjectInfo(element);

  return useObjectMemo(unsupported ? dropTypeDefaults : dropTypes);
};
