/**
 * Säkerhetsklass. A class on a specific element depending on how crucial the element is to avoid personal injury.
 * 1 - Unimportant,
 * 2 - Average
 * 3 - Critical
 * https://www.boverket.se/sv/PBL-kunskapsbanken/regler-om-byggande/boverkets-konstruktionsregler/overgripande-bestammelser/indelning-i-sakerhetsklasser/
 */
export type ProtectionClass = 1 | 2 | 3;

/**
 * Brandsäkerhetsklass
 * https://www.boverket.se/sv/PBL-kunskapsbanken/regler-om-byggande/boverkets-konstruktionsregler/laster/brandsakerhetsklasser/
 */
export type FireProtectionClass = 1 | 2 | 3 | 4 | 5;

/**
 * Byggnader ska delas in i byggnadsklasser (Br) utifrån byggnadens skyddsbehov.
 * Vid bedömningen av skyddsbehovet ska hänsyn tas till troliga brandförlopp, möjliga konsekvenser av en brand samt byggnadens komplexitet.
 * Med möjliga konsekvenser och byggnadens komplexitet avses bland annat antal våningar samt vilka verksamhetsklasser som finns i byggnaden.
 * https://www.boverket.se/sv/PBL-kunskapsbanken/regler-om-byggande/boverkets-byggregler/brandskydd/byggnadsklass-och-verksamhetsklasser/
 */
export type BuildingClass =
  /**
   * Byggnader med mycket stort skyddsbehov
   */
  | 'Br0'
  /**
   * Byggnader med stort skyddsbehov
   */
  | 'Br1'
  /**
   * Byggnader med måttligt skyddsbehov
   */
  | 'Br2'
  /**
   * Byggnader med litet skyddsbehov
   */
  | 'Br3';

export enum StrengthType {
  DeflectionParallel = 'Böjning parallellt med fibrerna fm,k',
  DeflectionPerpendicular = 'Böjning vinkelrätt mot fibrerna fm,k',
  PullParallel = 'Dragning parallellt med fibrerna ft,0,k',
  PullPerpendicular = 'Dragning vinkelrätt mot fibrerna ft,90,k',
  PressureParallel = 'Tryck parallellt med fibrerna fc,0,k',
  PressurePerpendicular = 'Tryck vinkelrätt mot fibrerna fc,90,k',
  ElasticModulusParallel = 'Elasticitetsmodul parallellt med fibrerna E0,mean',
  /**
   * Skjuvning.
   * https://en.wikipedia.org/wiki/Shearing_(physics)
   */
  Shear = 'Längdskjuvning fv,k',
}
