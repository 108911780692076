import { createWithEqualityFn } from 'zustand/traditional';
import {
  Project,
  ProjectID,
} from '../../../../shared/models/project.interface';
import axios, { AxiosRequestConfig, AxiosResponseHeaders } from 'axios';
import { DateTime } from 'luxon';
import { devtools } from 'zustand/middleware';
import {
  createProject,
  getBackwardsCompatibleProject,
} from '../../../../shared/helpers/project_factory_helpers';
import { StoreErrorMessage } from '../store_errors';
import { getSelectedOrganization } from '../organization/organization.hook';
import { IProjectState, ProjectsLookup } from './project-state.model';
import {
  preventNewProjectValidationErrors,
  validateProject,
} from '../../../../shared/validation/project.validation';
import { reloadApp } from '../utils';
import { projectToProjectInfo } from '../../../../shared/helpers/project_helpers';
import { useFolderStateStore } from '../folder/folder.store';
import { shallow } from 'zustand/shallow';
import { getTimestamp } from '../../../../shared/helpers/date.helpers';
import { omit } from '../../../../shared/helpers/object_helpers';
import { getConfig } from '../../providers/ConfigProvider';
import { getHmrStoreState, initHmrStore } from '../../helpers/vite.helpers';
import { IProjectFolder } from '../../../../shared/models/folder.interface';
import {
  isProjectFolder,
  isProjectInfo,
} from '../../../../shared/helpers/project-folder.helpers';

const projectReviver = (key: string, value: any): unknown => {
  if (
    value !== null &&
    ['updated_at', 'created_at', 'deleted_at'].includes(key)
  ) {
    return DateTime.fromISO(value).toString();
  }
  return value;
};

const STORE_NAME = 'project';

const REQUEST_CONFIG: AxiosRequestConfig = {
  transformResponse: (
    data: any,
    headers: AxiosResponseHeaders,
    status?: number,
  ): any => {
    return status && status < 400
      ? (JSON.parse(data, projectReviver) as Record<ProjectID, Project>)
      : undefined;
  },
} as const;

const DEFAULT_PROJECT: Project = {
  id: 0,
  owner: '',
  organizations: [],
  sharing_key: '',
  name: '',
  buildings: [],
  template: false,
  created_at: DateTime.fromISO('2023-01-01').toString(),
  updated_at: DateTime.fromISO('2023-01-01').toString(),
} as const;

/**
 * Store to hold project related values.
 * Avoid using directly. Use the useProjectStore hook instead.
 */
export const useProjectStateStore = createWithEqualityFn<IProjectState>()(
  devtools(
    (set, get) => ({
      isDeleting: false as boolean,
      isCreating: false as boolean,
      isLoading: false as boolean,
      showSettings: false as boolean,
      projectsFetched: false as boolean,
      projectsFetching: false as boolean,
      projectsLookup: {},
      project: DEFAULT_PROJECT,
      // HMR sometimes causes multiple reloads of this file. Keep store state between reloads
      ...getHmrStoreState(STORE_NAME),
      fetchPublicProject: async (sharing_key) => {
        const organization = getSelectedOrganization(true);

        set(() => ({ isLoading: true }));

        try {
          const config = await getConfig();
          const { data } = await axios.get<Project>(
            `projects/shared/${sharing_key}`,
            {
              headers: {
                AppVersion: config.version,
                Organization: organization,
              },
            },
          );

          set(() => ({
            project: data,
            isLoading: false,
            projectsLookup: {
              [data.id]: projectToProjectInfo(data),
            } as ProjectsLookup,
          }));

          return data;
        } catch (err) {
          set(() => ({ isLoading: false }));
          throw Promise.reject(err);
        }
      },
      fetchProjects: async () => {
        set(() => ({
          projectsFetching: true,
          projectsFetched: false,
          error: undefined,
        }));

        try {
          const { data } = await axios.get<ProjectsLookup>(
            `projects`,
            REQUEST_CONFIG,
          );
          set(() => ({
            projectsFetching: false,
            projectsFetched: true,
            error: undefined,
            projectsLookup: data,
          }));
        } catch (err) {
          set(() => ({
            projectsFetching: false,
            projectsFetched: false,
            error: err as Error,
          }));
          // throw err;
        }
      },
      fetchProject: async (id) => {
        set(() => ({ isLoading: true }));

        try {
          const project = await fetchProject(id);

          set(() => ({
            project,
            isLoading: false,
          }));

          return project;
        } catch (err) {
          set(() => ({ isLoading: false }));
          throw err;
        }
      },
      createProject: ({
        projectPartial,
        duplicate,
        useProjectPartialOnDuplicate,
      }) => {
        if (get().isCreating) {
          throw new Error('Already creating project');
        }

        set(() => ({ isCreating: true }));

        return axios
          .post<Project>(
            `projects${duplicate ? '/duplicate' : ''}`,
            projectPartial && duplicate && !useProjectPartialOnDuplicate
              ? validateProject(createProject(projectPartial))
              : projectPartial,
            REQUEST_CONFIG,
          )
          .then(({ data }) => {
            set(({ projectsLookup }) => ({
              project: data,
              projectsLookup: {
                ...projectsLookup,
                [data.id]: projectToProjectInfo(data),
              },
              isCreating: false,
            }));

            return data;
          })
          .catch((err) => {
            set(() => ({ isCreating: false }));
            throw err;
          });
      },
      createTemplate: async (projectId) => {
        const { fetchProject } = get();
        const fetchedProject = await fetchProject(projectId);

        if (!fetchedProject) {
          throw new Error('Project not found.');
        }

        if (!fetchedProject.organizations?.length) {
          throw new Error(StoreErrorMessage.NotBeloningToOrganization);
        }

        const templateProject: Project = {
          ...fetchedProject,
          locked: false,
          archived: false,
        };

        validateProject(templateProject);

        await axios.post<Project>(
          `projects/template`,
          templateProject,
          REQUEST_CONFIG,
        );
      },
      deleteProject: async (id) => {
        set(() => ({
          isDeleting: true,
        }));

        try {
          await axios.delete(`/projects/${id}`);
          set(({ projectsLookup }) => ({
            isDeleting: false,
            projectsLookup: omit(projectsLookup, id),
          }));
        } catch (err) {
          set(() => ({ isDeleting: false }));
          return Promise.reject(err);
        }
      },
      updateProject: async (updatedProject: Project): Promise<Project> => {
        const { project, updateProjectLocally } = get();

        // Set project in store without waiting for server response
        updatedProject = updateProjectLocally(updatedProject);

        // Don't send to server if nothing has changed
        if (project === updatedProject) {
          return project;
        }

        try {
          await axios.put<Project>(
            `projects`,
            { project: updatedProject },
            REQUEST_CONFIG,
          );
          return updatedProject;
        } catch (err: any) {
          // Revert changes if server update failed
          updateProjectLocally(project);
          reloadApp(err.response?.status);
          return Promise.reject(err);
        }
      },
      // TODO: Simpler if it was a Partial with a required id
      updateProjectDetails: async ({ projectId, data }) => {
        const { data: updatedProject } = await axios.put<Project>(
          `projects/details`,
          { projectId, data },
          REQUEST_CONFIG,
        );
        set(({ projectsLookup }) => ({
          project: updatedProject,
          projectsLookup: {
            ...projectsLookup,
            [projectId]: projectToProjectInfo(updatedProject),
          },
        }));
        return updatedProject;
      },
      updateProjectLocally: (updatedProject): Project => {
        const { project } = get();

        // Don't send to server if not changed or if updatedProject has not the same id as the current project
        if (project === updatedProject || updatedProject.id !== project.id) {
          return project;
        }

        // Make sure to update the updated_at timestamp // Not needed?
        updatedProject = { ...updatedProject, updated_at: getTimestamp() };

        // Crash if new errors are introduced
        preventNewProjectValidationErrors(updatedProject, project);

        set(({ projectsLookup }) => ({
          project: updatedProject,
          projectsLookup: {
            ...projectsLookup,
            [updatedProject.id]: projectToProjectInfo(updatedProject),
          },
        }));
        return updatedProject;
      },
      updateProjectAndFolderLocations: async (items) => {
        const { project } = get();
        const projectInfos = items.filter(isProjectInfo);
        const folders = items.filter(isProjectFolder);
        set(() => ({ isLoading: true }));

        await axios
          .put<{
            projects: Project[];
            folders: IProjectFolder[];
          }>(`/projects/locations`, { projectInfos, folders }, REQUEST_CONFIG)
          .then(({ data }) => {
            const changedProject = data.projects.find(
              ({ id }) => id === project.id,
            );
            set(({ projectsLookup }) => {
              return {
                isLoading: false,
                project: changedProject
                  ? {
                      ...project,
                      parent_id: changedProject.parent_id,
                      location: changedProject.location,
                    }
                  : project,
                projectsLookup: {
                  ...projectsLookup,
                  ...data.projects.reduce(
                    (acc, updatedProject) => ({
                      ...acc,
                      [updatedProject.id]: projectToProjectInfo(updatedProject),
                    }),
                    {} as ProjectsLookup,
                  ),
                },
              };
            });
            const folderState = useFolderStateStore.getState();
            folderState.updateFoldersLocally(data.folders);
          });
      },
    }),
    { name: STORE_NAME },
  ),
  shallow,
);

/**
 * Fetch project from server. Will not cause state change in project store.
 * @param id
 * @returns
 */
export const fetchProject = async (id: number) => {
  const { data } = await axios.get<Project>(`projects/${id}`, REQUEST_CONFIG);

  // TODO: Needed? Should maybe run migration on server instead
  return getBackwardsCompatibleProject(data);
};

// In HMR mode we need to keep the store state between reloads
initHmrStore(STORE_NAME, useProjectStateStore);
