import { makeStyles } from 'tss-react/mui';

const BORDER_COLOR = 'rgba(0,0,0,0.1)';

export const useListRowStyles = makeStyles()(() => {
  return {
    row: {
      // Always have a border, just hide it when not needed
      borderBottom: '1px solid transparent',
      borderTop: '1px solid transparent',
    },
    selected: {
      borderColor: BORDER_COLOR,
    },
  };
});
