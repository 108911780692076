/**
 * Check if an element has a parent that have one of the provided classes
 * @param element
 * @param classes
 * @returns
 */
export const hasParentElementOfClass = (
  element: HTMLElement | undefined,
  ...classes: string[]
) =>
  !!element &&
  !!findParentHTMLElement(element, (parentEl) =>
    classes.some((className) => parentEl.classList.contains(className)),
  );

export const findParentHTMLElement = (
  element: HTMLElement,
  findFn: (parentEl: HTMLElement) => boolean | undefined,
): HTMLElement | undefined => {
  let parent = element.parentElement;
  while (parent) {
    if (findFn(parent)) {
      return parent;
    }
    parent = parent.parentElement;
  }
  return undefined;
};
