import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  AppBar as MuiAppBar,
  Box,
  Button,
  IconButton,
  Link,
  Tab,
  Tabs,
  Theme,
  Toolbar,
  Typography,
  Snackbar,
  Tooltip,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import IosShareIcon from '@mui/icons-material/IosShare';
import Logo from '../nodon-logo.png';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ProfileMenu from '../components/ProfileMenu';
import { IProjectInfo } from '../../../shared/models/project.interface';
import OrganizationsMenu from './OrganizationsMenu';
import SettingsIcon from '@mui/icons-material/Settings';
import amplitudeLog from '../amplitude';
import { useOrganizationGFAQuotaIsExceeded } from '../store/organization';
import { getSelectedVersionId, useUIState } from '../store/ui';
import { Page } from '../store/ui/ui.model';
import { useNavigateTo } from '../hooks/router.hooks';
import { v4 } from 'uuid';
import { getProject, useProjectState } from '../store/project';
import GFAQuotaExceededAlert from './GFAQuotaExceededAlert';
import { AppBarConstant } from '../style/constants';
import { useUser } from '../hooks/user.hook';

const useStyles = makeStyles()(
  ({ zIndex, spacing, breakpoints, palette }: Theme) => ({
    appBar: {
      zIndex: zIndex.drawer + 1,
    },

    logo: {
      width: '48px',
      height: '48px',
    },

    toolbar: {
      flexDirection: 'column',
      justifyContent: 'center',
    },

    tabs: {
      marginLeft: spacing(12),
    },

    tab: {
      [breakpoints.down('lg')]: {
        minWidth: 125,
      },
    },

    tabIndicator: {
      display: 'none',
    },

    button: {
      alignItems: 'center',
      padding: spacing(1),
      fontSize: '0.5rem',
      ['@media (min-width:935px)']: {
        fontSize: '0.625rem',
      },
      ['@media (min-width:1088px)']: {
        padding: spacing(2),
        fontSize: '0.75rem',
      },
    },

    buttonIcon: {
      marginRight: 0,
      fontSize: '0.5rem',
      ['@media (min-width:935px)']: {
        fontSize: '0.625rem',
      },
      ['@media (min-width:1088px)']: {
        marginRight: spacing(1),
        fontSize: '0.75rem',
      },
    },

    buttonText: {
      marginBottom: -1,
    },

    settingsButton: {
      margin: spacing(0, 3),
    },

    buttonHide: {
      display: 'none',
    },

    projectMenuButton: {
      display: 'inline-block',
      maxWidth: '39%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      uppercase: 'default',
    },

    sharing: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: spacing(4),
      paddingRight: spacing(4),
      cursor: 'pointer',
    },

    sharingHeader: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 700,
      fontSize: '0.75rem',
      '> svg': {
        marginleft: spacing(2),
        fontSize: '0.75rem',
      },
    },

    shareButton: {
      width: '100%',
    },

    sharingText: {
      display: 'inline-block',
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '0.75rem',
      '&:hover': {
        color: palette.secondary.main,
      },
    },

    sharingDeleteIcon: {
      '&:hover': {
        color: palette.secondary.main,
      },
    },

    shareButtonIcon: {
      marginRight: spacing(1),
      '& svg': {
        fontSize: '0.5rem !important',
      },
      ['@media (min-width:935px)']: {
        marginTop: -2,
        '& svg': {
          fontSize: '0.75rem !important',
        },
      },
      ['@media (min-width:1088px)']: {
        marginTop: -3,
        marginRight: spacing(1),
        '& svg': {
          fontSize: '1rem !important',
        },
      },
    },
  }),
);

interface AppBarProps {
  project?: IProjectInfo;
}

const AppBar: React.FC<AppBarProps> = ({ project }) => {
  const { classes, cx } = useStyles();
  const {
    project: { id, owner, sharing_key },
    updateProjectDetails,
  } = useProjectState('project', 'updateProjectDetails');

  const {
    selectedPage,
    showSettings,
    setShowSettings,
    showProjectSelector,
    setShowProjectSelector,
    setAppHeaderOffset,
  } = useUIState(
    'selectedPage',
    'showSettings',
    'setShowSettings',
    'showProjectSelector',
    'setShowProjectSelector',
    'setAppHeaderOffset',
  );

  const user = useUser();
  const organizationGFAQuotaIsExceeded = useOrganizationGFAQuotaIsExceeded();

  const navigate = useNavigate();
  const navigateTo = useNavigateTo();

  const appBarRef = useRef<HTMLElement>(null);
  const [sharingConfirmationOpen, setSharingConfirmationOpen] = useState(false);

  const handleNewProjectClick = useCallback(() => {
    navigate('/projects/new');
    amplitudeLog('New Project clicked (in app bar)');
  }, [navigate]);

  const handleProjectMenuClick = useCallback(
    () => setShowProjectSelector(!showProjectSelector),
    [showProjectSelector, setShowProjectSelector],
  );

  const handleSettingsClick = useCallback(() => {
    if (!showSettings) {
      amplitudeLog('Options Open');
    }
    setShowSettings(!showSettings);
    navigateTo({ mainCategoryId: 'none', elementId: 'none' });
  }, [navigateTo, setShowSettings, showSettings]);

  const onChangeSelectPage = useCallback(
    (event: unknown, page: Page) => {
      navigateTo({ page });
    },
    [navigateTo],
  );

  const handleSharingClick = useCallback(() => {
    const { sharing_key } = getProject();
    const version = getSelectedVersionId();
    setSharingConfirmationOpen(true);
    navigator.clipboard.writeText(
      `${window.location.origin}/shared/${sharing_key ?? ''}/v/${
        version ?? 'none'
      }/elements/mc/none/e/none`,
    );
  }, []);

  const handleSharingConfirmationClose = useCallback(
    () => setSharingConfirmationOpen(false),
    [],
  );

  const handleGenerateSharingLinkClick: MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      const version = getSelectedVersionId();
      const sharingKey = v4();

      updateProjectDetails({
        projectId: id,
        data: { sharing_key: sharingKey },
      }).then(() => {
        setSharingConfirmationOpen(true);
        navigator.clipboard.writeText(
          `${window.location.origin}/shared/${sharingKey}/v/${
            version ?? 'none'
          }/elements/mc/none/e/none`,
        );
      });
    }, [id, updateProjectDetails]);

  const handleDeleteSharingClick: MouseEventHandler<HTMLOrSVGElement> =
    useCallback(
      (e) => {
        e.stopPropagation();
        updateProjectDetails({ projectId: id, data: { sharing_key: null } });
      },
      [id, updateProjectDetails],
    );

  const tabsClasses = useMemo(
    () => ({
      root: classes.tabs,
      indicator: classes.tabIndicator,
    }),
    [classes.tabs, classes.tabIndicator],
  );

  useEffect(() => {
    setTimeout(() => {
      if (appBarRef.current && project?.gfa) {
        setAppHeaderOffset(appBarRef.current.clientHeight);
      }
    }, 500);
  }, [project, setAppHeaderOffset]);

  return (
    <MuiAppBar
      position="fixed"
      className={classes.appBar}
      color="inherit"
      sx={APPBAR_SX}
      ref={appBarRef}
    >
      <Toolbar className={classes.toolbar} disableGutters>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          p="0 12px"
        >
          <Box
            width={AppBarConstant.SECTION_WIDTH}
            display="flex"
            alignItems="center"
          >
            <Link component={RouterLink} to="/" underline="none">
              <Box display="flex" alignItems="center" mr={1}>
                <img alt="nodon logo" src={Logo} className={classes.logo} />
              </Box>
            </Link>
            <Typography variant="h6" noWrap color="primary">
              <Link component={RouterLink} to="/" underline="none">
                Nodon
              </Link>
            </Typography>

            <Tabs
              value={selectedPage}
              textColor="secondary"
              classes={tabsClasses}
              onChange={onChangeSelectPage}
            >
              <Tab label="Elements" value="elements" className={classes.tab} />
              <Tab label="Products" value="products" className={classes.tab} />
              {selectedPage === 'calculations' && (
                <Tab
                  label="Calculations"
                  value="calculations"
                  className={classes.tab}
                />
              )}
            </Tabs>
          </Box>
          <Box
            width={AppBarConstant.SECTION_WIDTH}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {project?.name && (
              <Button
                className={classes.projectMenuButton}
                onClick={handleProjectMenuClick}
              >
                {project.name}
              </Button>
            )}
            {user && (
              <IconButton
                size="small"
                color={showSettings ? 'secondary' : 'default'}
                onClick={handleSettingsClick}
                className={classes.settingsButton}
              >
                <SettingsIcon />
              </IconButton>
            )}

            {user && (
              <Button
                disabled={showProjectSelector}
                className={classes.button}
                size="small"
                variant="outlined"
                color="secondary"
                onClick={handleNewProjectClick}
                startIcon={<AddIcon />}
                classes={{ startIcon: classes.buttonIcon }}
              >
                <span className={classes.buttonText}>
                  <FormattedMessage
                    id="projects_panel.new_project"
                    defaultMessage="New project"
                  />
                </span>
              </Button>
            )}
          </Box>

          <Box
            className={classes.sharing}
            width={AppBarConstant.SECTION_WIDTH}
            onClick={sharing_key ? handleSharingClick : undefined}
          >
            {sharing_key ? (
              <>
                <Snackbar
                  open={sharingConfirmationOpen}
                  onClose={handleSharingConfirmationClose}
                  autoHideDuration={2000}
                  message="Copied to clipboard"
                />
                <Typography className={classes.sharingHeader}>
                  Sharing link&nbsp;
                  <DeleteIcon
                    className={classes.sharingDeleteIcon}
                    onClick={handleDeleteSharingClick}
                  />
                </Typography>
                <Typography className={classes.sharingText}>
                  {`${window.location.origin}/shared/${sharing_key}`}
                </Typography>
              </>
            ) : (
              <Tooltip
                title={
                  user?.id !== owner
                    ? 'You need to be owner of the project to share it'
                    : ''
                }
              >
                <span>
                  <Button
                    className={cx(classes.button, classes.shareButton)}
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={handleGenerateSharingLinkClick}
                    startIcon={<IosShareIcon />}
                    classes={{ startIcon: classes.shareButtonIcon }}
                    disabled={user?.id !== owner}
                  >
                    <span className={classes.buttonText}>
                      <FormattedMessage
                        id="projects_panel.share"
                        defaultMessage="Share"
                      />
                    </span>
                  </Button>
                </span>
              </Tooltip>
            )}
          </Box>

          <Box
            width={AppBarConstant.SECTION_WIDTH}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            {!!user?.organizations.length && (
              <OrganizationsMenu organizations={user.organizations} />
            )}
            <ProfileMenu onChangeSelectPage={onChangeSelectPage} />
          </Box>
        </Box>
        {organizationGFAQuotaIsExceeded && <GFAQuotaExceededAlert />}
      </Toolbar>
    </MuiAppBar>
  );
};

const APPBAR_SX = { displayPrint: 'none' } as const;

export default AppBar;
