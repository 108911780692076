import {
  FootprintCoordinate,
  FootprintMetadata,
} from '../../../shared/models/project.interface';

export const getCoordinatesForNewPlace = (
  center: google.maps.LatLng,
  previousCenter: google.maps.LatLng,
  coordinates: google.maps.MVCArray<google.maps.LatLng>,
): FootprintMetadata => {
  const { computeDistanceBetween, computeHeading, computeOffset } =
    google.maps.geometry.spherical;

  const polygonRelationToMapCenter = coordinates
    .getArray()
    .map((coordinate) => {
      return {
        distance: computeDistanceBetween(previousCenter, coordinate),
        heading: computeHeading(previousCenter, coordinate),
      };
    });
  const newPolygonCoordinates = polygonRelationToMapCenter.map(
    (coordinateData) =>
      computeOffset(center, coordinateData.distance, coordinateData.heading),
  );
  const footprint = calculateFootprint(
    new google.maps.MVCArray(newPolygonCoordinates),
  );
  return footprint;
};

export const calculateFootprint = (
  vertices: google.maps.MVCArray<google.maps.LatLng>,
): FootprintMetadata => {
  const { computeArea, computeLength } = google.maps.geometry.spherical;

  const latLongArray = vertices.getArray();
  const area = computeArea(latLongArray);
  const perimeter = computeLength([...latLongArray, latLongArray[0]]);
  const coordinates = latLongArray.map((vertex) => vertex.toJSON());

  return {
    area,
    perimeter,
    coordinates,
  };
};

export const getFootprintCenter = (
  coordinates: FootprintCoordinate[],
): google.maps.LatLng => {
  const bounds = new google.maps.LatLngBounds();
  coordinates.forEach((coordinate) => bounds.extend(coordinate));

  return bounds.getCenter();
};
