import { useCallback } from 'react';
import { getFolders } from '../store/folder';
import {
  getProjectsLookup,
  useUpdateProjectAndFolderLocations,
} from '../store/project';
import { DropType } from '../components/drag-and-drop/Droppable';
import { OneOfProjectListElements } from '../../../shared/models/project.interface';
import { setElementExpanded } from './expand-elements.hook';

type ProjectSelectorDrop = (
  dragId: string,
  dropId: string | undefined | null,
  dropType?: DropType,
) => Promise<void>;

export const useProjectSelectorDrop = (): ProjectSelectorDrop => {
  const updateProjectAndFolderLocations = useUpdateProjectAndFolderLocations();

  const getModifiedItemsInParentFolderAfterDrop = useCallback(
    (
      itemsInsideParent: OneOfProjectListElements[],
      location: number,
    ): OneOfProjectListElements[] =>
      [
        ...itemsInsideParent.slice(0, location),
        ...itemsInsideParent.slice(location + 1),
      ].map((item, index) => ({
        ...item,
        location: index,
      })),
    [],
  );

  const getModifiedItemsInDroppedToParentFolderAfterDrop = useCallback(
    (
      dropType: DropType,
      droppedToLocation: number,
      itemsInsideDroppedToParent: OneOfProjectListElements[],
      draggedItemWithNewLocations: OneOfProjectListElements,
    ): OneOfProjectListElements[] => {
      if (dropType === 'inside') {
        return [draggedItemWithNewLocations, ...itemsInsideDroppedToParent].map(
          (item, index) => ({
            ...item,
            location: index,
          }),
        );
      }
      return (
        dropType === 'below'
          ? [
              ...itemsInsideDroppedToParent.slice(0, droppedToLocation + 1),
              draggedItemWithNewLocations,
              ...itemsInsideDroppedToParent.slice(droppedToLocation + 1),
            ]
          : [
              // above
              ...itemsInsideDroppedToParent.slice(0, droppedToLocation),
              draggedItemWithNewLocations,
              ...itemsInsideDroppedToParent.slice(droppedToLocation),
            ]
      )
        .filter(
          (item) =>
            !(
              item.id === draggedItemWithNewLocations.id &&
              !(
                item.location === draggedItemWithNewLocations.location &&
                item.parent_id === draggedItemWithNewLocations.parent_id
              )
            ),
        )
        .map((item, index) => ({
          ...item,
          location: index,
        }));
    },
    [],
  );

  const onDrop = useCallback(
    async ({
      dropType,
      draggedItem,
      location,
      droppedToParentId,
      droppedToLocation,
      itemsInsideParent,
      itemsInsideDroppedToParent,
    }: {
      dropType: DropType;
      draggedItem: OneOfProjectListElements;
      location: number;
      droppedToParentId: string | null;
      droppedToLocation: number;
      itemsInsideParent: OneOfProjectListElements[];
      itemsInsideDroppedToParent: OneOfProjectListElements[];
    }): Promise<void> => {
      const ids: Record<string, number> = {};

      const resultingItems = [
        // dropTo array has to come first here!
        ...getModifiedItemsInDroppedToParentFolderAfterDrop(
          dropType,
          droppedToLocation,
          itemsInsideDroppedToParent,
          {
            ...draggedItem,
            parent_id: droppedToParentId,
            location: droppedToLocation,
          },
        ),
        ...getModifiedItemsInParentFolderAfterDrop(itemsInsideParent, location),
      ].filter((item) => {
        ids[item.id] = ids[item.id] ? ids[item.id] + 1 : 1;
        return ids[item.id] === 1;
      });

      await updateProjectAndFolderLocations(resultingItems);

      // Expand the parent folder if the item was dropped inside it
      if (droppedToParentId) {
        setElementExpanded(droppedToParentId, true, true);
      }
    },
    [
      getModifiedItemsInDroppedToParentFolderAfterDrop,
      getModifiedItemsInParentFolderAfterDrop,
      updateProjectAndFolderLocations,
    ],
  );

  const onProjectSelectorDrop: ProjectSelectorDrop = useCallback(
    async (dragId, dropId, dropType = 'inside') => {
      if (!dropId) {
        return;
      }
      const folders = getFolders();
      const projectInfos = getProjectsLookup();

      const items = [...folders, ...Object.values(projectInfos)];
      const draggedItem = items.find(({ id }) => id === dragId);
      const droppedToItem = items.find(({ id }) => id === dropId);

      if (
        !draggedItem ||
        (!droppedToItem && dropId) ||
        draggedItem.id === droppedToItem?.id
      ) {
        return;
      }

      const parentId = draggedItem.parent_id;
      const location = draggedItem.location;
      const droppedToParentId =
        (dropType === 'inside'
          ? droppedToItem?.id
          : droppedToItem?.parent_id) ?? null;
      const droppedToLocation = droppedToItem?.location;

      const itemsInsideParent = items
        .filter(({ parent_id }) => parent_id === parentId)
        .sort((a, b) => a.location - b.location);
      const itemsInsideDroppedToParent = items
        .filter(
          ({ id, parent_id }) =>
            parent_id === droppedToParentId && id !== dragId,
        )
        .sort((a, b) => a.location - b.location);

      await onDrop({
        dropType,
        draggedItem,
        location,
        droppedToParentId: droppedToParentId ?? null,
        droppedToLocation:
          droppedToLocation === undefined
            ? itemsInsideParent.length + 1
            : droppedToLocation, // can be null
        itemsInsideParent,
        itemsInsideDroppedToParent,
      });
    },
    [onDrop],
  );

  return onProjectSelectorDrop;
};
