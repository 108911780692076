import React, { useMemo } from 'react';
import { DeleteForever } from '@mui/icons-material';
import { KebabMenu } from '../../kebab-menu/KebabMenu';
import { IProjectFolder } from '../../../../../shared/models/folder.interface';
import { useDeleteFolder } from '../../../store/folder/folder.hook';

interface IProjectFolderKebabMenuInput {
  folder: IProjectFolder;
}

const ProjectFolderKebabMenu: React.FC<IProjectFolderKebabMenuInput> = ({
  folder,
}) => {
  const { id } = folder;
  const deleteFolder = useDeleteFolder();

  const items = useMemo(
    () => [
      {
        id: 'delete',
        defaultMessage: 'Delete folder',
        icon: <DeleteForever />,
        onClick: () => deleteFolder(id),
      },
    ],
    [deleteFolder, id],
  );

  return <KebabMenu id={id} items={items} tooltipTitle="Product"></KebabMenu>;
};

export default ProjectFolderKebabMenu;
