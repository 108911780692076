import React, {
  ChangeEvent,
  FocusEvent,
  KeyboardEventHandler,
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { Drawer, Box, IconButton, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { AddCircleOutline } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import NodonTextField from './NodonTextField';
import ExpressionSelect from './ExpressionSelect';
import { useExpressionSelect } from '../hooks/useExpressionSelect';
import NodonSelect, {
  NODON_SELECT_ID,
  NODON_SELECT_OPEN_ID,
} from './NodonSelect';
import { useMouseEventCallback } from '../hooks/events.hook';
import {
  ExpressionValue,
  IElement,
} from '../../../shared/models/project.interface';
import {
  SelectableQuantityUnit,
  selectableQuantityUnits,
} from '../../../shared/models/unit.interface';
import { IElementQuantityExpressionProperty } from '../../../shared/models/element_quantities.interface';
import { IElementExpressionProperty } from '../../../shared/models/element_property.interface';
import { ExpressionVariables } from '../../../shared/helpers/expression_variables_helpers';
import shallowEqual from '../../../shared/helpers/array_helpers';
import { ValidRecordKeys } from '../../../shared/models/type_helpers.interface';
import { useSolveExpression } from '../hooks/useElementExpressionVariables';
import { debounce, isError } from 'lodash';
import { createExpression } from '../../../shared/helpers/expression_factory_helpers';
import { useUIState } from '../store/ui';
import { useIsReadonly } from '../hooks/user.hook';
import { SidePanelConstant } from '../style/constants';

const EXPRESSION_INPUT_PANEL_INPUT_ID = 'expression-input-panel-input';
const EXPRESSION_INPUT_PANEL_CONTAINER = 'expression-input-panel-container';
const NODON_CANCEL_BUTTON = 'nodon-cancel-button';
const NODON_SAVE_BUTTON = 'nodon-save-button';

export interface IExpressionInputPanelOutput {
  expressionValue?: ExpressionValue;
  unit?: SelectableQuantityUnit;
}

type ExpressionInputPanelReturnValue = IExpressionInputPanelOutput | undefined;

export interface IExpressionInputOptions {
  expressionValue?: ExpressionValue;
  fallbackExpressionValue?: ExpressionValue;
  min?: number;
  max?: number;
  unit?: SelectableQuantityUnit;
  source?:
    | IElement
    | IElementExpressionProperty
    | IElementQuantityExpressionProperty;
  variables?: ExpressionVariables;
  disableUnits?: boolean;
  selectableUnits?: SelectableQuantityUnit[];
  onChange?: (state: IExpressionInputPanelOutput) => void;
  onDebouncedChange?: (state: IExpressionInputPanelOutput) => void;
  onErrorStateChange?: (isError: boolean) => void;
}

type ExpressionInputPanelState = Pick<
  IExpressionInputOptions,
  'expressionValue' | 'unit'
> & {
  expression?: string;
};

type EmitFn = (state: ExpressionInputPanelReturnValue) => void;

export type ExpressionInputPanelOpenFn = (
  options: IExpressionInputOptions,
) => Promise<ExpressionInputPanelReturnValue>;

export type ExpressionInputPanelCloseFn = (
  cancel?: boolean,
) => ExpressionInputPanelReturnValue;

const globals: {
  openFn?: ExpressionInputPanelOpenFn;
  closeFn?: ExpressionInputPanelCloseFn;
} = {};
const voidFn = () => {
  // Empty
};
const promiseFn = () => Promise.resolve();

/**
 * Check if an element is the input field of this component
 * @param element
 * @returns
 */
export const isExpressionInputPanelInput = (
  element?: Element | EventTarget | null,
): element is HTMLInputElement =>
  !!element &&
  'id' in element &&
  element.id === EXPRESSION_INPUT_PANEL_INPUT_ID;

const ExpressionInputPanel: React.FC = () => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const inputRef = useRef<HTMLInputElement>();
  const errorRef = useRef<string>();
  const currentStateRef = useRef<Readonly<ExpressionInputPanelState>>();
  const optionsRef = useRef<Readonly<IExpressionInputOptions>>();
  const promiseRef = useRef<Promise<ExpressionInputPanelReturnValue>>();
  const promiseResolveRef = useRef<EmitFn>();

  const [localExpression, setLocalExpression] = useState<string>('');
  const [localUnit, setLocalUnit] =
    useState<IExpressionInputOptions['unit']>('kg');
  const localExpressionValue = useSolveExpression(
    localExpression,
    optionsRef.current,
  );

  const {
    matchingExpressions,
    activeExpressionIndex,
    setActiveExpressionIndex,
    handlePlusMenuClick,
    chooseExpression,
    customOnKeyDown: selectKeyDown,
    onKeyUp: selectOnKeyUp,
    close: closeExpressionSelect,
  } = useExpressionSelect(
    inputRef,
    localExpression,
    setLocalExpression,
    optionsRef.current?.variables,
  );

  const { setExpressionInputPanelError, setIsExpressionInputPanelOpen } =
    useUIState('setExpressionInputPanelError', 'setIsExpressionInputPanelOpen');

  const readonly = useIsReadonly();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsExpressionInputPanelOpen(isOpen);
  }, [isOpen, setIsExpressionInputPanelOpen]);

  // Check if expression and unit have changed since opening pael
  const hasChanged = useCallback((): boolean => {
    const options = optionsRef.current;
    const originalExpressionValue = options?.expressionValue;
    const { expressionValue, unit } = currentStateRef.current ?? {};
    const expression = expressionValue?.expression;

    if (unit !== options?.unit) {
      return true;
    }
    if (expressionValue === options?.expressionValue) {
      return false;
    }

    // Special case for empty expression
    if (!expression) {
      return !!originalExpressionValue?.expression;
    }

    return true;
  }, []);

  // Return an output if expression is valid and properties has changed
  const getReturnValue = useCallback(
    (ignoreHasChanged?: boolean): IExpressionInputPanelOutput | undefined => {
      const { unit, expressionValue, expression } =
        currentStateRef.current ?? {};

      if (!ignoreHasChanged && !hasChanged()) {
        return;
      }

      // Only return if expression is valid and has changed (compared to provided options)
      if (expressionValue && !errorRef.current) {
        const options = optionsRef.current;
        const hasFallback = !!options?.fallbackExpressionValue;

        // If expression is empty, return undefined if fallback is provided, else return a zero expression
        if (!expression) {
          return {
            expressionValue: hasFallback ? undefined : createExpression(0),
            unit,
          };
        }

        return {
          expressionValue,
          unit,
        };
      }
    },
    [hasChanged],
  );

  // Should never change
  const closePanel: ExpressionInputPanelCloseFn = useCallback(
    (cancel?: boolean) => {
      // Not opened, no need to close
      if (!promiseRef.current) {
        return;
      }

      closeExpressionSelect();

      const resolve = promiseResolveRef.current;
      const returnValue: ExpressionInputPanelReturnValue = cancel
        ? undefined
        : getReturnValue();

      // Error should force the panel open, unless it's cancelled
      if (!cancel && errorRef.current) {
        enqueueSnackbar(errorRef.current, snackbarOptions);

        inputRef.current?.focus();
        return;
      }

      setExpressionInputPanelError(undefined);

      // Resolve promise if it exists
      if (resolve) {
        resolve(returnValue);
      }

      // Reset state
      errorRef.current = undefined;
      promiseRef.current = undefined;
      promiseResolveRef.current = undefined;
      currentStateRef.current = undefined;
      optionsRef.current = undefined;

      setIsOpen(false);

      return returnValue;
    },
    [
      closeExpressionSelect,
      enqueueSnackbar,
      getReturnValue,
      setExpressionInputPanelError,
    ],
  );

  // Should never change
  const openPanel: ExpressionInputPanelOpenFn = useCallback(
    (options) => {
      const currentOptions = optionsRef.current;
      if (
        !promiseRef.current ||
        !shallowEqual(
          options as Record<ValidRecordKeys, any>,
          currentOptions as Record<ValidRecordKeys, any>,
        )
      ) {
        // First close panel if it's open
        closePanel();

        // If there is an error and panel is forced open,
        // return an immediately resolved promise
        if (errorRef.current) {
          return Promise.resolve(undefined);
        }

        // Store input options
        optionsRef.current = options;

        setLocalExpression(
          options.expressionValue?.expression &&
            options.expressionValue?.expression !== '0'
            ? options.expressionValue?.expression
            : '',
        );

        if (!options.unit) throw new Error('Empty options.unit');
        setLocalUnit(options.unit);

        promiseRef.current = new Promise<ExpressionInputPanelReturnValue>(
          (resolve) => {
            promiseResolveRef.current = resolve;
          },
        );

        setIsOpen(true);
        setTimeout(() => {
          inputRef.current?.focus();
        });
      }

      // Return promise for use to use await
      return promiseRef.current;
    },
    [closePanel],
  );

  // Make open and close functions available to hooks
  useEffect(() => {
    globals.openFn = openPanel;
    globals.closeFn = closePanel;
  }, [openPanel, closePanel]);

  // Update current state when local state changes
  useEffect(() => {
    const options = optionsRef.current;
    if (options) {
      const expressionValue = !isError(localExpressionValue)
        ? localExpressionValue
        : undefined;

      errorRef.current = isError(localExpressionValue)
        ? localExpressionValue.message
        : undefined;

      setExpressionInputPanelError(errorRef.current);

      currentStateRef.current = {
        expressionValue,
        expression: localExpression,
        unit: localUnit,
      };

      const { onChange, onDebouncedChange } = options;
      const returnValue = getReturnValue(true);

      // Note that this can be fired AFTER close & open have happend
      if (onChange && returnValue) {
        onChange(returnValue);
      }
      if (onDebouncedChange) {
        const options = optionsRef.current;
        debounce(() => {
          const returnValue = getReturnValue();
          // Only run if options have not changed since debounce was triggered
          // so user have not swapped expression to edit and we overwrite it with other value
          if (optionsRef === options && returnValue) {
            onDebouncedChange(returnValue);
          }
        }, 200);
      }
    }
  }, [
    getReturnValue,
    hasChanged,
    localExpression,
    localExpressionValue,
    localUnit,
    setExpressionInputPanelError,
  ]);

  const onExpressionChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      setLocalExpression(event.currentTarget.value);
    },
    [setLocalExpression],
  );

  const onUnitChange = useCallback(
    (value: string): void => {
      setLocalUnit(value as SelectableQuantityUnit);
      inputRef.current?.focus();
    },
    [inputRef],
  );

  const onScroll = useCallback(
    () => setActiveExpressionIndex(undefined),
    [setActiveExpressionIndex],
  );

  // Prevent clicks on panel to close it
  const onPanelContainerClick = useMouseEventCallback((event) => {
    const buttonOrDiv = event?.currentTarget as HTMLElement;

    if (buttonOrDiv?.classList.contains(EXPRESSION_INPUT_PANEL_CONTAINER)) {
      return;
    }
  });
  const onSaveButtonClick = useCallback(() => {
    closePanel();
  }, [closePanel]);

  const onCancelButtonClick = useCallback(() => {
    closePanel(true);
  }, [closePanel]);

  const onBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>): void => {
      const listElement = event.relatedTarget as HTMLLIElement;

      const buttonOrDiv = event.relatedTarget as
        | HTMLButtonElement
        | HTMLDivElement;

      const isListItem = listElement?.tagName === 'LI';

      const isPanelContainer = buttonOrDiv?.classList.contains(
        EXPRESSION_INPUT_PANEL_CONTAINER,
      );

      const isCancelButton =
        buttonOrDiv?.classList.contains(NODON_CANCEL_BUTTON);

      const isSaveButton = buttonOrDiv?.classList.contains(NODON_SAVE_BUTTON);

      if (buttonOrDiv?.classList.contains(NODON_SELECT_ID)) {
        if (buttonOrDiv.classList.contains(NODON_SELECT_OPEN_ID)) {
          setTimeout(() => inputRef.current?.focus(), 0);
        }
        return;
      }
      if (isPanelContainer || isListItem || isCancelButton || isSaveButton) {
        return;
      }
      closePanel();
    },
    [closePanel],
  );

  const onKeyUp: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        closePanel(true);
      }
      selectOnKeyUp(event);
    },
    [closePanel, selectOnKeyUp],
  );

  const defaultKeyDown: KeyboardEventHandler<
    HTMLInputElement | HTMLButtonElement | HTMLTextAreaElement
  > = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        closePanel();
      }
    },
    [closePanel],
  );

  const expressionInput = useMemo(() => {
    const options = optionsRef.current;
    const units = options?.selectableUnits ?? selectableQuantityUnits;
    const error = errorRef.current;
    const placeholder = options?.fallbackExpressionValue?.expression ?? '0';

    return (
      <NodonTextField
        inputRef={inputRef}
        id={EXPRESSION_INPUT_PANEL_INPUT_ID}
        value={localExpression}
        error={!!error}
        placeholder={placeholder}
        autoComplete="off"
        spellCheck={false}
        onKeyUp={onKeyUp}
        customOnKeyDown={
          matchingExpressions.length > 0 ? selectKeyDown : defaultKeyDown
        }
        onChange={onExpressionChange}
        onBlur={onBlur}
        sx={{ width: '100%' }}
        InputProps={
          localUnit && {
            endAdornment: (
              <>
                <IconButton
                  className={NODON_SELECT_ID}
                  aria-label="expression menu button"
                  onKeyDown={selectKeyDown}
                  onClick={handlePlusMenuClick}
                  size="large"
                >
                  <AddCircleOutline />
                </IconButton>
                <NodonSelect
                  buttonLabel={localUnit}
                  options={units}
                  onChange={onUnitChange}
                  disabled={options?.disableUnits}
                />
              </>
            ),
          }
        }
      />
    );
  }, [
    localExpression,
    onKeyUp,
    matchingExpressions.length,
    selectKeyDown,
    defaultKeyDown,
    onExpressionChange,
    onBlur,
    localUnit,
    handlePlusMenuClick,
    onUnitChange,
  ]);

  return (
    <Drawer
      tabIndex={0}
      classes={{
        paper: classes.paper,
      }}
      anchor={'bottom'}
      variant="persistent"
      open={isOpen && !readonly}
      className={EXPRESSION_INPUT_PANEL_CONTAINER}
      onClick={onPanelContainerClick}
    >
      <Box className={classes.root}>
        {expressionInput}
        <Box sx={{ marginTop: 2, float: 'right' }}>
          <Button className={NODON_CANCEL_BUTTON} onClick={onCancelButtonClick}>
            Cancel
          </Button>
          <Button className={NODON_SAVE_BUTTON} onClick={onSaveButtonClick}>
            Save
          </Button>
        </Box>
        <ExpressionSelect
          matchingExpressions={matchingExpressions}
          activeExpressionIndex={activeExpressionIndex}
          onScroll={onScroll}
          onClick={chooseExpression}
        />
      </Box>
    </Drawer>
  );
};

const snackbarOptions = {
  variant: 'error',
  autoHideDuration: 5000,
  /**
   * Move snackbar when expression panel is open
   */
  style: {
    height: 40,
    bottom: 105,
    position: 'relative',
    marginLeft: -12,
    padding: '0 8px',
  },
} as const;

const useStyles = makeStyles()(({ spacing, palette }) => ({
  root: {
    padding: spacing(2),
    backgroundColor: 'white',
    borderTop: '1px solid' + palette.primary.light,
  },

  paper: {
    backgroundColor: 'unset',
    border: 'none',
    overflow: 'visible',
    width: `calc(100% - ${SidePanelConstant.WIDTH}px)`,
  },
}));

export const useOpenExpressionInputPanel = (): ExpressionInputPanelOpenFn =>
  globals.openFn ?? (promiseFn as ExpressionInputPanelOpenFn);

export const useCloseExpressionInputPanel = (): ExpressionInputPanelCloseFn =>
  globals.closeFn ?? (voidFn as ExpressionInputPanelCloseFn);

export default ExpressionInputPanel;
