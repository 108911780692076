import React, { FC, useCallback, useMemo } from 'react';
import ProjectActionMenu, { ProjectActionMenuItem } from './ProjectActionMenu';
import { Project } from '../../../shared/models/project.interface';
import { enqueueSnackbar } from 'notistack';
import { useUpdateProject } from '../store/project';
import { Refresh } from '@mui/icons-material';
import amplitudeLog from '../amplitude';
import { useForceUpdateProductsInProjectCallback } from '../hooks/useProducts';
import { projectActionMenuIconStyles } from '../style/constants';

const ProjectSettingsActionMenu: FC<{ project: Project }> = ({ project }) => {
  const updateProject = useUpdateProject();

  const updateProductsInProjectCallback =
    useForceUpdateProductsInProjectCallback();

  const handleUpdateProducts = useCallback(async () => {
    const updatedProject = updateProductsInProjectCallback(project);

    if (updatedProject !== project) {
      await updateProject(updatedProject, {
        successMessage: `Products updated in "${project.name}"`,
        errorMessage: `Failed to update products in "${project.name}"`,
        logError: true,
      });
      amplitudeLog('Project Products Update', {
        ProjectId: project.id,
      });
    } else {
      enqueueSnackbar('Products are already up to date', {
        variant: 'info',
      });
    }
  }, [project, updateProductsInProjectCallback, updateProject]);

  const extraItems = useMemo<ProjectActionMenuItem[]>(
    () => [
      {
        id: 'update_products',
        defaultMessage: 'Update products in project',
        icon: <Refresh {...projectActionMenuIconStyles} />,
        onClick: handleUpdateProducts,
      },
    ],
    [handleUpdateProducts],
  );

  return (
    <ProjectActionMenu
      projectId={project.id}
      projectName={project.name}
      locked={!!project.locked}
      archived={!!project.archived}
      extraItems={extraItems}
      insertItemsLast
    />
  );
};

export default ProjectSettingsActionMenu;
