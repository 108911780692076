import React, { useCallback } from 'react';
import { Collapse, List } from '@mui/material';
import {
  IProductElementParentItem,
  ProductID,
} from '../../../../shared/models/product.interface';
import ProductsListChildItem from './ProductsListChildItem';
import ProductSelectorButton from '../ProductSelectorButton';
import { useIsReadonly } from '../../hooks/user.hook';
import { useProductSwitch } from '../../hooks/product-switch.hook';
import ProductSelector from '../../projects/EditProject/ProductSelector';
import {
  useIsElementExpanded,
  useToggleElementExpanded,
} from '../../hooks/expand-elements.hook';
import { Row } from '../ElementList/Row';
import { RowCell } from '../ElementList/RowCell';
import ExpandIcon from '../ElementList/ExpandIcon';
import { useBooleanState } from '../../hooks/hooks';
import { useSelectedVersionProduct } from '../../hooks/useProducts';
import {
  CONTENT_CELL_WIDTH_RESPONSIVE,
  LIST_ITEM_HEIGHT,
  LIST_SPACING,
  ROOT_CELL_WIDTH,
  ROOT_CELL_WIDTH_RESPONSIVE,
} from '../ElementList/list.constants';
import HorizontalBarCharts from '../charts/HorizontalBarCharts';
import ExpressionTypography from '../ExpressionTypography';

interface ProductsListParentItemProps extends IProductElementParentItem {
  selected: boolean;
  setSelectedProductId: (id: ProductID) => void;
}

const ProductsListParentItem: React.FC<ProductsListParentItemProps> = ({
  productId,
  name,
  unit,
  quantitiesSum,
  co2eSum,
  costSum,
  articles,
  selected,
  setSelectedProductId,
  disableProductSwitch,
}) => {
  const product = useSelectedVersionProduct(productId);
  const readonly = useIsReadonly();
  const expanded = useIsElementExpanded(productId);
  const toggleExpanded = useToggleElementExpanded(product);

  const [hover, startHover, stopHover] = useBooleanState(false);

  const {
    isProductSelectorOpen,
    openProductSelector,
    closeProductSelector,
    switchProducts,
  } = useProductSwitch(...articles);

  const handleProductClick = useCallback(() => {
    if (selected) {
      openProductSelector();
    }
    setSelectedProductId(productId);
  }, [openProductSelector, productId, selected, setSelectedProductId]);

  const handleContainerClick = useCallback(() => {
    setSelectedProductId(productId);
  }, [productId, setSelectedProductId]);

  return (
    <>
      <Row
        hover={hover}
        height={LIST_ITEM_HEIGHT}
        spacing={LIST_SPACING}
        padding={true}
        onClick={handleContainerClick}
        onMouseOver={startHover}
        onMouseLeave={stopHover}
        onDoubleClick={toggleExpanded}
        selected={selected}
      >
        {/* Expand icon */}
        <RowCell width={ROOT_CELL_WIDTH.ICON}>
          <ExpandIcon element={product} />
        </RowCell>

        {/* Content */}
        <RowCell
          width={ROOT_CELL_WIDTH.CONTENT}
          responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.CONTENT}
        >
          <Row>
            {/* Product selector */}
            <RowCell>
              <ProductSelectorButton
                onClick={handleProductClick}
                selected={selected}
                productName={name}
                disabled={disableProductSwitch || readonly}
              />
            </RowCell>
            <RowCell
              align="right"
              width={CONTENT_CELL_WIDTH_RESPONSIVE.EXPRESSION}
              responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.EXPRESSION}
            >
              <ExpressionTypography value={quantitiesSum} unit={unit} />
            </RowCell>
          </Row>
        </RowCell>

        {/* Bar charts */}
        <RowCell
          width={ROOT_CELL_WIDTH.BAR}
          responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.BAR}
        >
          <HorizontalBarCharts co2e={co2eSum} cost={costSum} hover={hover} />
        </RowCell>
      </Row>

      <Collapse in={expanded}>
        <List>
          {articles
            .filter((article) => article.quantity)
            .map((article, index) => (
              <ProductsListChildItem
                key={'prod-child-' + index}
                article={article}
              />
            ))}
        </List>
      </Collapse>
      {isProductSelectorOpen && (
        <ProductSelector
          open={isProductSelectorOpen}
          onClose={closeProductSelector}
          onSave={switchProducts}
          productId={productId}
        />
      )}
    </>
  );
};

export default ProductsListParentItem;
