import { IElement, OneOfPropertyElements } from '../models/project.interface';
import * as uuid from 'uuid';
import { omitUndefined } from './object_helpers';
import { createExpression } from './expression_factory_helpers';
import {
  CreatedElementProperty,
  ElementPropertyInputType,
  ElementPropertyInputTypeMap,
  ElementPropertySource,
  ElementPropertySourceID,
  FactoryPropertyInputTypeMap,
  IElementExpressionProperty,
  IElementProperty,
  IElementPropertyFactoryOption,
  IElementPropertyOption,
  IElementPropertySourceIdProperties,
  IElementSelectProperty,
  IElementSwitchProperty,
  IFactoryExpressionProperty,
  IFactoryProperty,
  IFactorySelectProperty,
  IFactorySwitchProperty,
} from '../models/element_property.interface';
import { createId } from './element_factory_helpers';
import { formatThousands } from './math_helpers';
import { capitalize, snakeCase } from 'lodash';
import {
  getElementProperties,
  getElementPropertyInputType,
  getSelectPropertyCountValue,
  getSelectPropertyOptionValue,
} from './element_property_helpers';
import { isUppercase, findFreeName } from './string_helpers';
import { validateProperty } from '../validation/project.validation';
import { resolveAutoRecipeId } from './recipe_helpers';

/**
 * Create a new property of a specific type.
 * Can also be used to shallow clone a property.
 * @param defaults A partial with overides of the default values. Need to contain kind
 * @returns
 */
export function addElementProperties<
  T extends IFactoryProperty,
  E extends OneOfPropertyElements,
>(element: E, ...propertyDefaults: T[]): E {
  // Make sure to clone properties
  const properties = [...getElementProperties(element)];

  if (!propertyDefaults?.length) {
    return element;
  }
  propertyDefaults.forEach((defaults) =>
    properties.push(createElementProperty(defaults, properties)),
  );
  return {
    ...element,
    properties,
  };
}

/**
 * Create a new property of a specific type.
 * Can also be used to shallow clone a property.
 * @param defaults A partial with overides of the default values. Need to contain kind
 * @returns
 */
export function createElementProperty<
  T extends IFactoryProperty = IFactoryExpressionProperty,
>(
  defaults: T = {} as T,
  existingProperties: IElementProperty[] = [],
  regenerateIds?: boolean,
): CreatedElementProperty<T['type']> {
  return createElementPropertyOfInputType(
    defaults.type,
    defaults,
    existingProperties,
    regenerateIds,
  ) as CreatedElementProperty<T['type']>;
}

export const createElementProperties = (
  factoryProperties: IFactoryProperty[] = [],
): IElementProperty[] =>
  factoryProperties.reduce(
    (properties, factoryProperty) => [
      ...properties,
      createElementProperty(factoryProperty, properties),
    ],
    [] as IElementProperty[],
  );

/**
 * Create a new ElementProperty with recipe_id or category_id set
 * @param element
 * @param source
 * @param sourceId
 * @returns
 */
export const createElementPropertyOfSource = <
  T extends IFactoryProperty = IFactoryExpressionProperty,
>(
  element: IElement,
  source?: ElementPropertySource,
  sourceId?: ElementPropertySourceID,
  defaults: T = {} as T,
  regenerateIds?: boolean,
): CreatedElementProperty<T['type']> => {
  return createElementProperty(
    {
      defaults,
      ...createSourceIdProperty(source, sourceId),
    },
    element.properties,
    regenerateIds,
  ) as CreatedElementProperty<T['type']>;
};

/**
 * Create a new property of a specific type.
 * Can also be used to shallow clone a property.
 * @param type Type of property which should be created, default to expression
 * @param defaults A partial with overides of the default values
 * @param existingProperties Optional existingProperties used to generate unique name for property
 * @returns
 */
export function createElementPropertyOfInputType<
  T extends
    keyof ElementPropertyInputTypeMap = ElementPropertyInputType.Expression,
>(
  type: T = ElementPropertyInputType.Expression as T,
  defaults: Partial<FactoryPropertyInputTypeMap[T]> = {},
  existingProperties?: IElementProperty[],
  regenerateIds?: boolean,
): CreatedElementProperty<T> {
  switch (type) {
    case ElementPropertyInputType.Expression: {
      return createElementExpressionProperty(
        defaults as IFactoryExpressionProperty,
        existingProperties,
        regenerateIds,
      ) as CreatedElementProperty<T>;
    }
    case ElementPropertyInputType.Select: {
      return createElementSelectProperty(
        defaults as IFactorySelectProperty,
        existingProperties,
        regenerateIds,
      ) as CreatedElementProperty<T>;
    }
    case ElementPropertyInputType.Switch: {
      return createElementSwitchProperty(
        defaults as IFactorySwitchProperty,
        existingProperties,
        regenerateIds,
      ) as CreatedElementProperty<T>;
    }

    default: {
      throw new Error(`Can't create element of unknown type ${type as string}`);
    }
  }
}

/**
 * Create a new ElementProperty
 * @param defaults Default values for the property
 * @param element Optional element generate unique name for property
 * @param existingProperties Optional existingProperties used to generate unique name for property
 * @returns
 */
const createElementExpressionProperty = (
  defaults: IFactoryExpressionProperty = {},
  existingProperties?: IElementProperty[],
  regenerateIds = true,
): IElementExpressionProperty => {
  const count =
    defaults.count !== undefined || defaults.fallbackCount === undefined
      ? createExpression(defaults.count)
      : undefined;
  const fallbackCount =
    defaults.fallbackCount !== undefined
      ? createExpression(defaults.fallbackCount)
      : undefined;

  return validateProperty({
    unit: 'kg',
    ...omitUndefined({ ...defaults, count, fallbackCount }),
    id: createId(defaults, regenerateIds),
    name: getPropertyName(defaults, existingProperties),
    type: ElementPropertyInputType.Expression,
  });
};

/**
 * Create a new ElementProperty
 * @param defaults Default values for the property
 * @param existingProperties Optional existingProperties used to generate unique name for property
 * @returns
 */
const createElementSelectProperty = (
  defaults: IFactorySelectProperty = { type: ElementPropertyInputType.Select },
  existingProperties?: IElementProperty[],
  regenerateIds = true,
): IElementSelectProperty => {
  const count = getSelectPropertyCountValue(defaults);
  // Fallback to count if no options are provided to at least provide one value
  const options = createPropertySelectOptions(
    defaults.options?.length ? defaults.options : [count],
  );

  return validateProperty({
    ...omitUndefined(defaults),
    count,
    type: ElementPropertyInputType.Select,
    name: getPropertyName(defaults, existingProperties),
    id: createId(defaults, regenerateIds),
    options,
  });
};

/**
 * Create a new ElementProperty
 * @param defaults Default values for the property
 * @param existingProperties Optional existingProperties used to generate unique name for property
 * @returns
 */
const createElementSwitchProperty = (
  defaults: Partial<IFactorySwitchProperty> = {},
  existingProperties?: IElementProperty[],
  regenerateIds = true,
): IElementSwitchProperty => {
  return validateProperty({
    count: false,
    ...omitUndefined(defaults),
    type: ElementPropertyInputType.Switch,
    name: getPropertyName(defaults, existingProperties),
    id: createId(defaults, regenerateIds),
  });
};

/**
 * Create a new ElementProperty
 * @param defaults Default values for the property
 * @param element Optional element generate unique name for property
 * @returns
 */
const getPropertyName = (
  defaults: Pick<IFactoryProperty, 'name'> = {},
  existingProperties: IElementProperty[] = [],
): string => {
  const names = existingProperties?.map((property) => property.name);
  return findFreeName(names, defaults.name ?? 'property');
};

/**
 * Create a new ElementProperty with recipe_id or category_id set
 * @param element
 * @param source
 * @param sourceId
 * @returns
 */
export const createSourceIdProperty = (
  source?: ElementPropertySource,
  sourceId?: ElementPropertySourceID,
): IElementPropertySourceIdProperties =>
  source && sourceId !== undefined
    ? {
        [source]:
          source === ElementPropertySource.Recipe
            ? resolveAutoRecipeId(sourceId)
            : sourceId,
      }
    : {};

/**
 * Create a valid options array from list of "stripped" options
 * @param options
 * @returns
 */
const createPropertySelectOptions = (
  options: IElementPropertyFactoryOption[],
): IElementPropertyOption[] =>
  options.map((option) => {
    const value = getSelectPropertyOptionValue(option);
    const label =
      (typeof option === 'object' && option.label) || labelFromValue(value);
    return { label, value };
  });

/**
 * Apply a new id and [type]: typeId to property
 * @param property
 * @param source
 * @param sourceId
 * @returns
 */
export const applyIdAndSourceToProperty = <T extends IElementProperty>(
  property: Omit<T, 'id'>,
  source?: ElementPropertySource,
  sourceId?: ElementPropertySourceID,
): T => {
  return {
    ...property,
    id: uuid.v4(),
    ...createSourceIdProperty(source, sourceId),
  } as T;
};

export const labelFromValue = (value?: string | number | boolean): string => {
  if (typeof value === 'number') {
    return formatThousands(value);
  } else if (typeof value === 'boolean') {
    return value ? 'On' : 'Off';
  } else if (!value) {
    return 'None';
  }

  // If the value is all uppercase, or contains spaces, return it as-is since it's most likely already well formatted
  if (isUppercase(value) || value.indexOf(' ') > -1) {
    return value;
  }

  return capitalize(snakeCase(value).replace(/[-_.\s]+/gi, ' '));
};

export const isFactoryExpressionProperty = (
  prop: IFactoryProperty | undefined,
): prop is IFactoryExpressionProperty =>
  !!prop &&
  getElementPropertyInputType(prop) === ElementPropertyInputType.Expression;
