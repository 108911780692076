import { ADMIN_DOMAIN_NAMES } from '../constants';
import { User } from '../models/user.interface';
import { nameMatchTrim } from './string_helpers';
import { Project } from '../models/project.interface';
import { Recipe } from '../models/recipe.interface';
import { Product } from '../models/product.interface';

/**
 * @returns true if the user's email domain is in the list of admin domains
 */
export const isAdmin = (user: User) =>
  ADMIN_DOMAIN_NAMES.some((domain) => user.name.includes(domain));

/**
 * @returns true if the user is a member of an admin group
 */
export const isAdminGroupMember = (user: User) =>
  user.organizations.some((org) =>
    ADMIN_DOMAIN_NAMES.includes(nameMatchTrim(org.name)),
  );

export const getUserOrganizationNames = (user: User): string[] => {
  return user.organizations.map((org) => org.name);
};

export const canWrite = (
  uid: string,
  organization: string,
  resource: Project | Recipe | Product,
): boolean => {
  const { organizations, owner } = resource;

  const isOwner = uid === owner;
  const isMember = !!organizations?.includes(organization);

  const isProject = 'buildings' in resource;

  if (isProject) {
    const { archived, locked } = resource;

    if (archived || (!isOwner && locked)) {
      return false;
    }
  }

  return isOwner || isMember;
};
