import { useCallback, useMemo } from 'react';
import {
  GetTooltipLabelFn,
  RenderTooltipFn,
} from '../../../shared/models/chart.interface';
import {
  externalTooltipHandler,
  getFormattedValueByGFA,
} from '../helpers/chart_helpers';
import { useProjectBuildingGFA } from '../store/project';
import { formatThousands } from '../../../shared/helpers/math_helpers';
import { convertToThousandSEK } from '../../../shared/helpers/conversion_helpers';

interface BarChartUtils {
  renderTooltip: RenderTooltipFn;
  getTooltipLabel: GetTooltipLabelFn;
}

export const useBarChart = (): BarChartUtils => {
  const gfa = useProjectBuildingGFA();

  const getTooltipLabel: GetTooltipLabelFn = useCallback(
    (value, { selectedUnit, displayFactor, unitLabel }) => {
      value = convertToThousandSEK(value, unitLabel);

      const formattedValue = selectedUnit
        ? formatThousands(value)
        : getFormattedValueByGFA(value, gfa);

      const unitPerFactor =
        unitLabel && displayFactor
          ? `${unitLabel} / ${selectedUnit ?? 'GFA'}`
          : '';

      return [formattedValue, unitPerFactor];
    },
    [gfa],
  );

  const renderTooltip: RenderTooltipFn = useCallback(
    (context, options) => {
      externalTooltipHandler(context, gfa, options);
    },
    [gfa],
  );

  return useMemo(
    () => ({ renderTooltip, getTooltipLabel }),
    [getTooltipLabel, renderTooltip],
  );
};
