import { useEffect, useMemo, useRef } from 'react';
import {
  IComment,
  ICommentID,
} from '../../../../shared/models/comment.interface';
import { IElementID } from '../../../../shared/models/project.interface';
import { useCommentsStore } from './comment.store';
import { orderBy } from 'lodash';
import { COMMENT_MAX_LENGTH } from '../../../../shared/constants';
import { useDebounce } from '../../hooks/hooks';
import { createLocalStorageRecordStore } from '../../helpers/local-storage.helpers';

export const { getItem: getDraft, setItem: setDraft } =
  createLocalStorageRecordStore<
    IComment['id'],
    IComment['message'] | undefined
  >('comment_drafts');

/**
 * @param id
 * @returns the comments belonging to the element
 */
export const useElementCommments = (id: IElementID): IComment[] => {
  const comments = useCommentsStore(({ comments }) => comments);

  return useMemo(
    () => comments.filter((comment) => comment.element_id === id),
    [comments, id],
  );
};

export const useSortedElementCommments = (
  id: IElementID,
  sortBy: keyof Omit<
    IComment,
    'project_id' | 'element_id' | 'id'
  > = 'created_at',
): IComment[] => {
  const comments = useElementCommments(id);

  return useMemo(() => orderBy(comments, sortBy, 'desc'), [comments, sortBy]);
};

export const useStoreDraftComment = (
  id: ICommentID,
  message: string,
  newMessage: string,
) => {
  const editingId = useCommentsStore(({ editingId }) => editingId);
  const debouncedInputValue = useDebounce(newMessage, 300);

  useEffect(() => {
    if (
      editingId === id &&
      debouncedInputValue !== message &&
      debouncedInputValue.length <= COMMENT_MAX_LENGTH
    ) {
      setDraft(id, debouncedInputValue);
    }
  }, [debouncedInputValue, editingId, id, message, newMessage]);
};

export const useFocusCommentInput = (id: ICommentID, message: string) => {
  const editingId = useCommentsStore(({ editingId }) => editingId);
  const inputRef = useRef<HTMLInputElement | undefined>(undefined);

  useEffect(() => {
    if (inputRef.current && editingId === id) {
      const { current: input } = inputRef;
      const carretPosition = message.length;

      input.focus();
      input.setSelectionRange(carretPosition, carretPosition);
    }
  }, [editingId, id, message.length]);

  return inputRef;
};
