import React, { useCallback, useMemo } from 'react';
import { Add, DeleteForever, FileCopy, ContentCopy } from '@mui/icons-material';
import {
  ElementKind,
  OneOfElements,
} from '../../../../../shared/models/project.interface';
import {
  isGeneratedProductElement,
  isElementWithGeneratedChildren,
  isDeactivated,
} from '../../../../../shared/helpers/element_helpers';
import { isElement } from '../../../../../shared/helpers/recursive_element_helpers';
import amplitudeLog from '../../../amplitude';
import { useAddElement, useRemoveElements } from '../../../store/project';
import { useAddElementVersion } from '../../../hooks/element-version.hook';
import {
  getElementVersions,
  isActiveElementVersion,
} from '../../../../../shared/helpers/element-version.helpers';
import { getSelectedVersion } from '../../../store/ui';
import { getId, omit } from '../../../../../shared/helpers/object_helpers';
import { setElementExpanded } from '../../../hooks/expand-elements.hook';
import {} from 'zustand';
import { IMenuItemData, KebabMenu } from '../../kebab-menu/KebabMenu';

interface IElementKebabMenuInput {
  element: OneOfElements;

  /**
   * If this is in element version picker mode, pass the element version id
   */
  elementVersionId?: string;
}

const ElementKebabMenu: React.FC<IElementKebabMenuInput> = ({
  element,
  elementVersionId,
}) => {
  const addElement = useAddElement();
  const removeElements = useRemoveElements({ showConfirm: true });
  const addElementVersion = useAddElementVersion();
  const isGenerated =
    isGeneratedProductElement(element) ||
    isElementWithGeneratedChildren(element);
  const { id } = element;

  if (!id) {
    throw new Error('Element ID is required');
  }

  const handleRemoveElement = useCallback(() => {
    // Deleting the active element version outside of element version picker mode should delete all versions
    if (!elementVersionId && isActiveElementVersion(element)) {
      const elements = getElementVersions(getSelectedVersion(true), element);
      return removeElements(...elements.map(getId));
    } else {
      return removeElements(id);
    }
  }, [element, elementVersionId, id, removeElements]);

  // const handleRemove

  const items = useMemo(() => {
    const elementMenuItemsDefaultData = [
      {
        onClick: () => {
          amplitudeLog('Element Duplicate', {
            ElementID: id,
          });

          // Remove any versioning of element (not supported yet)
          const newElement = isElement(element)
            ? omit(element, 'isActiveVersion', 'versionId', 'versionName')
            : element;
          return addElement(element, newElement, {
            isSibling: true,
            placement: 'after',
          });
        },
        icon: <FileCopy color="secondary" />,
        id: 'element.duplicate',
        defaultMessage: 'Duplicate element',
      },
      {
        onClick: () => {
          if (isElement(element)) {
            amplitudeLog('Element Version Duplicate', {
              ElementID: id,
            });
            return addElementVersion(element);
          }
        },
        icon: <ContentCopy color="secondary" />,
        id: 'elementVersion.new',
        defaultMessage: 'New element version',
        disabled: isDeactivated(element),
      },
      {
        onClick: handleRemoveElement,
        icon: <DeleteForever color="secondary" />,
        id: 'element.delete',
        defaultMessage: 'Delete element',
      },
    ];

    const groupElementItems: IMenuItemData[] = isGenerated
      ? elementMenuItemsDefaultData
      : [
          {
            onClick: async () => {
              await addElement(element, { kind: ElementKind.Element });

              amplitudeLog('Element New', { Place: 'Nested' });
              setElementExpanded(id, true);
            },
            icon: <Add color="secondary" />,
            id: 'element.new',
            defaultMessage: 'New Element',
          },
          // TODO: Should we be able to add elements this way?
          // {
          //   onClick: () => {
          //     throw new Error('Not implemented');
          //   },
          //   icon: <Add color="secondary" />,
          //   id: 'element_product.add',
          //   defaultMessage: 'Add Product',
          //   disabled: readonly,
          // },

          ...elementMenuItemsDefaultData,
        ];

    const elementItems = elementVersionId
      ? [
          {
            onClick: () => {
              if (isElement(element)) {
                amplitudeLog('Element Version Duplicate', {
                  ElementID: id,
                });
                return addElementVersion(element);
              }
            },
            icon: <ContentCopy color="secondary" />,
            id: 'elementVersion.new',
            defaultMessage: 'New version',
            disabled: window.location.hostname === 'app.nodon.se', // Disable for now
          },
          {
            onClick: handleRemoveElement,
            icon: <DeleteForever color="secondary" />,
            id: 'elementVersion.delete',
            defaultMessage: 'Delete version',
          },
        ]
      : groupElementItems;

    return elementItems;
  }, [
    element,
    handleRemoveElement,
    isGenerated,
    elementVersionId,
    id,
    addElement,
    addElementVersion,
  ]);

  return <KebabMenu id={id} items={items} tooltipTitle="Element"></KebabMenu>;
};

export default ElementKebabMenu;
