import { escapeRegExp } from 'lodash';
import { roundToDecimals } from './math_helpers';

export const toPercent = (value: number, decimals = 0): string => {
  return `${roundToDecimals(value * 100, decimals)}%`;
};

/**
 * Add suffix to string if not already present.
 * @param str
 * @param suffix
 * @returns
 */
export const addSuffix = (str: string | number, suffix: string): string =>
  typeof str === 'string' && str.endsWith(suffix) ? str : str + suffix;

const lengthUnits = [
  'px',
  'em',
  'rem',
  '%',
  'vh',
  'vw',
  'vmin',
  'vmax',
  'fr',
] as const;
type LengthUnit = (typeof lengthUnits)[number];

/**
 * Filter out special characters and spaces and replace åäö with a and o and turn string to lowercase.
 * Note: Only to use to compare names, not to display to user
 * @param name
 * @returns
 */
export const nameMatchTrim = (name = ''): string => {
  return name
    .toLowerCase()
    .trim()
    .replaceAll(/\s+/gi, '')
    .replaceAll(/[åä]/gi, 'a')
    .replaceAll(/ö/gi, 'o')
    .replaceAll(/[^a-z0-9]/gi, '');
};

/**
 * Simplest possible search filter. Returns true if search is empty or item contains search parts.
 * Mutiple search parts are separated by space.
 * @param item
 * @param search
 * @returns
 */
export const searchFilter = (item: string, search?: string): boolean => {
  search = nameMatchTrim(search);
  if (!search) {
    return true;
  }
  return search.split(' ').every((part) => nameMatchTrim(item).includes(part));
};

/**
 * Check if names are matching ignoring case, spaces and special characters
 * @param name1
 * @param name2
 * @returns
 */
export const isNameMatch = (name1?: string, name2?: string): boolean => {
  return nameMatchTrim(name1) === nameMatchTrim(name2);
};

export const addUnit = (
  value: string | number = 0,
  defaultUnit: LengthUnit = 'px',
): string => {
  if (
    typeof value === 'string' &&
    lengthUnits.some((unit) => value.endsWith(unit))
  ) {
    return value;
  }
  return `${value}${defaultUnit}`;
};

export const indentString = (
  str: string,
  depth: number,
  indentationStr = '    ',
): string => indentationStr.repeat(depth) + str;

export const isUppercase = (str: string): boolean => {
  return str === str.toUpperCase();
};

/**
 * Make a sentence from a list of names
 * @example makeSentence('a', 'b', 'c') => 'a, b and c'
 * @param names
 * @returns
 */
export const makeSentence = (...names: (string | undefined)[]): string => {
  const definedNames: string[] = names.filter((name): name is string => !!name);

  if (definedNames.length === 0) {
    return '';
  }

  if (definedNames.length === 1) {
    return definedNames[0];
  }

  const last = definedNames.pop() as string;
  const sentence = `${definedNames.join(', ')} and ${last}`;

  return sentence;
};

export const countOccurrences = (
  text: string,
  search: string,
  ignoreCase = false,
): number => {
  if (!search) {
    return 0;
  }
  const regExp = new RegExp(
    escapeRegExp(search),
    'g' + (ignoreCase ? 'i' : ''),
  );

  // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
  return (text.match(regExp) || []).length;
};

export const inputStringToNumber = (input?: string | number): number => {
  if (!input) {
    return 0;
  }
  if (typeof input === 'number') {
    return input;
  }

  // Replace comma with dot if only one comma and no dot to handle strings like '1,234,567.89'
  if (
    countOccurrences(input, ',') === 1 &&
    countOccurrences(input, '.') === 0
  ) {
    input = input.replace(',', '.');
  }

  return Number(input.replace(/[^0-9.-]+/g, ''));
};

export const convertName = (name: string): string => {
  return name.toLowerCase().replaceAll(' ', '_');
};
/**
 * Naming: if proposed name is exist, return new name
 * new name = proposed name + version number increased by one.
 * (version number = max last number in the same existing name).
 * @param names
 * @param name
 * @param delimiter
 */
export const findFreeName = (
  names: string[] | undefined,
  name: string,
  delimiter = '',
  startNum = 0,
): string => {
  if (!names) return name;
  names = names.map((n) => n?.toLowerCase());
  const lastNumRegEx = /\d+$/i;
  let maxNumInName = 0;
  const lastNumPosition: number = name.search(lastNumRegEx);
  let word = ''; // name without last numbers
  let isWordMatch = false;

  if (lastNumPosition != -1) {
    // if no number at the end
    word = name.slice(0, lastNumPosition).trim();
  } else {
    word = name.trim();
  }

  const sameNamesRegEx = new RegExp(`^${word}(${delimiter})* *[0-9]*$`, 'i');

  names.forEach((item) => {
    if (item?.search(sameNamesRegEx) != -1) {
      const match = lastNumRegEx.exec(item) || [0];
      const num: number = +match[0];
      maxNumInName = num > maxNumInName ? num : maxNumInName;
      isWordMatch = true;
    }
  });

  if (maxNumInName && startNum) {
    maxNumInName = maxNumInName > startNum ? maxNumInName : startNum;
  } else if (startNum) {
    maxNumInName = startNum - 1;
  }

  name = isWordMatch ? addSuffix(word, delimiter) + ++maxNumInName : name;

  return name;
};

export const replaceCharAt = (
  str: string,
  index: number,
  replacement: string,
): string => {
  if (index < 0 || index >= str.length) {
    throw new Error('Index out of bounds');
  }
  return (
    str.substring(0, index) +
    replacement +
    str.substring(index + replacement.length)
  );
};
