import { omit } from 'lodash';
import {
  calculatedUnits,
  ConversionFactors,
  CO2eConversionFactors,
  QuantityUnit,
  quantityUnits,
  CostEmissionFactorUnitLabel,
} from '../models/unit.interface';
import { mathJS } from './mathjs';
import { multiplyProperties } from './math_helpers';

type ConversionUnits =
  | 'mm'
  | 'cm'
  | 'dm'
  | 'm'
  | 'km'
  | 'inch'
  | 'ft'
  | 'yd'
  | 'mi'
  | 'nmi'
  | 'm2'
  | 'dm2'
  | 'km2'
  | 'cm2'
  | 'g'
  | 'hg'
  | 'kg'
  | 'ton'
  | 'kN'
  | 'N';

const G = 9.81;

if (
  typeof mathJS.unit !== 'function' ||
  typeof mathJS.createUnit !== 'function'
) {
  throw new Error('mathjs dependencies are not installed');
}

mathJS.createUnit('N', `${1 / G} kg`, { override: true });
mathJS.createUnit('kN', `1000 N`);

/**
 * Add calculated conversion factors like 'mm'.
 * @param conversionFactors
 * @param elementUnit
 * @returns
 */
export const applyCalculatedConversionFactors = <
  T extends ConversionFactors | CO2eConversionFactors = ConversionFactors,
>(
  conversionFactors: T,
): T => {
  const { 'm³': m3, m, MJ, l, kWh } = conversionFactors;

  const calculatedFactors: Partial<Record<QuantityUnit, number>> = {};

  // Volume
  if (typeof m3 === 'number') {
    calculatedFactors['mm'] = m3 * 1000;
    calculatedFactors['l'] = l || m3 * 1000;
  } else if (typeof l === 'number') {
    // Some products are in liter
    calculatedFactors['m³'] = l / 1000;
    calculatedFactors['mm'] = l;
  }

  // Length
  if (typeof m === 'number' && !m3) {
    calculatedFactors['mm'] = m * 1000;
  }

  // Energy
  if (typeof MJ === 'number') {
    calculatedFactors['kWh'] = MJ / 3.6;
  } else if (typeof kWh === 'number') {
    calculatedFactors['MJ'] = kWh * 3.6;
  }

  return { ...conversionFactors, ...calculatedFactors };
};

export const stripCalculatedConversionFactors = (
  conversionFactors: ConversionFactors,
): ConversionFactors => {
  return omit(conversionFactors, calculatedUnits);
};

/**
 * Convert the count to another unit maintaining the same ratio.
 * Note that ConversionFactors now has been migrated to expected format (2022-08-30)
 * Sample: {kg: 1, m³: 0.00142857}
 */
export const convertCount = (
  count: number,
  conversionFactors: ConversionFactors,
  from: QuantityUnit,
  to: QuantityUnit,
): number => {
  conversionFactors = applyCalculatedConversionFactors(conversionFactors);

  if (from === to) {
    return count;
  }
  const fromFactor = conversionFactors[from];
  const toFactor = conversionFactors[to];

  if (
    typeof fromFactor !== 'number' ||
    typeof toFactor !== 'number' ||
    toFactor === 0 ||
    fromFactor === 0
  ) {
    return 0;
  }
  return (count * toFactor) / fromFactor;
};

/**
 * Make conversionFactors to relate to the given unit.
 * Provide a count to scale the conversionFactors, if not provided it will be relaive to 1 unit of the conversionFactors.
 * If we can't convert to a unit all values will be 0
 * @param factors
 * @param toUnit
 * @param count
 * @returns
 */
export const convertConversionFactors = <
  T extends CO2eConversionFactors | ConversionFactors,
>(
  factors: T,
  toUnit: QuantityUnit,
  count = 1,
): T => {
  const to = factors[toUnit];
  return multiplyConversionFactors(factors, to ? count / to : 0);
};

export const multiplyConversionFactors = <
  T extends CO2eConversionFactors | ConversionFactors,
>(
  conversionFactors: T,
  factor: number,
): T => {
  return multiplyProperties(
    conversionFactors,
    factor,
    ...quantityUnits,
    'co2e_total',
  );
};

export const convert = (
  value: number,
  from: ConversionUnits,
  to: ConversionUnits,
): number => {
  return mathJS.unit(value, from).toNumber(to);
};

/**
 * Get densite as kg/m³
 * @param factors
 * @returns
 */
export const getDensity = (factors: ConversionFactors): number | undefined => {
  const { kg, 'm³': m3 } = factors;

  if (typeof kg === 'number' && typeof m3 === 'number') {
    return !m3 ? 0 : kg / m3;
  }
};

/**
 * Determine if cost should be in thousands SEK or not
 */
export const convertToThousandSEK = (
  value: number,
  unitLabel: CostEmissionFactorUnitLabel = 'kSEK',
): number => (unitLabel === 'kSEK' ? value / 1000 : value);
