interface WithID {
  id: number | string;
}

export const toLookup = <T extends WithID>(items: T[]): Record<T['id'], T> =>
  items.reduce(
    (acc, item) => ({
      ...acc,
      [item.id]: item,
    }),
    {} as Record<T['id'], T>,
  );

export const toBoolean = (
  bool: string | number | undefined | null | boolean,
): boolean => {
  if (!bool || bool === 'false') {
    return false;
  }
  return !!bool;
};
