import { IFactoryProductElement } from '../../../models/element_factory_helpers.interface';
import { createElementProperty } from '../../../helpers/element_property_factory_helpers';
import { getPropertyCountValueByName } from '../../../helpers/element_property_helpers';
import {
  IElementCategory,
  ElementCategoryID,
  IElementCategoryElementPropertiesFn,
  IElementCategoryElementsFn,
} from '../../../models/element_categories.interface';
import {
  ElementPropertyCountValues,
  IFactoryProperty,
} from '../../../models/element_property.interface';
import { ProductID } from '../../../models/product.interface';
import { ElementKind } from '../../../models/project.interface';
import {
  concreteTypeClimateImprovedRecord,
  concreteQualitylimateImprovedLevel1Record,
  concreteQualitylimateImprovedLevel2Record,
  concreteQualitylimateImprovedLevel3Record,
  concreteQualitylimateImprovedLevel4Record,
  concreteTypeRecord,
  ConcretePropertyName,
  ConcreteQuality,
  concreteQualityRecord,
  ConcreteType,
  reinforcementSteelProductId,
  reinforcementSteelStainlessProductId,
  concreteTypeProperty,
  climateImprovedProperty,
  ClimateImprovedLevels,
  climateImprovedLevelProperty,
  concreteQualityProperty,
  stainlessReinforcementProperty,
  reinforcementSteelAmountProperty,
} from './concrete.model';
import {
  reusedContentProperty,
  reusedContentProductId,
  ProductCategoryPropertyName,
} from '../processor.model';
import {
  DEFAULT_COUNT_AND_UNIT_WITH_QUANTITY_PROPERTIES,
  DEFAULT_QUANTITY_PROPERTIES,
} from '../../../models/element_quantities.interface';

const allProperties = [
  concreteTypeProperty,
  climateImprovedProperty,
  climateImprovedLevelProperty,
  concreteQualityProperty,
  stainlessReinforcementProperty,
  reinforcementSteelAmountProperty,
  reusedContentProperty,
];

const getProductIdFromConcreteQuality = (
  quality: ConcreteQuality | string,
  climateImprovedLevel?: string,
): ProductID => {
  let record = concreteQualityRecord;

  switch (climateImprovedLevel) {
    case ClimateImprovedLevels.Level_1:
      record = concreteQualitylimateImprovedLevel1Record;
      break;
    case ClimateImprovedLevels.Level_2:
      record = concreteQualitylimateImprovedLevel2Record;
      break;
    case ClimateImprovedLevels.Level_3:
      record = concreteQualitylimateImprovedLevel3Record;
      break;
    case ClimateImprovedLevels.Level_4:
      record = concreteQualitylimateImprovedLevel4Record;
      break;
  }

  const product_id = record[quality as ConcreteQuality];

  if (!product_id) {
    throw new Error(`Product id not found for quality ${'' + quality}`);
  }
  return product_id;
};

const getProductIdFromConcreteType = (
  type: ConcreteType | string,
  climateImproved?: boolean,
): ProductID => {
  const record = climateImproved
    ? concreteTypeClimateImprovedRecord
    : concreteTypeRecord;

  const product_id = record[type as ConcreteType];

  if (!product_id) {
    throw new Error(`Product id not found for type ${'' + type}`);
  }
  return product_id;
};

const getReinforcementSteelProductId = (stainless: boolean): ProductID =>
  stainless
    ? reinforcementSteelStainlessProductId
    : reinforcementSteelProductId;

const getElementProperties: IElementCategoryElementPropertiesFn = (element) => {
  // Short-hand to get property with default value
  const getValue = (type: ConcretePropertyName): ElementPropertyCountValues =>
    getPropertyCountValueByName(element, type, allProperties);

  const properties: IFactoryProperty[] = [
    reusedContentProperty,
    concreteTypeProperty,
  ];

  // Use default value since it will not be set first time
  const type = getPropertyCountValueByName(
    element,
    ConcretePropertyName.ConcreteType,
    allProperties,
  );

  if (type === ConcreteType.CastInPlace) {
    const reinforcementSteelAmount = getValue(
      ConcretePropertyName.ReinforcementSteel,
    );
    properties.push(
      climateImprovedLevelProperty,
      concreteQualityProperty,
      reinforcementSteelAmountProperty,
    );

    // Only add stainless steel property if there is any amount of steel
    if (reinforcementSteelAmount && +reinforcementSteelAmount > 0) {
      properties.push(stainlessReinforcementProperty);
    }
  } else {
    properties.push(climateImprovedProperty);
  }
  return properties.map((p) => createElementProperty(p));
};

const generated = true;

const getChildElements: IElementCategoryElementsFn = (element) => {
  const elements: IFactoryProductElement[] = [];

  // Short-hand to get property with default value
  const getValue = (
    type: ConcretePropertyName | ProductCategoryPropertyName,
  ): ElementPropertyCountValues =>
    getPropertyCountValueByName(element, type, allProperties);

  const reusedFraction = +getValue(ProductCategoryPropertyName.ReusedContent);
  const type = getValue(ConcretePropertyName.ConcreteType) as ConcreteType;
  const climateImproved = getValue(ConcretePropertyName.ClimateImproved);
  const climateImprovedLevel = getValue(
    ConcretePropertyName.ClimateImprovedLevel,
  );

  if (typeof type !== 'string') {
    throw new Error('ConcreteType is not a string');
  }
  if (typeof climateImproved !== 'boolean') {
    throw new Error('Climate improved is not a boolean');
  }
  if (typeof climateImprovedLevel !== 'string') {
    throw new Error('Climate improved Level is not a string');
  }

  if (type === ConcreteType.CastInPlace) {
    const quality = getValue(ConcretePropertyName.ConcreteQuality);
    const stainlessReinforcement = getValue(
      ConcretePropertyName.StainlessReinforcement,
    );
    const reinforcementSteelAmount = getValue(
      ConcretePropertyName.ReinforcementSteel,
    );

    if (typeof quality !== 'string') {
      throw new Error('ConcreteQuality is not a string');
    }
    if (typeof stainlessReinforcement !== 'boolean') {
      throw new Error('Climate improved is not a boolean');
    }
    if (typeof reinforcementSteelAmount !== 'number') {
      throw new Error('reinforcement steel is not a number');
    }

    const concrete_id = getProductIdFromConcreteQuality(
      quality,
      climateImprovedLevel,
    );

    const steel_id = getReinforcementSteelProductId(stainlessReinforcement);

    elements.push({
      kind: ElementKind.Product,
      product_id: concrete_id,
      count: `(${
        1 - reusedFraction
      }) * (mass - ${reinforcementSteelAmount} * volume)`,
      unit: 'kg',
      generated,
    });

    // Always add steel since list will hide it if 0
    elements.push({
      kind: ElementKind.Product,
      product_id: steel_id,
      count: `(${1 - reusedFraction}) * volume * ${reinforcementSteelAmount}`,
      unit: 'kg',
      generated,
    });
  } else {
    const product_id = getProductIdFromConcreteType(type, climateImproved);
    elements.push({
      kind: ElementKind.Product,
      product_id,
      count: `(${1 - reusedFraction}) * mass`,
      unit: 'kg',
      generated,
    });
  }
  // Add reused content last for density to work
  elements.push({
    kind: ElementKind.Product,
    product_id: reusedContentProductId,
    count: `(${reusedFraction}) * mass`,
    unit: 'kg',
    generated,
  });

  return elements;
};

const readyMixConreteDensity = 'densityArray[1]';
const steelRebarDensity = 'densityArray[2]';
export const castInPlaceDensity = `${ConcretePropertyName.ReinforcementSteel} * (1 - ${readyMixConreteDensity}/${steelRebarDensity}) + ${readyMixConreteDensity}`;
export const otherTypeDesity = `densityArray[1]`;
export const concreteDensity = `${ConcretePropertyName.ConcreteType} == "${ConcreteType.CastInPlace}" ? ${castInPlaceDensity} : ${otherTypeDesity}`;

export const concrete: IElementCategory = {
  ...DEFAULT_COUNT_AND_UNIT_WITH_QUANTITY_PROPERTIES,
  id: ElementCategoryID.Concrete,
  name: 'Concrete',
  color: '#91A6CE',
  getChildElements,
  getQuantityProperties: () => {
    return {
      ...DEFAULT_QUANTITY_PROPERTIES,
      density: {
        fallbackCount: concreteDensity,
      },
    };
  },

  getElementProperties,
};
