import { MouseEvent, useCallback, useRef } from 'react';
import {
  IEventHandlerOptions,
  eventHandler,
} from '../../../shared/helpers/event_helpers';
import { useIsomorphicLayoutEffect } from 'usehooks-ts';

/**
 * Create a mouse event handler that can be used in JSX.
 * @param fn
 */
export function useMouseEventCallback<R>(
  fn: ((e: MouseEvent) => R) | undefined,
  options?: IEventHandlerOptions,
): (e: MouseEvent) => R | undefined {
  return useEventCallback(fn, options);
}

/**
 * Create a keyboard event handler that can be used in JSX.
 * @param fn
 */
export function useKeyboardEventCallback<R>(
  fn: ((e: KeyboardEvent) => R) | undefined,
  options?: IEventHandlerOptions,
): (e: KeyboardEvent) => R | undefined {
  return useEventCallback(fn, options);
}

/**
 * Create an event handler that can be used in JSX.
 * Based on useEventCallback from 'usehooks-ts'
 * @param fn
 */
function useEventCallback<E extends MouseEvent | KeyboardEvent, R>(
  fn: ((e: E) => R) | undefined,
  options?: IEventHandlerOptions,
): (e: E) => R | undefined {
  const ref = useRef<typeof fn>(() => {
    throw new Error('Cannot call an event handler while rendering.');
  });
  const {
    stopPropagation,
    preventDefault,
    ignoreInputEvents,
    ignoreModalEvents,
  } = options || {};

  useIsomorphicLayoutEffect(() => {
    ref.current = fn;
  }, [fn]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    (e) => {
      return eventHandler<E, R>(ref.current, {
        ignoreInputEvents,
        preventDefault,
        stopPropagation,
        ignoreModalEvents,
      })(e);
    },

    [ignoreInputEvents, preventDefault, stopPropagation, ignoreModalEvents],
  ) as (e: E) => R;
}
