import React, {
  FC,
  useCallback,
  useMemo,
  FocusEvent,
  BaseSyntheticEvent,
  useEffect,
} from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { useForm, Controller, ControllerRenderProps } from 'react-hook-form';
import * as Yup from 'yup';
import { Project } from '../../../shared/models/project.interface';
import SettingsForm from './SettingsForm';
import amplitudeLog from '../amplitude';
import NodonTextField from './NodonTextField';
import ElementClose from './SidePanel/Element/ElementClose';
import ProjectSettingsActionMenu from './ProjectSettingsActionMenu';
import { useIsReadonly, useUserIsOwner } from '../hooks/user.hook';
import { useProjectState } from '../store/project';

interface ProjectSettingsProps {
  project: Project;
  isNewProject: boolean;
  setIsNewProject: (isNewProject: boolean) => void;
}
export interface ProjectSettingsFormData {
  name: string;
  description: string;
}

const ProjectSettings: FC<ProjectSettingsProps> = ({
  project,
  isNewProject,
  setIsNewProject,
}) => {
  const { updateProjectDetails } = useProjectState('updateProjectDetails');
  const isReadonly = useIsReadonly();
  const isOwner = useUserIsOwner();
  const schema = Yup.object({
    name: Yup.string().trim().required('Name is required'),
  });

  const defaultValues: ProjectSettingsFormData = useMemo(
    () => ({
      name: project.name,
      description: project.description ?? '',
    }),
    [project],
  );

  const { control, handleSubmit, formState, reset } =
    useForm<ProjectSettingsFormData>({
      defaultValues,
      resolver: yupResolver(schema),
      mode: 'onChange',
      reValidateMode: 'onChange',
    });

  useEffect(() => {
    if (project) {
      reset(defaultValues);
    }
  }, [reset, defaultValues, project]);

  const { errors } = formState;

  const submit = useCallback(
    (event: BaseSyntheticEvent<Record<string, unknown>, any, any>) => {
      handleSubmit(({ name, description }) => {
        updateProjectDetails({
          projectId: project.id,
          data: { name, description },
        });

        name !== project.name
          ? amplitudeLog('Project Name Set', {
              ProjectName: name,
            })
          : amplitudeLog('Project Description Set', {
              ProjectDescription: description,
            });
      })(event);
    },
    [handleSubmit, project, updateProjectDetails],
  );

  const onFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>): void => {
      isNewProject && e.currentTarget.select();
      setIsNewProject(false);
    },
    [isNewProject, setIsNewProject],
  );

  const onBlur = useCallback((e: FocusEvent<HTMLInputElement>): void => {
    e.currentTarget.blur();
  }, []);

  const renderNameInput = useCallback(
    ({
      field: { ref, ...rest }, // eslint-disable-line @typescript-eslint/no-unused-vars
    }: {
      field: ControllerRenderProps<ProjectSettingsFormData, 'name'>;
    }) => (
      <NodonTextField
        {...rest}
        label="Name"
        fullWidth
        required
        error={!!errors.name}
        helperText={errors.name?.message}
        onFocus={onFocus}
        onBlur={onBlur}
        autoFocus
        disabled={!isOwner && project.locked}
      />
    ),
    [errors.name, onFocus, onBlur, isOwner, project.locked],
  );

  const renderDescriptionInput = useCallback(
    ({
      field: { ref, ...rest }, // eslint-disable-line @typescript-eslint/no-unused-vars
    }: {
      field: ControllerRenderProps<ProjectSettingsFormData, 'description'>;
    }) => (
      <NodonTextField
        {...rest}
        multiline
        minRows={4}
        label="Description"
        error={!!errors.description}
        helperText={errors.description?.message}
        disabled={isReadonly}
      />
    ),
    [errors.description, isReadonly],
  );

  return (
    <Box>
      <ElementClose />
      <SettingsForm formState={formState} submit={submit}>
        <Box display="flex" justifyContent="space-between">
          <Controller control={control} name="name" render={renderNameInput} />
          <ProjectSettingsActionMenu project={project} />
        </Box>
        <Controller
          control={control}
          name="description"
          render={renderDescriptionInput}
        />
      </SettingsForm>
    </Box>
  );
};

export default ProjectSettings;
