import React, { ReactElement, useMemo } from 'react';
import { IRowCellInput } from './RowCell';
import { Box, BoxProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { asArray } from '../../../../shared/helpers/array_helpers';
import { omit, omitUndefined } from '../../../../shared/helpers/object_helpers';
import {
  ArrayOrSingle,
  PartialRecord,
} from '../../../../shared/models/type_helpers.interface';
import { CxArg } from 'tss-react/types';

const rowClassNames = [
  'deactivated',
  'disabled',
  'hover',
  'row',
  'selected',
] as const;

type CellElement = ReactElement<IRowCellInput>;
type UnwantedValues = undefined | null | false | '';
export type RowClassNames = (typeof rowClassNames)[number];
type ClassOverrides = PartialRecord<RowClassNames, string>;

export interface IRowInput
  extends Omit<BoxProps, 'height' | 'children' | 'classes' | 'padding'> {
  //   content: (typeof RowCell)[];
  children?: ArrayOrSingle<CellElement | UnwantedValues>;
  classes?: ClassOverrides;
  deactivated?: boolean;
  disabled?: boolean;
  height?: string | number;
  hover?: boolean;
  selected?: boolean;

  /**
   * Spacing number. String will be converted to px. Number will use material ui spacing
   */
  spacing?: number | string;

  /**
   * Turn on/off padding left and right for the row. Boolean will reuse spacing prop. Number will use material ui spacing
   */
  padding?: boolean | number;
}

export const Row: React.FC<IRowInput> = (props) => {
  const {
    deactivated,
    disabled,
    height,
    hover,
    padding,
    selected,
    spacing = 0,
  } = props;
  const children = asArray(props.children).filter((c): c is CellElement => !!c);
  const { classes, cx } = useStyles();

  // Allow for additional props to be passed to the Box component (like listeners, etc)
  const boxProps = omit(
    props,
    'children',
    'classes',
    'deactivated',
    'disabled',
    'height',
    'hover',
    'padding',
    'spacing',
  );

  // Get class names for the row as a concatenated string
  const className = useMemo(() => {
    // Injected classes
    const external = omitUndefined(props.classes ?? {});

    const classList: CxArg[] = [classes.row, props.classes?.row];
    if (disabled) {
      classList.push(classes.disabled, external.disabled);
    } else {
      classList.push(deactivated && [props.deactivated && classes.deactivated]);
      classList.push(hover && [classes.hover, external.hover]);
      classList.push(selected && [classes.selected, external.selected]);
    }
    return cx(...classList);
  }, [
    classes.deactivated,
    classes.disabled,
    classes.hover,
    classes.row,
    classes.selected,
    cx,
    deactivated,
    disabled,
    hover,
    props.classes,
    props.deactivated,
    selected,
  ]);

  const paddingValue = padding === true ? spacing : padding || 0;

  return (
    <Box
      {...boxProps}
      paddingLeft={paddingValue}
      paddingRight={paddingValue}
      className={className}
      height={height}
      columnGap={spacing}
    >
      {children}
    </Box>
  );
};

const useStyles = makeStyles()(() => ({
  row: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    maxWidth: '100%',
    transition:
      'background-color 0.2s ease-in-out, opacity 0.2s ease-in-out, border-color 0.2s ease-in-out',
    boxSizing: 'border-box', // Include borders in height
    overflow: 'visible',
  },
  disabled: {
    opacity: 0.5,
    userSelect: 'none',
  },
  deactivated: {
    opacity: 0.5,
  },
  selected: {
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  hover: {
    zIndex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
  },
}));
