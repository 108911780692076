import React, { ChangeEventHandler, useCallback, useMemo } from 'react';
import {
  TextField,
  MenuItem,
  IconButton,
  Tooltip,
  Divider,
  ListItemIcon,
  SelectProps,
  SxProps,
} from '@mui/material';
import {
  Product,
  ProductID,
} from '../../../../../../../shared/models/product.interface';
import ClearIcon from '@mui/icons-material/Clear';
import { Add, KeyboardArrowRight } from '@mui/icons-material';
import { useIsReadonly } from '../../../../../hooks/user.hook';

interface EPDMenuProps {
  menuValue: ProductID;
  epds: Product[];
  onMenuChange: (id: ProductID) => void;
  onRemoveMappingClick: (id: ProductID) => void;
}

const EPDMenu: React.FC<EPDMenuProps> = ({
  menuValue,
  epds,
  onMenuChange,
  onRemoveMappingClick,
}) => {
  const readonly = useIsReadonly();

  const selectProps = useMemo<SelectProps>(
    () => ({
      renderValue: (value: unknown) =>
        epds.find(({ id }) => id === value)?.name,
    }),
    [epds],
  );
  const textFieldStyles = useMemo<SxProps>(
    () => ({ mt: menuValue ? 2 : 0 }),
    [menuValue],
  );

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => {
      onMenuChange(value);
    },
    [onMenuChange],
  );

  const handleIconClick = useCallback(
    (id: string) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      onRemoveMappingClick(id);
    },
    [onRemoveMappingClick],
  );

  return (
    <TextField
      disabled={readonly}
      select
      fullWidth
      size="small"
      label="Select EPD"
      value={menuValue}
      onChange={handleChange}
      SelectProps={selectProps}
      sx={textFieldStyles}
    >
      <MenuItem value="none">None</MenuItem>
      <Divider />

      {epds.map(({ id, name }) => (
        <MenuItem
          key={id}
          value={id}
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          {name}
          <Tooltip title="Remove EPD mapping" disableInteractive>
            <IconButton
              size="small"
              sx={iconBtnStyles}
              onClick={handleIconClick(id)}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </MenuItem>
      ))}

      {!!epds.length && <Divider />}
      <MenuItem
        value="other"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          '& .MuiListItemIcon-root': { minWidth: 26 },
        }}
      >
        Other EPD
        <ListItemIcon sx={{ justifyContent: 'center' }}>
          <KeyboardArrowRight fontSize="small" />
        </ListItemIcon>
      </MenuItem>
      <MenuItem value="new">
        <ListItemIcon>
          <Add fontSize="small" />
        </ListItemIcon>
        New EPD
      </MenuItem>
    </TextField>
  );
};

const iconBtnStyles: SxProps = { marginLeft: 2 } as const;

export default EPDMenu;
