import React, { useCallback, FC, useEffect } from 'react';
import { Box, MenuItem, Select, SelectProps, Tooltip } from '@mui/material';
import { getKeys } from '../../../../shared/helpers/object_helpers';
import {
  sortByAlternatives,
  SortByAlternative,
} from '../../../../shared/models/sort.interface';
import amplitudeLog from '../../amplitude';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { useSortBy, setSortBy } from '../../hooks/sort.hook';

const SortSelect: FC = () => {
  const sortBy = useSortBy();

  const handleChange: SelectProps<SortByAlternative>['onChange'] = useCallback(
    (e: SelectChangeEvent<SortByAlternative>) => {
      setSortBy(e.target.value as SortByAlternative);

      e.preventDefault();
      e.stopPropagation();
      amplitudeLog('Elements Sort', {
        Sort: e.target.value,
      });
    },
    [],
  );

  useEffect(() => {
    if (!sortBy) {
      setSortBy('name');
    }
  }, [sortBy]);

  return (
    <Box
      display="flex"
      flexGrow={1}
      justifyContent="flexEnd"
      alignItems="center"
    >
      <Box flexGrow={0}>
        <Tooltip title={'Sort elements by ...'} placement="right">
          <Select
            labelId="sort_by_label"
            variant="standard"
            value={sortBy}
            onChange={handleChange}
          >
            {getKeys(sortByAlternatives).map((sortOption) => (
              <MenuItem key={sortOption} value={sortOption}>
                {sortByAlternatives[sortOption]}
              </MenuItem>
            ))}
          </Select>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default SortSelect;
