import { useMemo } from 'react';
import {
  flattenSortElements,
  sortElements,
} from '../../../shared/helpers/sort_helpers';
import {
  IElement,
  OneOfChildElements,
  OneOfListElements,
} from '../../../shared/models/project.interface';
import { useVersionConversionFactorQuantitiesRecord } from './useCO2Calculations';
import { useElementChildren } from './useElement';
import { SortByAlternative } from '../../../shared/models/sort.interface';
import { getSelectedVersion } from '../store/ui';
import { isEmpty } from 'lodash';
import { createLocalStorageStore } from '../helpers/local-storage.helpers';

export const { useStore: useSortBy, set: setSortBy } =
  createLocalStorageStore<SortByAlternative>('sort_by', 'name');

export const useFlattenSortedElements = (element: IElement) => {
  const sortBy = useSortBy();
  const version = getSelectedVersion();
  const quantitiesRecord = useVersionConversionFactorQuantitiesRecord();
  const children = useElementChildren(element);

  return useMemo(
    () =>
      !sortBy || !version?.products || isEmpty(quantitiesRecord)
        ? children
        : flattenSortElements(
            sortBy,
            version.products,
            quantitiesRecord,
            ...children,
          ),
    [version?.products, quantitiesRecord, sortBy, children],
  );
};

export const useSortedElements = <T extends OneOfListElements = IElement>(
  elements: T[],
): T[] => {
  const sortBy = useSortBy();
  const version = getSelectedVersion();
  const quantitiesRecord = useVersionConversionFactorQuantitiesRecord();

  return useMemo(
    () =>
      !sortBy || !version?.products || isEmpty(quantitiesRecord)
        ? elements
        : sortElements(elements, {
            sortBy,
            quantitiesRecord,
            productLookup: version.products,
          }),
    [elements, quantitiesRecord, sortBy, version?.products],
  );
};

export const useSortedChildElements = <T extends OneOfListElements>(
  element: T,
): OneOfChildElements<T>[] => {
  const children = useElementChildren(element);
  return useSortedElements(children);
};
