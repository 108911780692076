import React, { useCallback, useMemo } from 'react';
import {
  Add,
  AddCircleOutline,
  BorderAll,
  DeleteForever,
  FileCopy,
} from '@mui/icons-material';
import {
  ElementKind,
  OneOfElements,
} from '../../../../../shared/models/project.interface';
import { isBuildingVersionElement } from '../../../../../shared/helpers/recursive_element_helpers';
import amplitudeLog from '../../../amplitude';
import { useErrorSnackbar } from '../../../hooks/snackbar.hook';
import { useVersions } from '../../../hooks/useElement';
import { useExportSpreadsheet } from '../../../hooks/useExportSpreadsheet';
import { useIsReadonly } from '../../../hooks/user.hook';
import {
  useAddVersion,
  useAddElement,
  useRemoveElements,
} from '../../../store/project';
import { KebabMenu } from '../../kebab-menu/KebabMenu';

interface IBuildingVersionKebabMenuInput {
  version: OneOfElements;
}

const BuildingVersionKebabMenu: React.FC<IBuildingVersionKebabMenuInput> = ({
  version,
}) => {
  const addVersion = useAddVersion();
  const addElement = useAddElement();
  const removeElements = useRemoveElements({ showConfirm: true });
  const exportSpreadsheet = useExportSpreadsheet();
  const errorSnackbar = useErrorSnackbar();

  const readonly = useIsReadonly();
  const versions = useVersions();
  const { id } = version;

  if (!id) {
    throw new Error('Element ID is required');
  }

  const handleRemoveElement = useCallback(() => {
    return removeElements(id);
  }, [id, removeElements]);

  const items = useMemo(
    () => [
      {
        onClick: () => {
          amplitudeLog('Version New', {
            ElementID: id,
          });
          return addVersion();
        },
        icon: <AddCircleOutline color="secondary" />,
        id: 'projectVersion.new',
        defaultMessage: 'New Version',
      },
      {
        onClick: () => {
          amplitudeLog('Element New', {
            ElementID: id,
          });
          return addElement(
            version,
            { kind: ElementKind.Element },
            { placement: 'first' },
          );
        },
        icon: <Add color="secondary" />,
        id: 'element.new',
        defaultMessage: 'New Element',
      },
      {
        onClick: () => {
          if (version.kind !== ElementKind.Version) {
            return;
          }
          amplitudeLog('Version Duplicate', {
            ElementID: id,
          });
          return addVersion(version);
        },
        icon: <FileCopy color="secondary" />,
        id: 'projectVersion.duplicate',
        defaultMessage: 'Duplicate version',
      },

      {
        onClick: () => {
          amplitudeLog('Version Export Spreadsheet', {
            ElementID: id,
          });
          try {
            return errorSnackbar(() => exportSpreadsheet());
          } catch (e) {
            console.error(e);
          }
        },
        icon: <BorderAll color="secondary" />,
        id: 'projectVersion.export_spreadsheet',
        defaultMessage: 'Export spreadsheet',
        disabled: readonly,
      },

      {
        onClick: handleRemoveElement,
        icon: <DeleteForever color="secondary" />,
        id: 'projectVersion.remove',
        defaultMessage: 'Delete version',
        disabled: versions.length < 2 || !isBuildingVersionElement(version),
      },
    ],
    [
      addElement,
      addVersion,
      errorSnackbar,
      exportSpreadsheet,
      handleRemoveElement,
      id,
      readonly,
      version,
      versions.length,
    ],
  );

  return (
    <KebabMenu
      id={id}
      items={items}
      tooltipTitle="Version of project"
    ></KebabMenu>
  );
};

export default BuildingVersionKebabMenu;
