import React, {
  ReactNode,
  FC,
  useCallback,
  useEffect,
  FocusEventHandler,
} from 'react';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FieldValues, FormState } from 'react-hook-form';
import {
  UnsavedChangesProvider,
  useUnsavedChanges,
} from '../providers/UnsavedChangesProvider';
import { ProjectSettingsFormData } from './ProjectSettings';
import { GeometrySettingsFormData } from './SidePanel/Insights/GeometrySettings';

interface SettingsFormProps<FormData extends FieldValues> {
  children?: ReactNode;
  formState: FormState<FormData>;
  extraPadding?: boolean;
  submit: (event: any) => void;
}

export const SettingsForm: FC<
  | SettingsFormProps<ProjectSettingsFormData>
  | SettingsFormProps<GeometrySettingsFormData>
> = (props) => {
  return (
    <UnsavedChangesProvider>
      <ActualForm {...props} />
    </UnsavedChangesProvider>
  );
};

const ActualForm: FC<
  | SettingsFormProps<ProjectSettingsFormData>
  | SettingsFormProps<GeometrySettingsFormData>
> = ({
  children,
  formState: { dirtyFields, isSubmitting, isSubmitted },
  extraPadding,
  submit,
}) => {
  const { classes, cx } = useStyles();
  const { setUnsavedChanges } = useUnsavedChanges();

  const submitIsAllowed = Object.entries(dirtyFields).length;

  const handleBlur: FocusEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      if (submitIsAllowed) {
        submit(event);
      }
    },
    [submitIsAllowed, submit],
  );

  useEffect(() => {
    const unsaved =
      Object.entries(dirtyFields).length > 0 && !isSubmitting && !isSubmitted;

    setUnsavedChanges(unsaved);
  }, [dirtyFields, isSubmitted, isSubmitting, setUnsavedChanges]);

  return (
    <form
      className={cx(
        classes.form,
        classes.formContainers,
        extraPadding && classes.extraPadding,
      )}
      onBlur={handleBlur}
    >
      <Box
        display="flex"
        flexDirection="column"
        className={classes.numberInputContainer}
      >
        {children}
      </Box>
    </form>
  );
};

const useStyles = makeStyles()(({ spacing }) => ({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(2),
    overflow: 'auto',
  },

  extraPadding: {
    padding: spacing(6),
  },

  formContainers: {
    '& > * ~ *': {
      marginTop: spacing(6),
      display: 'flex',
      justifyContent: 'space-between',
    },
  },

  numberInputContainer: {
    '& > *': {
      marginBottom: spacing(3),
    },
    '& :last-child': {
      marginBottom: 0,
    },
  },
}));

export default SettingsForm;
