import React, { useState, useCallback } from 'react';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Theme,
  Typography,
  Accordion,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FormattedMessage } from 'react-intl';
import { formatThousands } from '../../../../../shared/helpers/math_helpers';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ElementResultItem from './ElementResultItem';
import { IResult, IResultCapacity } from '../../../hooks/useResults';

export interface ElementResultProps {
  results?: (IResultCapacity | IResult)[];
}

const useStyles = makeStyles()((theme: Theme) => ({
  accordionSummary: {
    // paddingRight: 0,
    // paddingLeft: 0,
  },

  textAcceptable: {
    color: theme.palette.success.main,
  },

  textUnacceptable: {
    color: theme.palette.error.main,
  },

  bottomDivider: {
    borderBottom: '1px solid ' + theme.palette.divider,
  },
}));

const ElementResult: React.FC<ElementResultProps> = ({ results }) => {
  const [resultExpanded, setResultExpanded] = useState(true);
  const [advancedExpanded, setAdvancedExpanded] = useState(false);
  const { classes } = useStyles();

  const handleResultAccordionChange = useCallback(
    (_e: React.SyntheticEvent, expanded: boolean) => {
      setResultExpanded(expanded);
    },
    [],
  );

  const handleAdvancedAccordionChange = useCallback(
    (_e: React.SyntheticEvent, expanded: boolean) => {
      setAdvancedExpanded(expanded);
    },
    [],
  );

  if (!results?.length) {
    return null;
  }

  return (
    <Box mt={4}>
      <Accordion
        elevation={0}
        expanded={resultExpanded}
        onChange={handleResultAccordionChange}
      >
        <AccordionSummary
          classes={{
            root: classes.accordionSummary,
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="subtitle2">Result</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            {results.filter(isResultCapacity).map((result) => (
              <ElementResultItem
                acceptable={result.acceptable}
                label={result.label}
                unit={result.unit}
                capacity={result.capacity}
                condition={result.condition}
                key={`result_item.${result.label}`}
              ></ElementResultItem>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        elevation={0}
        expanded={advancedExpanded}
        onChange={handleAdvancedAccordionChange}
      >
        <AccordionSummary
          classes={{
            root: classes.accordionSummary,
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="subtitle2">Advanced</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            {results.filter(isResult).map((result, index) => (
              <Box
                mb={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                key={result.label}
              >
                <Box>
                  <Typography variant="body2">
                    <FormattedMessage
                      id={`result.label-${index}`}
                      defaultMessage={result.label}
                    />
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1">
                    <FormattedMessage
                      id={`result.value-${index}`}
                      defaultMessage={formatValue(result.value)}
                    />
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

const isResultCapacity = (
  result: IResult | IResultCapacity,
): result is IResultCapacity =>
  'capacity' in result && typeof result.capacity === 'number';

const isResult = (result: IResult | IResultCapacity): result is IResult =>
  typeof result === 'object' && !isResultCapacity(result);

const formatValue = (value?: string | number): string => {
  return typeof value === 'number' ? formatThousands(value) : value ?? '-';
};

export default ElementResult;
