import { IElement } from '../../../shared/models/project.interface';
import { useSelectedVersion } from '../store/ui';
import { getNewFallbackName } from '../../../shared/helpers/project_helpers';
import { useUpdateElements } from '../store/project';
import { getElementCategory } from '../../../shared/helpers/element_category_helpers';

type ElementFallbackName = (
  element: IElement,
  categoryID?: string,
  name?: string,
) => void;

export const useElementFallbackName = (): ElementFallbackName => {
  const selectedVersion = useSelectedVersion();
  const updateElements = useUpdateElements();

  return (element, categoryID?, name?): void => {
    if (name === '' || (name === undefined && element.name === '')) {
      const categoryName =
        categoryID === undefined
          ? getElementCategory(element)?.name
          : getElementCategory(categoryID)?.name;

      const baseName = `${categoryName ? categoryName : 'Element'} 1`;

      const fallbackName =
        selectedVersion && getNewFallbackName(selectedVersion, baseName);

      void updateElements({
        id: element.id,
        name: '',
        fallbackName,
      });
    }
  };
};
