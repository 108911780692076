import { capitalize, mapValues } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { utils, writeFile, WorkSheet, CellObject } from 'xlsx';
import { cloneMoveItem } from '../../../shared/helpers/array_helpers';
import { getDensity } from '../../../shared/helpers/conversion_helpers';
import { getElementName } from '../../../shared/helpers/element_helpers';
import { roundToDecimals } from '../../../shared/helpers/math_helpers';
import {
  getProductCategories,
  getOriginalProductId,
  isBoverketProduct,
  isNodonProduct,
} from '../../../shared/helpers/product_helpers';
import {
  flattenElements,
  getAllBuildingVersions,
  getPathToElement,
  isProductElement,
} from '../../../shared/helpers/recursive_element_helpers';
import { getKeys } from '../../../shared/helpers/object_helpers';
import {
  indentString,
  findFreeName,
} from '../../../shared/helpers/string_helpers';
import {
  IBuildingVersion,
  IElement,
} from '../../../shared/models/project.interface';
import { ConversionFactorQuantityRecord } from '../../../shared/models/unit.interface';
import { useProjectConversionFactorQuantitiesRecord } from './useCO2Calculations';
import { getElementTotalCount } from '../../../shared/helpers/expression_solving_helpers';
import { getEnrichedProject, getProject } from '../store/project';
import { getSelectedVersion } from '../store/ui';

const format = 'yyyy-MM-dd';

export const useExportSpreadsheet = (): (() => void) => {
  const quantityRecord = useProjectConversionFactorQuantitiesRecord();

  const handleExportSpreadsheet = useCallback(() => {
    const project = getEnrichedProject(getProject());
    const selectedVersion = getSelectedVersion();

    const timestamp = DateTime.now().toFormat(format);
    if (project && selectedVersion && quantityRecord) {
      const workbook = utils.book_new();

      // Move selected version to first position
      const versions = cloneMoveItem(
        getAllBuildingVersions(project),
        selectedVersion,
        0,
      );

      const versionsWithUniqueNames = getVersionsWithUniqueNames(versions);

      versionsWithUniqueNames.forEach((version) => {
        const sheet = versionToSheet(version, quantityRecord);
        utils.book_append_sheet(workbook, sheet, version.name);
      });

      const fileName = `${project.name.slice(0, 50)} ${timestamp}.xlsx`;
      writeFile(workbook, fileName);
    }
  }, [quantityRecord]);
  return handleExportSpreadsheet;
};

const versionToSheet = (
  version: IBuildingVersion,
  quantityRecord: ConversionFactorQuantityRecord,
): WorkSheet => {
  const elements = version?.elements ?? [];

  const rows = flattenElements(...elements).map((element) => {
    const factors = quantityRecord[element.id];

    if (!factors) {
      console.info(
        'Could not find conversion factors (hint: it or its parent might be deactivated) for element',
        element,
      );
    }

    const roundedValues = mapValues(factors, (value) =>
      typeof value === 'number' ? round(value) : value,
    );

    const unit = (element as IElement).unit;
    const quantity = round(getElementTotalCount(version, element));
    const depth = getPathToElement(version, element).length - 1;
    const name = indentString(getElementName(element, version.products), depth);

    const density = factors ? getDensity(factors) : undefined;

    const elementValues = {
      Element: name,
      Kvantitet: quantity,
      Enhet: unit,
      'CO2e A1-A3': roundedValues['co2e_A1-A3'],
      'CO2e A4': roundedValues['co2e_A4'],
      'CO2e A5.1': roundedValues['co2e_A5'],
      'CO2e Total (A1-A5)': roundedValues['co2e_total'],
      'Vikt (kg)': roundedValues.kg,
      'Kostnad (SEK)': roundedValues['sek_A1-A3'],
      'Densitet (kg/m³)': density ? round(density) : density,
      Källa: '',
      'Källa ID': '',
      'Källa URL': '',
      'Kategori Boverket': '',
      'Kategori BK04': '',
      'Kategori ILCD': '',
    };

    const product =
      isProductElement(element) && version.products[element.product_id];

    if (product) {
      const id = getOriginalProductId(product);
      const isBoverket = isBoverketProduct(product);
      const isNodon = isNodonProduct(product);
      const source = capitalize(product.source);

      elementValues['Källa'] = source;
      elementValues['Källa ID'] = id;

      elementValues['Kategori Boverket'] = getProductCategories(
        product,
        'Boverket',
      ).join(', ');
      elementValues['Kategori BK04'] = getProductCategories(
        product,
        'BK04',
      ).join(', ');
      elementValues['Kategori ILCD'] = getProductCategories(
        product,
        'ILCD',
      ).join(', ');

      if (isBoverket || isNodon) {
        elementValues['Källa URL'] =
          `https://www.boverket.se/sv/klimatdeklaration/klimatdatabas/klimatdatabas/Search/?version=3&climatedatabasequery=${id}`;
      }
    }

    return elementValues;
  });
  const sheet = utils.json_to_sheet(rows, { cellStyles: true });
  const columns = getKeys(rows[0]);
  const sourceUrlIndex = columns.indexOf('Källa URL');

  // Make hyperlinks of source urls
  rows.forEach((row, index) => {
    const cell = getCell(sheet, sourceUrlIndex, index);
    utils.cell_set_hyperlink(
      cell,
      row['Källa URL'],
      'Länk till Boverkets databas',
    );
  });

  return sheet;
};

const round = (value: number): number => roundToDecimals(value, 2);

/**
 * Sheet name can be max 31 characters and not have certain characters like ? and /
 * @param version
 * @returns
 */
const getCleanSheetName = (name: string) =>
  name
    .replace(/[^\w .,:åäö]/gi, '')
    .toLowerCase()
    .substring(0, 28); // 28 to make room for version number if name already exists

/**
 * Get versions with unique and clean sheet names
 * @param versions
 * @returns
 */
const getVersionsWithUniqueNames = (
  versions: IBuildingVersion[],
): IBuildingVersion[] => {
  const versionsWithUniqueNames: IBuildingVersion[] = [];

  versions.forEach((version) => {
    const cleanSheetName = getCleanSheetName(version.name);
    if (
      versionsWithUniqueNames.length &&
      versionsWithUniqueNames.some(({ name }) => name === cleanSheetName)
    ) {
      versionsWithUniqueNames.push({
        ...version,
        name: findFreeName(
          versionsWithUniqueNames.map((uv) => uv.name),
          cleanSheetName,
        ),
      });
      return;
    }
    versionsWithUniqueNames.push({
      ...version,
      name: getCleanSheetName(version.name),
    });
  });

  return versionsWithUniqueNames;
};

const getCell = (sheet: WorkSheet, col: number, row: number): CellObject => {
  const cell = sheet[getCellName(col, row)];
  if (!cell) {
    throw new Error('Cell not found');
  }
  return cell as CellObject;
};

const getCellName = (col: number, row: number, hasHeader = true): string => {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  return `${letters[col]}${row + (hasHeader ? 2 : 1)}`;
};
